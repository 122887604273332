import React, { Suspense, lazy } from 'react';
import ct from 'classnames';
import ComponentLoading from 'Components/reusable/loading/Loading';
import './style.scss';

const DropdownMenu = lazy(() => import('Components/reusable/dropdown'));

const SectionWrapper = ({
    children,
    heading,
    isBackButtonShow,
    isDropdownShow,
    setSelectContainer,
    links = [],
    isEditPricing,
    isAccordion,
}) => {
    return (
        <div className={ct('setting-wrapper', { ['top-0']: isAccordion, ['p-4']: !isAccordion })}>
            <div className="d-flex justify-content-between mb-2">
                <div
                    className={ct('d-flex align-items-baseline', {
                        ['justify-content-center']: isEditPricing,
                        ['w-100']: isEditPricing,
                    })}>
                    {isBackButtonShow && (
                        <i
                            className="fas fa-angle-left"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSelectContainer('paymentMethod')}></i>
                    )}
                    <p className="heading mb-0">{heading}</p>
                </div>

                {isDropdownShow && (
                    <Suspense fallback={<ComponentLoading />}>
                        <DropdownMenu links={links} setContainer={setSelectContainer}>
                            <img
                                src="/assets/settings/3dots.png"
                                style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                                alt="img"
                            />
                        </DropdownMenu>
                    </Suspense>
                )}
            </div>

            {children}
        </div>
    );
};

export default SectionWrapper;
