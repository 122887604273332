import api from '../util/instance';
import { urls } from './Api';

export const markDefaultCard = async (id, payload = {}) => await api.post(`${urls.markDefaultCard}/${id}`, payload);

export const addPaymentCard = async (payload = {}) => await api.post(`${urls.addCard}`, payload);

export const getDefaultBrokerageApi = async () => await api.get(`${urls.getDefaultBroker}`);

export const brokerageDetails = async () => await api.get(`${urls.brokerageDetails}`);

export const setDefaultBrokerage = async (id, payload = {}) =>
    await api.post(`${urls.setDefaultBrokerage}/${id}`, payload);

export const updateProfile = async (payload = {}) => await api.post(urls.updateProfile, payload);

// subscriptions
export const getInfluencerProducts = async (influencerId) =>
    await api.get(`/api/subscriptions/influencers/${influencerId}/stripe/products`);

export const createInfluencerSubscriptionProduct = async (amount) =>
    await api.post(`/api/subscriptions/stripe/product`, { amount });

export const updatePriceInfluencerSubscriptionProduct = async (stripeProductId, newPrice, influencerId) =>
    await api.put(`/api/subscriptions/stripe/product/price`, { stripeProductId, newPrice, influencerId });

export const getUserProfile = async (id) => await api.get(`${urls.getUser}/${id}/profile`);

export const fetchUserFollowingApi = async () => await api.get(urls.fetchUserFollwing);

export const getAnalystRequestApi = async () => await api.get(urls.HandleAnalystRequest);

export const unsubscribeUser = async (id, payload = {}) => await api.post(`${urls.unsubscribeUser}/${id}`, payload);

export const updateTierPlan = async (payload = {}) => await api.post(`${urls.tierPlan}`, payload);

export const createProduct = async (payload = {}) => await api.post(urls.CreateProduct, payload);

export const fetchAvatar = async () => await api.get(urls.fetchAvtar);

export const becomeInfluencer = async (payload = {}) => await api.post(`${urls.becomeInfluencer}`, payload);

export const deleteAccount = async (payload = {}) => await api.delete(urls.DeleteAccount, payload);

export const updateProfilePicture = async (payload = {}) => await api.post(urls.updateProfilePicture, payload);

export const updateAnalystRequest = async (id, payload = {}) =>
    await api.post(`${urls.updateAnalystRequest}/${id}`, payload);

export const updateReferralRedeem = async (id, value) => await api.post(`${urls.updateReferralRedeem}/${value}/${id}`);

export const hideInfluencer = async (id, payload = {}) => await api.put(`${urls.hideInfluencer}/${id}`, payload);

export const removeAnalyst = async (id, analystId, payload = {}) =>
    await api.post(`${urls.removeAnalyst}/${id}/${analystId}`, payload);

export const createReferral = async (payload = {}) => await api.post(urls.CreateReferal, payload);

export const getAnalystTradeList = async (id) => await api.get(`${urls.getAnalystTradeListApi}/${id}`);

export const submitTradeList = async (id, payload = {}) =>
    await api.post(`${urls.ManageAnalystSelectedTradeApi}/${id}`, payload);

export const manageRemoveAnalyst = async (id, analystId, payload = {}) =>
    await api.post(`${urls.removeMangeAnalystApi}/${id}/${analystId}`, payload);

export const getHideStockStatus = async (id) => await api.get(`${urls.hideStockStatusApi}/${id}`);

export const removeCardPost = async (id, payload = {}) => await api.post(`${urls.removeCardPost}/${id}`, payload);

export const InfluencerTradesList = async () => await api.get(urls.InfluencerTradesListApi);
