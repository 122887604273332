import React from 'react';
import SideNotificationComponent from '../../../DashBoard/sideNotification/SideNotificationComponent';
import './style.scss';

const MobileNotification = () => {
    return (
        <div>
            <div className="dropdown notification-dropdown-container">
                <i
                    class="fa-solid fa-bell"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"></i>
                <div className="dropdown-menu notification-list-container" aria-labelledby="dropdownMenuButton">
                    <div className="col-3 col-lg-3 col-md-3 col-sm-3 side-notification-bar">
                        <SideNotificationComponent />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileNotification;
