import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTopInfluencer } from 'Api/influencerDashboard';
import SliderCard from 'Components/reusable/exploreInfluencer/card/sliderCard';
import '@fortawesome/fontawesome-free/css/all.css';
import '../Dashboard.css';
import './style.scss';

const ExploreInfluencer = ({ isExploreInfluencer, fetchTradeSpotlight }) => {
    const [ftechInfluencerAll, setFetchInfluencerAll] = useState([]);

    useEffect(() => {
        getTopInfluencer().then((response) => {
            setFetchInfluencerAll(response);
        });
    }, []);

    const location = useLocation();
    const fetchTradeSpotlightClick = location?.state?.fetchTradeSpotlightClick;

    return (
        <div className="normal-user-spotlight">
            <div className="col-xl-6 col-lg-6 col-md-7 mx-auto mt-5">
                <div>
                    <div
                        id="carouselExampleControls"
                        className="carousel slide m-auto"
                        data-ride="carousel"
                        style={{
                            zIndex: 1,
                            width: '80%',
                        }}>
                        <div className="carousel-inner" style={{ borderRadius: '30px' }}>
                            {fetchTradeSpotlightClick
                                ? fetchTradeSpotlight?.map((item, index) => (
                                      <SliderCard
                                          index={index}
                                          profilePicture={item?.profilePicture}
                                          userName={item.userName}
                                      />
                                  ))
                                : ftechInfluencerAll?.influencers?.map((item, index) => (
                                      <SliderCard
                                          index={index}
                                          profilePicture={item?.profilePicture}
                                          userName={item.userName}
                                      />
                                  ))}
                        </div>
                        <div className="">
                            <div>
                                <a
                                    className="carousel-control-prev"
                                    href="#carouselExampleControls"
                                    role="button"
                                    data-slide="prev">
                                    <span aria-hidden="true">
                                        <i className="fas fa-angle-left carousal-left"></i>
                                    </span>
                                </a>
                            </div>
                            <div>
                                {' '}
                                <a
                                    className="carousel-control-next"
                                    href="#carouselExampleControls"
                                    role="button"
                                    data-slide="next">
                                    <span aria-hidden="true">
                                        <i className="fas fa-angle-right carousal-right"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <img
                    src="/assets/dashboardImg/layer1.png"
                    alt="layer"
                    style={{
                        width: '87%',
                        height: '542px',
                        position: 'absolute',
                        top: '5%',
                        zIndex: 0,
                        left: '6.5%',
                    }}
                />
                <img
                    src="/assets/dashboardImg/Layer2.png"
                    alt="Layer"
                    style={{
                        width: '94%',
                        height: '500px',
                        position: 'absolute',
                        top: '9%',
                        left: '3%',
                        zIndex: 0,
                    }}
                />
            </div>
        </div>
    );
};

export default ExploreInfluencer;
