import React from 'react';
import { get } from 'lodash';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { getInfluencerAmt } from './selector';
import { customStyles } from './style.';
import './subscription.scss';

const Subscription = ({ isOpened = false, handleContinue, influencer, onModelClose }) => {
    const InfluencerAmt = useSelector(getInfluencerAmt);
    const planName = get(influencer, 'firstName', '');

    return (
        <Modal isOpen={isOpened} style={customStyles} onRequestClose={onModelClose} contentLabel="subscription-model">
            <div className="subscription-model p-3">
                <div className="d-flex justify-content-center">
                    <p className="heading">{planName} subscription plan</p>
                </div>

                <div class=" image d-flex justify-content-evenly mt-3">
                    <div class="d-flex">
                        <div className="mt-2 p-4 plan-block-container">
                            <img src="/assets/Influencer/Diamond.png" className="diamond-img" alt="diamond" />
                            <div className="mt-3">
                                <p className="influencer-amt">${InfluencerAmt !== 'null' ? InfluencerAmt : 0}</p>
                                <div className="per-month">Per month</div>
                            </div>
                            <p className="plan-text mt-5">Subscription Plan</p>
                        </div>
                    </div>

                    <div class="d-flex">
                        <div className="mt-2 p-4 plan-block-container">
                            <img src="/assets/Influencer/diamondWhite.png" className="diamond-img" alt="diamond" />
                            <div className="mt-3">
                                <p className="influencer-amt">$499</p>
                                <div className="per-month">Per year</div>
                            </div>
                            <p className="plan-text mt-5">Subscription Plan</p>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <ul>
                        <div className="d-flex justify-content-start mt-4">
                            <img src="/assets/images2/orangeLock.png" className="lock-icon" alt="lock icon" />
                            <p className="holding-text">Access to All Holdings of the Verified Traders</p>
                        </div>

                        <div className="d-flex justify-content-start mt-4">
                            <img src="/assets/images2/BLUE_bell.png" className="lock-icon" alt="bell icon" />
                            <p className="holding-text">Real time notifications of when they trade</p>
                        </div>

                        <div className="d-flex justify-content-start mt-4">
                            <img
                                src="/assets/images2/purple_notification.png"
                                className="lock-icon"
                                alt="circle icon"
                            />
                            <p className="holding-text">Access to their full portfolio and its details</p>
                        </div>
                    </ul>
                </div>
                <div class=" d-flex justify-content-center mt-4 ">
                    <button className="btn btn-sm m-2 continue-btn" onClick={handleContinue}>
                        CONTINUE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(Subscription);
