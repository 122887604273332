import React, { useState } from 'react';
import Modal from 'react-modal';
import './Support.css';

const Support = () => {
    const [modalIsSecOpenLink, setSecIsOpenLink] = useState(false);

    const customStyles2 = {
        content: {
            top: '90%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            backgroundColor: '#FFF',
            color: 'black',
            height: '80%',
            borderRadius: '28px',
        },
    };

    function secModalClose() {
        setSecIsOpenLink(false);
    }
    return (
        <>
            <Modal
                isOpen={modalIsSecOpenLink}
                onRequestClose={secModalClose}
                style={customStyles2}
                contentLabel="Example Modal">
                <div className="container" style={{ width: '1000px' }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-4 ">
                            <div className="d-flex justify-content-between">
                                {' '}
                                <h3>Select Topic</h3>
                                <h5 style={{ color: '#FF5445' }} onClick={() => setSecIsOpenLink(false)}>
                                    Cancel
                                </h5>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                {' '}
                                <p>Payment Issue</p>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                {' '}
                                <p>Login Issue</p>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                {' '}
                                <p>Notification Issue</p>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                {' '}
                                <p>Subscription Issue</p>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                {' '}
                                <p>Brokerage Link Issue</p>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                                {' '}
                                <p>Others</p>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Support;
