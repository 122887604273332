import React, { useEffect, useState, Suspense } from 'react';
import ct from 'classnames';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import Search from '../../Components/DashBoard/Search/Search';
import DashBoard3 from '../../Components/DashBoard/exploreInfluencer';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import { Col, Row } from 'react-bootstrap';
import MobileNavbar from '../../Components/Navbar/MobileNavbar';
import MediaQuery from 'react-responsive';
import Subscription from 'Components/reusable/model/subscription';
import SubscriptionPlan from 'Components/reusable/model/subscriptionPlan';
import PaymentConfirmation from 'Components/reusable/model/paymentConfirmation';
import PaymentSuccess from 'Components/reusable/model/paymentSuccess';
import Investment from 'Components/reusable/model/investment';
import FilterDropdown from 'Components/reusable/model/filterDropdown';
import * as CONSTANT from './constant';
import LoaderModal from '../Login/LoaderModal';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import {
    fetchProfile,
    filterHighestFollowers,
    filterHighestReturn,
    highestSubscription,
    lowestSubscription,
} from 'Api/dashboard';
import { userLoggedIn } from 'globalStore/actions/login';
import { getInfluencerByID, getTopInfluencer } from 'Api/influencerDashboard';
import { createSubscriptionCheckoutSession, userFollow, userSubscribe } from 'Api/influencerDetails';
import { fetchUserFollowingApi } from 'Api/setting';
import ComponentLoading from 'Components/reusable/loading/Loading';
import { customStyles } from './style';
import SureModel from 'Components/reusable/settings/sureModel';
import { getSubscription } from 'Api/UserSubscription';
import CommonModal from 'Components/reusable/settings/commonModal';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.scss';

const DashBoard2 = ({ isExploreInfluencer, fetchTradeSpotlight }) => {
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const [ftechInfluencerAll, setFetchInfluencerAll] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardSwitch, setdashboardSwitch] = useState(false);
    const [token, setToken] = useState('');
    const [getFollowState] = useState([]);
    const [modalSubscriptionIsOpen, setModalSubscriptionsIsOpen] = useState(false);
    const [modalPaymentMethodOpen, setModalPaymentMethodIsOpen] = useState(false);
    const [modalPaymentCardOpen, setModalPaymentCardOpen] = useState(false);
    const [modalConfirmPaymentOpen, setModalConfirmPaymentOpen] = useState(false);
    const [modalCongratulationOpen, setModalCongratulationOpen] = useState(false);
    const [modalInformationType, setModalInformationType] = useState(false);
    const [blueTickClickHigestReturn, setBlueTickClickHigestReturn] = useState(false);
    const [blueTickClickHigestFollowers, setBlueTickClickHigestFollowers] = useState(false);
    const [blueTickClickLowestSubscription, setBlueTickClickLowestSubscription] = useState(false);
    const [blueTickClickHigestSubscription, setBlueTickClickHigestSubscription] = useState(false);
    const [fetchHigestReturn, setFetchHigestReturn] = useState([]);
    const [fetchHigestFollowers, setFetchHigestFollwers] = useState([]);
    const [fetchLowestSubscription, setFetchLowestSubscription] = useState([]);
    const [fetchHigestSubscription, setFetchHigestSubscription] = useState([]);
    const [getFollowingIds, setGetFollowingIds] = useState([]);
    const [isOpenFollowModel, setIsOpenFollowModel] = useState(false);
    const [userInfluencerId, setUserInfluencerId] = useState('');
    const [userSubscriberIds, setUserSubscriberIds] = useState([]);
    const [subscribeModel, setSubscribeModel] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const storedToken = JSON.parse(localStorage.getItem('userData'));
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    const getProfile = async () => {
        await fetchProfile(id);
    };

    const getInfluencerFollowing = async () => {
        try {
            const response = await fetchUserFollowingApi();
            const ids = response?.following?.map((data) => data?.influencerId);
            setGetFollowingIds(ids);
        } catch (error) {
            console.error(error);
        }
    };

    const getInfluencerSubscriber = async () => {
        try {
            const response = await getSubscription();
            const ids = response?.map((data) => data?.influencerId);
            setUserSubscriberIds(ids || []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getProfile();
        getInfluencerFollowing();
        getInfluencerSubscriber();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getTopInfluencer().then((response) => {
            setFetchInfluencerAll(response);
        });
    }, []);

    function secModalClose() {
        setModalSubscriptionsIsOpen(false);
        setModalPaymentMethodIsOpen(false);
    }

    const handleFollow = (ind) => {
        localStorage.setItem('InfluencerId', ind);
        localStorage.setItem('handleButtonColorFollow', true);
        const data = {
            follow: true,
        };

        userFollow(ind, data).then((response) => {
            setModalMsg('You are now following this influencer.');
            setModalOpen(true);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        });
    };

    const fetchInfluencerById = (ind) => {
        getInfluencerByID(ind).then((response) => {
            setFetchInfluencer(response);
            dispatch(
                userLoggedIn({
                    InfluencerAmt: response?.influencer?.influencer?.amount,
                })
            );
        });
    };

    const handlePaymentSubscription = async () => {
        // setIsLoading(true);

        // const data = {
        //     amount: ftechInfluencer?.influencer?.influencer?.amount,
        // };

        const response = await createSubscriptionCheckoutSession(id, ftechInfluencer?.influencer?.influencer?.id);
        console.log('subscription resp first user dashboard', response);

        window.location.href = response.url;

        // userSubscribe(ftechInfluencer?.influencer?.influencer?.id, data)
        //     .then((response) => {
        //         setIsLoading(false);

        //     })
        //     .catch((error) => {
        //         console.warn(error);
        //     });
    };

    const handleSubscribe = (ind) => {
        fetchInfluencerById(ind);
        setModalSubscriptionsIsOpen(true);
    };

    const handleSubscribepayment = () => {
        setModalSubscriptionsIsOpen(false);
        setModalPaymentMethodIsOpen(true);
    };

    const handleBrokerage = () => {
        const newTab = window.open(`${process.env.REACT_APP_FRONT_INTEGRATION_URL}?ephemeral=${token}`, '_blank');
        if (newTab) {
            newTab.focus();
        }
    };

    const handleHigestReturn = () => {
        setBlueTickClickHigestReturn(!blueTickClickHigestReturn);
        setBlueTickClickHigestFollowers(false);
        setBlueTickClickLowestSubscription(false);
        setBlueTickClickHigestSubscription(false);

        filterHighestReturn()
            .then((response) => {
                setFetchHigestReturn(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleFollowInfluencer = () => {
        setIsOpenFollowModel(false);
        handleFollow(userInfluencerId);
    };

    const handleSubscribeInfluencer = () => {
        setSubscribeModel(false);
        handleSubscribe(userInfluencerId);
    };

    const handleHigestFollowers = () => {
        setBlueTickClickHigestFollowers(!blueTickClickHigestFollowers);
        setBlueTickClickLowestSubscription(false);
        setBlueTickClickHigestSubscription(false);
        setBlueTickClickHigestReturn(false);

        filterHighestFollowers()
            .then((response) => {
                setFetchHigestFollwers(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleLowestSubscription = () => {
        setBlueTickClickLowestSubscription(!blueTickClickLowestSubscription);
        setBlueTickClickHigestFollowers(false);
        setBlueTickClickHigestSubscription(false);
        setBlueTickClickHigestReturn(false);

        lowestSubscription()
            .then((response) => {
                setFetchLowestSubscription(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleHigestSubscription = () => {
        setBlueTickClickHigestSubscription(!blueTickClickHigestSubscription);
        setBlueTickClickHigestFollowers(false);
        setBlueTickClickLowestSubscription(false);
        setBlueTickClickHigestReturn(false);

        highestSubscription()
            .then((response) => {
                setFetchHigestSubscription(response);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    const redirectUser = (id) => {
        if (isExploreInfluencer) {
            dispatch(
                userLoggedIn({
                    influencer_id: id,
                })
            );

            navigate(`/dashboard/explore-inflencer/influencer-detail`);
        }
    };
    const location = useLocation();
    const fetchTradeSpotlightClick = location?.state?.fetchTradeSpotlightClick;

    return (
        <div>
            {isLoading && <LoaderModal />}

            <div></div>

            {!dashboardSwitch && <></>}

            <Col className="unsubscribe-user-dashboard">
                <MediaQuery maxWidth={768}>
                    {!isExploreInfluencer || !fetchTradeSpotlight ? <MobileNavbar /> : <MobileNavBarWithInfluencer />}
                </MediaQuery>
                <div className="black-row" />
                <div>
                    <div className="header-content">
                        <Col className="d-flex justify-content-between middle-flex pt-5">
                            <div className="header-text-content">
                                <p className="header-welcome">Welcome to</p>
                                <h1 className="header-finance mt-3">Parrot Finance</h1>
                            </div>

                            <div className="search-bar">
                                <Search id={id} />
                            </div>
                        </Col>

                        <Col className="d-flex justify-content-between flex-after-middle mb-3">
                            <div className="header-text-content">
                                <p className="featured">
                                    Featured
                                    <img src={CONSTANT.VERIFY_ICON} alt="verify icon" className="m-1 verify-img" />
                                    <p className="trader-text">Traders</p>
                                </p>
                            </div>

                            <div className="d-flex search-section">
                                <div className="d-flex right-rem">
                                    <img src={CONSTANT.SEARCH_ICON} alt="search" className="search-icon" />
                                </div>

                                <FilterDropdown
                                    handleHigestReturn={handleHigestReturn}
                                    handleHigestFollowers={handleHigestFollowers}
                                    handleLowestSubscription={handleLowestSubscription}
                                    handleHigestSubscription={handleHigestSubscription}
                                    blueTickClickHigestReturn={blueTickClickHigestReturn}
                                    blueTickClickHigestFollowers={blueTickClickHigestFollowers}
                                    blueTickClickLowestSubscription={blueTickClickLowestSubscription}
                                    blueTickClickHigestSubscription={blueTickClickHigestSubscription}>
                                    <img src={CONSTANT.FILTER_ICON} className="filter-icon" alt="filter icon" />
                                </FilterDropdown>

                                <img
                                    src={dashboardSwitch ? CONSTANT.GRID_DOTS_ICON : CONSTANT.MOBILE_ICON}
                                    onClick={() => setdashboardSwitch(!dashboardSwitch)}
                                    className="mobile-icon"
                                    alt="mobile icon"
                                />
                            </div>
                        </Col>
                    </div>

                    {isLoading ? (
                        <div className="d-flex justify-content-between align-item-center mt-2 "></div>
                    ) : (
                        <div className="row m-0">
                            <div className="col-xl-1 col-lg-2 isMobile">{<Navbar />}</div>
                            <div className="col-xl-8 m-0">
                                {(!isExploreInfluencer || !fetchTradeSpotlight) && (
                                    <div className="mb-3 brokerage-container">
                                        <div className="d-flex justify-content-between p-4 green-section">
                                            <p className="brokerages-text mt-3">You haven’t added any brokerages yet</p>
                                            <button className="brokerages-btn" onClick={handleBrokerage}>
                                                Link Brokerage
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {!dashboardSwitch && (
                                    <>
                                        {blueTickClickHigestReturn ? (
                                            <div className="col-xl-12 m-0">
                                                <Row>
                                                    {fetchHigestReturn?.map((influencerData, index) => {
                                                        return (
                                                            <div key={index} className="col-xl-4 col-md-4 mb-4">
                                                                <div className="card-content">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                influencerData.profilePicture
                                                                                    ? influencerData.profilePicture
                                                                                    : '/assets/images/profile_img2.jpeg'
                                                                            }
                                                                            alt="influencer"
                                                                            className="influencer-img"
                                                                        />
                                                                    </div>

                                                                    <div className={'card-bottom-container2'}>
                                                                        <div className="d-flex justify-content-between p-3">
                                                                            <div>
                                                                                <div className="d-flex">
                                                                                    <img
                                                                                        src={CONSTANT.TWITTER_ICON}
                                                                                        alt="twitter icon"
                                                                                        className="twitter-icon"
                                                                                    />
                                                                                    <p className="twitter-text">
                                                                                        {influencerData?.twitterFollowers ||
                                                                                            0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="d-flex mt-2 ">
                                                                                    <p className="card-text">
                                                                                        @
                                                                                        {influencerData?.userName ??
                                                                                            influencerData?.username}
                                                                                    </p>
                                                                                    <img
                                                                                        src={CONSTANT.VERIFY_ICON}
                                                                                        alt="verify icon"
                                                                                        className="verify-icon"
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                                <p className="return-text">Returns</p>
                                                                                <div className="d-flex green-container">
                                                                                    <img
                                                                                        src={CONSTANT.ARROW_TOP_ICON}
                                                                                        className="mt-2 top-arrow-icon"
                                                                                        alt="top arrow"
                                                                                    />
                                                                                    <p className="middle-present-text">
                                                                                        3.32%
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex justify-content-between mb-2 mx-3 pb-3">
                                                                            <button
                                                                                className={ct('card-button', {
                                                                                    greenColor: getFollowState,
                                                                                    transparentColor: !getFollowState,
                                                                                })}
                                                                                onClick={() =>
                                                                                    handleFollow(influencerData?.id)
                                                                                }>
                                                                                <p className="follow-text m-1">
                                                                                    Follow
                                                                                </p>
                                                                            </button>
                                                                            <button
                                                                                className="subscribe-btn"
                                                                                onClick={() =>
                                                                                    handleSubscribe(influencerData?.id)
                                                                                }>
                                                                                <p className="m-1 subscribe-btn-text">
                                                                                    Subscribe
                                                                                </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                        ) : blueTickClickHigestFollowers ? (
                                            <Row>
                                                {fetchHigestFollowers?.map((influencerData, index) => {
                                                    return (
                                                        <div className="col-xl-4 col-md-4 mb-4" key={index}>
                                                            <div className="card-content">
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            influencerData.profilePicture
                                                                                ? influencerData.profilePicture
                                                                                : '/assets/images/profile_img2.jpeg'
                                                                        }
                                                                        alt="influencer"
                                                                        className="influencer-img"
                                                                    />
                                                                </div>

                                                                <div className={'card-bottom-container2'}>
                                                                    <div className="d-flex justify-content-between p-3">
                                                                        <div>
                                                                            <div className="d-flex">
                                                                                <img
                                                                                    src={CONSTANT.TWITTER_ICON}
                                                                                    alt="twitter icon"
                                                                                    className="twitter-icon"
                                                                                />
                                                                                <p className="twitter-text">
                                                                                    {influencerData?.twitterFollowers ||
                                                                                        0}
                                                                                </p>
                                                                            </div>

                                                                            <div className="d-flex mt-2 ">
                                                                                <p className="card-text">
                                                                                    @
                                                                                    {influencerData?.userName ??
                                                                                        influencerData?.username}
                                                                                </p>
                                                                                <img
                                                                                    src={CONSTANT.VERIFY_ICON}
                                                                                    alt="verify icon"
                                                                                    className="verify-icon"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <p className="return-text">Returns</p>
                                                                            <div className="d-flex green-container">
                                                                                <img
                                                                                    src={CONSTANT.ARROW_TOP_ICON}
                                                                                    className="mt-2 top-arrow-icon"
                                                                                    alt="top-arrow-icon"
                                                                                />
                                                                                <p className="middle-present-text">
                                                                                    3.32%
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex justify-content-between mb-2 mx-3 pb-3">
                                                                        <button
                                                                            className={ct('card-button', {
                                                                                greenColor: getFollowState,
                                                                                transparentColor: !getFollowState,
                                                                            })}
                                                                            onClick={() =>
                                                                                handleFollow(influencerData?.id)
                                                                            }>
                                                                            <p className="follow-text m-1">Follow</p>
                                                                        </button>
                                                                        <button
                                                                            className="subscribe-btn"
                                                                            onClick={() =>
                                                                                handleSubscribe(influencerData?.id)
                                                                            }>
                                                                            <p className="m-1 subscribe-btn-text">
                                                                                Subscribe
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Row>
                                        ) : blueTickClickLowestSubscription ? (
                                            <div className="col-xl-12 m-0">
                                                <Row>
                                                    {fetchLowestSubscription?.map((influencerData, index) => {
                                                        return (
                                                            <div className="col-xl-4 col-md-4 mb-4" key={index}>
                                                                <div className="card-content">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                influencerData.profilePicture
                                                                                    ? influencerData.profilePicture
                                                                                    : '/assets/images/profile_img2.jpeg'
                                                                            }
                                                                            alt="influencer"
                                                                            className="influencer-img"
                                                                        />
                                                                    </div>

                                                                    <div className={'card-bottom-container2'}>
                                                                        <div className="d-flex justify-content-between p-3">
                                                                            <div>
                                                                                <div className="d-flex">
                                                                                    <img
                                                                                        src={CONSTANT.TWITTER_ICON}
                                                                                        alt="twitter icon"
                                                                                        className="twitter-icon"
                                                                                    />
                                                                                    <p className="twitter-text">
                                                                                        {influencerData?.twitterFollowers ||
                                                                                            0}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="d-flex mt-2 ">
                                                                                    <p className="card-text">
                                                                                        @
                                                                                        {influencerData?.userName ??
                                                                                            influencerData?.username}
                                                                                    </p>
                                                                                    <img
                                                                                        src={CONSTANT.VERIFY_ICON}
                                                                                        alt="verify icon"
                                                                                        className="verify-icon"
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex">
                                                                                <p className="return-text">Returns</p>
                                                                                <div className="d-flex green-container">
                                                                                    <img
                                                                                        src={CONSTANT.ARROW_TOP_ICON}
                                                                                        className="mt-2 top-arrow-icon"
                                                                                        alt="top arrow"
                                                                                    />
                                                                                    <p className="middle-present-text">
                                                                                        3.32%
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex justify-content-between mb-2 mx-3 pb-3">
                                                                            <button
                                                                                className={ct('card-button', {
                                                                                    greenColor: getFollowState,
                                                                                    transparentColor: !getFollowState,
                                                                                })}
                                                                                onClick={() =>
                                                                                    handleFollow(influencerData?.id)
                                                                                }>
                                                                                <p className="follow-text m-1">
                                                                                    Follow
                                                                                </p>
                                                                            </button>
                                                                            <button
                                                                                className="subscribe-btn"
                                                                                onClick={() =>
                                                                                    handleSubscribe(influencerData?.id)
                                                                                }>
                                                                                <p className="m-1 subscribe-btn-text">
                                                                                    Subscribe
                                                                                </p>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Row>
                                            </div>
                                        ) : blueTickClickHigestSubscription ? (
                                            <Row>
                                                {fetchHigestSubscription?.map((influencerData, index) => {
                                                    return (
                                                        <div key={index} className="col-xl-4 col-md-4 mb-4">
                                                            <div className="card-content">
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            influencerData.profilePicture
                                                                                ? influencerData.profilePicture
                                                                                : '/assets/images/profile_img2.jpeg'
                                                                        }
                                                                        alt="influencer"
                                                                        className="influencer-img"
                                                                    />
                                                                </div>

                                                                <div className={'card-bottom-container2'}>
                                                                    <div className="d-flex justify-content-between p-3">
                                                                        <div>
                                                                            <div className="d-flex">
                                                                                <img
                                                                                    src={CONSTANT.TWITTER_ICON}
                                                                                    alt="twitter icon"
                                                                                    className="twitter-icon"
                                                                                />
                                                                                <p className="twitter-text">
                                                                                    {influencerData?.twitterFollowers ||
                                                                                        0}
                                                                                </p>
                                                                            </div>

                                                                            <div className="d-flex mt-2 ">
                                                                                <p className="card-text">
                                                                                    @
                                                                                    {influencerData?.userName ??
                                                                                        influencerData?.username}
                                                                                </p>
                                                                                <img
                                                                                    src={CONSTANT.VERIFY_ICON}
                                                                                    alt="verify icon"
                                                                                    className="verify-icon"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="d-flex">
                                                                            <p className="return-text">Returns</p>
                                                                            <div className="d-flex green-container">
                                                                                <img
                                                                                    src={CONSTANT.ARROW_TOP_ICON}
                                                                                    className="mt-2 top-arrow-icon"
                                                                                    alt="top arrow"
                                                                                />
                                                                                <p className="middle-present-text">
                                                                                    3.32%
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex justify-content-between mb-2 mx-3 pb-3">
                                                                        <button
                                                                            className={ct('card-button', {
                                                                                greenColor: getFollowState,
                                                                                transparentColor: !getFollowState,
                                                                            })}
                                                                            onClick={() =>
                                                                                handleFollow(influencerData?.id)
                                                                            }>
                                                                            <p className="follow-text m-1">Follow</p>
                                                                        </button>
                                                                        <button
                                                                            className="subscribe-btn"
                                                                            onClick={() =>
                                                                                handleSubscribe(influencerData?.id)
                                                                            }>
                                                                            <p className="m-1 subscribe-btn-text">
                                                                                Subscribe
                                                                            </p>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </Row>
                                        ) : (
                                            <div className="col-xl-12 m-0">
                                                <Row>
                                                    {fetchTradeSpotlightClick
                                                        ? fetchTradeSpotlight?.map((influencerData, index) => {
                                                              return (
                                                                  <div className="col-xl-4 col-md-4 mb-4" key={index}>
                                                                      <div className="card-content">
                                                                          <div>
                                                                              <img
                                                                                  src={
                                                                                      influencerData.profilePicture
                                                                                          ? influencerData.profilePicture
                                                                                          : '/assets/images/profile_img2.jpeg'
                                                                                  }
                                                                                  alt="influencer"
                                                                                  className="influencer-img"
                                                                                  onClick={() =>
                                                                                      redirectUser(influencerData.id)
                                                                                  }
                                                                              />
                                                                          </div>

                                                                          <div className={'card-bottom-container2'}>
                                                                              <div className="d-flex justify-content-between p-3">
                                                                                  <div>
                                                                                      <div className="d-flex">
                                                                                          <img
                                                                                              src={
                                                                                                  CONSTANT.TWITTER_ICON
                                                                                              }
                                                                                              alt="twitter icon"
                                                                                              className="twitter-icon"
                                                                                          />
                                                                                          <p className="twitter-text">
                                                                                              {influencerData?.twitterFollowers ||
                                                                                                  0}
                                                                                          </p>
                                                                                      </div>

                                                                                      <div className="d-flex mt-2">
                                                                                          <p
                                                                                              className="card-text"
                                                                                              onClick={() =>
                                                                                                  redirectUser(
                                                                                                      influencerData.id
                                                                                                  )
                                                                                              }>
                                                                                              @
                                                                                              {influencerData?.userName ??
                                                                                                  influencerData?.username}
                                                                                          </p>
                                                                                          <img
                                                                                              src={CONSTANT.VERIFY_ICON}
                                                                                              alt="verify icon"
                                                                                              className="verify-icon"
                                                                                          />
                                                                                      </div>
                                                                                  </div>

                                                                                  <div className="d-flex">
                                                                                      <p className="return-text">
                                                                                          Returns
                                                                                      </p>
                                                                                      <div className="d-flex green-container">
                                                                                          <img
                                                                                              src={
                                                                                                  CONSTANT.ARROW_TOP_ICON
                                                                                              }
                                                                                              className="mt-2 top-arrow-icon"
                                                                                              alt="top arrow"
                                                                                          />
                                                                                          <p className="middle-present-text">
                                                                                              3.32%
                                                                                          </p>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>

                                                                              <div className="d-flex justify-content-between mb-2 mx-3 pb-3">
                                                                                  <button
                                                                                      disabled={getFollowingIds?.includes(
                                                                                          influencerData?.id
                                                                                      )}
                                                                                      className={ct('card-button', {
                                                                                          greenColor: getFollowState,
                                                                                          transparentColor:
                                                                                              !getFollowState,
                                                                                      })}
                                                                                      onClick={() => {
                                                                                          setIsOpenFollowModel(true);
                                                                                          setUserInfluencerId(
                                                                                              influencerData?.id
                                                                                          );
                                                                                      }}>
                                                                                      <p className="follow-text m-1">
                                                                                          {getFollowingIds?.includes(
                                                                                              influencerData?.id
                                                                                          )
                                                                                              ? 'Following'
                                                                                              : 'Follow'}
                                                                                      </p>
                                                                                  </button>
                                                                                  <button
                                                                                      disabled={userSubscriberIds?.includes(
                                                                                          influencerData?.id
                                                                                      )}
                                                                                      className="subscribe-btn"
                                                                                      onClick={() => {
                                                                                          setSubscribeModel(true);
                                                                                          setUserInfluencerId(
                                                                                              influencerData?.id
                                                                                          );
                                                                                      }}>
                                                                                      <p className="m-1 subscribe-btn-text">
                                                                                          {userSubscriberIds?.includes(
                                                                                              influencerData?.id
                                                                                          )
                                                                                              ? 'Subscribed'
                                                                                              : 'Subscribe'}
                                                                                      </p>
                                                                                  </button>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              );
                                                          })
                                                        : ftechInfluencerAll?.influencers?.map(
                                                              (influencerData, index) => {
                                                                  return (
                                                                      <div
                                                                          className="col-xl-4 col-md-4 mb-4"
                                                                          key={index}>
                                                                          <div className="card-content">
                                                                              <div>
                                                                                  <img
                                                                                      src={
                                                                                          influencerData.profilePicture
                                                                                              ? influencerData.profilePicture
                                                                                              : '/assets/images/profile_img2.jpeg'
                                                                                      }
                                                                                      alt="influencer"
                                                                                      className="influencer-img"
                                                                                      onClick={() =>
                                                                                          redirectUser(
                                                                                              influencerData.id
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              </div>

                                                                              <div className={'card-bottom-container2'}>
                                                                                  <div className="d-flex justify-content-between p-3">
                                                                                      <div>
                                                                                          <div className="d-flex">
                                                                                              <img
                                                                                                  src={
                                                                                                      CONSTANT.TWITTER_ICON
                                                                                                  }
                                                                                                  alt="twitter icon"
                                                                                                  className="twitter-icon"
                                                                                              />
                                                                                              <p className="twitter-text">
                                                                                                  {influencerData?.twitterFollowers ||
                                                                                                      0}
                                                                                              </p>
                                                                                          </div>

                                                                                          <div className="d-flex mt-2">
                                                                                              <p
                                                                                                  className="card-text"
                                                                                                  onClick={() =>
                                                                                                      redirectUser(
                                                                                                          influencerData.id
                                                                                                      )
                                                                                                  }>
                                                                                                  @
                                                                                                  {influencerData?.userName ??
                                                                                                      influencerData?.username}
                                                                                              </p>
                                                                                              <img
                                                                                                  src={
                                                                                                      CONSTANT.VERIFY_ICON
                                                                                                  }
                                                                                                  alt="verify icon"
                                                                                                  className="verify-icon"
                                                                                              />
                                                                                          </div>
                                                                                      </div>

                                                                                      <div className="d-flex">
                                                                                          <p className="return-text">
                                                                                              Returns
                                                                                          </p>
                                                                                          <div className="d-flex green-container">
                                                                                              <img
                                                                                                  src={
                                                                                                      CONSTANT.ARROW_TOP_ICON
                                                                                                  }
                                                                                                  className="mt-2 top-arrow-icon"
                                                                                                  alt="top arrow"
                                                                                              />
                                                                                              <p className="middle-present-text">
                                                                                                  3.32%
                                                                                              </p>
                                                                                          </div>
                                                                                      </div>
                                                                                  </div>

                                                                                  <div className="d-flex justify-content-between mb-2 mx-3 pb-3">
                                                                                      <button
                                                                                          disabled={getFollowingIds?.includes(
                                                                                              influencerData?.id
                                                                                          )}
                                                                                          className={ct('card-button', {
                                                                                              greenColor:
                                                                                                  getFollowState,
                                                                                              transparentColor:
                                                                                                  !getFollowState,
                                                                                          })}
                                                                                          onClick={() => {
                                                                                              setIsOpenFollowModel(
                                                                                                  true
                                                                                              );
                                                                                              setUserInfluencerId(
                                                                                                  influencerData?.id
                                                                                              );
                                                                                          }}>
                                                                                          <p className="follow-text m-1">
                                                                                              {getFollowingIds?.includes(
                                                                                                  influencerData?.id
                                                                                              )
                                                                                                  ? 'Following'
                                                                                                  : 'Follow'}
                                                                                          </p>
                                                                                      </button>
                                                                                      <button
                                                                                          disabled={userSubscriberIds?.includes(
                                                                                              influencerData?.id
                                                                                          )}
                                                                                          className="subscribe-btn"
                                                                                          onClick={() => {
                                                                                              setSubscribeModel(true);
                                                                                              setUserInfluencerId(
                                                                                                  influencerData?.id
                                                                                              );
                                                                                          }}>
                                                                                          <p className="m-1 subscribe-btn-text">
                                                                                              {userSubscriberIds?.includes(
                                                                                                  influencerData?.id
                                                                                              )
                                                                                                  ? 'Subscribed'
                                                                                                  : 'Subscribe'}
                                                                                          </p>
                                                                                      </button>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  );
                                                              }
                                                          )}
                                                </Row>
                                            </div>
                                        )}
                                    </>
                                )}

                                {dashboardSwitch && (
                                    <DashBoard3
                                        isExploreInfluencer={isExploreInfluencer}
                                        fetchTradeSpotlight={fetchTradeSpotlight}
                                    />
                                )}
                            </div>

                            <MediaQuery minWidth={1025}>
                                <div className="col-xl-3 col-lg-3 pe-0">
                                    <div className="scroll-sideNotification side-notification">
                                        <SideNotificationComponent />
                                    </div>
                                </div>
                            </MediaQuery>
                        </div>
                    )}
                </div>

                <div>
                    <Subscription
                        isOpened={modalSubscriptionIsOpen}
                        onModelClose={secModalClose}
                        handleContinue={handleSubscribepayment}
                        influencer={ftechInfluencer?.influencer?.influencer}
                    />

                    <Modal
                        isOpen={modalPaymentMethodOpen}
                        onRequestClose={secModalClose}
                        style={customStyles}
                        contentLabel="Example Modal">
                        <div className="">
                            <div class="mt-3">
                                <p className="mt-2 pending-payment-text">Pending in-app payments</p>
                            </div>

                            <div class="mt-3 p-2">
                                <p className="mt-2 pending-payment-description">
                                    In the meantime please go to the Parrot web page and pay for your subscription
                                </p>
                            </div>

                            <div class=" d-flex justify-content-center px-2 rounded mt-4 date ">
                                <button
                                    className="btn btn-sm m-2 go-to-webpage-btn"
                                    onClick={handlePaymentSubscription}>
                                    GO TO WEB PAGE
                                </button>
                            </div>
                        </div>
                    </Modal>

                    <SubscriptionPlan
                        isOpened={modalPaymentCardOpen}
                        onModelClose={secModalClose}
                        influencer={ftechInfluencer?.influencer?.influencer}
                        isConfirmModelOpened={setModalConfirmPaymentOpen}
                        setPaymentCardOpen={setModalPaymentCardOpen}
                    />

                    <PaymentConfirmation
                        isOpened={modalConfirmPaymentOpen}
                        onModelClose={secModalClose}
                        handlePaymentSubscription={handlePaymentSubscription}
                    />

                    <PaymentSuccess
                        isOpened={modalCongratulationOpen}
                        onModelClose={secModalClose}
                        setCongratulationOpen={setModalCongratulationOpen}
                    />

                    <Investment
                        isOpened={modalInformationType}
                        onModelClose={secModalClose}
                        setCloseModel={setModalInformationType}
                    />

                    <Suspense fallback={<ComponentLoading />}>
                        <SureModel
                            isOpen={isOpenFollowModel}
                            onModelClose={() => {
                                setIsOpenFollowModel(false);
                            }}
                            text="Are you sure you want to follow this influencer ?"
                            onYes={handleFollowInfluencer}
                            onNo={() => {
                                setIsOpenFollowModel(false);
                            }}
                        />
                    </Suspense>

                    <Suspense fallback={<ComponentLoading />}>
                        <SureModel
                            isOpen={subscribeModel}
                            onModelClose={() => {
                                setSubscribeModel(false);
                            }}
                            text="Are you sure you want to Subscribe this influencer ?"
                            onYes={handleSubscribeInfluencer}
                            onNo={() => {
                                setSubscribeModel(false);
                            }}
                        />
                    </Suspense>

                    <Suspense fallback={<ComponentLoading />}>
                        <CommonModal
                            isOpen={modalOpen}
                            onModelClose={() => setModalOpen(false)}
                            text={modalMsg}
                            onYes={() => setModalOpen(false)}
                            btn1Text={'Ok'}
                        />
                    </Suspense>
                </div>
            </Col>
        </div>
    );
};

export default React.memo(DashBoard2);
