import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import * as CONSTANT from './constant';
import { MASTER_CARD_LOGO } from './constant';
import './style.scss';

const PaymentConfirmation = ({ isOpened = false, onModelClose, handlePaymentSubscription }) => {
    const cardDropDown = CONSTANT.CARD_DROPDOWN;
    return (
        <Modal isOpen={isOpened} onRequestClose={onModelClose} style={customStyles} contentLabel="payment confirmation">
            <div className="p-4 payment-confirmation">
                <span className="angle-position">
                    <i className="fas fa-angle-left"></i>
                </span>
                <div className="d-flex justify-content-center">
                    <p className="confirmation-text">Confirm Payment</p>
                </div>

                <div class="mt-5">
                    <p className="payment-card-text">Payment via card</p>
                </div>

                <div>
                    <div class="dropdown">
                        <button type="button" class="btn dropdown-toggle btn-master-card" data-bs-toggle="dropdown">
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <img src={MASTER_CARD_LOGO} alt="master card logo" className="master-card-logo" />
                                    <div>
                                        <p className="code-text">
                                            2542 2369 8546 4786
                                            <div className="d-flex expire-date">
                                                <p className="expiry-date-para">Exp 08/28</p>
                                                <p className="dashed-text">|</p>
                                                <p className="user-name">John Doe</p>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <i className="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </button>
                        <ul className="dropdown-menu menu-container">
                            {cardDropDown?.map((card) => (
                                <li>
                                    <div className="d-flex justify-content-between mt-1 middle-container">
                                        <div className="d-flex">
                                            <img
                                                src={card?.image}
                                                alt="master card logo"
                                                className="master-card-logo"
                                            />
                                            <div>
                                                <p className="middle-code-text">
                                                    {card?.card_code}
                                                    <div className="d-flex expire-date">
                                                        <p className="expiry-date-para">{card?.expiry}</p>
                                                        <p className="dashed-text">|</p>
                                                        <p className="user-name">{card?.user_name}</p>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <input
                                                class="form-check-input input-check-bg"
                                                type="checkbox"
                                                id="check1"
                                                name="option1"
                                                value="something"
                                            />
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="mt-3 p-3 prize-container">
                        <p className="debited-text">Amount to be debited</p>
                        <div className="d-flex justify-content-between">
                            <p className="debit-amount">$50</p>
                            <p className="mt-2 monthly-plan">Monthly plan</p>
                        </div>
                        <div class=" d-flex justify-content-center px-2  mt-4  ">
                            <span class="join">
                                <button className="btn btn-sm m-2 make-payment-btn" onClick={handlePaymentSubscription}>
                                    MAKE PAYMENT
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(PaymentConfirmation);
