import React from 'react';
import './style.scss';

const HeadingWrapper = ({ text, isShowVerified, lastText, onClickLastText, trader }) => {
    return (
        <div className="d-flex mt-3 media-center-flex justify-content-between w-100 heading-wrapper">
            <div>
                <div className="d-flex media-direction">
                    <div>
                        <p className="recent-trades">{text}</p>
                    </div>

                    {isShowVerified && (
                        <div className="wrapper-margin-left">
                            <p className="wrapper-verified">
                                &nbsp;
                                <img
                                    src="/assets/images2/verify 1.png"
                                    className="wrapper-verified-icon"
                                    alt="verify"
                                />
                                Verified {trader}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div>
                <p className="view-all-text" onClick={onClickLastText}>
                    {lastText}
                </p>
            </div>
        </div>
    );
};

export default HeadingWrapper;
