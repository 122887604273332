import React, { lazy, Suspense } from 'react';
import ComponentLoading from 'Components/reusable/loading/Loading';
import './style.scss';

const AnalyticsArea = lazy(() => import('Components/reusable/graph/AnalyticsArea'));

const Mimicked = ({ mimic, month, mimickedTrades, setActiveMimicked }) => {
    return (
        <div className=" p-4 mt-4 analytics-mimicked-graph">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <img src="/assets/Analytics/blackLifeWithmimic.png" alt="mimic_img" className="mimic_img" />

                    <div className="left-margin-mimic-graph">
                        <p className="mimic-graph-count">
                            {mimic}
                            <p className="mimic-month-show">Mimicked on {month}</p>
                        </p>
                    </div>
                </div>
                <div className="m-2 mimic-graph-select">
                    <select className="mt-1 mimic-main-selector">
                        <option>Monthly</option>
                    </select>
                </div>
            </div>
            <div className="analytics-graph">
                <Suspense fallback={<ComponentLoading />}>
                    <AnalyticsArea mimickedTrades={mimickedTrades} activeMimicked={setActiveMimicked} />
                </Suspense>
            </div>
        </div>
    );
};

export default React.memo(Mimicked);
