import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import { useDispatch } from 'react-redux';
import { userLoggedIn } from 'globalStore/actions/login';
import { brokerageDetails } from 'Api/setting';
import { getStockConfirmModalSimulate } from 'Api/stocksDetails';
import numeral from 'numeral';
import LoaderModal from 'pages/Login/LoaderModal';
import './style.scss';

import './style.scss';

const BuyStocks = ({
    isOpened = false,
    onModelClose,
    setModalReviewIsIsOpen,
    setSelectMarketOrderModal,
    selectUnit,
    setSelectUnit,
    logoUrl,
    ticker,
    price,
    dayC,
    name,
    currBidPrice,
    InputPrice,
    setInputPrice,
    changeOptionBuy,
    changeOptionSell,
    inputOptionValue,
    setInpuOptionValue,
    fetchStocksData,
    inputOptionValueUnit,
    setInpuOptionValueUnit,
    setIsLoading,
    isLoading,
    getBrokerageDropdownData,
    setGetBrokerageDropdownData,
    fetchBrokerageById,
    getBrokerageDropdownDataName,
}) => {
    const [brokerDropdown, setBrokerDropdown] = useState([]);

    const [getReviewData, setGetReviewData] = useState([]);
    const dispatch = useDispatch();

    const secModalClose = () => {
        onModelClose(false);
    };

    const estimateAmount = localStorage.getItem('estimateAmount');
    const handleMarketOrder = () => {
        onModelClose(false);
        setSelectMarketOrderModal && setSelectMarketOrderModal(true);
    };

    localStorage.setItem('selectUnit', selectUnit);

    useEffect(() => {
        if (getBrokerageDropdownData)
            dispatch(
                userLoggedIn({
                    getBrokerageDropdownData: getBrokerageDropdownData,
                })
            );
    }, [getBrokerageDropdownData]);

    const handleReview = async () => {
        setIsLoading(true);
        onModelClose(false);
        setModalReviewIsIsOpen(true);
        let data;

        if (selectUnit === 'USD' || selectUnit === '') {
            data = {
                symbol: fetchStocksData.details.ticker,
                amount: 0,
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: changeOptionBuy ? 'buy' : 'sell',
                fractionalAmountInUsd: Number(inputOptionValue),
                fractional: true,
            };
        } else {
            data = {
                symbol: fetchStocksData.details.ticker,
                amount: Number(inputOptionValueUnit),
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: changeOptionBuy ? 'buy' : 'sell',
                fractional: false,
            };
        }

        try {
            const response = await getStockConfirmModalSimulate(data);
            if (response) {
                setIsLoading(false);
                setGetReviewData(response);
            }
            return response;
        } catch (error) {
            console.warn(error);
            setIsLoading(false);
        }
    };

    const amount = Number(getReviewData?.content?.amount);
    const estimatedUnitPrice = Number(getReviewData?.content?.estimatedUnitPrice);

    if (isNaN(amount) || isNaN(estimatedUnitPrice)) {
    } else {
        const estimateCost = numeral(amount * estimatedUnitPrice).format('$0,0.00');

        localStorage.setItem('estimateCost', estimateCost);
        localStorage.setItem('estimateAmount', amount.toFixed(2));
    }

    useEffect(() => {
        (async () => {
            const response = await brokerageDetails();
            setBrokerDropdown(response);
        })();
    }, [getBrokerageDropdownData]);

    const onchangeUnits = (e) => {
        setSelectUnit(e.target.value);
    };

    const handleInputPrice = (e) => {
        setInputPrice(e.target.value);
    };

    const handleChangeOption = (e) => {
        setInpuOptionValue(e.target.value);
    };

    const handleChangeOptionUnit = (e) => {
        setInpuOptionValueUnit(e.target.value);
    };

    localStorage.setItem('inputPriceRecentTrade', InputPrice);
    const result = changeOptionBuy ? 'Buy' : changeOptionSell ? 'Sell' : 'Buy';
    console.log(getBrokerageDropdownData, 'getBrokerageDropdownData');
    const handleBrokerageDropdown = (e) => {
        localStorage.setItem('getBrokerageId', getBrokerageDropdownData);

        setGetBrokerageDropdownData(e.target.value);
    };

    useEffect(() => {
        setIsLoading(true);

        localStorage.setItem('brokerageName', getBrokerageDropdownDataName);
        setIsLoading(false);
        // eslint-disable-next-line
    }, [getBrokerageDropdownData, fetchBrokerageById]);

    const Brokeragecash = fetchBrokerageById[0]?.cash;

    return (
        <Modal isOpen={isOpened} onRequestClose={secModalClose} style={customStyles} contentLabel="Buy Stocks Modal">
            <div className=" p-3 buy-stocks">
                <div className="d-flex justify-content-between">
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                            top: '10px',
                        }}>
                        {result}
                    </p>
                    <p
                        style={{
                            color: '#64A2FF',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textAlign: 'center',
                            cursor: 'pointer',
                            top: '10px',
                        }}
                        onClick={handleMarketOrder}>
                        Market Order
                    </p>
                </div>
                <hr />

                <div style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="d-flex justify-content-between mt-4">
                        <div className="">
                            <div className="d-flex">
                                <img src={logoUrl} alt="logo" style={{ width: '48px', height: '48px' }} />
                                <div style={{ marginLeft: '10px' }}>
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1">
                                        {ticker}
                                    </p>
                                    <div>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                                marginLeft: '4px',
                                            }}>
                                            {currBidPrice}
                                        </p>
                                    </div>
                                </div>
                                <div className="m-1">|</div>{' '}
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: '125%',
                                    }}
                                    className="m-1 mt-2">
                                    {name}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="d-flex justify-content-center align-items-center p-3"
                    style={{
                        width: '25%',
                        borderRadius: '69px',
                        backgroundColor: '#F5F8F8',
                        border: 'none',
                        backgroundPosition: 'right 10px bottom 50%',
                        backgroundSize: '0.625em',
                        backgroundRepeat: 'no-repeat',
                        appearance: 'none',
                        backgroundImage: `#F5F8F8`,
                        marginLeft: '37%',
                    }}>
                    <select
                        className="d-flex justify-content-center  text-center buy-stock-unit-dropdown"
                        value={selectUnit}
                        onChange={(e) => onchangeUnits(e)}>
                        <option value="USD">USD</option>
                        <option value="UNIT">UNIT</option>
                    </select>
                    {/* <img src="/assets/settings/downRRowBlack.png" className="buy_stock_down_arrow" /> */}
                </div>

                <div className="d-flex justify-content-center align-items-center">
                    {selectUnit === 'USD' || selectUnit === '' ? (
                        <sup
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '25px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: '125%',
                                marginLeft: '1rem',
                                marginTop: '1rem',
                            }}
                            className="">
                            $
                        </sup>
                    ) : (
                        ''
                    )}

                    {selectUnit === 'USD' || selectUnit === '' ? (
                        <input
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '40px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                border: 'none',
                                textAlign: 'center',
                                width: '120px',
                                top: '5px',
                            }}
                            value={changeOptionBuy || changeOptionSell ? inputOptionValue : InputPrice}
                            onChange={(e) =>
                                changeOptionBuy || changeOptionSell ? handleChangeOption(e) : handleInputPrice(e)
                            }
                        />
                    ) : (
                        <input
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '40px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                border: 'none',
                                textAlign: 'center',
                                width: '150px',
                                top: '5px',
                            }}
                            value={changeOptionBuy || changeOptionSell ? inputOptionValueUnit : InputPrice}
                            onChange={(e) =>
                                changeOptionBuy || changeOptionSell ? handleChangeOptionUnit(e) : handleInputPrice(e)
                            }
                        />
                    )}
                </div>

                <div className="p-2 robesHood-container mt-3">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex justify-content-between">
                            <img
                                src="/assets/stockdetails/robinhood_green.png"
                                style={{
                                    width: '38px',
                                    height: '38px',
                                    borderRadius: '118px',
                                    border: '2px solid #FFF',
                                }}
                                alt="robinhood"
                                className="mt-1"
                            />
                            <div className="robesHood">
                                <div className="d-flex">
                                    <select
                                        className="form-control"
                                        style={{
                                            width: '140%',
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            border: 'none',
                                            background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8)',
                                        }}
                                        onChange={(e) => handleBrokerageDropdown(e)}
                                        value={getBrokerageDropdownData}>
                                        <option selected>Select Broker</option>
                                        {brokerDropdown?.map((ele) => {
                                            return (
                                                <>
                                                    <option value={ele.accountId}>{ele.accountName}</option>
                                                </>
                                            );
                                        })}
                                    </select>
                                    <img
                                        src="/assets/settings/downRRowBlack.png"
                                        className="buy_stock_down_arrow_brokerage"
                                        alt="img"
                                    />
                                </div>

                                <p style={{ marginLeft: '3%' }}>
                                    $ {Brokeragecash !== 'undefined' ? Brokeragecash : 0} Available
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '1.5rem' }}>
                    <p
                        style={{
                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                            fontStyle: 'normal',
                            fontSize: '12px',
                            fontFamily: 'Figtree',
                            fontWeight: '400',
                            lineHeight: '125%',
                        }}>
                        ${Brokeragecash !== 'undefined' ? Brokeragecash : 0} available
                    </p>
                </div>

                <div className="d-flex justify-content-center align-items-center mt-2">
                    <button
                        style={{
                            width: '351px',
                            height: '52px',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            borderRadius: '54px',
                            letterSpacing: '0.7px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        }}
                        onClick={handleReview}>
                        REVIEW
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default BuyStocks;
