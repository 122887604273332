import React from 'react';
import { useParams } from 'react-router-dom';

const AddBrokerage = () => {
    const { token } = useParams();

    React.useEffect(() => {
        window.location.href = `https://d5x4tuc5c72gg.cloudfront.net?ephemeral=${token}`;
    }, [token]);
    return <></>;
};

export default AddBrokerage;
