import React from 'react';
import './style.scss';

const HeadingWrapper = ({ children, labelHeading, label, onClickViewAll }) => {
    return (
        <div className="w-100">
            <div className="d-flex justify-content-between mt-4">
                <p className="main-heading-label">{labelHeading}</p>
                <p className="portfolio-view-all" onClick={onClickViewAll}>
                    {label}
                </p>
            </div>
            {children}
        </div>
    );
};

export default HeadingWrapper;
