import React, { useEffect, useState, Suspense, lazy } from 'react';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import LoaderModal from '../Login/LoaderModal';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { brokeragesRobinHoodPortFolio } from 'Api/portfolio';
import TopHolding from 'Components/reusable/influencer/cards/topHolding';
import ComponentLoading from 'Components/reusable/loading/Loading';
import { getUserId } from 'pages/analytics/slector';
import { getBrokage_id } from 'pages/Portfolio/selector';
import './style.scss';

const ChartLine = lazy(() => import('Components/reusable/graph/lineChart'));

const PortfolioHoldingStocks = () => {
    const [loading, setLoading] = useState(true);
    const [fetchTopHolding, setFetchTopHolding] = useState([]);
    const id = useSelector(getUserId);
    const brokage_id = useSelector(getBrokage_id);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const getPortFolioBrokerages = () => {
        brokeragesRobinHoodPortFolio(brokage_id)
            .then((response) => {
                setFetchTopHolding(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getPortFolioBrokerages();
        // eslint-disable-next-line
    }, []);

    const influencer = {
        firstName: 'Portfolio Holding Trades',
    };

    return (
        <div className="portfolio-holding-trades">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar />
                        </div>
                    </MediaQuery>
                    <div className="col-xl-8 col-lg-5">
                        <div className="mt-4">
                            <h2>All Holdings</h2>
                        </div>
                        {loading ? (
                            <div className="m-4 text-center">{loading && <LoaderModal />}</div>
                        ) : (
                            <div>
                                {fetchTopHolding.status === 404 ? (
                                    <div className="m-4 text-center">
                                        <h3 className="no-trades-found-trade-history-main">
                                            No trades found for this influencer
                                        </h3>
                                    </div>
                                ) : (
                                    <div className="row">
                                        {fetchTopHolding?.positions &&
                                        Array.isArray(fetchTopHolding?.positions) &&
                                        fetchTopHolding?.positions.length > 0 ? (
                                            fetchTopHolding?.positions?.map((trade, index) => (
                                                <div key={index} className="col-md-6">
                                                    <TopHolding
                                                        symbol={trade && trade.symbol}
                                                        name={trade && trade.name}
                                                        logoUrl={
                                                            trade?.logoUrl
                                                                ? trade?.logoUrl
                                                                : '/assets/images2/portfolio_icon.png'
                                                        }
                                                        amount={trade?.amount}>
                                                        <Suspense fallback={<ComponentLoading />}>
                                                            <ChartLine chartData={trade?.chartData?.data} />
                                                        </Suspense>
                                                    </TopHolding>
                                                </div>
                                            ))
                                        ) : (
                                            <div className="m-4 text-center"></div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div className="scroll-sideNotification side-notification">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
        </div>
    );
};

export default PortfolioHoldingStocks;
