import React from 'react';
import Modal from 'react-modal';

const LimitOrderModal = ({
    modalBuyIsOpenLimitOrder,
    secModalCloseLimitMarketModal,
    customStyles,
    setSelectMarketOrderModal,
    fetchStocksData,
    setFetchStocksData,
    currBid,
    onchangeUnits,
    selectUnit,
    LimitOrderInput,
    limitOrderDisable,
    setLimitOrderDisable,
    setLimitOrderInput,
    handleBrokerageDropdown,
    getBrokerageDropdownData,
    brokerDropdown,
    Brokeragecash,
    setModalBuyIsOpenLimitOrder,
    setModalReviewIsOpenLimitOrder,
}) => {
    console.log(LimitOrderInput, 'LimitOrderInput');
    return (
        <div>
            <Modal
                isOpen={modalBuyIsOpenLimitOrder}
                onRequestClose={secModalCloseLimitMarketModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div
                    style={{
                        width: '490px',
                        height: '171px',
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className=" p-3 ">
                    <div className="d-flex justify-content-between">
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                                top: '10px',
                                left: '5px',
                            }}>
                            Buy
                        </p>

                        <p
                            style={{
                                color: '#64A2FF',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                textAlign: 'center',
                                cursor: 'pointer',
                                top: '10px',
                                right: '5px',
                            }}
                            onClick={() => {
                                setSelectMarketOrderModal(false);
                                setSelectMarketOrderModal(true);
                            }}>
                            Market Order
                        </p>
                    </div>
                    <hr />

                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="">
                                <div className="d-flex" style={{ left: '10px' }}>
                                    <img
                                        src={fetchStocksData?.details?.logoUrl}
                                        style={{ width: '48px', height: '48px' }}
                                        alt="img"
                                    />
                                    <div style={{ marginLeft: '10px' }}>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1">
                                            {fetchStocksData?.details?.ticker}
                                        </p>
                                        <div>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '16px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '600',
                                                    lineHeight: '125%',
                                                    marginLeft: '4px',
                                                }}>
                                                {currBid}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="m-1">|</div>{' '}
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1 mt-2">
                                        Microsoft
                                    </p>
                                </div>
                            </div>

                            <div style={{ right: '10px' }}>
                                <div className="d-flex" style={{ left: '11px' }}>
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1">
                                        $15.26
                                    </p>
                                    <div
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                            right: '2px',
                                        }}
                                        className="">
                                        <img
                                            src="/assets/stockdetails/arrow_drop_up_black_24dp (1) 1.png"
                                            style={{ width: '10px', height: '10px', bottom: '1px' }}
                                            className="mt-1"
                                            alt="img"
                                        />
                                    </div>
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '500',
                                            lineHeight: '125%',
                                            top: '6px',
                                        }}
                                        className="">
                                        0.2%
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <img
                                        src="/assets/stockdetails/alertCircle.png"
                                        style={{ width: '15px', height: '15px', bottom: '5px' }}
                                        className="mt-1"
                                        alt="img"
                                    />
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: '125%',
                                            marginLeft: '5px',
                                        }}>
                                        Price Difference
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-3">
                        <select
                            style={{
                                width: '100px',
                                borderRadius: '69px',
                                backgroundColor: '#F5F8F8',
                                border: 'none',
                                backgroundPosition: 'right 10px bottom 50%',
                                backgroundSize: '0.625em',
                                backgroundRepeat: 'no-repeat',
                                appearance: 'none',
                                backgroundImage: `#F5F8F8`,
                            }}
                            className="d-flex justify-content-center align-items-center text-center "
                            onChange={(e) => onchangeUnits(e)}
                            value={selectUnit}>
                            <option value="USD">USD</option>
                            <option value="UNIT">UNIT</option>
                        </select>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        {selectUnit === 'USD' || selectUnit === '' ? (
                            <sup
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '25px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: '125%',
                                    marginLeft: '1rem',
                                    marginTop: '1rem',
                                }}
                                className="">
                                $
                            </sup>
                        ) : (
                            ''
                        )}

                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '40px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                border: 'none',
                                textAlign: 'center',
                                width: '150px',
                                top: '5px',
                            }}>
                            {currBid}
                        </p>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-1">
                        <p
                            style={{
                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: '125%',
                                bottom: '10px',
                            }}>
                            {/* todo  1 share */}
                        </p>
                    </div>

                    <div
                        style={{
                            width: '430px',
                            height: '121px',
                            borderRadius: '14px',
                            border: '1px solid var(--pf-secondary-geyser-dae-5-e-3, #DAE5E3)',
                            marginLeft: '15px',
                        }}
                        className="p-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                {' '}
                                <p
                                    style={{
                                        color: '#8f8f8f',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    Limit Price
                                </p>
                            </div>

                            <div className="d-flex">
                                {' '}
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        top: '2px',
                                    }}>
                                    $
                                </p>
                                <input
                                    type="text"
                                    value={LimitOrderInput}
                                    onChange={(e) => setLimitOrderInput(e.target.value)}
                                    style={{ width: '60px', border: 'none', bottom: '5px' }}
                                    disabled={limitOrderDisable}
                                />
                            </div>
                        </div>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                            }}>
                            Execute during market hours
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                Expire in 90 days
                            </p>
                        </p>

                        <p
                            style={{
                                color: '#64A2FF',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                bottom: '11px',
                            }}
                            onClick={() => setLimitOrderDisable(false)}>
                            Edit Order
                        </p>
                    </div>

                    <div
                        className=" m-3 p-3"
                        style={{
                            width: '430px',
                            height: '70px',
                            borderRadius: '14px',
                            background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #F5F8F8)',
                        }}>
                        <div className=" ">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <img
                                        src="/assets/stockdetails/robinhood_green.png"
                                        style={{
                                            width: '38px',
                                            height: '38px',
                                            borderRadius: '118px',
                                            border: '2px solid #FFF',
                                        }}
                                        alt="robinhood"
                                    />
                                    <div className="robesHood">
                                        <select
                                            className="form-control"
                                            style={{
                                                width: '140%',
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                                border: 'none',
                                                background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8)',
                                            }}
                                            onChange={(e) => handleBrokerageDropdown(e)}
                                            value={getBrokerageDropdownData}>
                                            <option value="Robinhood_0001">Sandbox Robinhood account</option>
                                            {brokerDropdown?.map((ele) => {
                                                return (
                                                    <>
                                                        <option value={ele.accountId}>{ele.accountName}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center "
                            style={{ marginTop: '1rem' }}>
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: '125%',
                                }}>
                                ${Brokeragecash ? Brokeragecash : 0} available
                            </p>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <button
                                style={{
                                    width: '351px',
                                    height: '52px',
                                    color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    borderRadius: '54px',
                                    letterSpacing: '0.7px',
                                    backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                }}
                                onClick={() => {
                                    setModalBuyIsOpenLimitOrder(false);
                                    setModalReviewIsOpenLimitOrder(true);
                                }}>
                                REVIEW
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default LimitOrderModal;
