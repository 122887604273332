import React, { useEffect, useState, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import LoaderModal from '../../pages/Login/LoaderModal';
import { fetchTrades, updatePublicTrade } from 'Api/trade';
import { InfluencerTradesList } from 'Api/setting';
import './updateTrades.scss';
import CommonModal from 'Components/reusable/settings/commonModal';
import ComponentLoading from 'Components/reusable/loading/Loading';

const UpdatedTrades = () => {
    const [selectedTrades, setSelectedTrades] = useState([]);
    const [getInfluencerTrades, setGetInfluencerTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [check, setCheck] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const { id } = useParams();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMsg, setModalMsg] = useState('');

    const getInfluencerTradesFunc = () => {
        InfluencerTradesList()
            .then((response) => {
                setSelectedTrades(response.content.transactions);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        getInfluencerTradesFunc();
    }, []);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const dataToDisplay = selectedTrades.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    // todo //const getInfluencerTradesFunc = () => {
    //     InfluencerTradesList()
    //         .then((response) => {
    //             setGetInfluencerTrades();
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setLoading(false);
    //         });
    // };

    // useEffect(() => {
    //     getInfluencerTradesFunc();
    //     // eslint-disable-next-line
    // }, []);

    const handleSubmit = () => {
        const data = {
            stocks: check,
        };
        updatePublicTrade(data)
            .then((response) => {
                // swal(response.message);
                setModalMsg(response.message);
                setModalOpen(true);
                getInfluencerTradesFunc();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <div className="container mt-4 mb-4">
                <div>
                    <div className="image d-flex flex-column justify-content-center align-items-center">
                        <div className="w-100">
                            {loading ? (
                                <div className="m-4 text-center">
                                    <h1 style={{ color: '#C0FF02' }}>{loading && <LoaderModal />}</h1>
                                </div>
                            ) : (
                                dataToDisplay.reverse().map((ele) => {
                                    const isChecked = check.some((val) => val.id === ele.id);
                                    return (
                                        <div
                                            className="m-4 trade-item "
                                            onClick={() =>
                                                isChecked
                                                    ? setCheck(check.filter((val) => val.id !== ele.id))
                                                    : setCheck([...check, ele])
                                            }
                                            key={ele.id}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <p className="m-0">{ele.symbol}</p>
                                                    <p className="m-0 px-2">{ele.name}</p>
                                                    {ele.price === 0 ? (
                                                        <p className="m-0">Amount: {ele.amount}</p>
                                                    ) : (
                                                        <p className="m-0">Price Executed: {ele.price}</p>
                                                    )}
                                                </div>
                                                <img
                                                    src={
                                                        isChecked
                                                            ? '/assets/settings/checkboxClick.png'
                                                            : '/assets/settings/checkboxUnclick.png'
                                                    }
                                                    alt="checkbox"
                                                    width="15px"
                                                    height="15px"
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>

                        <div className="d-flex justify-content-center">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`prev-btn ${currentPage === 1 ? 'disable-btn' : ''}`}>
                                Previous
                            </button>

                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === Math.ceil(selectedTrades.length / itemsPerPage)}
                                className={`next-btn ${
                                    currentPage === Math.ceil(selectedTrades.length / itemsPerPage) ? 'disable-btn' : ''
                                }`}>
                                Next
                            </button>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-sm m-2 submit-btn" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalOpen}
                    onModelClose={() => setModalOpen(false)}
                    text={modalMsg}
                    onYes={() => setModalOpen(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>
        </>
    );
};

export default UpdatedTrades;
