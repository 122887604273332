import React from 'react';
import Modal from 'react-modal';
import { customStyles1 } from './Style';
import './Style.scss';

const AnalyticsSubscriber = ({
    modalReqestMsg,
    secModalreqMsgClose,
    analystSubsImg,
    analystsubs,
    setModalConfirmAnalystIsOpen,
    setModalReqestMsg,
}) => {
    return (
        <div>
            <Modal
                isOpen={modalReqestMsg}
                onRequestClose={secModalreqMsgClose}
                style={customStyles1}
                contentLabel="Example Modal">
                <div className="container">
                    <div className="row">
                        <div className="text-center mt-3">
                            <div className="d-flex justify-content-center">
                                <div className="text-center  ">
                                    <img src={analystSubsImg} className="analyst-subs-img" alt="img" />
                                </div>
                                <div className="mt-3">
                                    <p className="analyst-subs">{analystsubs}</p>
                                </div>
                            </div>

                            <button
                                className="invite-button mt-5"
                                onClick={() => {
                                    setModalConfirmAnalystIsOpen(true);
                                    setModalReqestMsg(false);
                                }}>
                                INVITE TO BE AN ANALYST
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AnalyticsSubscriber;
