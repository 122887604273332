import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const SetTimeInForce = ({
    isOpened = false,
    onModelClose,
    item,
    item2,
    item3,
    fetchBrokerageByIdDetails,
    setNextOpen,
    setPreviousOpen,
}) => {
    const secModalClose = () => {
        onModelClose(false);
    };

    const handleConfirm = () => {
        onModelClose(false);
        setNextOpen(true);
    };

    const handleCancel = () => {
        onModelClose(false);
    };

    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={secModalClose}
            style={customStyles}
            contentLabel="Set Time In Force Modal">
            <div className="py-4">
                <div className="d-flex justify-content-between" style={{ padding: '0 2rem' }}>
                    <img
                        src="/assets/stockdetails/arrow_left_black.png"
                        style={{ width: '24px', height: '24px', textAlign: 'left', cursor: 'pointer' }}
                        alt="img"
                        onClick={() => {
                            if (setPreviousOpen) {
                                onModelClose(false);
                                setPreviousOpen(true);
                            }
                        }}
                    />
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                            margin: '0px',
                        }}>
                        Set Time In Force
                    </p>
                    <p
                        style={{
                            color: '#FF5445',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            margin: '0px',
                            cursor: 'pointer',
                        }}
                        onClick={handleCancel}>
                        Cancel
                    </p>
                </div>

                <hr style={{ opacity: 0.1 }} />
                <div
                    className="d-flex justify-content-between"
                    style={{
                        width: '100%',
                        padding: '0 2rem',
                        opacity:
                            fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType?.supportedTimeInForceList
                                ?.supportedTimeInForceList !== 'goodTillCanceled' ||
                            fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType?.supportedTimeInForceList
                                ?.supportedTimeInForceList?.length === 0
                                ? 0.7
                                : '',
                    }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}>
                            4:00 PM Today
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                                bottom: '10px',
                            }}>
                            Order will be open till market close
                        </p>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="order-will-open"
                            name="order-will-open"
                            disabled={item2 === 'goodTillCanceled' ? false : true}
                        />
                    </div>
                </div>

                <div
                    className="d-flex justify-content-between"
                    style={{
                        width: '100%',
                        opacity: item === 'goodTillCanceled' ? 0.7 : 1,
                        padding: '0 2rem',
                    }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}>
                            Oct 9, 2023
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                                bottom: '10px',
                            }}>
                            Good till canceled (Up to 90 days)
                        </p>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="good-till-cancel"
                            name="good-till-cancel"
                            disabled={item2 !== 'goodTillCanceled' ? false : true}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <button
                        style={{
                            width: '350px',
                            height: '52px',
                            borderRadius: '54px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            letterSpacing: '0.7px',
                        }}
                        onClick={handleConfirm}>
                        CONTINUE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default SetTimeInForce;
