import React, { useState, useLayoutEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import ct from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from './constant';
import PasswordEye from 'Components/reusable/PasswordEye';
import { registerPassword } from 'Api/registration';
import { getAuthFlowToken } from './selector';
import { signUpCredential } from 'globalStore/actions/signUp';

const SignUpPassword = () => {
    const [skipAction, setSkipAction] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const id = useSelector(getAuthFlowToken);
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const handleSkip = () => {
        setSkipAction(true);
    };

    const handlePassword = (e) => {
        e.preventDefault();
        const data = {
            password: password,
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + id,
            },
        };

        registerPassword(data, config)
            .then(() => {
                swal('Signup Succesful', '', 'success');
                dispatch(signUpCredential({}));
                navigate(`/`);
            })
            .catch((error) => {
                console.log(error.response, 'password error');
                swal(
                    'Password must contain at least one capital letter,small letter, one special character, and one number.',
                    '',
                    'error'
                );
            });
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <Row className="user-registration-otp">
            <Col lg="6" className="first-block">
                {!skipAction && (
                    <>
                        <LoginSlider />
                        <Button className={ct('skip-button')} onClick={handleSkip}>
                            Skip
                        </Button>
                    </>
                )}
            </Col>

            <Col lg="6" md="12" className={ct('form-container m-auto', { isVisible: !skipAction })}>
                <Col lg="7" md="10">
                    <Form>
                        <Col className="parrot-logo">
                            <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                        </Col>

                        <div className="form-group mt-5">
                            <Col className={ct('password-field')}>
                                <input
                                    className="form-control otp-input"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <PasswordEye isVisible={showPassword} setIsVisible={setShowPassword} />
                            </Col>
                        </div>

                        <div className="mt-4">
                            <button
                                className="btn border border-secondary rounded-pill m-2 confirm-btn"
                                onClick={handlePassword}>
                                CONFIRM
                            </button>
                        </div>
                    </Form>
                </Col>
            </Col>
        </Row>
    );
};

export default SignUpPassword;
