import React, { useState, useEffect } from 'react';
import ct from 'classnames';
import _debounce from 'lodash.debounce';
import TrendingList from 'Components/reusable//model/trendingList';
import SearchInput from 'Components/reusable//model/searchInput';
import { fetchTrendingStocks } from 'Api/stocksDetails';
import { searchData } from 'Api/dashboard';
import { getNews } from 'Api/influencerDashboard';
import { Allbrokerages } from 'Api/portfolio';
import './style.scss';
import '../Dashboard.css';

const Search = ({ id }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [modalIsSecOpenLink, setSecIsOpenLink] = useState(false);
    const [modalSearchOpen, setModalSearchOpen] = useState(false);
    const [searchOnchangeToggle, setSearchOnChangeToggle] = useState(false);
    const [ftechNewsDashboard, setFetchNnewsDashboard] = useState([]);
    const [fetchTrendingStockData, setFtchTrendingStockData] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setModalSearchOpen(true);
        setSecIsOpenLink(false);
        setSearchOnChangeToggle(false);
    };

    const debouncedSearch = _debounce((term) => {
        if (term.trim() !== '') {
            searchData(term)
                .then((response) => {
                    setSearchResults(response);
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
        }
    });

    useEffect(() => {
        debouncedSearch(searchTerm);
        return () => {
            debouncedSearch.cancel();
        };
        // eslint-disable-next-line
    }, [searchTerm]);

    const handleSearchOnChange = (e) => {
        setSearchOnChangeToggle(!searchOnchangeToggle);
        setSecIsOpenLink(true);
    };

    const handleSearchOnChange2 = (e) => {
        setSearchOnChangeToggle(true);
    };

    function secModalCloseLink() {
        setSecIsOpenLink(false);
    }

    const secModalClose = () => {
        setModalSearchOpen(false);
        setSearchTerm('');
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchTrendingStocks();
                setFtchTrendingStockData(response);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const fetchNews = () => {
        getNews().then((response) => {
            setFetchNnewsDashboard(response);
        });
    };

    useEffect(() => {
        fetchNews();
    }, []);

    return (
        <>
            <TrendingList
                isOpened={modalIsSecOpenLink}
                onModelClose={secModalCloseLink}
                trendingStock={fetchTrendingStockData}
                dashboard={ftechNewsDashboard}>
                <div className="input-group ">
                    <div>
                        <img
                            src="/assets/dashboardImg/Search.png"
                            alt="Search"
                            style={{
                                position: 'absolute',
                                left: '12px',
                                zIndex: '1',
                                color: '#FFF',
                                width: '20px',
                                height: '20px',
                                top: '15px',
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div className="w-100">
                        <input
                            type="search"
                            className={ct(
                                'form-control',
                                { inputWidthWithNotToggle: searchOnchangeToggle },
                                { inputWidthWithNotToggle: !searchOnchangeToggle }
                            )}
                            placeholder=""
                            aria-label="Search"
                            aria-describedby="search-addon"
                            value={searchTerm}
                            onClick={(e) => handleSearchOnChange(e)}
                            style={{
                                height: '52px',
                                borderRadius: '25px',
                                background: '#414141',
                                color: '#FFF',
                                paddingLeft: '40px',
                                border: 'none',
                            }}
                            onChange={(e) => handleSearch(e)}
                        />
                    </div>
                </div>
            </TrendingList>
            <SearchInput
                isOpened={modalSearchOpen}
                onModelClose={secModalClose}
                handleSearchChange={handleSearchOnChange2}
                handleSearch={handleSearch}
                searchResults={searchResults}
                searchText={searchTerm}
                setSecIsOpenLink={setSecIsOpenLink}
                modalIsSecOpenLink={modalIsSecOpenLink}
            />
        </>
    );
};

export default Search;
