import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const Investment = ({ isOpened = false, onModelClose, setCloseModel }) => {
    const handleComplete = () => {
        setCloseModel(false);
    };
    return (
        <Modal isOpen={isOpened} onRequestClose={onModelClose} style={customStyles} contentLabel="stock investments">
            <div className="stock-investment">
                <div className="d-flex justify-content-center mt-3">
                    <p className="stock-header-text">Investment style</p>
                </div>

                <div className="d-flex justify-content-start">
                    <p className="stock-middle-header-text">Stocks</p>
                </div>

                <div className=" p-3">
                    <p className="stock-description">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                        the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                        of type and scrambled it to make a type specimen book.
                    </p>

                    <p className="stock-description">
                        It has survived not only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
                        sheets containing.
                    </p>

                    <p className="stock-description">
                        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
                        including versions of Lorem Ipsum.
                    </p>
                </div>

                <div class=" d-flex justify-content-center px-2  mt-4  ">
                    <span class="join">
                        <button
                            className="btn btn-sm m-2 complete-btn"
                            style={{
                                width: '280px',
                                color: '#C0FF02',
                                backgroundColor: '#1F1F1F',
                                borderRadius: '54px',
                                height: '52px',
                            }}
                            onClick={handleComplete}>
                            DONE
                        </button>
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default Investment;
