import React, { useEffect, useState } from 'react';
import './style.scss';
import LineGraph from 'Components/reusable/LineGraph';
import StockChart from 'Components/reusable/LineGraph/StockChart';

const TopHolding = ({ children, symbol, name, logoUrl, amount, isBluer, position }) => {
    // const [dataPoints, setDataPoints] = useState([]);
    // useEffect(() => {
    //     if (position.chartData && position.chartData.length > 0) {
    //         const points = position.chartData.map((data) => {
    //             return {
    //                 date: new Date(data.createdAt._seconds * 1000),
    //                 value: data.equitiesValue,
    //             };
    //         });
    //         setDataPoints(points);
    //     }
    // }, [position]);
    return (
        <div
            className="d-flex mx-2 w-100 top-holding-sub-container"
            style={{
                filter: isBluer ? 'blur(6px)' : 'blur(0px)',
            }}>
            <div className="sub-top-holding-card">
                <div className="d-flex justify-content-between ">
                    <div className="pt-3 ">
                        <p className="sub-stock-symbol">
                            {symbol}
                            <p className="top-holding-sub-name">{name}</p>
                        </p>
                    </div>

                    <div>
                        <div className="mt-4 ">
                            <p className="holding-sub-amount">${amount?.toFixed(2)}</p>
                        </div>
                        <div className="d-flex mt-3 top-holding-padding-right">
                            {position?.tickerSnapshot?.ticker?.todaysChangePerc > 0 ? (
                                <i className="fas fa-angle-up fa-angle-up-color"></i>
                            ) : (
                                <i className="fas fa-angle-down fa-angle-down-color"></i>
                            )}
                            <p
                                className={
                                    position?.tickerSnapshot?.ticker?.todaysChangePerc > 0
                                        ? 'fa-angle-up-color'
                                        : 'fa-angle-down-color'
                                }>
                                {position?.tickerSnapshot?.ticker?.todaysChangePerc?.toFixed(3) ?? '0'}%
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="w-100 top-holding-children">{children}</div> */}
                {/* <LineGraph dataPoints={dataPoints} /> */}
                <div>
                    <StockChart
                        key={symbol}
                        data={position?.chartData}
                        chartId={symbol}
                        color={position?.tickerSnapshot?.ticker?.todaysChangePerc > 0 ? '#71c901' : 'red'}
                    />
                </div>
                <div className="d-flex justify-content-between mt-4">
                    <div className="top-holding-logo-bg-black">
                        <img
                            src={logoUrl ? logoUrl : '/assets/images2/portfolio_icon.png'}
                            className=" top-holding-sub-logo"
                            alt="logo"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopHolding;
