import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import SideNotificationComponent from '../../DashBoard/sideNotification/SideNotificationComponent';
import LoaderModal from '../../../pages/Login/LoaderModal';
import Navbar from '../../Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable//welcomeSection';
import TradeListing from 'Components/reusable//tradeListing';
import { getUserTopTradeAggregate } from 'Api/UserDashboard';
import './TrendingStocks.scss';

const TrendingStocks = () => {
    const [fetchData, setFetchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        getUserTopTradeAggregate()
            .then((res) => {
                setFetchData(res);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);

    let stocksData = fetchData?.content?.equityPositions;

    const influencer = {
        firstName: 'Home',
    };

    return (
        <>
            <div className="trending-stocks">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>

                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar />
                        </div>
                    </MediaQuery>

                    <div className="col-xl-8 col-lg-9 col-md-12 middle-container">
                        <div className="mt-4">
                            <h2> Trending</h2>
                        </div>

                        {loading ? (
                            <div className="m-4 text-center">{loading && <LoaderModal />}</div>
                        ) : (
                            <TradeListing stocksData={stocksData} />
                        )}
                    </div>

                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div
                                className="scroll-sideNotification"
                                style={{
                                    height: '849px',
                                    borderRadius: '25px 0px 0px 25px',
                                    backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                    bottom: '4rem',
                                    boxShadow: '0px 9px 20px 0px rgba(0, 0, 0, 0.06)',
                                    paddingTop: '2rem',
                                    paddingLeft: '2.2rem',
                                }}>
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
        </>
    );
};

export default TrendingStocks;
