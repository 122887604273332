import React from 'react';
import isValidHttpsUrl from 'util/isValidHttpsUrl';
import './style.scss';

const Analyst = ({ fetchAnalyst, fetchYTDandTotalTrade, children }) => {
    return (
        <div className="mt-3 p-3 analyst-is-subscribe w-100">
            <p className="analyst-sub-heading">Analysts</p>
            <div className="d-flex" style={{ overflowX: 'auto' }}>
                {fetchAnalyst?.map((ele) => {
                    return (
                        <>
                            <div className="m-2 analyst-influencer-card">
                                <div className="text-center">
                                    <img
                                        src={
                                            isValidHttpsUrl(ele.analystImage)
                                                ? ele.analystImage
                                                : '/assets/images/image (1).png'
                                        }
                                        className="mt-2 influencer-image-from-analyst"
                                        alt="img"
                                    />
                                    <p className="mt-2 analyst-sub-name">@{ele.analystUsername}</p>

                                    <div className="m-auto analyst-right-icon">
                                        <i class="fa-solid fa-angle-right"></i>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
            <div className="d-flex justify-content-between mt-3">
                <p className="analyst-team-performance">Team Performance</p>
                <i class="fa fa-angle-up grow-analyst-fa"></i>
            </div>
            <div className="d-flex justify-content-between p-3 team-performance">
                <img src="/assets/Influencer/trending_up_arrow.png" className="gorw-icon-analyst" alt="uparrow" />
                <p className="analyst-win-rate">32.05%</p>
                <p className="analyst-win-rate-text">Win rate</p>
            </div>
            <div className="d-flex mt-3">
                <div className="m-1 p-3 analyst-ytd-block">
                    <p className="analyst-ytd-count">
                        {/* {percentageValue} % */}
                        <div className="d-flex mt-2">
                            <p className="analyst-ytd-text">YTD Return%</p>
                            <img src="/assets/images2/Outline.png" className="switch-icon" alt="outline" />
                        </div>
                    </p>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: '72px',
                        borderRadius: '15px',
                        background: '#DAE5E3',
                    }}
                    className="m-1 p-3 analyst-total-trade">
                    <p>
                        {fetchYTDandTotalTrade?.totalTrades}
                        <div className="d-flex mt-2">
                            <p className="total-trade-sub-text">Total Trades</p>
                        </div>
                    </p>
                </div>
            </div>

            {children}
        </div>
    );
};

export default Analyst;
