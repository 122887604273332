export const TOGGLE_DAYS = [
    {
        active: true,
        key: '1D',
        value: 'day',
    },
    {
        active: false,
        key: '1W',
        value: 'week',
    },
    {
        active: false,
        key: '1M',
        value: 'month',
    },
    {
        active: false,
        key: '1Y',
        value: 'year',
    },
    {
        active: false,
        key: 'All',
        value: 'year',
    },
];

export const CUSTOM_STYLES3 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '415px',
        height: '478px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
        position: 'absolute',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};

export const CUSTOM_STYLES4 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '325px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
        position: 'absolute',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};
