export const urls = {
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    registerEmail: `/api/auth/v2/register/email`,
    registerUserName: `${process.env.REACT_APP_API_URL}/api/auth/v2/register/username`,
    registerPassword: `/api/auth/v2/register/password`,
    fetchTrendingStocks: `/api/stocks/trending`,
    Login: `${process.env.REACT_APP_API_URL}/api/auth/v2/login/password`,
    fetchNews: `${process.env.REACT_APP_API_URL}/api/stocks/news`,
    searchData: `/api/stocks/search?asset=`,
    forgetPassword: `/api/auth/v2/forgot/phone`,
    resetCode: `/api/auth/v2/forgot/otp`,
    resetPassword: `/api/auth/v2/forgot/password`,
    register: `${process.env.REACT_APP_API_URL}/api/auth/register`,
    fetchInfluencer: `/api/influencers`,
    fetchInfluencerById: `/api/influencers/influencer`,
    fetchProfile: `/api/users`,
    brokerageDetails: `/api/front/user/brokerages`,
    deleteBrokerage: `/api/front/user/brokerage`,
    fractionalTrade: `/api/front/trade`,
    totalEarning: `${process.env.REACT_APP_API_URL}/api/influencers/earnings`,
    tierPlan: `/api/influencers/update-paid-tier`,
    fetchTrades: `/api/influencers/list-trades`,
    updatePublicTrade: '/api/influencers/update-public-trades',
    becomeInfluencer: `${process.env.REACT_APP_API_URL}/api/influencers/apply`,
    InfluencerfollowerApi: `/api/influencers/followers`,
    InfluencersubscriberApi: `/api/influencers/subscribers`,
    pendingOrderCanelApi: '/api/front/brokerages/Robinhood_001/transactions',
    userFollowerSubscriber: `${process.env.REACT_APP_API_URL}/api/users/fetch/following`,
    fetchUserSubscriber: `/api/users/fetch/subscriptions`,
    fetchUserFollwing: `/api/users/fetch/following`,
    DeleteAccount: `/api/auth/v2/account`,
    CreateProduct: `/api/influencers/create-product`,
    fetchAvtar: `/api/auth/v2/avatars`,
    Notification: `${process.env.REACT_APP_API_URL}/api/notification/v2`,
    RegisterAvtar: `/api/auth/v2/register/avatar`,
    FilterHigestReturn: `/api/influencers/get-featured-influencers?sort=highestReturn`,
    FilterHigestFollowers: `${process.env.REACT_APP_API_URL}/api/influencers/get-featured-influencers?sort=highestFollowers`,
    LowestSubscription: `${process.env.REACT_APP_API_URL}/api/influencers/get-featured-influencers?sort=lowestSubscription`,
    HighestSubscription: `${process.env.REACT_APP_API_URL}/api/influencers/get-featured-influencers?sort=highestSubscription`,
    HandleAnalystRequest: `/api/analyst/requests?status=pending&page=1`,
    getStockDetailGraph: `/api/stocks/polygon/aggregates`,
    getInfluencerGraph: `/api/influencers/graph`,
    CreateReferal: `/api/referral`,
    SupportTicketGetApi: `${process.env.REACT_APP_API_URL}/api/support/tickets`,
    RaiseSupportTicket: `${process.env.REACT_APP_API_URL}/api/support/raise-ticket`,
    TopSpotlight: `/api/influencers/featured`,
    frontUserChart: `/api/front/user/chart`,
    InfluencerTopHolding: `/api/influencers/top-holdings`,
    refreshToken: '/api/auth/v2/token/renew',
    PaymentSucessApi: '/api/users/subscribed/success',
    AnalystInvite: `/api/analyst/invite`,
    getUsersCards: '/api/users/cards',
    showTrades: '/api/influencers/show-trades',
    getInfluencer: '/api/analyst/get/influencer',
    averageTrades: '/api/influencers/average-trades',
    influencerReview: '/api/influencers/reviews',
    userReview: '/api/users/review',
    userFollow: '/api/users/follow',
    userSubscribe: '/api/users/subscribe',
    stripeSubscribeCheckoutSession: '/api/subscriptions/stripe/user/subscribe',
    stripePortalSession: '/api/subscriptions/stripe/portal/session/create',
    tradeHistory: `/api/front/user/brokerages/transactions`,
    markDefaultCard: '/api/users/mark-default-card',
    addCard: '/api/users/add-card',
    getDefaultBroker: '/api/users/brokerages/get-default-broker',
    setDefaultBrokerage: '/api/front/user/brokerages/set-default-brokerage',
    getBrokerageById: '/api/front/user/brokerages',
    updateProfile: '/api/influencers/update-profile',
    getUser: '/api/users',
    unsubscribeUser: '/api/users/unsubscribe',
    unFollowingUser: '/api/users/follow',
    updateProfilePicture: '/api/users/avatar',
    updateAnalystRequest: '/api/analyst/handle-request',
    updateReferralRedeem: '/api/referral/redeem',
    hideInfluencer: '/api/influencers/hide',
    removeAnalyst: '/api/analyst/remove',
    userBrokerages: '/api/front/user/brokerages',
    stockDetail: '/api/stocks',
    stocksimulateTradeApi: '/api/front/simulate/trade ',
    churnRate: '/api/influencers/churn-rate',
    getAnalystTradeListApi: 'api/analyst/trades',
    ManageAnalystSelectedTradeApi: '/api/influencers/save-analyst-trades',
    getYTDandTotalTradeApi: '/api/front/user/portfolio/metrics',
    registerPhone: '/api/auth/v2/register/phone',
    registerOTP: '/api/auth/v2/register/otp',
    removeMangeAnalystApi: '/api/analyst/remove',
    hideStockStatusApi: '/api/influencers/stock-status',
    InfluencerProfitLossPerformanceGraph: '/api/front/user/chart/profit-loss',
    InfluencerProfitLossPerformanceGraphYear: '/api/front/user/chart/profit-loss?range=year',
    userProfitLossGraph: '/api/front/user/chart/profit-loss',
    removeCardPost: '/api/users/remove-card',
    InfluencerTradesListApi: 'api/front/user/brokerages/Robinhood_0001/transactions?count=1000',
    UserTopTradesAggregateApi: '/api/front/user/portfolio/aggregate',
    marketIndicates: '/api/stocks/market-indices',
    aggregatePositionApi: '/api/front/user/portfolio/aggregate',
    InfluencerTradesListApi: 'api/front/user/brokerages/Robinhood_0001/transactions?count=1000',
    showSelectedTrades: '/api/analyst/show-selected-trades',
};
