import React, { createContext, useState, useContext } from 'react';
import App from '../../App';

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  const lightColors = {
    background: '#F5F5F5',
    textPrimary: '#1F1F1F',
    textSecondary:'#8F8F8F',
   
  };

  const darkColors = {
    background: '#1F1F1F',
    // text: '#f4f4f4',
    textPrimary: '#F5F5F5',
    textSecondary: '#8F8F8F'
  };

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const themeContextValue = {
    theme,
    colors: theme === 'light' ? lightColors : darkColors,
    toggleTheme
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
    <App/>
    </ThemeContext.Provider>
  );
};


export default ThemeContext;