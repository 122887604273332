import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './style.scss';

function DropdownMenu({ children, links = [], setContainer }) {
    return (
        <DropdownButton title={children} className="dropdown-children-container">
            {links.map((data, index) => (
                <Dropdown.Item key={index} onClick={() => setContainer(data.value)}>
                    {data.title}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}

export default React.memo(DropdownMenu);
