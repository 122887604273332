export const OPTIONS = [
    {
        name: 'Past 24 hours',
        value: 'past24hrMetrics',
    },
    {
        name: 'Today',
        value: 'dayMetrics',
    },
    {
        name: 'Week',
        value: 'weekMetrics',
    },
    {
        name: 'Month',
        value: 'monthMetrics',
    },
    {
        name: 'Year',
        value: 'yearMetrics',
    },
    {
        name: 'YTD',
        value: 'ytdMetrics',
    },
    {
        name: 'Total',
        value: 'totalMetrics',
    },
];
