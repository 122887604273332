import React from 'react';
import ct from 'classnames';
import { Button } from 'react-bootstrap';
import DropdownMenu from 'Components/reusable/dropdown';
import BuyingPower from 'Components/reusable/buyingPower';
import InfluencerBroker from 'Components/reusable/graph/InfluencerBroker';
import './style.scss';

const BrockragesGraph = ({
    brokerDropdown,
    setContainer,
    selectDropdown,
    onChangeSelect,
    buyingPower,
    performanceValue,
    acValueGraph,
    setPerformanceValue,
    toggleDays,
    onClickToggleDays,
}) => {
    return (
        <div className="broker-graph-main">
            <div className="d-flex justify-content-between broker-graph">
                <div className="broker-dropdown w-100">
                    <DropdownMenu
                        links={brokerDropdown}
                        setContainer={setContainer}
                        value={selectDropdown}
                        onChange={onChangeSelect}>
                        <div className="d-flex justify-content-between p-3 all-brokers">
                            <div className="d-flex">
                                <img
                                    src="/assets/images2/portfolio_icon.png"
                                    alt="portfolio icon"
                                    className="broker-portfolio-icon"
                                />
                                <div>
                                    <p className="drop-down-para">
                                        {selectDropdown}
                                        <p className="brockrages-label">Brokerage</p>
                                    </p>
                                </div>
                            </div>

                            <div className="down-arrow">
                                <i className="fa fa-angle-down p-2"></i>
                            </div>
                        </div>
                    </DropdownMenu>
                </div>

                <BuyingPower buyingPower={buyingPower} />
            </div>

            <div className="p-4 graph-value-container">
                <div>
                    <p className="account-value-label">Account Value</p>
                    <p className="performance-value-label">$ {performanceValue}</p>
                </div>

                <div>
                    <div className="d-flex justify-content-center" style={{ height: '300px' }}>
                        <InfluencerBroker graphData={acValueGraph} setValue={setPerformanceValue} />
                    </div>

                    <div className="d-flex justify-content-between graph-button-container">
                        {toggleDays.map((data, index) => (
                            <Button
                                key={index}
                                className={ct({ activeEarning: data.active })}
                                onClick={() => {
                                    onClickToggleDays(data, index);
                                }}>
                                {data.key}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrockragesGraph;
