import React, { useState, Suspense, useEffect } from 'react';
import isValidHttpsUrl from 'util/isValidHttpsUrl';
import { getAnalystRequestApi, updateAnalystRequest } from 'Api/setting';
import ComponentLoading from 'Components/reusable/loading/Loading.js';
import SureModel from '../sureModel';
import './style.scss';

const BecomeAnalyst = ({ id, setLoading, setModalMsg, setModalOpen, setModalErr }) => {
    const [fetchAnalystPendingRequest, setFetchAnalystPendingRequest] = useState([]);
    const [modalConfirmAcceptAnalystIsOpen, setmodalConfirmAcceptAnalystIsOpenIsOpen] = useState(false);
    const [modalConfirmDecilneAnalystIsOpen, setmodalConfirmDeclineAnalystIsOpenIsOpen] = useState(false);

    const getAnalystRequest = () => {
        getAnalystRequestApi()
            .then((response) => {
                setFetchAnalystPendingRequest(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleAcceptAnalyst = async () => {
        setmodalConfirmAcceptAnalystIsOpenIsOpen(false);

        const data = {
            status: 'accept',
        };

        try {
            const response = await updateAnalystRequest(id, data);
            setModalMsg('Request sent successfully.');
            setModalOpen(true);
            getAnalystRequest();
            return response;
        } catch (error) {
            setModalMsg(error?.response?.data?.errors?.message || 'An error occurred');
            setModalErr(true);
        }
    };

    const handleDeclineAnalyst = async () => {
        setmodalConfirmDeclineAnalystIsOpenIsOpen(false);

        const data = {
            status: 'reject',
        };

        try {
            const response = await updateAnalystRequest(id, data);
            setModalMsg('Request sent successfully.');
            setModalOpen(true);
            getAnalystRequest();
            return response;
        } catch (error) {
            setModalMsg(error?.response?.data?.errors?.message || 'An error occurred');
            setModalErr(true);
        }
    };

    const handleAcceptAnalystInfluencer = (analystId) => {
        setmodalConfirmAcceptAnalystIsOpenIsOpen(true);
    };

    useEffect(() => {
        getAnalystRequest();
    }, []);

    return (
        <div className="become-analyst-setting">
            {Boolean(fetchAnalystPendingRequest.length) &&
                fetchAnalystPendingRequest?.map((ele) => (
                    <div className="d-flex justify-content-between mt-3 left-margin" key={ele.influencerId}>
                        <div className="d-flex">
                            <div>
                                <img
                                    src={
                                        isValidHttpsUrl(ele.analystImage)
                                            ? ele.analystImage
                                            : '/assets/images/image (1).png'
                                    }
                                    className="analyst-user-image"
                                    alt="img"
                                />
                                <img src="/assets/images/orange_icon.png" className="orange-icon" alt="img" />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="mt-2">
                                <p className="influencerfullname">{ele.influencerFullname}</p>
                                <p className="influencerUsername">@{ele.influencerUsername}</p>
                            </div>
                        </div>
                        <div>
                            <button className="accept-btn" onClick={() => handleAcceptAnalystInfluencer(ele.analystId)}>
                                Accept
                            </button>
                            <button
                                className="decline-btn"
                                onClick={() => {
                                    setmodalConfirmDeclineAnalystIsOpenIsOpen(true);
                                }}>
                                Decline
                            </button>
                        </div>
                    </div>
                ))}

            {!Boolean(fetchAnalystPendingRequest.length) && (
                <h5 className="d-flex justify-content-center align-item-center mt-5">Sorry! No Analyst Request</h5>
            )}

            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={modalConfirmAcceptAnalystIsOpen}
                    onModelClose={() => {
                        setmodalConfirmAcceptAnalystIsOpenIsOpen(false);
                    }}
                    text="Are you sure you want to become analyst of this influncer ?"
                    onYes={handleAcceptAnalyst}
                    onNo={() => {
                        setmodalConfirmAcceptAnalystIsOpenIsOpen(false);
                    }}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={modalConfirmDecilneAnalystIsOpen}
                    onModelClose={() => {
                        setmodalConfirmDeclineAnalystIsOpenIsOpen(false);
                    }}
                    text="Are you sure you don't want to become analyst of this influencer ?"
                    onYes={handleDeclineAnalyst}
                    onNo={() => {
                        setmodalConfirmDeclineAnalystIsOpenIsOpen(false);
                    }}
                />
            </Suspense>
        </div>
    );
};

export default BecomeAnalyst;
