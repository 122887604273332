import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const OptionCall = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate(`/dashboard/trending-stocks/stocks-details/${id}/options/option-call/next`);
    };
    return (
        <>
            <div class="container mt-4 mb-4 p- d-flex justify-content-center">
                <div class="card p-4" style={{ backgroundColor: 'black', color: 'white' }}>
                    <div class=" image d-flex flex-column justify-content-center align-items-center">
                        <div
                            class="card"
                            style={{
                                width: '50rem',
                                backgroundColor: 'black',
                                color: 'white',
                                border: '1px solid grey',
                            }}>
                            <div class="card-header text-center">
                                <h1 className="mt-2">Options</h1>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-center m-5">
                                <p className="m-4 text-center" style={{ fontSize: '20px' }}>
                                    Apple
                                </p>
                                <p className="m-4 text-center" style={{ fontSize: '20px' }}>
                                    $164.44 (4.4%)
                                </p>
                            </div>

                            <div
                                className=" m-4"
                                style={{
                                    borderTopRightRadius: '28px',
                                    borderTopLeftRadius: '28px',
                                    border: '1px solid grey',
                                }}>
                                <div className="text-center mt-2">
                                    <h3>The Greeks</h3>
                                </div>
                                <div className="d-flex justify-content-evenly m-4">
                                    <p className=" m-2">
                                        -0.5421
                                        <div>Delta</div>
                                    </p>
                                    <p className=" m-2">
                                        0.01245
                                        <div>Gamma</div>
                                    </p>
                                    <p className=" m-2">
                                        -0.3245
                                        <div>Theta</div>
                                    </p>
                                </div>
                                <div className="d-flex justify-content-around m-4">
                                    <p className=" m-2">
                                        -0.0456
                                        <div>Vega</div>
                                    </p>
                                    <p className=" m-2">
                                        -0.0456
                                        <div>Rho</div>
                                    </p>
                                </div>
                            </div>

                            <div style={{ border: '1px solid grey' }}>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Open</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">$160</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Prev Close</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">$160</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Today's High-Low</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">$160-$155</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Volume</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">68 M</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Average Volume</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">40 M</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Market Cap</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">2,52,56 M</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">52 Week High-Low</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">$ 182-$ 142</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">PE Ratio</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">23.5</div>
                                </div>
                                <div className="d-flex justify-content-between m-4">
                                    <div className="m-4">Dividend Yield</div>
                                    <div style={{ color: 'red' }} className="m-4">
                                        -----------------------------------
                                    </div>
                                    <div className="m-4">8.2%</div>
                                </div>
                                <div className="d-flex justify-content-center" style={{ backgroundColor: 'black' }}>
                                    <button
                                        className="btn btn-sm m-2 mb-3"
                                        style={{
                                            width: '45rem',
                                            backgroundColor: 'black',
                                            color: '#C0FF02',
                                            borderRadius: '28px',
                                            height: '50px',
                                            border: '1px solid grey',
                                        }}
                                        onClick={handleSubmit}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OptionCall;
