import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import Modal from 'react-modal';
import LoaderModal from '../../pages/Login/LoaderModal';
import { getInfluencerByID } from 'Api/influencerDashboard';
import { createProduct, updateTierPlan } from 'Api/setting';
import { useDispatch } from 'react-redux';
import { userLoggedIn } from 'globalStore/actions/login';

const TierPlan = () => {
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const [modalIsSecOpen, setSecIsOpen] = useState(false);
    const [updateTier, setUpdateTier] = useState('');
    const [createTier, setCreateTier] = useState('');
    const [updateData, setUpdateData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateName, setUpdateName] = useState('');
    const [updateDescp, setUpdateDescp] = useState('');
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            userLoggedIn({
                active: 'TierPlan',
            })
        );
    }, []);

    const influencerById = () => {
        setLoading(true);
        getInfluencerByID(id)
            .then((response) => {
                setFetchInfluencer(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error, 'catch error');
                setLoading(false);
            });
    };

    useEffect(() => {
        influencerById();
        // eslint-disable-next-line
    }, []);

    const handleCreatePlan = (input) => {
        setSecIsOpen(false);
        setLoading(true);
        const data = {
            amount: input === 'create' ? createTier : updateTier,
        };

        updateTierPlan(data)
            .then((response) => {
                input === 'create' && setUpdateTier('');
                setLoading(false);
                swal(
                    input === 'create' ? 'Tier Plan Created Successfully' : 'Tier Plan Updated Successfully',
                    '',
                    'success'
                );
                window.location.reload();
                input === 'create' && setUpdateTier('');
            })
            .catch((error) => {
                setSecIsOpen(false);
                setLoading(false);
                swal(error?.response?.data?.errors?.message, '', 'warning');
                console.error(error);
            });

        const TierData = {
            name: updateName,
            description: updateDescp,
            amount: updateTier,
        };

        try {
            (async () => {
                await createProduct(TierData);
            })();
        } catch (error) {
            swal(error?.response?.data?.errors?.message, '', 'warning');
            setSecIsOpen(false);
        }
    };

    function secModalClose() {
        setSecIsOpen(false);
    }

    const customStyles2 = {
        content: {
            top: '90%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            backgroundColor: 'black',
            color: 'white',
            height: '50%',
            borderRadius: '28px',
        },
    };

    return (
        <>
            <div style={{ backgroundColor: '' }} className="mt-3 text-center">
                <h1 style={{ color: 'black' }}>Tier Plan</h1>

                <div class="container mt-4 mb-4 p- d-flex justify-content-center">
                    <div class="card p-4">
                        <div class=" image d-flex flex-column justify-content-center align-items-center">
                            <div class="card" style={{ width: '100%' }}>
                                <div class="card-header d-flex justify-content-center"></div>

                                <div className="d-flex justify-content-center">
                                    <div className="m-3">$</div>{' '}
                                    <input
                                        type="text"
                                        className="form-control w-25 m-2"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={
                                            updateData
                                                ? updateTier
                                                : ftechInfluencer &&
                                                  ftechInfluencer.influencer &&
                                                  ftechInfluencer.influencer.influencer.amount
                                        }
                                        onChange={(e) => {
                                            setUpdateData(true);
                                            setUpdateTier(e.target.value);
                                        }}
                                    />
                                    {updateData ? (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control w-25 m-2"
                                                placeholder="Enter Name"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                value={updateName}
                                                onChange={(e) => {
                                                    setUpdateName(e.target.value);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {updateData ? (
                                        <>
                                            <input
                                                type="text"
                                                className="form-control w-25 m-2"
                                                placeholder="Enter Description"
                                                aria-label="descp"
                                                aria-describedby="basic-addon1"
                                                value={updateDescp}
                                                onChange={(e) => {
                                                    setUpdateDescp(e.target.value);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <button
                                        className="btn  btn-sm m-2"
                                        style={{
                                            backgroundColor: '#C0FF02',
                                            width: '150px',
                                            borderRadius: '25px',
                                        }}
                                        onClick={() => handleCreatePlan('update')}>
                                        Update
                                    </button>
                                    <button
                                        className="btn  btn-sm m-2"
                                        style={{
                                            backgroundColor: 'red',
                                            width: '150px',
                                            borderRadius: '25px',
                                        }}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                        <table>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Username</th>
                                <th>Price</th>
                            </tr>
                            <tr>
                                <td>
                                    {ftechInfluencer &&
                                        ftechInfluencer.influencer &&
                                        ftechInfluencer.influencer.influencer.firstName}
                                </td>
                                <td>
                                    {ftechInfluencer &&
                                        ftechInfluencer.influencer &&
                                        ftechInfluencer.influencer.influencer.lastName}
                                </td>
                                <td>
                                    {ftechInfluencer &&
                                        ftechInfluencer.influencer &&
                                        ftechInfluencer.influencer.influencer.userName}
                                </td>
                                <td>
                                    {ftechInfluencer &&
                                        ftechInfluencer.influencer &&
                                        ftechInfluencer.influencer.influencer.amount}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <button
                    className="btn btn-info text-light"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setSecIsOpen(true)}>
                    Create Another Tier Plan
                </button>

                <div className="collapse container " id="collapseExample" style={{ backgroundColor: 'black' }}>
                    <form>
                        <div className="form-group">
                            <label style={{ color: 'white' }} className="mb-2">
                                Enter Monthly Planning Price
                            </label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    Price
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Price"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={createTier}
                                    onChange={(e) => setCreateTier(e.target.value)}
                                />
                            </div>
                            <div className="valid-feedback">Valid.</div>
                            <div className="invalid-feedback">Please fill out this field.</div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm m-2"
                                    style={{ width: '200px', backgroundColor: 'black', color: '#C0FF02' }}
                                    onClick={() => handleCreatePlan('create')}>
                                    Create Plan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <Modal
                isOpen={modalIsSecOpen}
                onRequestClose={secModalClose}
                style={customStyles2}
                contentLabel="Example Modal">
                <button
                    onClick={secModalClose}
                    style={{
                        border: 'none',
                        backgroundColor: 'black',
                        color: 'red',
                    }}>
                    X
                </button>
                <div className="container text-center" style={{ width: '100%' }}>
                    <div className="">
                        <h3>Add Plan</h3>
                        <hr />

                        <p>Enter Monthly Planning Price</p>
                        <input
                            type="text"
                            className="form-control w-100"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            value={createTier}
                            onChange={(e) => setCreateTier(e.target.value)}
                        />
                        <button
                            className="btn btn-info m-2"
                            style={{
                                width: '100%',
                                textDecoration: 'none',
                                backgroundColor: 'black',
                                color: 'white',
                            }}
                            onClick={() => handleCreatePlan('create')}>
                            Create Plan
                        </button>
                    </div>
                </div>
            </Modal>
            {loading && <LoaderModal />}
        </>
    );
};

export default TierPlan;
