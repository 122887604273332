import React, { useState, useLayoutEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import ct from 'classnames';
import { useMediaQuery } from 'react-responsive';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from './constant';
import { registerAvatar } from 'Api/registration';

const ProfilePic = () => {
    const [skipAction, setSkipAction] = useState(false);
    const [inputDoc, setInputDoc] = useState('');
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const handleSkip = () => {
        setSkipAction(true);
    };

    function handleChange(event) {
        setInputDoc(event.target.files[0]);
    }

    const handleProfilePic = (e) => {
        e.preventDefault();
        const data = {
            imageUrl: inputDoc.name,
        };

        registerAvatar(data)
            .then((response) => {
                swal('Image update successful', '', 'success');
                window.location.reload();
            })
            .catch((error) => {
                swal('sorry', error.message, 'error');
            });
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <div>
            {' '}
            <Row className="user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container m-auto', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />

                                <h5 className="mt-5">Add a Profile Picture</h5>
                            </Col>

                            <div className="form-group mt-5">
                                <Col className={ct('password-field')}>
                                    <input className="form-control otp-input" type="file" onChange={handleChange} />
                                </Col>
                            </div>

                            <div className="mt-5">
                                <button
                                    className="btn border border-secondary rounded-pill m-2 confirm-btn"
                                    onClick={(e) => handleProfilePic(e)}>
                                    NEXT
                                </button>

                                <h6
                                    className="text-center mt-3 skipStyle"
                                    onClick={() => navigate(`/signup/code/email/username/profilePic/profilegallery`)}>
                                    Skip for now
                                </h6>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default ProfilePic;
