import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const TradeOptionsCall = ({ isOpened = false, onModelClose, setModalTradeOption, setModalTradeOptionMarket }) => {
    const secModalClose = () => {
        onModelClose(false);
    };

    const handleContinued = () => {
        setModalTradeOption(false);
        setModalTradeOptionMarket(true);
    };

    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={secModalClose}
            style={customStyles}
            contentLabel="Trade Option Call Modal">
            <div className="p-3 trade-option-call">
                <p
                    style={{
                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        fontStyle: 'normal',
                        fontSize: '18px',
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        textAlign: 'center',
                    }}>
                    Trade Options
                </p>

                <hr style={{ opacity: 0.10000000149011612 }} />

                <div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="d-flex">
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                       
                                    }}>
                                    $ 190.00 |
                                </p>

                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginLeft: '5px',
                                    }}>
                                    10 Apr{' '}
                                    <span
                                        style={{
                                            color: '#71C901',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            marginLeft: '5px',
                                        }}>
                                        CALL
                                    </span>
                                </p>
                            </div>
                            <div className="d-flex">
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                        bottom: '10px',
                                       
                                    }}>
                                    EXP
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                      
                                        bottom: '10px',
                                        marginLeft: '5px',
                                    }}>
                                    15 JUN
                                </p>
                            </div>
                        </div>
                        <div>
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                $ 20.90
                            </p>
                            <p
                                style={{
                                    color: '#FF5445',
                                    fontStyle: 'normal',
                                    fontSize: '11px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    letterSpacing: '0.55px',
                                    bottom: '10px',
                                    marginLeft: '20px',
                                }}>
                                2.46%
                            </p>
                        </div>
                    </div>
                </div>
                <p
                    style={{
                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        fontStyle: 'normal',
                        fontSize: '18px',
                        fontFamily: 'Figtree',
                        fontWeight: '600',
                        lineHeight: 'normal',
                      
                    }}>
                    The Greeks
                </p>
                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',

                                opacity: 0.5,
                            }}>
                            Delta
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                bottom: '10px',
                            }}>
                            -0.5421
                        </p>
                    </div>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',

                                opacity: 0.5,
                            }}>
                            Gamma
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                bottom: '10px',
                            }}>
                            0.01245
                        </p>
                    </div>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',

                                opacity: 0.5,
                            }}>
                            Theta
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                bottom: '10px',
                            }}>
                            -0.3245
                        </p>
                    </div>
                </div>

                <div className="d-flex justify-content-between" style={{ width: '170px' }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',

                                opacity: 0.5,
                            }}>
                            Vega
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                bottom: '10px',
                            }}>
                            0.1856
                        </p>
                    </div>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',

                                opacity: 0.5,
                            }}>
                            Rho
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                bottom: '10px',
                            }}>
                            -0.0456
                        </p>
                    </div>
                </div>

                <p
                    style={{
                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        fontStyle: 'normal',
                        fontSize: '18px',
                        fontFamily: 'Figtree',
                        fontWeight: '600',
                        lineHeight: 'normal',
                      
                    }}>
                    Overviews
                </p>

                <div className="">
                    <div style={{ width: '100%' }}>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                Today’s High - Low
                            </p>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                $ 315.26
                            </p>
                            <img src="/assets/stockdetails/meterLine.png" alt="meterline" style={{ width: '52px', height: '4px' }} />
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                $ 300.26
                            </p>
                        </div>

                        <div className="d-flex justify-content-between mt-4" style={{ width: '100%' }}>
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                52 week’s High - Low
                            </p>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                $ 415.12
                            </p>
                            <img src="/assets/stockdetails/meterLine.png" alt="meterline" style={{ width: '52px', height: '4px' }} />
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                $ 201.2
                            </p>
                        </div>
                    </div>

                    <hr style={{ opacity: 0.10000000149011612 }} />
                    <div>
                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Prev. Open
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    $ 300
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Volume
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    68 M
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    PE Ratio
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    23.5
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Dividend Yields
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    15%
                                </p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Prev. Close
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    $ 300
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Avg Volume
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    68 M
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Market Cap
                                </p>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    2,52,56 M
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-4">
                    <p
                        style={{
                            color: '#1F1F1F',
                            fontStyle: 'normal',
                            fontSize: '12px',
                            fontFamily: 'Figtree',
                            fontWeight: '400',
                            lineHeight: '125%',
                            opacity: 0.5,
                        }}>
                        $2,457.90 available
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <button
                        style={{
                            width: '100%',
                            height: '52px',
                            borderRadius: '54px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            letterSpacing: '0.7px',
                        }}
                        onClick={handleContinued}>
                        CONTINUE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default TradeOptionsCall;
