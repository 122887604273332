import React from 'react';
import isValidHttpsUrl from 'util/isValidHttpsUrl';

const Card = ({ index, image, onClick, name }) => {
    return (
        <div className="d-flex mt-3 influencer-user-card" key={index}>
            <div>
                <img
                    src={isValidHttpsUrl(image) ? image : '/assets/images/image (1).png'}
                    className="user-image"
                    alt="img"
                />
                <img src="/assets/images/orange_icon.png" className="orange-icon" alt="img" />
            </div>
            <div style={{ marginLeft: '10px' }} className="mt-2">
                <p className="user-name" onClick={onClick}>
                    @{name}
                </p>
            </div>
        </div>
    );
};

export default Card;
