import React from 'react';
import { ClipLoader } from 'react-spinners';

const LoaderModal = () => {
  return (
    <div className="loader-modal">
      <div className="loader-content">
        <ClipLoader size={100} color="#71C901" />
      </div>
    </div>
  );
};

export default LoaderModal;