import React from 'react';
import './style.scss';

const TopTradesCard = ({ logoUrl, symbol, amount, totalReturn, returnPercentage }) => {
    const DEFAULT_LOGO_URL = '/assets/dashboardImg/logoCrop512 2.png';

    const getColorBasedOnValue = (value) => (value > 0 ? 'green' : 'red');

    const getProfitLossPercentageDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        return isPositive ? `+${value?.toFixed(2)}%` : `${value?.toFixed(2)}%`;
    };

    const getProfitLossDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        const formatted = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);

        return isPositive ? `+${formatted}` : `${formatted}`;
    };

    return (
        <div className="d-flex justify-content-between p-3 mb-3 stock-card-endUser">
            <div className="d-flex">
                <div className="d-flex justify-content-center align-items-center stock-card-logo-container">
                    <img
                        src={logoUrl || DEFAULT_LOGO_URL}
                        alt="Logo"
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = DEFAULT_LOGO_URL;
                        }}
                    />
                </div>
                <div>
                    <div className="d-flex w-100">
                        <div>
                            <p className="stock-card-symbol">{symbol}</p>
                        </div>
                    </div>

                    <p className="stock-card-amount">{amount} shares</p>
                </div>
            </div>

            <div>
                <div className="stock-card-values">
                    <span
                        className="font-size-15"
                        style={{
                            color: getColorBasedOnValue(totalReturn),
                        }}>
                        {getProfitLossDisplay(totalReturn)}
                    </span>
                    <p
                        className="font-size-12"
                        style={{
                            color: getColorBasedOnValue(returnPercentage),
                        }}>
                        {getProfitLossPercentageDisplay(returnPercentage)}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TopTradesCard;
