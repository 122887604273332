import React from 'react';
import * as CONSTANT from './constant';
import './style.scss';

const FilterDropdown = ({
    handleHigestReturn,
    handleHigestFollowers,
    blueTickClickHigestReturn,
    blueTickClickHigestFollowers,
    blueTickClickLowestSubscription,
    blueTickClickHigestSubscription,
    handleLowestSubscription,
    handleHigestSubscription,
    children,
}) => {
    return (
        <div class="dropdown">
            <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                {children}
            </a>

            <ul
                class="dropdown-menu"
                aria-labelledby="dropdownMenuLink"
                style={{ padding: '0 1rem', minWidth: '300px' }}>
                <li>
                    <p className="mt-4 filter-text">Filter By</p>
                </li>
                <li>
                    <div className="d-flex justify-content-between ">
                        <div>
                            <p className=" drop-item" onClick={handleHigestReturn}>
                                Highest returns
                            </p>
                        </div>

                        <div className="blue-tik-icon">
                            {blueTickClickHigestReturn && (
                                <div className="blue-tik-icon">
                                    <img src={CONSTANT.BLUE_TIK_ICON} alt="blue tick icon" className="w-100" />
                                </div>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                        <div>
                            <p className="drop-item" onClick={handleHigestFollowers}>
                                Highest followers
                            </p>
                        </div>
                        {blueTickClickHigestFollowers && (
                            <div className="blue-tik-icon">
                                <img src={CONSTANT.BLUE_TIK_ICON} alt="blue tick icon" className="w-100" />
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                        <div>
                            <p className=" drop-item" onClick={handleLowestSubscription}>
                                Highest to lowest subscription rate
                            </p>
                        </div>
                        {blueTickClickLowestSubscription && (
                            <div className="blue-tik-icon">
                                <img src={CONSTANT.BLUE_TIK_ICON} alt="blue tick icon" className="w-100" />
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                        <div>
                            <p className=" drop-item" onClick={handleHigestSubscription}>
                                Lowest to highest subscription rate
                            </p>
                        </div>
                        {blueTickClickHigestSubscription && (
                            <div className="blue-tik-icon">
                                <img src={CONSTANT.BLUE_TIK_ICON} alt="blue tick icon" className="w-100" />
                            </div>
                        )}
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default FilterDropdown;
