import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import LoaderModal from '../Login/LoaderModal';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import AnalyticsSubscriber from 'Components/reusable/model/AnalyticsSubscriberPage/AnalyticsSubscriber';
import AnalyticsSubscriberConfirmation from 'Components/reusable/model/AnalyticsSubscriberConfirmation/AnalyticsSubscriberConfirmation';
import isValidHttpsUrl from 'util/isValidHttpsUrl';
import { InfluencerSubscriber } from 'Api/influencerDashboard';
import { getUserId } from './slector';
import './style.scss';

const SubscriberPageAnalytics = () => {
    const [loading, setLoading] = useState(true);
    const [fetchSubscriber, setFetchSubscriber] = useState([]);
    const [modalReqestMsg, setModalReqestMsg] = useState(false);
    const [modalConfirmAnalystIsOpen, setModalConfirmAnalystIsOpen] = useState(false);
    const [getInfo, setGetInfo] = useState({});
    const id = useSelector(getUserId);

    useEffect(() => {
        InfluencerSubscriber()
            .then((response) => {
                setFetchSubscriber(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }, []);

    const secModalreqMsgClose = () => {
        setModalReqestMsg(false);
    };

    const secModalConfirmClose = () => {
        setModalConfirmAnalystIsOpen(false);
    };

    const handleRequestModal = (username, img, id) => {
        setGetInfo({
            analystSubscriber: username,
            analystSubsImg: img,
            analystSubsid: id,
        });

        setModalReqestMsg(true);
    };

    const analystsubs = getInfo?.analystSubscriber;
    const analystSubsImg = getInfo?.analystSubsImg;
    const analystId = getInfo?.analystSubsid;

    const influencer = {
        firstName: 'Subscriber',
    };

    return (
        <div className="portfolio-trade-history subscriber-main">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar />
                        </div>
                    </MediaQuery>
                    <div className="col-xl-8 col-lg-5">
                        {loading ? (
                            <div className="m-4 text-center">{loading && <LoaderModal />}</div>
                        ) : fetchSubscriber?.length > 0 ? (
                            <>
                                {fetchSubscriber?.length > 0 && (
                                    <>
                                        {fetchSubscriber?.map((ele) => (
                                            <div className="d-flex justify-content-between mt-3">
                                                <div className="d-flex">
                                                    <div>
                                                        <img
                                                            src={
                                                                isValidHttpsUrl(ele?.userImage)
                                                                    ? ele?.userImage
                                                                    : '/assets/images/image (1).png'
                                                            }
                                                            className="subscriber-main-user-image"
                                                            alt="img"
                                                        />
                                                        <img
                                                            src="/assets/images/orange_icon.png"
                                                            className="subscriber-main-orange-icon"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: '10px' }} className="mt-2">
                                                        <p className="subscriber-main-username">
                                                            {ele.userName}
                                                            <p className="subscriber-main-subscriptionId">
                                                                {ele.subscriptionId}
                                                            </p>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="arrowAnalyst">
                                                    <img
                                                        src="/assets/Analytics/arrow-up-right/Outline.png"
                                                        className="arrowImg"
                                                        alt="arrowRight"
                                                        onClick={() =>
                                                            handleRequestModal(ele.userName, ele.userImage, ele.userId)
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center">
                                <p>{fetchSubscriber?.message}</p>
                            </div>
                        )}
                    </div>

                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div className="side-notification-component scroll-sideNotification">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
            <AnalyticsSubscriber
                modalReqestMsg={modalReqestMsg}
                secModalreqMsgClose={secModalreqMsgClose}
                analystSubsImg={analystSubsImg}
                analystsubs={analystsubs}
                setModalConfirmAnalystIsOpen={setModalConfirmAnalystIsOpen}
                setModalReqestMsg={setModalReqestMsg}
            />
            <AnalyticsSubscriberConfirmation
                modalConfirmAnalystIsOpen={modalConfirmAnalystIsOpen}
                secModalConfirmClose={secModalConfirmClose}
                setModalConfirmAnalystIsOpen={setModalConfirmAnalystIsOpen}
                analystId={analystId}
            />
        </div>
    );
};

export default SubscriberPageAnalytics;
