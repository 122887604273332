import React from 'react';
import Modal from 'react-modal';
import { customStyles8 } from './Style.js';
import './style.scss';

const UnsubscribeSubscription = ({ handleUnSubscribe, UnSubscriptionModal, secModalClose }) => {
    return (
        <div>
            <Modal
                isOpen={UnSubscriptionModal}
                onRequestClose={secModalClose}
                style={customStyles8}
                contentLabel="Example Modal">
                <div className="p-3 parent-div">
                    <div className="d-flex justify-content-between" style={{ width: '330px' }}>
                        <div className="d-flex justify-content-between mt-3" style={{ marginLeft: '15px' }}>
                            <div>
                                <img
                                    src="/assets/Influencer/InfluencerCongratulation.png"
                                    alt="img"
                                    className="influencer-img"
                                />
                                <img src="/assets/images/orange_icon.png" alt="img" className="orange-icon" />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="mt-2">
                                <p className="subscription-name">@{localStorage.getItem('SubscriptionName')}</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="btn-block">BLOCK</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            onClick={() => {
                                handleUnSubscribe();
                            }}
                            className="unsubscribe-btn">
                            UNSUBSCRIBE
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UnsubscribeSubscription;
