import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import * as CONSTANT from './constant';
import { customStyles } from './style';
import { marketIndicates } from 'Api/dashboard';
import './style.scss';

const TrendingList = ({ isOpened = false, onModelClose, trendingStock, dashboard, children }) => {
    const navigate = useNavigate('');
    const [market, setMarket] = useState([]);
    const dashboardImages = [
        CONSTANT.FIRST_DASHBOARD_LEARN_IMAGE,
        CONSTANT.SECOND_DASHBOARD_LEARN_IMAGE,
        CONSTANT.THIRD_DASHBOARD_LEARN_IMAGE,
    ];

    const getMarketIndicates = async () => {
        try {
            const response = await marketIndicates();
            setMarket(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getMarketIndicates();
    }, []);

    return (
        <>
            {children}
            <Modal isOpen={isOpened} onRequestClose={onModelClose} style={customStyles} contentLabel="Trending Lists">
                <div className="mb-2">{children}</div>
                <div className="trending-list">
                    <p className="trending-header">Trending Lists</p>
                    <div className="d-flex flex-wrap stock-container">
                        {Boolean(trendingStock.length) &&
                            trendingStock?.map((ele) => (
                                <div
                                    className="d-flex m-1 data-container"
                                    onClick={() => navigate(`${CONSTANT.NAVIGATION_URL}${ele.ticker}`)}>
                                    <img
                                        src={ele.logoUrl || '/assets/images2/portfolio_icon.png'}
                                        alt="trending logos"
                                        className="trending-logos"
                                    />
                                    <p className="ticker-text m-1">{ele.ticker}</p>
                                </div>
                            ))}
                    </div>
                    <p className="mt-3 market-text">Markets</p>
                    <div className="d-flex flex-wrap pe-3 mb-3">
                        {market?.map((marketValue, index) => (
                            <div
                                className="market-section"
                                key={index}
                                style={{
                                    borderRight: `${market.length === index + 1 ? `0` : '1.5px'} solid #e5e5e5`,
                                    paddingRight: '1.5rem',
                                    paddingLeft: '1rem',
                                }}>
                                <img
                                    src="/assets/images2/portfolio_icon.png"
                                    alt="market"
                                    style={{ width: '50px', height: '50px', borderRadius: '50px' }}
                                    className="mb-2"
                                />
                                <h4
                                    style={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        width: '100px',
                                    }}>
                                    {marketValue?.shortName}
                                </h4>
                                <p className="dollar-value">$0{marketValue?.regularMarketPrice || 0}</p>
                                <p className="percent-value">
                                    <i class="fa-solid fa-caret-up"></i> {marketValue?.regularMarketChangePercent}%
                                </p>
                            </div>
                        ))}
                    </div>
                    <div>
                        <div className="d-flex justify-content-between ">
                            <p className="learn">Learn</p>
                            <p className="view-all">View All</p>
                        </div>
                        <div className="d-flex justify-content-evenly mt-2 dashboard-image-container" style={{}}>
                            {dashboardImages?.map((dashboard, index) => (
                                <img key={index} src={dashboard} alt="dashboard learn" className="dashboard-image" />
                            ))}
                        </div>
                        <p className="mt-4 news">News</p>
                        <div className="d-flex image-gallery">
                            {dashboard.slice(0, 2)?.map((newsData) => (
                                <div className="d-flex justify-content-between w-50 pe-3">
                                    <div className="middle-gallery-container m-0">
                                        <div className="d-flex align-items-center mb-3">
                                            <p className="author-text d-flex flex-wrap mb-0">
                                                {newsData?.publisher?.name}
                                            </p>
                                            <span className="publishedUtc ms-2">
                                                {moment(newsData?.publishedUtc).fromNow('mm')}
                                            </span>
                                        </div>

                                        <p className="news-title mb-0">{newsData?.title}</p>
                                        <div className="d-flex flex-wrap">
                                            {newsData?.tickers?.slice(0, 1)?.map((data, index) => (
                                                <p className="mb-3 mt-2 me-2 tickers-map" key={index}>
                                                    {data}
                                                </p>
                                            ))}
                                        </div>
                                    </div>

                                    <img
                                        src={newsData.imageUrl}
                                        alt="dashboardlearnimage"
                                        className="bottom-dashboard-image"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TrendingList;
