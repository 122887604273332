export const CARD_IMAGE = '/assets/Influencer/cardImage.png';
export const USER_IMAGE = '/assets/Influencer/subscriptionPlanImg.png';

export const STATIC_CARDS = [
    {
        id: 1,
        text: 'Card',
        image: CARD_IMAGE,
    },
    {
        id: 2,
        text: 'Bank',
        image: CARD_IMAGE,
    },
    {
        id: 3,
        text: 'Apple pay',
        image: CARD_IMAGE,
    },
    {
        id: 4,
        text: 'PayPal',
        image: CARD_IMAGE,
    },
];
