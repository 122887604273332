import React from 'react';
import Select from 'react-select';
import ToDoItem from '../settings/toDoItem';
import ProfilePicture from './profilePicture';
import './style.scss';

const InfluencerProfile = ({
    getUserProfileImage,
    setModalUpdatePhotoOpen,
    setFname,
    setLname,
    username,
    fname,
    lname,
    email,
    setEmail,
    handleInputChange,
    ftechInfluencer,
    selectedOptions,
    options,
    handleSelectChange,
    handleAddLink,
    socialLinks,
    onSubmit,
    setSocialLinks,
    handlePlatformChange,
}) => {
    return (
        <div className="influencer-profile">
            <ProfilePicture
                picture={getUserProfileImage}
                modelOpen={() => {
                    setModalUpdatePhotoOpen(true);
                }}
            />

            <div className="mt-2">
                <div className="d-flex justify-content-evenly mt-3">
                    <input
                        type="text"
                        className="form-control input-control"
                        value={fname || ''}
                        onChange={(e) => setFname(e.target.value)}
                    />

                    <input
                        type="text"
                        className="form-control input-control ms-3"
                        value={lname || ''}
                        onChange={(e) => setLname(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        className="form-control mt-4 input-control"
                        value={'@' + username || ''}
                        onChange={(e) => handleInputChange(e.target.value)}
                    />

                    <input
                        type="text"
                        className="form-control mt-4 input-control"
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>

            <p className="edit-tag mt-4">Edit Tag</p>

            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <img src="/assets/images/orange_icon.png" className="orange-icon-influencer" alt="orange-icon" />
                    <select
                        disabled
                        className="form-control select-changer"
                        value={ftechInfluencer?.influencer?.influencer?.investmentPattern}>
                        <option value="Balanced">Balanced</option>
                        <option value="Aggressive">Aggressive</option>
                        <option value="Conservative">Conservative</option>
                    </select>
                </div>

                <div className="d-flex">
                    <p className="change-tag">Change Tag</p>
                    <i className="fas fa-angle-down down-angle"></i>
                </div>
            </div>
            <hr style={{ opacity: 0.10000000149011612 }} />

            <div className="">
                <div>
                    <Select
                        value={selectedOptions}
                        options={options}
                        onChange={handleSelectChange}
                        placeholder="Select / Edit Tag"
                    />
                </div>
            </div>
            <hr style={{ opacity: 0.10000000149011612 }} />

            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <p className="social-links">Social Links</p>
                </div>

                <div className="d-flex">
                    <p className="add-platform" onClick={handleAddLink}>
                        Add Platform
                    </p>
                    <i className="fas fa-angle-down down-angle"></i>
                </div>
            </div>
            <hr style={{ opacity: 0.10000000149011612 }} />

            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div style={{ bottom: '20px' }}>
                        {socialLinks.map((linkObj) => (
                            <ToDoItem
                                key={linkObj.id}
                                linkObj={linkObj}
                                socialLinks={socialLinks}
                                setSocialLinks={setSocialLinks}
                                handlePlatformChange={handlePlatformChange}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <button className="save-button" onClick={(e) => onSubmit(e)}>
                    SAVE
                </button>
            </div>
        </div>
    );
};

export default InfluencerProfile;
