import React from 'react';
import Modal from 'react-modal';

const MimicLimitOrderFinalModal = ({
    modalFinalIsOpenLimitOrder,
    setModalFinalIsOpenLimitOrder,
    customStyles4,
    setModalReviewIsOpenLimitOrder,
    fetchStocksData,
    LimitOrderInput,
    estimateAmount,
    setModalQueuedIsOpenLimitOrder,
}) => {
    const secModalClose = () => {
        setModalFinalIsOpenLimitOrder(false);
    };
    return (
        <div>
            {' '}
            <Modal
                isOpen={modalFinalIsOpenLimitOrder}
                onRequestClose={secModalClose}
                style={customStyles4}
                contentLabel="Example Modal">
                <div
                    style={{
                        width: '490px',
                        height: '171px',
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className="p-3 ">
                    <div className="d-flex justify-content-around mt-2 mb-4 " style={{ marginRight: '7rem' }}>
                        <img
                            src="/assets/stockdetails/arrow_left_black.png"
                            style={{ width: '24px', height: '24px' }}
                            alt="img"
                            onClick={() => {
                                if (setModalReviewIsOpenLimitOrder) {
                                    setModalFinalIsOpenLimitOrder(false);
                                    setModalReviewIsOpenLimitOrder(true);
                                }
                            }}
                        />
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                // textAlign: 'center',
                                marginLeft: '4rem',
                            }}>
                            Order Success
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-additional-sunset-ff-5445, #FF5445)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',

                                cursor: 'pointer',
                            }}
                            onClick={() => setModalFinalIsOpenLimitOrder(false)}>
                            Cancel
                        </p>
                    </div>

                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className=" mt-5" style={{}}>
                            <div className="d-flex justify-content-evenly " style={{ right: '3rem' }}>
                                <div className="">
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="m-1">
                                        Stock
                                    </p>
                                </div>

                                <div className="d-flex" style={{ marginLeft: '7rem' }}>
                                    <img
                                        src={fetchStocksData?.details?.logoUrl}
                                        style={{ width: '35px', height: '35px' }}
                                        alt="mg"
                                    />
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1">
                                        {fetchStocksData?.details?.ticker}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-2">
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        marginLeft: '6%',
                                    }}>
                                    Amount Invested
                                </p>

                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F))',
                                        fontStyle: 'normal',
                                        fontSize: '20px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        width: '36%',
                                        border: 'none',
                                        marginRight: '5%',
                                    }}>
                                    ${LimitOrderInput}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between ">
                        <p
                            style={{
                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                marginLeft: '6%',
                            }}>
                            Estimated Share
                        </p>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                width: '33%',
                                marginRight: '7%',
                            }}>
                            {estimateAmount}
                        </p>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <button
                            style={{
                                width: '280px',
                                height: '52px',
                                color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                borderRadius: '54px',
                                letterSpacing: '0.7px',
                                backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                right: '3.5rem',
                            }}
                            onClick={() => {
                                setModalFinalIsOpenLimitOrder(false);
                                setModalQueuedIsOpenLimitOrder(true);
                            }}>
                            DONE
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MimicLimitOrderFinalModal;
