import React, { useState, useEffect, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import * as CONSTANT from './constant';
import Navbar from '../../../Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../../Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { brokerageDetails } from 'Api/setting';
import TradeOptions from 'Components/reusable/model/tradeOptions';
import TradeOptionsCall from 'Components/reusable/model/tradeOptionsCall';
import MarketOrder from 'Components/reusable/model/marketOrder';
import SelectCondition from 'Components/reusable/model/selectCondition';
import SetTimeInForce from 'Components/reusable/model/setTimeInForce';
import BuyStocks from 'Components/reusable/model/buyStocks';
import OrderConfirmation from 'Components/reusable/model/orderConfirmation';
import OrderSuccess from 'Components/reusable/model/orderSuccess';
import OrderQueued from 'Components/reusable/model/orderQueued';
import { getTradeHistory } from '../../../../Api/TradeHistoryStock';
import { customStyles, customStyles4, customStyles5, customStyles11, customStyles12, customStyles13 } from './style';
import SideNotificationComponent from '../../sideNotification/SideNotificationComponent';
import {
    fractionalTrade,
    getStockDetailGraph,
    stockDetail,
    BrokeragesById,
    AggregatePosition,
    getPositionData,
    getStockConfirmModalSimulate,
} from 'Api/stocksDetails';
import MarketOrderModal from 'Components/reusable/model/MarketOrderModal/MarketOrderModal';
import LoaderModal from '../../../../pages/Login/LoaderModal';
import './style.scss';
import './StocksDetails.css';
import ModalSetTimeMarketOrder from 'Components/reusable/model/ModalSetTimeMarketOrder/ModalSetTimeMarketOrder';
import CommonModal from 'Components/reusable/settings/commonModal';
import ComponentLoading from 'Components/reusable/loading/Loading';
import numeral from 'numeral';
import { getBrokerageId } from 'pages/RecentTrades/selector';
import { useSelector } from 'react-redux';

const StockLineChart = React.lazy(() => import('./charts/LineChart'));

const StocksDetails = () => {
    const [fetchStocksData, setFetchStocksData] = useState([]);
    const [fetchStocksData52Weeks, setFetchStocksData52Weeks] = useState([]);
    const [modalBuyIsOpen, setModalBuyIsOpen] = useState(false);
    const [modalReviewIsOpen, setModalReviewIsIsOpen] = useState(false);
    const [modalFinalIsOpen, setModalFinalIsIsOpen] = useState(false);
    const [modalQueuedIsOpen, setModalQueuedIsOpen] = useState(false);
    const [modalOptionList, setModalOptionList] = useState(false);
    const [modalTradeOption, setModalTradeOption] = useState(false);
    const [modalTradeOptionMarket, setModalTradeOptionMarket] = useState(false);
    const [modalSelectCondition, setSelectCondition] = useState(false);
    const [modalSetTime, setModalSetTime] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectUnit, setSelectUnit] = useState('');
    const [selectMarketOrderModal, setSelectMarketOrderModal] = useState(false);
    const [limitOrderDisable, setLimitOrderDisable] = useState(true);
    const [modalSetTimeMarketOrder, setModalSetTimeMarketOrder] = useState(false);
    const [modalBuyIsOpenLimitOrder, setModalBuyIsOpenLimitOrder] = useState(false);
    const [modalFinalIsOpenLimitOrder, setModalFinalIsOpenLimitOrder] = useState(false);
    const [modalQueuedIsOpenLimitOrder, setModalQueuedIsOpenLimitOrder] = useState(false);
    const [LimitOrderInput, setLimitOrderInput] = useState('');
    const [modalReviewIsOpenLimitOrder, setModalReviewIsOpenLimitOrder] = useState('');
    const [stockDetailGraph, setStockDetailGraph] = useState([]);
    const [getPositionDataVal, setGetPositionDataVal] = useState([]);
    const [graphButtonsLabels, setGraphButtonsLabels] = useState(CONSTANT.BUTTON_LABELS);
    const [getCurrentValue, setGetCurrentValue] = useState({});
    const [changeOptionBuy, setChangeOptionBuy] = useState(false);
    const [changeOptionSell, setChangeOptionSell] = useState(false);
    const [fetchTradeHistory, setFetchTradeHistory] = useState([]);
    const [inputOptionValue, setInpuOptionValue] = useState();
    const [inputOptionValueUnit, setInpuOptionValueUnit] = useState();
    const [fetchBrokerageById, setFetchBrokerageById] = useState([]);
    const [fetchBrokerageByIdDetails, setFetchBrokerageByIdDetails] = useState([]);
    const [getBrokerageDropdownData, setGetBrokerageDropdownData] = useState('');
    const [getBrokerageDropdownDataName, setGetBrokerageDropdownDataName] = useState('Robinhood');
    const [selectOrder, setSelectOrder] = useState('');
    const [brokerDropdown, setBrokerDropdown] = useState([]);
    const [getpositionValue, setGetPositionValue] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenErr, setModalOpenErr] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [getAggregatePosition, setGetAggregatePosition] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const brokerageId = useSelector(getBrokerageId);

    console.log(id, 'id');
    const getStockDetail = () => {
        stockDetail(id)
            .then((response) => {
                setFetchStocksData(response);
                setFetchStocksData52Weeks(response);

                setGetCurrentValue({
                    closed: response?.snapshot?.ticker?.day?.c,
                    perc: response?.snapshot?.ticker?.todaysChangePerc,
                });
                setLimitOrderInput(response?.snapshot?.ticker?.day?.c);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    const secModalCloseLimitMarketModal = () => {
        setModalBuyIsOpenLimitOrder(false);
    };

    useEffect(() => {
        getStockDetail();
        // eslint-disable-next-line
    }, [id]);

    const getBrokerageById = () => {
        const brokerageId = localStorage.getItem('getBrokerageDropdownData');
        console.log(brokerageId, 'brokerageId');
        BrokeragesById(brokerageId === '' ? 'Robinhood_0001' : brokerageId)
            .then((response) => {
                setFetchBrokerageById(response?.balance);
                setFetchBrokerageByIdDetails(response?.supportedOrderFeatures);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    const getAggregatePostion = () => {
        AggregatePosition()
            .then((response) => {
                setGetAggregatePosition(response);
                setGetPositionValue(response?.getAggregatePosition?.content?.equityPositions);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    useEffect(() => {
        getAggregatePostion();
    }, []);

    useEffect(() => {
        getBrokerageById();
        // eslint-disable-next-line
    }, []);

    const currBid = fetchStocksData?.lastQuote?.results?.P;

    useEffect(() => {
        setInpuOptionValue(currBid);
    }, [currBid, id]);

    function openModal(price) {
        localStorage.setItem('price', price);
    }

    function secModalClose() {}

    const handleBuyCost = () => {
        setIsLoading(true);
        setModalQueuedIsOpen(false);

        let data;

        if (selectUnit === 'USD' || selectUnit === '') {
            data = {
                symbol: fetchStocksData.details.ticker,
                amount: 0,
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: changeOptionBuy ? 'buy' : 'sell',
                fractionalAmountInUsd: Number(inputOptionValue),
                fractional: true,
            };
        } else {
            data = {
                symbol: fetchStocksData.details.ticker,
                amount: Number(inputOptionValueUnit),
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: changeOptionBuy ? 'buy' : 'sell',
                fractional: false,
            };
        }
        fractionalTrade(data)
            .then((response) => {
                setModalMsg('Your order was placed and will be executed after market opening.');
                setModalOpen(true);
            })
            .catch((error) => {
                console.log(error, 'error');
                setModalMsg('Sorry ! Your order failed.');
                setModalOpenErr(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleTradeHistory = async () => {
        setIsLoading(true);
        try {
            const response = await getTradeHistory(id);
            if (response) {
                setFetchTradeHistory(response);
                setIsLoading(false);
            }
            return response;
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleTradeHistory();
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        (async () => {
            const response = await brokerageDetails();
            setBrokerDropdown(response);
        })();
    }, [getBrokerageDropdownData]);

    const handleBuyCostLimitOrder = (e) => {
        setIsLoading(true);
        e.preventDefault();
        setModalQueuedIsOpenLimitOrder(false);

        const data = JSON.stringify({
            symbol: fetchStocksData.details.ticker,
            amount: 1,
            extendedHours: false,
            orderType: 'limit',
            timeInForce: 'goodTillCanceled',
            brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
            side: changeOptionSell ? 'sell' : 'buy',
            fractional: false,
            price: Number(LimitOrderInput),
        });

        fractionalTrade(data)
            .then((response) => {
                setModalMsg('Your order was placed and will be executed after market opening.');
                setModalOpen(true);
            })
            .catch((error) => {
                setModalMsg('Sorry ! Your order failed.');
                setModalOpenErr(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const estimateAmount = localStorage.getItem('estimateAmount');

    const getGraphData = async (range, interval) => {
        try {
            const response = await getStockDetailGraph('AMD', range, interval);
            const formattedData = response?.data.map(({ t: timestamp, o: value, c: closed }) => ({
                timestamp: timestamp * 1000,
                value,
                closed,
            }));
            setStockDetailGraph(formattedData);
        } catch (error) {
            setStockDetailGraph([]);
        }
    };

    const getPostionData = async (range, interval) => {
        try {
            const response = await getPositionData(id);

            setGetPositionDataVal(response);
        } catch (error) {
            setGetPositionDataVal([]);
        }
    };

    console.log(getPositionDataVal, 'getPositionDataVal');

    const resetGraphButtonsLabels = (day, index) => {
        graphButtonsLabels.forEach((data, i) => {
            graphButtonsLabels[i].active = false;
        });
        graphButtonsLabels[index].active = true;
        setGraphButtonsLabels(() => {
            return [...graphButtonsLabels];
        });
        getGraphData(day?.range, day?.interval);
    };

    useEffect(() => {
        getPostionData(id);
        getGraphData('1d');
    }, [id]);

    const influencer = {
        firstName: (
            <img
                src="/assets/stockdetails/chevron-left.png"
                alt="img"
                className="arrow-back-stocks"
                onClick={() => navigate(-1)}
            />
        ),
    };

    const Brokeragecash = fetchBrokerageById[0]?.cash;

    const handleBrokerageDropdown = (e) => {
        localStorage.setItem('getBrokerageId', getBrokerageDropdownData);

        setGetBrokerageDropdownData(e.target.value);
        setGetBrokerageDropdownDataName(e.target.name);
    };

    const secModalCloseLimitQueued = () => {
        setModalQueuedIsOpenLimitOrder(false);
    };

    const handleBuyStocks = () => {
        setModalBuyIsOpen(true);
        setChangeOptionBuy(true);
    };

    const handleSellStocks = () => {
        setChangeOptionBuy(false);
        setModalBuyIsOpen(true);
        setChangeOptionSell(true);
    };

    const handleOpenModal = () => {
        setModalQueuedIsOpen(false);
        setModalBuyIsOpen(true);
    };

    const onchangeUnits = (e) => {
        setSelectUnit(e.target.value);
    };

    return (
        <>
            <div className="stock-detail-container">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-9 middle-container">
                            <div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '116px',
                                        borderRadius: '20px',
                                        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                        boxShadow: '0px 9px 20px 0px rgba(0, 0, 0, 0.06)',
                                        paddingTop: '25px',
                                        paddingRight: '25px',
                                        paddingLeft: '25px',
                                    }}
                                    className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div
                                            style={{
                                                width: '62px',
                                                height: '62px',
                                                backgroundColor: '#1F1F1F',
                                                borderRadius: '50%',
                                            }}>
                                            <img
                                                src={fetchStocksData?.details?.logoUrl}
                                                style={{ width: '62px', height: '62px', borderRadius: '50%' }}
                                                alt="img"
                                            />
                                        </div>
                                        <div className="mt-1">
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontSize: '24px',
                                                    fontFamily: 'Figtree',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    lineHeight: 'normal',
                                                    marginLeft: '10px',
                                                }}>
                                                {fetchStocksData?.details?.ticker}
                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                        fontSize: '14px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    {fetchStocksData?.details?.name}
                                                </p>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mt-1">
                                        <div class="dropdown">
                                            <button
                                                id="dropdownMenuButton1"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                style={{
                                                    width: '142px',
                                                    height: '52px',
                                                    borderRadius: '60px',
                                                    backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                                    fontSize: '14px',
                                                    fontFamily: 'Figtree',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    letterSpacing: '0.7px',
                                                }}
                                                onClick={() =>
                                                    openModal(
                                                        fetchStocksData?.snapshot?.ticker
                                                            ? fetchStocksData?.snapshot?.ticker?.lastQuote?.P
                                                            : fetchStocksData?.lastQuote?.results?.P
                                                    )
                                                }>
                                                TRADE
                                            </button>

                                            <div
                                                className="trade-dropdown dropdown-menu"
                                                style={{ border: 0 }}
                                                aria-labelledby="dropdownMenuButton1">
                                                <div className="row">
                                                    <div className=" ">
                                                        <button
                                                            style={{
                                                                width: '180px',
                                                                height: '46px',
                                                                textDecoration: 'none',
                                                                backgroundColor:
                                                                    'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                                                borderRadius: '54px',
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',

                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '600',
                                                                lineHeight: 'normal',
                                                                letterSpacing: '0.7px',
                                                                cursor: 'pointer',
                                                                border: 'none',
                                                            }}
                                                            onClick={() => setModalOptionList(true)}>
                                                            OPTIONS
                                                            {/* </Link> */}
                                                        </button>

                                                        <button
                                                            className=" mt-3"
                                                            style={{
                                                                width: '180px',
                                                                height: '46px',
                                                                textDecoration: 'none',
                                                                backgroundColor:
                                                                    'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                                                borderRadius: '54px',
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',

                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '600',
                                                                lineHeight: 'normal',
                                                                letterSpacing: '0.7px',
                                                                cursor: 'pointer',
                                                                border: 'none',
                                                            }}
                                                            onClick={handleBuyStocks}>
                                                            BUY
                                                        </button>

                                                        <button
                                                            className="sell-stock-btn mt-3"
                                                            onClick={handleSellStocks}>
                                                            SELL
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    borderRadius: '20px',
                                    backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                    border: '1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ECF2F1)',
                                }}
                                className="mt-3 mb-3">
                                <div className="mt-3 p-3 d-flex justify-content-between today-stock-record">
                                    <div>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontSize: '30px',
                                                fontFamily: 'Figtree',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginLeft: '10px',
                                            }}>
                                            $ {getCurrentValue?.closed || currBid}
                                        </p>
                                        <div className="d-flex ">
                                            <div className="d-flex " style={{ left: '8px' }}>
                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                        marginLeft: '5px',
                                                    }}>
                                                    Today
                                                </p>

                                                {getCurrentValue?.perc < 0 ? (
                                                    <img
                                                        src="/assets/stockdetails/arrow_drop_up_black_24dp (1) 1 (1).png"
                                                        style={{ width: '8px', height: '8px', left: '5px' }}
                                                        className="mt-1"
                                                        alt="img"
                                                    />
                                                ) : (
                                                    <img
                                                        src="/assets/dashboardImg/uparrow.png"
                                                        style={{ width: '8px', height: '8px', left: '5px' }}
                                                        className="mt-1"
                                                        alt="img"
                                                    />
                                                )}

                                                <p
                                                    style={{
                                                        color:
                                                            getCurrentValue?.perc < 0
                                                                ? '#FF5445'
                                                                : 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: 'normal',
                                                        letterSpacing: '0.6px',
                                                        marginLeft: '10px',
                                                    }}>
                                                    {getCurrentValue?.perc}%
                                                </p>

                                                <img
                                                    src="/assets/Navbar/Middle_line.png"
                                                    style={{ height: '15px', marginLeft: '10px', marginRight: '10px' }}
                                                    alt="img"
                                                />

                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    After hrs
                                                </p>
                                                <img
                                                    src="/assets/dashboardImg/uparrow.png"
                                                    style={{ width: '8px', height: '8px', left: '5px' }}
                                                    className="mt-1"
                                                    alt="img"
                                                />
                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: 'normal',
                                                        letterSpacing: '0.6px',
                                                        marginLeft: '10px',
                                                    }}>
                                                    0.3%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between" style={{ width: '50%' }}>
                                        {graphButtonsLabels?.map((day, index) => (
                                            <p
                                                onClick={() => resetGraphButtonsLabels(day, index)}
                                                key={index}
                                                style={{
                                                    backgroundColor: day?.active
                                                        ? 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)'
                                                        : 'unset',
                                                }}
                                                className="graph-days-buttons">
                                                {day?.label}
                                            </p>
                                        ))}
                                    </div>
                                </div>

                                <div
                                    className="stock-line-chart"
                                    style={{
                                        background: "url('/assets/stockdetails/stockGraph.png')",
                                    }}>
                                    <Suspense fallback={<div>loading please wait...</div>}>
                                        <StockLineChart
                                            chartData={stockDetailGraph}
                                            getCurrentValue={setGetCurrentValue}
                                        />
                                    </Suspense>
                                </div>
                            </div>

                            <div style={{ width: '100%' }} className="mt-2">
                                {Boolean(fetchTradeHistory?.pendingOrders?.length) && (
                                    <div className="d-flex justify-content-between mt-2">
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}>
                                            Pending Orders
                                        </p>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}>
                                            View All
                                        </p>
                                    </div>
                                )}

                                {fetchTradeHistory?.pendingOrders?.map((ele, index) => (
                                    <div className="d-flex pending-order" key={index}>
                                        <div className="d-flex justify-content-between m-1 w-100">
                                            <div className="d-flex">
                                                <div
                                                    style={{
                                                        width: '34px',
                                                        height: '34px',
                                                        backgroundColor: '#1F1F1F',
                                                        borderRadius: '50%',
                                                    }}>
                                                    <img
                                                        src={fetchStocksData?.details?.logoUrl}
                                                        style={{ width: '34px', height: '34px', borderRadius: '50%' }}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div>
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                            fontSize: '14px',
                                                            fontFamily: 'Figtree',
                                                            fontStyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: '125%',
                                                            marginLeft: '10px',
                                                        }}>
                                                        {fetchStocksData?.details?.ticker}
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                marginTop: '6px',
                                                                whiteSpace: 'nowrap',
                                                            }}>
                                                            Entered Qty :
                                                            <span
                                                                style={{
                                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                    fontSize: '13px',
                                                                    fontFamily: 'Figtree',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '400',
                                                                    lineHeight: 'normal',
                                                                    marginTop: '6px',
                                                                }}>
                                                                {ele.amount}
                                                            </span>
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    Type
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                            fontSize: '14px',
                                                            fontFamily: 'Figtree',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                            marginTop: '6px',
                                                        }}>
                                                        {ele.paymentType}
                                                    </p>
                                                </p>
                                            </div>
                                            <div>
                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    Submitted
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                            fontSize: '14px',
                                                            fontFamily: 'Figtree',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                            marginTop: '6px',
                                                        }}>
                                                        {moment.unix(ele.updatedTimestamp).format('MMM DD YYYY')}
                                                    </p>
                                                </p>
                                            </div>
                                            <div>
                                                <p
                                                    style={{
                                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                        fontSize: '12px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    Status
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                            fontSize: '14px',
                                                            fontFamily: 'Figtree',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                            marginTop: '6px',
                                                        }}>
                                                        {ele.status}
                                                    </p>
                                                </p>
                                            </div>
                                            <div>
                                                <button
                                                    style={{
                                                        width: '112px',
                                                        height: '28px',
                                                        borderRadius: '54px',
                                                        border: '1px solid var(--pf-additional-sunset-ff-5445, #FF5445)',
                                                        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                                        color: 'var(--pf-additional-sunset-ff-5445, #FF5445)',
                                                        fontSize: '10px',
                                                        fontFamily: 'Figtree',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        letterSpacing: '0.5px',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    CANCEL ORDER
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex justify-content-between mt-4 trade-position">
                                <div className="view-trade-position">
                                    <div className="d-flex justify-content-between">
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}>
                                            Your Position
                                        </p>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}>
                                            View All
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '168px',
                                            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                            borderRadius: '15px',
                                            border: '1px solid var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        }}
                                        className="pt-1">
                                        {getAggregatePosition ? (
                                            getAggregatePosition?.content?.equityPositions?.map((val) => {
                                                console.log(val, 'val');
                                                if (val?.symbol === id) {
                                                    return (
                                                        <>
                                                            <div
                                                                className="p-3 d-flex justify-content-between"
                                                                key={val?.symbol}>
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                                            fontSize: '14px',
                                                                            fontFamily: 'Figtree',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: 'normal',
                                                                        }}>
                                                                        Shares
                                                                        <p
                                                                            style={{
                                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                                fontSize: '16px',
                                                                                fontFamily: 'Figtree',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: 'normal',
                                                                            }}
                                                                            className="mt-1">
                                                                            {numeral(val?.amount).format('0,0.[000]')}
                                                                        </p>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                                            fontSize: '14px',
                                                                            fontFamily: 'Figtree',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: 'normal',
                                                                        }}>
                                                                        Current Value
                                                                        <p
                                                                            style={{
                                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                                fontSize: '16px',
                                                                                fontFamily: 'Figtree',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: 'normal',
                                                                            }}
                                                                            className="mt-1">
                                                                            ${' '}
                                                                            {numeral(val?.marketValue).format(
                                                                                '0,0.[000]'
                                                                            )}
                                                                        </p>
                                                                    </p>
                                                                </div>

                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                                            fontSize: '14px',
                                                                            fontFamily: 'Figtree',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: 'normal',
                                                                        }}>
                                                                        Avg Cost
                                                                        <p
                                                                            style={{
                                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                                fontSize: '16px',
                                                                                fontFamily: 'Figtree',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: 'normal',
                                                                            }}
                                                                            className="mt-1">
                                                                            ${' '}
                                                                            {numeral(val?.costBasis).format(
                                                                                '0,0.[000]'
                                                                            )}
                                                                        </p>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="p-3 d-flex justify-content-start">
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                                            fontSize: '14px',
                                                                            fontFamily: 'Figtree',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: 'normal',
                                                                        }}>
                                                                        Portfolio Diversity
                                                                        <div className="d-flex">
                                                                            <p
                                                                                style={{
                                                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                                    fontSize: '16px',
                                                                                    fontFamily: 'Figtree',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '400',
                                                                                    lineHeight: 'normal',
                                                                                }}
                                                                                className="mt-1">
                                                                                {numeral(
                                                                                    val?.portfolioPercentage
                                                                                ).format('0,0.[000]')}
                                                                                %
                                                                            </p>
                                                                            <img
                                                                                src="/assets/stockdetails/PortfolioDevercityRing.png"
                                                                                className="mt-1"
                                                                                style={{
                                                                                    width: '17.969px',
                                                                                    height: '17.969px',
                                                                                    marginLeft: '5px',
                                                                                }}
                                                                                alt="img"
                                                                            />
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <p
                                                                        style={{
                                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                                            fontSize: '14px',
                                                                            fontFamily: 'Figtree',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: 'normal',
                                                                            marginLeft: '20px',
                                                                        }}>
                                                                        Total Return
                                                                        <p
                                                                            style={{
                                                                                color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                                                                fontSize: '12px',
                                                                                fontFamily: 'Figtree',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '500',
                                                                                lineHeight: 'normal',
                                                                            }}
                                                                            className="mt-1">
                                                                            +{' '}
                                                                            {numeral(val?.totalReturn).format(
                                                                                '0,0.[00]'
                                                                            )}{' '}
                                                                            (
                                                                            {numeral(val?.returnPercentage).format(
                                                                                '0,0.[000]'
                                                                            )}
                                                                            )%
                                                                        </p>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            })
                                        ) : (
                                            <p>Data not available.</p>
                                        )}
                                    </div>
                                </div>
                                <div className="trade-history">
                                    <div className="d-flex justify-content-between">
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}>
                                            Trade History
                                        </p>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/dashboard/trending-stocks/stocks-details/${id}/trade-history`
                                                )
                                            }>
                                            View All
                                        </p>
                                    </div>
                                    <div>
                                        {fetchTradeHistory?.history?.slice(0, 2).map((trade) => {
                                            const dateTime = new Date(trade.updatedTimestamp * 1000);
                                            const formattedDateTime = moment(dateTime).format('M/D/YY h:mm A');
                                            return (
                                                <>
                                                    <div className="p-3 trade-history-parent-div mt-2" key={trade.id}>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="d-flex ">
                                                                {' '}
                                                                <div className="trade-img-div">
                                                                    <img
                                                                        src={fetchStocksData?.details?.logoUrl}
                                                                        style={{
                                                                            width: '34px',
                                                                            height: '34px',
                                                                            borderRadius: '50px',
                                                                        }}
                                                                        alt="img"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <p className="trade-symbl">
                                                                        {trade?.symbol}
                                                                        <span className="trade-side-text-tradehistory">
                                                                            {trade?.side}
                                                                        </span>
                                                                        <div className="d-flex">
                                                                            <p className="mt-1 mb-0 share-text">
                                                                                {trade.amount} Shares
                                                                            </p>
                                                                            <p className="mt-1 mb-0 at-symbl">@</p>

                                                                            <p className="mt-1 mb-0 trade-price">
                                                                                ${trade?.price}
                                                                            </p>
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="trade-amt">
                                                                <p className="mb-0">
                                                                    $ {trade?.value ? trade?.value?.toFixed(2) : 0}
                                                                </p>
                                                                <p className="mt-1 trade-date mb-0">
                                                                    {formattedDateTime}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    borderRadius: '18px',
                                    backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                }}
                                className="mt-3 p-4">
                                <div>
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                        }}>
                                        About{' '}
                                        {fetchStocksData && fetchStocksData.details && fetchStocksData.details.ticker}
                                    </p>

                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: '20px',
                                        }}>
                                        {fetchStocksData &&
                                            fetchStocksData.details &&
                                            fetchStocksData.details.description}
                                    </p>

                                    <div className="mt-4 d-flex justify-content-between flex-wrap">
                                        <div>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                }}>
                                                CEO
                                                <p
                                                    style={{
                                                        color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                        fontStyle: 'normal',
                                                        fontSize: '14px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}
                                                    className="mt-1">
                                                    Satya Nadella
                                                </p>
                                            </p>
                                        </div>
                                        <img
                                            src="/assets/stockdetails/longMiddleLine.png"
                                            alt="longMiddleLine"
                                            style={{ height: '29px' }}
                                        />
                                        <div>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                }}>
                                                Founded
                                                <p
                                                    style={{
                                                        color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                        fontStyle: 'normal',
                                                        fontSize: '14px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}
                                                    className="mt-1">
                                                    {fetchStocksData52Weeks?.details?.list_date}
                                                </p>
                                            </p>
                                        </div>
                                        <img
                                            src="/assets/stockdetails/longMiddleLine.png"
                                            alt="longMiddleLine"
                                            style={{ height: '29px' }}
                                        />

                                        <div>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                }}>
                                                Employees
                                                <p
                                                    style={{
                                                        color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                        fontStyle: 'normal',
                                                        fontSize: '14px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}
                                                    className="mt-1">
                                                    {(fetchStocksData52Weeks &&
                                                        fetchStocksData52Weeks.details &&
                                                        fetchStocksData52Weeks.details.total_employees) ||
                                                        0}
                                                </p>
                                            </p>
                                        </div>
                                        <img
                                            src="/assets/stockdetails/longMiddleLine.png"
                                            alt="longMiddleLine"
                                            style={{ height: '29px' }}
                                        />

                                        <div>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                }}>
                                                Headquarters
                                                <p
                                                    style={{
                                                        color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                        fontStyle: 'normal',
                                                        fontSize: '14px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '400',
                                                        lineHeight: 'normal',
                                                    }}
                                                    className="mt-1">
                                                    {fetchStocksData52Weeks.details?.address?.address1
                                                        ? `${fetchStocksData52Weeks.details?.address?.address1}, ${fetchStocksData52Weeks.details?.address?.city}, ${fetchStocksData52Weeks.details?.address?.state} ${fetchStocksData52Weeks.details?.address?.postal_code}`
                                                        : ''}
                                                </p>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="mt-2">
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}>
                                            Overview
                                        </p>

                                        <div className="d-flex justify-content-between flex-wrap">
                                            <div>
                                                <div className="d-flex justify-content-between flex-wrap-on-media">
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                            fontStyle: 'normal',
                                                            fontSize: '12px',
                                                            fontFamily: 'Figtree',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                        }}>
                                                        Today’s High - Low
                                                    </p>
                                                    <div className="d-flex">
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            $
                                                            {fetchStocksData52Weeks?.snapshot?.ticker?.day?.h?.toPrecision(
                                                                4
                                                            ) || 0}
                                                        </p>
                                                        <img
                                                            src="/assets/stockdetails/meterLine.png"
                                                            style={{
                                                                width: '52px',
                                                                height: '4px',
                                                                marginLeft: '10px',
                                                                marginRight: '10px',
                                                            }}
                                                            className="mt-1"
                                                            alt="meterLine"
                                                        />
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            ${' '}
                                                            {fetchStocksData52Weeks?.snapshot?.ticker?.day?.l?.toPrecision(
                                                                4
                                                            ) || 0}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="d-flex justify-content-between mt-2 flex-wrap-on-media"
                                                    style={{ width: '361px' }}>
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                            fontStyle: 'normal',
                                                            fontSize: '12px',
                                                            fontFamily: 'Figtree',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                        }}>
                                                        52 week’s High - Low
                                                    </p>
                                                    <div className="d-flex">
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            ${' '}
                                                            {fetchStocksData52Weeks &&
                                                                fetchStocksData52Weeks.fiftyTwoWeekHighLow &&
                                                                fetchStocksData52Weeks.fiftyTwoWeekHighLow.high}
                                                        </p>

                                                        <img
                                                            src="/assets/stockdetails/meterLine.png"
                                                            style={{
                                                                width: '52px',
                                                                height: '4px',
                                                                marginLeft: '10px',
                                                                marginRight: '10px',
                                                            }}
                                                            className="mt-1"
                                                            alt="meterLine"
                                                        />
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            ${' '}
                                                            {fetchStocksData52Weeks &&
                                                                fetchStocksData52Weeks.fiftyTwoWeekHighLow &&
                                                                fetchStocksData52Weeks.fiftyTwoWeekHighLow.low}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-between mt-2 flex-wrap-on-media">
                                                    <p
                                                        style={{
                                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                            fontStyle: 'normal',
                                                            fontSize: '12px',
                                                            fontFamily: 'Figtree',
                                                            fontWeight: '400',
                                                            lineHeight: 'normal',
                                                        }}>
                                                        Market Cap
                                                    </p>

                                                    <p
                                                        style={{
                                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                            fontStyle: 'normal',
                                                            fontSize: '12px',
                                                            fontFamily: 'Figtree',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal',
                                                        }}>
                                                        {fetchStocksData52Weeks &&
                                                            fetchStocksData52Weeks.details &&
                                                            fetchStocksData52Weeks.details.market_cap}{' '}
                                                        M
                                                    </p>
                                                </div>
                                            </div>

                                            <img
                                                src="/assets/stockdetails/longMiddleLine.png"
                                                alt="longMiddleLine"
                                                className="hide-on-mobile"
                                                style={{ height: '82px', width: '1px' }}
                                            />
                                            <div>
                                                <div
                                                    className="d-flex justify-content-between"
                                                    style={{ width: '300px' }}>
                                                    <div>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            Prev. Open
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                bottom: '10px',
                                                            }}>
                                                            ${' '}
                                                            {fetchStocksData52Weeks &&
                                                                fetchStocksData52Weeks.snapshot &&
                                                                fetchStocksData52Weeks.snapshot &&
                                                                fetchStocksData52Weeks.snapshot.ticker &&
                                                                fetchStocksData52Weeks.snapshot.ticker.prevDay &&
                                                                fetchStocksData52Weeks.snapshot.ticker.prevDay.o}
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            Today’s Volume
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                bottom: '10px',
                                                            }}>
                                                            {fetchStocksData52Weeks &&
                                                                fetchStocksData52Weeks.snapshot &&
                                                                fetchStocksData52Weeks.snapshot &&
                                                                fetchStocksData52Weeks.snapshot.ticker &&
                                                                fetchStocksData52Weeks.snapshot.ticker.prevDay &&
                                                                fetchStocksData52Weeks.snapshot.ticker.prevDay.v}{' '}
                                                            M
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            Dividend Yields
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                bottom: '10px',
                                                            }}>
                                                            {fetchStocksData52Weeks.dividendYieldPercentage || 0}%
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="d-flex justify-content-between"
                                                    style={{ width: '300px', bottom: '10px' }}>
                                                    <div>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            Prev. Close
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                bottom: '10px',
                                                            }}>
                                                            ${' '}
                                                            {fetchStocksData52Weeks &&
                                                                fetchStocksData52Weeks.snapshot &&
                                                                fetchStocksData52Weeks.snapshot &&
                                                                fetchStocksData52Weeks.snapshot.ticker &&
                                                                fetchStocksData52Weeks.snapshot.ticker.prevDay &&
                                                                fetchStocksData52Weeks.snapshot.ticker.prevDay.c}
                                                        </p>
                                                    </div>

                                                    <div style={{ right: '23px' }}>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            Avg Volume
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                bottom: '10px',
                                                            }}>
                                                            {fetchStocksData52Weeks?.snapshot?.ticker?.day?.vw || 0} M
                                                        </p>
                                                    </div>

                                                    <div style={{ right: '33px' }}>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                fontStyle: 'normal',
                                                                fontSize: '12px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}>
                                                            PE Ratio
                                                        </p>
                                                        <p
                                                            style={{
                                                                color: 'var(--pf-secondary-crusta-ff-792-e, #FF792E)',
                                                                fontStyle: 'normal',
                                                                fontSize: '14px',
                                                                fontFamily: 'Figtree',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                                bottom: '10px',
                                                            }}>
                                                            {fetchStocksData52Weeks.peRatio || 0}%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ width: '100%' }} className="d-flex justify-content-between mt-3">
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '18px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '600',
                                                    lineHeight: 'normal',
                                                }}>
                                                News
                                            </p>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '14px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal',
                                                }}>
                                                View All
                                            </p>
                                        </div>

                                        <div className="image-gallery mt-4">
                                            {fetchStocksData &&
                                                fetchStocksData.details &&
                                                fetchStocksData.details.news &&
                                                fetchStocksData.details.news?.slice(0, 4)?.map((stockNews, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div className="image-gallery-item">
                                                                <img
                                                                    src={stockNews.image_url}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '150px',
                                                                        borderRadius: '10px',
                                                                    }}
                                                                    alt="gallery"
                                                                />
                                                                <div className="d-flex flex-warp">
                                                                    <p
                                                                        style={{
                                                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                                            fontStyle: 'normal',
                                                                            fontSize: '14px',
                                                                            fontFamily: 'Figtree',
                                                                            fontWeight: '500',
                                                                            lineHeight: 'normal',
                                                                            wordWrap: 'break-word',
                                                                            whiteSpace: 'initial',
                                                                        }}
                                                                        className="mt-2 ">
                                                                        {stockNews.description}
                                                                    </p>
                                                                </div>
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        color: 'rgb(130 130 130)',
                                                                    }}>
                                                                    {moment(stockNews.published_utc).format('ll')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div
                                    className="scroll-sideNotification"
                                    style={{
                                        height: '849px',
                                        borderRadius: '25px 0px 0px 25px',
                                        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                        bottom: '4rem',
                                        boxShadow: '0px 9px 20px 0px rgba(0, 0, 0, 0.06)',
                                        paddingTop: '2rem',
                                        paddingLeft: '2.2rem',
                                    }}>
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>

            {/* Buy stock modal */}

            <BuyStocks
                isOpened={modalBuyIsOpen}
                onModelClose={setModalBuyIsOpen}
                setModalReviewIsIsOpen={setModalReviewIsIsOpen}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                selectUnit={selectUnit}
                setSelectUnit={setSelectUnit}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                price={fetchStocksData?.details?.price}
                dayC={fetchStocksData52Weeks?.snapshot?.ticker?.day?.c}
                name={fetchStocksData?.details?.name}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                setInpuOptionValue={setInpuOptionValue}
                inputOptionValue={inputOptionValue}
                setInpuOptionValueUnit={setInpuOptionValueUnit}
                inputOptionValueUnit={inputOptionValueUnit}
                currBidPrice={currBid}
                fetchStocksData={fetchStocksData}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                getBrokerageDropdownData={getBrokerageDropdownData}
                setGetBrokerageDropdownData={setGetBrokerageDropdownData}
                fetchBrokerageById={fetchBrokerageById}
                getBrokerageDropdownDataName={getBrokerageDropdownDataName}
            />

            <OrderConfirmation
                isOpened={modalReviewIsOpen}
                onModelClose={setModalReviewIsIsOpen}
                setModalFinalIsIsOpen={setModalFinalIsIsOpen}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                inputOptionValue={inputOptionValue}
                setModalBuyIsOpen={setModalBuyIsOpen}
                getBrokerageDropdownDataName={getBrokerageDropdownDataName}
            />

            <OrderSuccess
                isOpened={modalFinalIsOpen}
                onModelClose={setModalFinalIsIsOpen}
                setModalQueuedIsOpen={setModalQueuedIsOpen}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                inputOptionValue={inputOptionValue}
                setModalReviewIsIsOpen={setModalReviewIsIsOpen}
            />

            {/* Queued Modal */}

            <OrderQueued
                isOpened={modalQueuedIsOpen}
                onModelClose={setModalQueuedIsOpen}
                handleBuyCost={handleBuyCost}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                inputOptionValue={inputOptionValue}
                handleOpenModal={handleOpenModal}
            />

            {/* Market order Modal */}

            {/* Select Condition */}

            <MarketOrderModal
                selectMarketOrderModal={selectMarketOrderModal}
                secModalClose={secModalClose}
                customStyles11={customStyles11}
                setModalBuyIsOpen={setModalBuyIsOpen}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                fetchBrokerageByIdDetails={fetchBrokerageByIdDetails}
                setModalSetTimeMarketOrder={setModalSetTimeMarketOrder}
                setSelectOrder={setSelectOrder}
                selectOrder={selectOrder}
            />

            <ModalSetTimeMarketOrder
                modalSetTimeMarketOrder={modalSetTimeMarketOrder}
                setModalSetTimeMarketOrder={setModalSetTimeMarketOrder}
                customStyles12={customStyles12}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                setModalBuyIsOpenLimitOrder={setModalBuyIsOpenLimitOrder}
                selectOrder={selectOrder}
                fetchBrokerageByIdDetails={fetchBrokerageByIdDetails}
            />

            {/* Buy limit order Modal */}

            <Modal
                isOpen={modalBuyIsOpenLimitOrder}
                onRequestClose={secModalCloseLimitMarketModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div
                    style={{
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className=" p-3 ">
                    <div className="d-flex justify-content-between">
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                                top: '10px',
                                left: '5px',
                            }}>
                            {changeOptionSell ? 'Sell' : 'Buy'}
                        </p>

                        <p
                            style={{
                                color: '#64A2FF',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                textAlign: 'center',
                                cursor: 'pointer',
                                top: '10px',
                                right: '5px',
                            }}
                            onClick={() => {
                                setModalBuyIsOpen(false);
                                setSelectMarketOrderModal(true);
                            }}>
                            Market Order
                        </p>
                    </div>
                    <hr />

                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="">
                                <div className="d-flex" style={{ left: '10px' }}>
                                    <img
                                        src={fetchStocksData?.details?.logoUrl}
                                        style={{ width: '48px', height: '48px' }}
                                        alt="img"
                                    />
                                    <div style={{ marginLeft: '10px' }}>
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1">
                                            {fetchStocksData?.details?.ticker}
                                        </p>
                                        <div>
                                            <p
                                                style={{
                                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                    fontStyle: 'normal',
                                                    fontSize: '16px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '600',
                                                    lineHeight: '125%',
                                                    marginLeft: '4px',
                                                }}>
                                                {currBid}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="m-1">|</div>{' '}
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1 mt-2">
                                        Microsoft
                                    </p>
                                </div>
                            </div>

                            <div style={{ right: '10px' }}>
                                <div className="d-flex" style={{ left: '11px' }}>
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1">
                                        $15.26
                                    </p>
                                    <div
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                            right: '2px',
                                        }}
                                        className="">
                                        <img
                                            src="/assets/stockdetails/arrow_drop_up_black_24dp (1) 1.png"
                                            style={{ width: '10px', height: '10px', bottom: '1px' }}
                                            className="mt-1"
                                            alt="img"
                                        />
                                    </div>
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '500',
                                            lineHeight: '125%',
                                            top: '6px',
                                        }}
                                        className="">
                                        0.2%
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <img
                                        src="/assets/stockdetails/alertCircle.png"
                                        style={{ width: '15px', height: '15px', bottom: '5px' }}
                                        className="mt-1"
                                        alt="img"
                                    />
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: '125%',
                                            marginLeft: '5px',
                                        }}>
                                        Price Difference
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center p-3">
                        <select
                            style={{
                                width: '100px',
                                borderRadius: '69px',
                                backgroundColor: '#F5F8F8',
                                border: 'none',
                                backgroundPosition: 'right 10px bottom 50%',
                                backgroundSize: '0.625em',
                                backgroundRepeat: 'no-repeat',
                                appearance: 'none',
                                backgroundImage: `#F5F8F8`,
                            }}
                            className="d-flex justify-content-center align-items-center text-center "
                            onChange={(e) => onchangeUnits(e)}
                            value={selectUnit}>
                            <option value="USD">USD</option>
                            <option value="UNIT">UNIT</option>
                        </select>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        {selectUnit === 'USD' || selectUnit === '' ? (
                            <sup
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '25px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: '125%',
                                    marginLeft: '1rem',
                                    marginTop: '1rem',
                                }}
                                className="">
                                $
                            </sup>
                        ) : (
                            ''
                        )}

                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '40px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                border: 'none',
                                textAlign: 'center',
                                width: '150px',
                                top: '5px',
                            }}>
                            {currBid}
                        </p>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-1">
                        <p
                            style={{
                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: '125%',
                                bottom: '10px',
                            }}></p>
                    </div>

                    <div
                        style={{
                            width: '430px',
                            height: '121px',
                            borderRadius: '14px',
                            border: '1px solid var(--pf-secondary-geyser-dae-5-e-3, #DAE5E3)',
                            marginLeft: '15px',
                        }}
                        className="p-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                {' '}
                                <p
                                    style={{
                                        color: '#8f8f8f',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    Limit Price
                                </p>
                            </div>

                            <div className="d-flex">
                                {selectUnit === 'USD' || selectUnit === '' ? (
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            top: '2px',
                                        }}>
                                        $
                                    </p>
                                ) : (
                                    ''
                                )}

                                <input
                                    type="text"
                                    value={LimitOrderInput}
                                    onChange={(e) => setLimitOrderInput(e.target.value)}
                                    style={{ width: '60px', border: 'none', bottom: '5px' }}
                                    disabled={limitOrderDisable}
                                />
                            </div>
                        </div>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                            }}>
                            Execute during market hours
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                Expire in 90 days
                            </p>
                        </p>

                        <p
                            style={{
                                color: '#64A2FF',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                bottom: '11px',
                            }}
                            onClick={() => setLimitOrderDisable(false)}>
                            Edit Order
                        </p>
                    </div>

                    <div
                        className=" m-3 p-3"
                        style={{
                            width: '430px',
                            height: '70px',
                            borderRadius: '14px',
                            background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #F5F8F8)',
                        }}>
                        <div className=" ">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <img
                                        src="/assets/stockdetails/robinhood_green.png"
                                        style={{
                                            width: '38px',
                                            height: '38px',
                                            borderRadius: '118px',
                                            border: '2px solid #FFF',
                                        }}
                                        alt="robinhood"
                                    />
                                    <div className="robesHood">
                                        <select
                                            className="form-control"
                                            style={{
                                                width: '140%',
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                                border: 'none',
                                                background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8)',
                                            }}
                                            onChange={(e) => handleBrokerageDropdown(e)}
                                            value={getBrokerageDropdownData}>
                                            <option value="Robinhood_0001">Sandbox Robinhood account</option>
                                            {brokerDropdown?.map((ele) => {
                                                return (
                                                    <>
                                                        <option value={ele.accountId}>{ele.accountName}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center "
                            style={{ marginTop: '1rem' }}>
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: '125%',
                                }}>
                                ${Brokeragecash ? Brokeragecash : 0} available
                            </p>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <button
                                style={{
                                    width: '351px',
                                    height: '52px',
                                    color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    borderRadius: '54px',
                                    letterSpacing: '0.7px',
                                    backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                }}
                                onClick={() => {
                                    setModalBuyIsOpenLimitOrder(false);
                                    setModalReviewIsOpenLimitOrder(true);
                                }}>
                                REVIEW
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* Review Modal */}

            <OrderConfirmation
                isOpened={modalReviewIsOpenLimitOrder}
                onModelClose={setModalReviewIsOpenLimitOrder}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                inputOptionValue={inputOptionValue}
                setModalBuyIsOpen={setModalBuyIsOpen}
                LimitOrderInput={LimitOrderInput}
                setModalFinalIsIsOpen={setModalFinalIsOpenLimitOrder}
            />

            {/* final Modal */}

            <OrderSuccess
                isOpened={modalFinalIsOpenLimitOrder}
                onModelClose={setModalFinalIsOpenLimitOrder}
                setModalQueuedIsOpen={setModalQueuedIsOpenLimitOrder}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                inputOptionValue={inputOptionValue}
                setModalReviewIsIsOpen={setModalReviewIsIsOpen}
            />

            {/* Queued Modal */}

            <OrderQueued
                isOpened={modalQueuedIsOpenLimitOrder}
                onModelClose={setModalQueuedIsOpenLimitOrder}
                handleBuyCost={handleBuyCostLimitOrder}
                logoUrl={fetchStocksData?.details?.logoUrl}
                ticker={fetchStocksData?.details?.ticker}
                LimitOrderInput={LimitOrderInput}
                changeOptionBuy={changeOptionBuy}
                changeOptionSell={changeOptionSell}
                inputOptionValue={inputOptionValue}
                handleOpenModal={setModalBuyIsOpenLimitOrder}
            />

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalOpen}
                    onModelClose={() => setModalOpen(false)}
                    text={modalMsg}
                    onYes={() => setModalOpen(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalOpenErr}
                    onModelClose={() => setModalOpenErr(false)}
                    text={modalMsg}
                    onYes={() => setModalOpenErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <TradeOptions
                isOpened={modalOptionList}
                onModelClose={setModalOptionList}
                setModalOptionList={setModalOptionList}
                setModalTradeOption={setModalTradeOption}
            />

            <TradeOptionsCall
                isOpened={modalTradeOption}
                onModelClose={setModalTradeOption}
                setModalTradeOption={setModalTradeOption}
                setModalTradeOptionMarket={setModalTradeOptionMarket}
            />

            <MarketOrder
                isOpened={modalTradeOptionMarket}
                onModelClose={setModalTradeOptionMarket}
                setModalTradeOptionMarket={setModalTradeOptionMarket}
                setSelectCondition={setSelectCondition}
            />

            <SelectCondition
                isOpened={modalSelectCondition}
                onModelClose={setSelectCondition}
                setSelectCondition={setSelectCondition}
                setModalSetTime={setModalSetTime}
            />

            <SetTimeInForce
                isOpened={modalSetTime}
                onModelClose={setModalSetTime}
                setModalSetTime={setModalSetTime}
                setSelectCondition={setSelectCondition}
            />
            {isLoading && <LoaderModal />}
        </>
    );
};

export default StocksDetails;
