import React, { useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import ct from 'classnames';
import { useMediaQuery } from 'react-responsive';
import * as CONSTANT from './constant';
import LoginSlider from 'Components/reusable/LoginSlider';
import { registerPhone } from 'Api/registration';
import swal from 'sweetalert';
import { signUpCredential } from 'globalStore/actions/signUp';
import 'react-phone-input-2/lib/bootstrap.css';
import 'react-phone-input-2/lib/style.css';
import './signUp.scss';

const SignUpScreen = () => {
    const [skipAction, setSkipAction] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const dispatch = useDispatch();

    const handleSkip = () => {
        setSkipAction(true);
    };

    const handlePhoneSubmition = (e) => {
        e.preventDefault();

        const data = {
            phoneNumber: `+${phoneNumber}`,
            deviceId: 'testDeviceId',
        };

        registerPhone(data)
            .then((response) => {
                navigate(`/signup/code`);
                dispatch(
                    signUpCredential({
                        phoneNumber: phoneNumber,
                        sessionToken: response.data.sessionToken,
                    })
                );
            })
            .catch((error) => {
                swal('Sorry !', error.response.data.errors.message, 'error');
                console.error(error);
            });
    };

    const handleOnChange = (value) => {
        setPhoneNumber(value);
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <>
            <Col className={ct('main-container-signup')}>
                <Row className="text-center user-registration-number">
                    <Col lg="6" className="first-block">
                        {!skipAction && (
                            <>
                                <LoginSlider />
                                <Button className={ct('skip-button')} onClick={handleSkip}>
                                    Skip
                                </Button>
                            </>
                        )}
                    </Col>

                    <Col lg="6" md="12" className={ct('form-container', { isVisible: !skipAction })}>
                        <Col lg="7" md="10">
                            <form>
                                <Col className="parrot-logo">
                                    <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                                </Col>
                                <div className="form-group mt-5">
                                    <PhoneInput
                                        country={'us'}
                                        onlyCountries={['us']}
                                        value={phoneNumber}
                                        prefix="+"
                                        onChange={handleOnChange}
                                        placeholder="Enter phone number"
                                        className="form-control"
                                    />
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="border border-secondary  rounded-pill m-2 confirm-btn"
                                        onClick={handlePhoneSubmition}>
                                        CONFIRM
                                    </button>
                                </div>
                            </form>
                        </Col>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default SignUpScreen;
