import React from 'react';
import moment from 'moment';
import './style.scss';

const RecentTrades = ({ logoUrl, ticker, side, amount, price, updatedAt, isBlur, onclickTrade, tradeData }) => {
    return (
        <div
            className="mt-2 fetchrecenttrade-parent-div-not-blur recent-trade-from-influencer"
            style={{ filter: isBlur ? 'blur(6px)' : 0 }}
            onClick={onclickTrade}>
            <div className="d-flex  justify-content-between">
                <div className="fetchrecenttrade-div">
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center trade-logo-recent">
                                <img
                                    src={
                                        logoUrl ??
                                        tradeData?.stockDetails?.logoUrl ??
                                        '/assets/images2/portfolio_icon.png'
                                    }
                                    alt="logo"
                                    className="mt-2 logo-from-recent-trade"
                                />
                            </div>
                            <div className="m-1">
                                <p className="mb-0 recent-trade-ticker">
                                    {tradeData?.symbol}
                                    <span className="recent-trade-side">&nbsp; {side}</span>
                                    <p className="shares-number mb-0">
                                        <span className="shares-number">{amount} Shares </span>@ ${price?.toFixed(2)}
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="mb-0 recent-trade-value">
                                ${Math.round(tradeData?.amount * tradeData?.price)}
                                <p className="mt-1 mb-0 recent-trade-date">
                                    {moment(tradeData?.updatedTimestamp * 1000).format('MM/DD/YY hh:mm a')}
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentTrades;
