import React from 'react';
import ct from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';
import tooltip from './tooltip';
import './style.scss';

const BuyingPower = ({ buyingPower }) => {
    const lessValueBuyingPower = buyingPower < 100;

    return (
        <div
            className={ct('d-flex justify-content-between align-items-center p-3 buying-power', {
                warnBg: lessValueBuyingPower,
            })}>
            <p className="mb-0 power-text">{lessValueBuyingPower ? 'Buying Power is Low' : 'Buying Power'}</p>
            <p
                className={ct('mb-0 d-flex align-items-center power-value', {
                    textWarn: lessValueBuyingPower,
                })}>
                $ {buyingPower || 0}{' '}
                {lessValueBuyingPower && (
                    <>
                        &nbsp;
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            <i class="fa-solid fa-exclamation exclamation-buying-power"></i>
                        </OverlayTrigger>
                    </>
                )}
            </p>
        </div>
    );
};

export default BuyingPower;
