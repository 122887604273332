import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import LoaderModal from '../Login/LoaderModal';
import { useNavigate } from 'react-router-dom';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { InfluencerFollowing } from 'Api/influencerDashboard';
import { getUserId } from 'pages/analytics/slector';
import './style.scss';

const FollowerPage = () => {
    const [loading, setLoading] = useState(true);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const navigate = useNavigate();
    const id = useSelector(getUserId);

    useEffect(() => {
        InfluencerFollowing()
            .then((response) => {
                setFetchUserFollowing(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, []);

    const influencer = {
        firstName: 'Followers',
    };

    return (
        <div className="portfolio-trade-history influencer-follower-page">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar />
                        </div>
                    </MediaQuery>
                    <div className="col-xl-8 col-lg-5">
                        {loading ? (
                            <div className="m-4 text-center">{loading && <LoaderModal />}</div>
                        ) : fetchUserFollowing?.followers?.length > 0 ? (
                            <>
                                {fetchUserFollowing?.followers?.length > 0 && (
                                    <>
                                        {fetchUserFollowing?.followers?.map((ele) => (
                                            <div className="d-flex justify-content-between mt-4">
                                                <div className="d-flex">
                                                    <div>
                                                        <img
                                                            src="/assets/images/profile_img2.jpeg"
                                                            className="influencer-follower-user"
                                                            alt="img"
                                                        />
                                                        <img
                                                            src="/assets/images/orange_icon.png"
                                                            className="influencer-user-orange"
                                                            alt="img"
                                                        />
                                                    </div>
                                                    <div className="mt-2 influencer-left-margin">
                                                        <p className="influencer-follower-user-name">
                                                            {ele.followerUserName}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="arrowAnalyst">
                                                    <img
                                                        src="/assets/Analytics/arrow-up-right/Outline.png"
                                                        className="arrowImg"
                                                        alt="arrowRight"
                                                        onClick={() =>
                                                            navigate(`/dashboard/followerpage/enduserprofile`, {
                                                                state: {
                                                                    followerName: ele.followerUserName,
                                                                    id: ele.followerId,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center">
                                <p>{fetchUserFollowing?.message}</p>
                            </div>
                        )}
                    </div>

                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div style={{}} className="SideNotificationComponent scroll-sideNotification">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
        </div>
    );
};

export default FollowerPage;
