import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import './style.scss';

const ProfilePicture = ({ picture, modelOpen }) => {
    return (
        <>
            {picture ? (
                <img src={picture} alt="influencer profile update" className="influencer-profile-update" />
            ) : (
                <FaUserCircle size={135} color={'#fff'} />
            )}
            <div className="influencer-profile-edit" onClick={modelOpen}>
                <img src="/assets/settings/Edit 2.png" className="mt-2 pencil-icon-edit" alt="pencil-icon" />
            </div>
        </>
    );
};

export default ProfilePicture;
