import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import { useSelector } from 'react-redux';
import { getBrokerageId } from 'pages/RecentTrades/selector';

const OrderQueued = ({
    isOpened = false,
    onModelClose,
    handleBuyCost,
    logoUrl,
    ticker,
    handleOpenModal,
    getName,
    changeOptionBuy,
    changeOptionSell,
    inputOptionValue,
    LimitOrderInput,
}) => {
    const brokerageId = useSelector(getBrokerageId);

    const secModalClose = () => {
        onModelClose(false);
    };

    const handleEdit = () => {
        onModelClose(false);
        handleOpenModal(ticker, getInputPrice, getName, logoUrl);
    };

    const getInputPrice = localStorage.getItem('inputPriceRecentTrade');

    const result = changeOptionBuy ? 'buy' : changeOptionSell ? 'sell' : 'buy';

    const brokerageName = brokerageId;

    return (
        <Modal isOpen={isOpened} onRequestClose={secModalClose} style={customStyles} contentLabel="Example Modal">
            <div
                style={{
                    width: '100%',
                    borderRadius: '25px 25px 0px 0px',
                }}
                className="p-4">
                <div className="d-flex justify-content-center">
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                        }}>
                        Order Queued
                    </p>
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '16px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                            width: '100%',
                        }}>
                        Your order to {result} ${' '}
                        {changeOptionBuy || changeOptionSell ? inputOptionValue : getInputPrice} of {ticker} has been
                        queued until market open
                    </p>
                </div>

                <div style={{ top: '1rem' }}>
                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className=" " style={{ marginTop: '25px' }}>
                            <div className="d-flex justify-content-between align-items-center">
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        margin: '0px',
                                    }}>
                                    Stock
                                </p>

                                <div className="d-flex">
                                    <img
                                        src={logoUrl}
                                        alt="logo"
                                        style={{ width: '25px', height: '25px', marginRight: '5px' }}
                                    />
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                        }}>
                                        {ticker}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-4">
                                <div>
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}>
                                        Broker
                                    </p>
                                </div>

                                <div className="d-flex">
                                    <img
                                        src="/assets/images2/Robinhoodimg.png"
                                        alt="Robinhood"
                                        style={{ width: '17px', height: '23px' }}
                                    />
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            marginLeft: '5px',
                                        }}>
                                        {brokerageName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {LimitOrderInput ? (
                    <div className="mt-3">
                        <div className="d-flex justify-content-between">
                            <div>
                                <p
                                    style={{
                                        color: '#8f8f8f',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        margin: '0px',
                                    }}>
                                    Limit Price
                                </p>
                            </div>

                            <div>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '18px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}>
                                    $ {LimitOrderInput}
                                </p>
                            </div>
                        </div>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                            }}>
                            Execute during market hours <br /> Expire in 90 days
                        </p>
                    </div>
                ) : (
                    ''
                )}

                <div className="d-flex justify-content-between" style={{ marginTop: '40px' }}>
                    <button
                        style={{
                            width: '150px',
                            height: '52px',
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            borderRadius: '54px',
                            letterSpacing: '0.7px',
                            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                        }}
                        onClick={handleEdit}>
                        EDIT
                    </button>

                    <button
                        style={{
                            width: '150px',
                            height: '52px',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            borderRadius: '54px',
                            letterSpacing: '0.7px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        }}
                        onClick={(e) => handleBuyCost(e)}>
                        DONE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderQueued;
