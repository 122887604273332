import React, { useEffect, useState, Suspense, lazy } from 'react';
import MediaQuery from 'react-responsive';
import LoaderModal from '../Login/LoaderModal';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import WelcomeSection from 'Components/reusable/welcomeSection';
import ComponentLoading from 'Components/reusable/loading/Loading';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import { brokeragesRobinHoodPortFolio } from 'Api/portfolio';
import { getBuyingPower } from 'Api/influencerDashboard';
import { getUserId } from 'pages/analytics/slector';
import { getBrokage_id } from 'pages/Portfolio/selector';
import { useSelector } from 'react-redux';
import './style.scss';

const TradeListing = lazy(() => import('Components/reusable/tradeListing'));

const PortfolioTrendingStocks = () => {
    const [loading, setLoading] = useState(true);
    const [fetchStocksData, setFetchStocksData] = useState([]);
    const id = useSelector(getUserId);
    const brokage_id = useSelector(getBrokage_id);
    const [stocksData, setStocksData] = useState([]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const getBrokeragesRobinHoodPortFolio = () => {
        brokeragesRobinHoodPortFolio()
            .then((response) => {
                setFetchStocksData(response);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getUserBrokerages = (id) => {
        getBuyingPower(id)
            .then((response) => {
                const stocksInfo = response?.transactions?.map((tradesData) => ({
                    updatedAt: tradesData?.updatedAt,
                    ticker: tradesData?.symbol,
                    side: tradesData?.side,
                    amount: tradesData?.amount,
                    price: tradesData?.price,
                    value: tradesData?.value || 0,
                    logoUrl: tradesData?.logoUrl ? tradesData?.logoUrl : '/assets/images2/portfolio_icon.png',
                    name: tradesData?.name,
                    updatedTimestamp: tradesData?.updatedTimestamp,
                }));
                setStocksData(stocksInfo);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getBrokeragesRobinHoodPortFolio();
        getUserBrokerages(brokage_id);
    }, []);

    const influencer = {
        firstName: 'Portfolio Trade History',
    };

    return (
        <div className="portfolio-trade-history">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar />
                        </div>
                    </MediaQuery>
                    <div className="col-xl-8 col-lg-5">
                        <div className="mt-4">
                            <h2> Trade History</h2>
                        </div>
                        {loading ? (
                            <div className="m-4 text-center">{loading && <LoaderModal />}</div>
                        ) : (
                            <Suspense fallback={<ComponentLoading />}>
                                <TradeListing stocksData={stocksData} />
                            </Suspense>
                        )}
                    </div>

                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div className="scroll-sideNotification side-notification">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
        </div>
    );
};

export default PortfolioTrendingStocks;
