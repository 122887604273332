import api from '../util/instance';
import { urls } from './Api';

export const stockDetail = async (id) => await api.get(`${urls.stockDetail}/${id}/detail`);

export const fractionalTrade = async (payload = {}) => await api.post(`${urls.fractionalTrade}`, payload);

export const getStockDetailGraph = async (symbol, range, interval) =>
    await api.get(`${urls.getStockDetailGraph}?symbol=${symbol}&range=${range}&interval=${interval}`);

export const getPositionData = async (symbol) => await api.get(`${urls.getStockDetailGraph}?symbol=${symbol}`);

export const fetchTrendingStocks = async () => await api.get(urls.fetchTrendingStocks);

export const getStockConfirmModalSimulate = async (payload = {}) =>
    await api.post(`${urls.stocksimulateTradeApi}`, payload);

export const BrokeragesById = async (brokerageId) => await api.get(`${urls.getBrokerageById}/${brokerageId}`);

export const AggregatePosition = async () => await api.get(`${urls.aggregatePositionApi}`);
