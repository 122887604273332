import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';

const OrderSuccess = ({
    isOpened = false,
    onModelClose,
    setModalQueuedIsOpen,
    logoUrl,
    ticker,
    changeOptionBuy,
    changeOptionSell,
    inputOptionValue,
}) => {
    const secModalClose = () => {
        onModelClose(false);
    };

    const handleDone = () => {
        onModelClose(false);
        setModalQueuedIsOpen(true);
    };

    const getInputPrice = localStorage.getItem('inputPriceRecentTrade');

    const estimateAmount = localStorage.getItem('estimateAmount');

    return (
        <Modal isOpen={isOpened} onRequestClose={secModalClose} style={customStyles} contentLabel="Example Modal">
            <div
                style={{
                    width: '100%',
                    borderRadius: '25px 25px 0px 0px',
                }}
                className="p-3 ">
                <div className="d-flex justify-content-center mt-2 mb-4 ">
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                            margin: '0px',
                        }}>
                        Order Success
                    </p>
                </div>

                <div style={{ backgroundColor: '#FFFFFF' }}>
                    <div className=" my-5">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}
                                    className="m-1">
                                    Stock
                                </p>
                            </div>

                            <div className="d-flex">
                                <img src={logoUrl} alt="logo" style={{ width: '25px', height: '25px' }} />
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '18px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: '125%',
                                    }}
                                    className="m-1">
                                    {ticker}
                                </p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between my-2">
                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        marginLeft: '2px',
                                        margin: '0px',
                                    }}>
                                    Amount Invested
                                </p>
                            </div>

                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F))',
                                        fontStyle: 'normal',
                                        fontSize: '18px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        border: 'none',
                                        margin: '0px',
                                    }}>
                                    $ {changeOptionBuy || changeOptionSell ? inputOptionValue : getInputPrice}
                                </p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    margin: '0px',
                                }}>
                                Estimated Share
                            </p>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '18px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    margin: '0px',
                                }}>
                                {estimateAmount}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <button
                        style={{
                            width: '280px',
                            height: '52px',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            borderRadius: '54px',
                            letterSpacing: '0.7px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        }}
                        onClick={handleDone}>
                        DONE
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderSuccess;
