import axios from 'axios';
import api from '../util/instance';
import { urls } from './Api';

export const registerPhone = async (payload = {}) =>
    await axios.post(`${process.env.REACT_APP_API_URL}${urls.registerPhone}`, payload);

export const registerOTP = async (payload = {}) => await api.post(urls.registerOTP, payload);

export const registerAvatar = async (payload = {}, config = {}) => await api.post(urls.RegisterAvtar, payload, config);

export const registerEmail = async (payload = {}, config = {}) => await api.post(urls.registerEmail, payload, config);

export const registerPassword = async (payload = {}, config = {}) =>
    await api.post(urls.registerPassword, payload, config);

export const registerUserName = async (payload = {}, config = {}) =>
    await api.post(urls.registerUserName, payload, config);
