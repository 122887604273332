import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const OrderConfirmation = ({
    isOpened = false,
    onModelClose,
    setModalFinalIsIsOpen,
    logoUrl,
    ticker,
    setModalBuyIsOpen,
    LimitOrderInput,
    getBrokerageDropdownDataName,
}) => {
    const secModalClose = () => {
        onModelClose(false);
    };

    const handleConfirm = () => {
        onModelClose(false);
        setModalFinalIsIsOpen(true);
    };

    const estimateCost = localStorage.getItem('estimateCost');

    const estimateAmount = localStorage.getItem('estimateAmount');

    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={secModalClose}
            style={customStyles}
            contentLabel="Order Confirmation Modal">
            <div
                style={{
                    width: '100%',
                    borderRadius: '25px 25px 0px 0px',
                }}
                className="p-3">
                <div className="d-flex justify-content-between mt-2 mb-4 ">
                    <img
                        src="/assets/stockdetails/arrow_left_black.png"
                        alt="arrow left black"
                        style={{ width: '24px', height: '24px', textAlign: 'left', left: '1rem', cursor: 'pointer' }}
                        onClick={() => {
                            if (setModalBuyIsOpen) {
                                onModelClose(false);
                                setModalBuyIsOpen(true);
                            }
                        }}
                    />
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                        }}>
                        Order Confirmation
                    </p>

                    <p
                        style={{
                            color: 'var(--pf-additional-sunset-ff-5445, #FF5445)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            right: '2rem',
                            cursor: 'pointer',
                        }}
                        onClick={secModalClose}>
                        Cancel
                    </p>
                </div>

                <div style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="mt-1">
                        <div>
                            <div
                                className="d-flex  justify-content-between align-items-center"
                                style={{ paddingLeft: '1rem', paddingRight: '2rem' }}>
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        margin: '0px',
                                    }}>
                                    Stock
                                </p>
                                <div className="d-flex  justify-content-between align-items-center">
                                    <img
                                        src={logoUrl}
                                        alt="logo"
                                        style={{ width: '25px', height: '25px', marginRight: '5px' }}
                                    />
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                            margin: '0px',
                                        }}>
                                        {ticker}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div
                                className="d-flex  justify-content-between "
                                style={{ paddingLeft: '1rem', paddingRight: '2rem', paddingTop: '1rem' }}>
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    Broker
                                </p>

                                <div className="d-flex">
                                    <img
                                        src="/assets/images2/Robinhoodimg.png"
                                        alt="Robinhood"
                                        style={{ width: '17px', height: '23px' }}
                                    />
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            marginLeft: '5px',
                                        }}>
                                        {getBrokerageDropdownDataName !== ''
                                            ? getBrokerageDropdownDataName
                                            : 'Robinhood'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {LimitOrderInput ? (
                            <div className="px-3">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p
                                            style={{
                                                color: '#8f8f8f',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                                margin: '0px',
                                            }}>
                                            Limit Price
                                        </p>
                                    </div>

                                    <div>
                                        <p
                                            style={{
                                                color: '#1F1F1F',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                                marginRight: '1rem',
                                            }}>
                                            $ {LimitOrderInput}
                                        </p>
                                    </div>
                                </div>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Execute during market hours <br /> Expire in 90 days
                                </p>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>

                <div className="m-4 mt-4 p-3 cost-estimation">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '20px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                                className="text-center">
                                {LimitOrderInput ? LimitOrderInput : estimateCost}
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}
                                    className="mt-1">
                                    Estimated cost
                                </p>
                            </p>
                        </div>

                        <img src="/assets/Navbar/Middle_line.png" alt="Middle Line" style={{ height: '48px' }} />

                        <div className="">
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '20px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                                className="text-center">
                                {estimateAmount}
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}
                                    className="mt-1">
                                    Estimated shares
                                </p>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mt-2">
                    <button
                        style={{
                            width: '351px',
                            height: '52px',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            borderRadius: '54px',
                            letterSpacing: '0.7px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        }}
                        onClick={handleConfirm}>
                        CONFIRM
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default OrderConfirmation;
