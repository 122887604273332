import React, { useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const TradeOptions = ({ isOpened = false, onModelClose, setModalOptionList, setModalTradeOption }) => {
    const handleCall = () => {
        setModalOptionList(false);
        setModalTradeOption(true);
    };

    const secModalClose = () => {
        onModelClose(false);
    };

    let dates = ['Jun 10', 'Jun 11', 'Jun 12', 'Jun 13', 'Jun 14', 'Jun 15'];

    let stock_data = [
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'down',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'down',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'down',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'down',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'up',
            status_value: '5.23',
        },
        {
            value1: '190.00',
            name: 'TSLA',
            label: 'CALL',
            value2: '20.90',
            status: 'down',
            status_value: '5.23',
        },
    ];

    const [selectedDate, setSelectedDate] = useState(dates[0]);

    const handleDateClick = (item) => {
        setSelectedDate(item);
    };

    return (
        <Modal isOpen={isOpened} onRequestClose={secModalClose} style={customStyles} contentLabel="Trade Options Modal">
            <div className="trade-option-model">
                <p
                    style={{
                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        fontStyle: 'normal',
                        fontSize: '18px',
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        lineHeight: '24px',
                        textAlign: 'center'
                    }}>
                    Trade Options
                </p>

                <hr style={{ width: '100%', opacity: 0.1 }} />

                <div style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <img
                                src="/assets/stockdetails/microsoft_round.png"
                                alt="microsoft round"
                                style={{ width: '48px', height: '48px' }}
                            />
                            <div className="d-flex" style={{ bottom: '5px', left: '5px' }}>
                                <div>
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                        }}
                                        className="m-1">
                                        MSFT{' '}
                                    </p>

                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '125%',
                                            marginLeft: '4px',
                                        }}>
                                        $315.26
                                    </p>
                                </div>
                                <div className="m-1" style={{ opacity: 0.5, right: '5px' }}>
                                    |
                                </div>{' '}
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: '125%',
                                        right: '5px',
                                    }}
                                    className="m-1 mt-2">
                                    Microsoft
                                </p>
                            </div>
                        </div>

                        <div>
                            <div className="d-flex" style={{ left: ' 40px' }}>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: '125%',
                                    }}
                                    className="m-1">
                                    $15.26
                                </p>
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                    }}
                                    className="m-1">
                                    <img
                                        src="/assets/stockdetails/arrow_drop_up_black_24dp (1) 1.png"
                                        style={{ width: '10px', height: '10px', bottom: '5px' }}
                                        alt="arrowdrop"
                                    />
                                </div>
                                <p
                                    style={{
                                        color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '500',
                                        lineHeight: '125%',
                                    }}
                                    className="m-1">
                                    0.2%
                                </p>
                            </div>
                            <div className="d-flex">
                                <img
                                    src="/assets/stockdetails/alertCircle.png"
                                    style={{ width: '15px', height: '15px' }}
                                    alt="circle"
                                />
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: '125%',
                                        marginLeft: '5px',
                                    }}>
                                    Option price difference
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between">
                    <div
                        style={{ width: '80px', height: '37px', borderRadius: '33px', background: '#71C901' }}
                        className="d-flex justify-content-center align-items-center pt-3">
                        <p
                            style={{
                                color: '#FFF',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '0.7px',
                                cursor: 'pointer',
                                width: '80.3px',
                                height: '17px',
                                textAlign: 'center',
                            }}
                            onClick={handleCall}>
                            CALL
                        </p>
                    </div>

                    <div
                        style={{ width: '80px', height: '37px', borderRadius: '33px'}}
                        className="d-flex justify-content-center align-items-center pt-3">
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '0.7px',
                                opacity: 0.5,
                            }}>
                            PUT
                        </p>
                    </div>

                    <img src="/assets/stockdetails/longMiddleLine.png" alt="line" style={{ height: '28px' }} />

                    <div
                        style={{
                            width: '80px',
                            height: '37px',
                            borderRadius: '33px',
                            background: '#64A2FF',
                            left: '1rem',
                        }}
                        className="d-flex justify-content-center align-items-center pt-3">
                        <p
                            style={{
                                color: '#FFF',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '0.7px',
                                width: '80.3px',
                                height: '17px',
                                textAlign: 'center',
                            }}>
                            BUY
                        </p>
                    </div>
                    <div
                        style={{
                            width: '80px',
                            height: '37px',
                            borderRadius: '33px',
                            background: '',
                            left: '1rem',
                        }}
                        className="d-flex justify-content-center align-items-center pt-3">
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '0.7px',
                                opacity: 0.5,
                            }}>
                            SELL
                        </p>
                    </div>
                </div>

                <div className="d-flex mt-4 mb-2">
                    {dates.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                width: '70px',
                                height: '25px',
                                borderRadius: '33px',
                                background: selectedDate === item ? '#000' : '#FFF',
                                color: selectedDate === item ? '#FFF' : '#000',
                                top: '7px',
                                cursor: 'pointer',
                            }}
                            className="d-flex justify-content-center align-items-center pt-3"
                            onClick={() => handleDateClick(item)}>
                            <p
                                style={{
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                {item}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="stock-options">
                    {stock_data.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    width: '100%',
                                    height: '60px',
                                    borderRadius: '12px',
                                    background: '#F5F8F8',
                                }}
                                className="mt-3 p-2">
                                <div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="d-flex">
                                                <p
                                                    style={{
                                                        color: '#1F1F1F',
                                                        fontStyle: 'normal',
                                                        fontSize: '16px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal',
                                                    }}>
                                                    $ {item.value1}
                                                </p>
                                                <p style={{ opacity: 0.2, left: '10px' }}> |</p>

                                                <p
                                                    style={{
                                                        color: '#71C901',
                                                        fontStyle: 'normal',
                                                        fontSize: '16px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal',
                                                        marginLeft: '20px',
                                                    }}>
                                                    {item.label}
                                                </p>
                                            </div>

                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontStyle: 'normal',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',
                                                    lineHeight: 'normal',
                                                    opacity: 0.5,
                                                    bottom: '10px',
                                                }}>
                                                {item.name}
                                            </p>
                                        </div>
                                        <div>
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontStyle: 'normal',
                                                    fontSize: '16px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal',
                                                }}>
                                                $ {item.value2}
                                            </p>
                                            <div className="d-flex">
                                                {' '}
                                                <img
                                                    src="/assets/stockdetails/arrow_drop_up_black_24dp (1) 1 (1).png"
                                                    style={{
                                                        width: '8px',
                                                        height: '8px',
                                                        bottom: '6px',
                                                        left: '15px',
                                                    }}
                                                    alt="arrowdown"
                                                />
                                                <p
                                                    style={{
                                                        color: '#FF5445',
                                                        fontStyle: 'normal',
                                                        fontSize: '11px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '600',
                                                        lineHeight: 'normal',
                                                        letterSpacing: '0.55px',
                                                        bottom: '10px',
                                                        marginLeft: '20px',
                                                    }}>
                                                    {item.status_value}%
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
};

export default TradeOptions;
