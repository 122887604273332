import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const SelectCondition = ({ isOpened = false, onModelClose, setSelectCondition, setModalSetTime }) => {
    const secModalClose = () => {
        onModelClose(false);
    };

    const handleConfirm = () => {
        setSelectCondition(false);
        setModalSetTime(true);
    };

    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={secModalClose}
            style={customStyles}
            contentLabel="Select Conditions Modal">
            <div className="p-3 select-conditions">
                <div className="d-flex justify-content-center heading-content">
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                        }}>
                        Select Conditions
                    </p>
                    <p
                        style={{
                            color: '#FF5445',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            marginLeft: '50px',
                        }}
                        className="mt-1"
                        onClick={() => setSelectCondition(false)}>
                        Cancel
                    </p>
                </div>

                <hr style={{ opacity: 0.1 }} />
                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}>
                            Market Order
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                                bottom: '10px',
                            }}>
                            Order will be placed at market price
                        </p>
                    </div>
                    <div>
                        <img
                            src="/assets/stockdetails/radioNotSelected.png"
                            alt="radioNotSelected"
                            style={{ width: '18px', height: '18px' }}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}>
                            Stop Order
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                                bottom: '10px',
                            }}>
                            Order will be placed at market price
                        </p>
                    </div>
                    <div>
                        <img
                            src="/assets/stockdetails/radioNotSelected.png"
                            style={{ width: '18px', height: '18px' }}
                            alt="radioSelected"
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}>
                            Limit Order
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                opacity: 0.5,
                                bottom: '10px',
                            }}>
                            Order will be placed at market price
                        </p>
                    </div>
                    <div>
                        <img
                            src="/assets/stockdetails/radioSelected.png"
                            alt="radioSelected"
                            style={{ width: '18px', height: '18px' }}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <button
                        style={{
                            width: '350px',
                            height: '52px',
                            borderRadius: '54px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            letterSpacing: '0.7px',
                        }}
                        onClick={handleConfirm}>
                        CONFIRM
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default SelectCondition;
