import React from 'react';
import Modal from 'react-modal';
import { customStyles5 } from './Style';
const ReferAndEarn = ({
    inputValueRefer,
    handleInputChangeRefer,
    handleCopyClick,
    modalReferAndEarn,
    secModalCloseRefer,
}) => {
    return (
        <div>
            <Modal
                isOpen={modalReferAndEarn}
                onRequestClose={secModalCloseRefer}
                style={customStyles5}
                contentLabel="Example Modal">
                <div className=" text-center mt-3" style={{ width: '228px', marginLeft: '4rem' }}>
                    <p className="invite-frnd-text">Invite Friends & Earn Money</p>
                </div>

                <div className=" text-center mt-3" style={{ width: '228px', marginLeft: '4rem' }}>
                    <p className="copy-code-text">Copy code and share with your friends to get reward points of</p>

                    <img src="/assets/settings/greenLeaf.png" className="greenleaf mt-2" alt="green-leaf" />
                    <p className="leaf-text">20</p>

                    <div className="input-button-div mt-3">
                        <div className="d-flex justify-content-center pt-1">
                            <div className="input-button-sec-div  d-flex justify-content-between p-2 mt-3">
                                <input
                                    type="text"
                                    value={inputValueRefer}
                                    onChange={handleInputChangeRefer}
                                    placeholder="Enter a value"
                                />

                                <button className="copy-code-btn" onClick={handleCopyClick}>
                                    Copy Code
                                </button>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                            <p className="or-text mt-1">OR</p>
                        </div>

                        <div className="d-flex justify-content-center mt-2">
                            <img
                                src="/assets/settings/ManageAnalyticsTwitterIconsFrame.png"
                                alt="Manage Analytics Twitter Icons Frame"
                                className="manange-icons"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ReferAndEarn;
