import React from 'react';
import './style.scss';

const Profile = ({
    profilePicture,
    userName,
    email,
    getFollowState,
    handleFollow,
    investmentPattern,
    onClickInvestmentPattern,
    amount,
    getSubscribeState,
    handleSubscribe,
    isSameUser,
    handleEditInfluencer,
}) => {
    return (
        <div className="mt-3 profile-from-influencer">
            <img
                src={profilePicture || '/assets/images/image (1).png'}
                alt="profile_pic"
                className="influencer-profile-pic"
            />
            <div className="subscribe-button-details w-100">
                <div className="person-info">
                    <div className="d-flex justify-content-evenly">
                        <div>
                            <img
                                className="profile-orange-icon"
                                src="/assets/images/orange_icon.png"
                                alt="orangeicon"
                            />
                        </div>
                        <div>
                            <div className="user-name">{userName}</div>
                            <p className="user-email">{email}</p>
                        </div>

                        {isSameUser && (
                            <div>
                                <img
                                    src="/assets/Influencer/Edit.png"
                                    className="edit-icon"
                                    onClick={handleEditInfluencer}
                                    alt="editinfluencer"
                                />
                            </div>
                        )}

                        {!isSameUser && (
                            <div>
                                <button
                                    className="follow-state"
                                    style={{
                                        backgroundColor:
                                            getFollowState === true
                                                ? 'rgba(255, 255, 255, 0.10)'
                                                : 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                        opacity: getFollowState === true ? 0.7 : '',
                                    }}
                                    disabled={getFollowState}
                                    onClick={handleFollow}>
                                    <p
                                        className="follow-text"
                                        style={{
                                            color:
                                                getFollowState === true
                                                    ? ' var(--pf-primary-canary-d-3-ff-5-b, #FFF)'
                                                    : ' var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            opacity: getFollowState === true ? 0.7 : '',
                                        }}>
                                        {getFollowState === true ? 'FOLLOWING' : 'FOLLOW'}
                                    </p>
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="d-flex ">
                        <button className="investment-pattern" onClick={onClickInvestmentPattern}>
                            {investmentPattern}
                        </button>
                        <button className="investment-pattern">Stocks</button>

                        {!isSameUser && <button className="investment-pattern">Day Trader</button>}
                    </div>
                </div>

                {!isSameUser && (
                    <div className="d-flex justify-content-between mt-4 mb-4">
                        <div className="per-month">
                            <div className="p-2 amount-per-month">
                                <p className="text-center amount-profile">
                                    $ {amount}
                                    <p className="per-month-profile">Per Month</p>
                                </p>
                            </div>
                        </div>
                        <div className="mt-2 w-100">
                            {' '}
                            <button
                                className="subscribe-person-info"
                                style={{
                                    backgroundColor:
                                        getSubscribeState === true
                                            ? 'rgba(255, 255, 255, 0.10)'
                                            : 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    opacity: getSubscribeState === true ? 0.7 : '',
                                }}
                                onClick={handleSubscribe}
                                disabled={getSubscribeState}>
                                <p
                                    style={{
                                        color:
                                            getSubscribeState === true
                                                ? 'var(--pf-primary-canary-d-3-ff-5-b, #FFF)'
                                                : 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        opacity: getSubscribeState === true ? 0.7 : '',
                                    }}
                                    className="mt-3 subscribe-text-flow">
                                    {getSubscribeState === true ? 'SUBCRIBED' : 'SUBSCRIBE'}
                                </p>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Profile;
