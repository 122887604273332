import React from 'react';
import './style.scss';

const LowerSearch = () => {
    return (
        <div className="text-light searchIcons-lower">
            <img src="/assets/dashboardImg/Search.png" alt="search icon" width="20" height="20" />
            <img src="/assets/dashboardImg/UploadIcon.png" alt="search icon" width="20" height="20" />
            <div class="dropdown">
                <i
                    className="fa-solid fa-ellipsis-vertical px-2"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"></i>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                        <a class="dropdown-item" href="#">
                            Block User
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="#">
                            Report User
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default LowerSearch;
