import React from 'react';
import './style.scss';

const SliderCard = ({ index, profilePicture, userName }) => {
    return (
        <div className={`carousel-item ${index === 0 && 'active'} scrolling-slider`}>
            <div className="layers" style={{ position: 'relative' }}>
                <img
                    className="d-block w-100 carousal-image-slide"
                    src={profilePicture || '/assets/images/image (1).png'}
                    alt={`slide ${index}`}
                />
            </div>
            <div className="slider-info-container">
                <div className="d-flex justify-content-between p-3">
                    <div className="mt-3">
                        <div className="d-flex">
                            <img
                                src="/assets/images2/twitter_icon.png"
                                className="slider-twitter-icon"
                                alt="twitter icon"
                            />
                            <p className="slider-twitter-follower-count">0K</p>
                        </div>

                        <div className="d-flex ">
                            <p className="slider-user-name">@{userName}</p>
                            <img src="/assets/images2/verify 1.png" className="mt-1 slider-verify-img" alt="verify" />
                        </div>
                    </div>

                    <div className="d-flex mt-3" style={{ right: '5px' }}>
                        <p className="slider-return-label">Returns</p>
                        <div className="d-flex slider-blue-area-contain">
                            <img src="/assets/DashboardNewUser/arrowTop.png" alt="arrow top" />
                            <p className="slider-return-value">3.32%</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2 ">
                    {' '}
                    <button className="m-3 follow-btn">Follow</button>
                    <button className="m-3 subscribe-btn">Subscribe</button>
                </div>
            </div>
        </div>
    );
};

export default SliderCard;
