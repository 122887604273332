import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';
import ct from 'classnames';
import { useSelector } from 'react-redux';
import LoaderModal from 'pages/Login/LoaderModal';
import Navbar from 'Components/Navbar/Navbar';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { influencerReview, userReview } from 'Api/influencerDetails';
import { getInfluencerByID } from 'Api/influencerDashboard';
import { getInfluencerId } from 'pages/influencerDetails/selector';
import './style.scss';

const Review = () => {
    const [fetchReview, setFetchReview] = useState([]);
    const [review, setReview] = useState('');
    const [loading, setLoading] = useState(true);
    const [reviewCount, setReviewCount] = useState(0);
    const [getSubscribeState, setGetSubscribeState] = useState(false);
    const id = useSelector(getInfluencerId);

    const getInfluencerReview = () => {
        influencerReview(id)
            .then((response) => {
                setReviewCount(response?.reviewCount);
                setFetchReview(response?.reviews || []);
            })
            .catch((error) => {
                console.error(error);
            });
        setLoading(false);
    };

    useEffect(() => {
        getInfluencerReview();
        (async () => {
            const response = await getInfluencerByID(id);
            setGetSubscribeState(response?.followerSubscriber?.isSubscribed);
        })();
        // eslint-disable-next-line
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        const data = {
            rating: 0,
            review: review,
        };

        userReview(id, data)
            .then((response) => {
                setLoading(false);
                swal({
                    title: 'Thanks !',
                    icon: 'success',
                    timer: 2000,
                    buttons: false,
                });
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                swal('You must be a subscriber to this influencer to add a review', '', 'error');
            });
    };

    const influencer = {
        firstName: 'Reviews',
    };

    return (
        <>
            <div className="influencer-review">
                <div className="influencer-container">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-1 col-lg-1">
                            <MediaQuery minWidth={769}>
                                <Navbar />
                            </MediaQuery>
                        </div>

                        <div className="col-xl-8 col-lg-10 mx-auto">
                            <div className="p-3 review-container">
                                <div className="d-flex">
                                    <div className="d-flex justify-content-center align-items-center star-container">
                                        <img
                                            src="/assets/Influencer/Star icon (1).png"
                                            alt="star icon"
                                            className="star-icon"
                                        />
                                    </div>

                                    <div className="review-margin-left">
                                        <p className="review-count">${reviewCount}</p>
                                        <p className="all-review-heading">All Reviews</p>
                                    </div>
                                </div>
                                <hr />
                                {fetchReview &&
                                    fetchReview?.map((reviewData) => {
                                        return (
                                            <div className="d-flex mt-4 w-100">
                                                <img
                                                    src="/assets/images/image (1).png"
                                                    alt="alexa icon"
                                                    className="influencer-image-review"
                                                />
                                                <div className="w-100 review-margin-left">
                                                    <div className="d-flex justify-content-between w-100">
                                                        <p className="review-user-name">{reviewData.user}</p>
                                                        <p className="review-date">15 Jan 2023</p>
                                                    </div>

                                                    <p className="review-desc">{reviewData.review}</p>
                                                </div>
                                            </div>
                                        );
                                    })}

                                {getSubscribeState && (
                                    <div
                                        className={ct('d-flex', 'justify-content-between', 'subscribe-section-review')}>
                                        <input
                                            type="text"
                                            className="form-control input-review"
                                            value={review}
                                            onChange={(e) => setReview(e.target.value)}
                                            placeholder="Add a Review"
                                        />
                                        <div className="p-1 mt-2 submit-review-button">
                                            <img
                                                src="/assets/Influencer/arrow_left.png"
                                                className="left-arrow"
                                                alt="influencer left arrow"
                                                onClick={handleSubmit}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 pe-0">
                                <div className="scroll-sideNotification side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>
            {loading && <LoaderModal />}
        </>
    );
};

export default Review;
