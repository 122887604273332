export const customStyles5 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '385px',
        height: '475px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};

export const customStyles4 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '385px',
        height: '351px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '490px',
        height: '713px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};

export const customStyles2 = {
    content: {
        top: '51%',
        left: '58.5%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '240px',
        height: '228px',
        borderRadius: '18px',
        backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};

export const customStyles13 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '490px',
        height: '505px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
        position: 'absolute',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};

export const customStyles11 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '460px',
        height: '431px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
        position: 'absolute',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};

export const customStyles12 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '460px',
        height: '328px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
        position: 'absolute',
    },
    overlay: {
        backgroundColor: 'rgba(31, 31, 31, 0.8)',
    },
};
