import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const UnFollowInfo = ({ isOpened = false, onModelClose, image, name, buttonText, buttonClick }) => {
    return (
        <div>
            <Modal
                isOpen={isOpened}
                onRequestClose={onModelClose}
                style={customStyles}
                contentLabel="influencer information">
                <div className="p-3 influencer-information-popup">
                    <div className="d-flex justify-content-between middle-section">
                        <div className="d-flex justify-content-between mt-3 name-block">
                            <div>
                                <img src={image || '/assets/images/image (1).png'} className="user-image" alt="img" />
                                <img src="/assets/images/orange_icon.png" className="orange-icon" alt="img" />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="mt-2">
                                <p className="influencer-name mb-0">@{name}</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="block-button">BLOCK</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button className="un-follow-btn" onClick={buttonClick}>
                            {buttonText}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UnFollowInfo;
