import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoaderModal from '../../pages/Login/LoaderModal';
import UserDashboard from '../../pages/normalUserDashboard';
import InfluencerDashBoard from '../../pages/InfluencerDashBoard';
import DashBoard2 from '../../pages/firstUserDashboard';
import { userBrokerages } from 'Api/brokerageDetails';
import { fetchUserFollowingApi, getUserProfile } from 'Api/setting';
import { getSubscription } from 'Api/UserSubscription';
import { getUserId } from 'pages/analytics/slector';
import '@fortawesome/fontawesome-free/css/all.css';
import './Dashboard.css';

const DashBoard = () => {
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [BrokragesAllData, setBrokarageAllData] = useState([]);
    const [getUserProfileData, setGetUserProfileData] = useState([]);
    const [getUserSubscription, setGetUserSubscription] = useState([]);
    const id = useSelector(getUserId);

    useEffect(() => {
        getUserProfile(id)
            .then((response) => {
                setGetUserProfileData(response);
            })
            .catch((error) => {
                console.error(error, 'catch error');
            });
    }, [id]);

    useEffect(() => {
        userBrokerages()
            .then((response) => {
                setBrokarageAllData(response);
            })
            .catch((error) => {
                console.error(error, 'catch error');
            });
    }, []);

    useEffect(() => {
        getSubscription()
            .then((response) => {
                setGetUserSubscription(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        fetchUserFollowingApi()
            .then((response) => {
                setFetchUserFollowing(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    let componentToRender = null;

    if (
        getUserProfileData?.userType === 'user' &&
        BrokragesAllData &&
        BrokragesAllData?.length !== 0 &&
        getUserSubscription &&
        getUserSubscription?.length !== 0
    ) {
        componentToRender = <UserDashboard />;
    } else if (getUserProfileData?.userType === 'influencer') {
        componentToRender = <InfluencerDashBoard />;
    } else if (
        getUserProfileData?.userType === 'user' &&
        BrokragesAllData &&
        BrokragesAllData?.length === 0 &&
        getUserSubscription &&
        getUserSubscription?.length === 0
    ) {
        componentToRender = <DashBoard2 />;
    } else if (
        getUserProfileData?.userType === 'user' &&
        fetchUserFollowing &&
        fetchUserFollowing?.followingCount !== 0
    ) {
        componentToRender = <DashBoard2 />;
    } else {
        componentToRender = <LoaderModal />;
    }

    return <>{componentToRender}</>;
};

export default DashBoard;
