export const customStyles = {
    content: {
        left: 'auto',
        right: '8px',
        bottom: 'auto',
        marginRight: 'auto',
        transform: 'unset',
        width: '735px',
        border: 0,
        padding: '0px',
        zIndex: 9999,
        backgroundColor: 'transparent',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9998,
    },
};
