import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import loginReducer from './actions/login';
import signUp from './actions/signUp';

const loginConfig = {
    key: 'auth',
    storage,
};

const signConfig = {
    key: 'sign-up',
    storage,
};

const logIn = persistReducer(loginConfig, loginReducer);
const signUpUser = persistReducer(signConfig, signUp);

export const store = configureStore({
    reducer: {
        login: logIn,
        signUp: signUpUser,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export const persister = persistStore(store);
