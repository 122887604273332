import React, { useRef, useEffect } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import * as CONSTANT from './constant';
import { customStyles } from './style';
import './style.scss';

const SearchInput = ({
    isOpened = false,
    onModelClose,
    handleSearchChange,
    handleSearch,
    searchResults,
    searchText,
    setSecIsOpenLink,
    modalIsSecOpenLink,
    getStockDetail,
}) => {
    const inputRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            if (searchText) inputRef?.current?.focus();
        }, 100);
    }, [searchText]);

    const handleSearchStocks = (ticker) => {
        setSecIsOpenLink(false);
        const newURL = `${CONSTANT.STATIC_LINK_PATH}${ticker}`;

        navigate(newURL);

        window.location.reload();
    };

    return (
        <Modal isOpen={isOpened} onRequestClose={onModelClose} style={customStyles} contentLabel="search input model">
            <div className="search-input-model">
                <div>
                    <div>
                        <img src={CONSTANT.SEARCH_ICON} alt="search icon" className="search-icon" />
                    </div>
                    <input
                        type="search"
                        className="form-control input-search"
                        placeholder=""
                        aria-label="Search"
                        aria-describedby="search-addon"
                        ref={inputRef}
                        value={searchText}
                        onClick={(e) => handleSearchChange(e)}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div className="image-container">
                    {searchResults?.map((ele, i) => (
                        <div key={ele.id}>
                            <div
                                onClick={() => handleSearchStocks(ele.ticker)}
                                target="_blank"
                                className="link-decoration">
                                <div className="d-flex justify-content-between mt-2 map-content">
                                    <div className="right-flex">
                                        <div className="d-flex justify-content-center align-items-center logo-image-container">
                                            <img src={ele.logourl} alt="Lights" className="logo-image" />
                                        </div>
                                        <div className="name-container">
                                            <p className="ticker-para">
                                                {ele.ticker}

                                                <p className="name-para">{ele.name}</p>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <p className="return-text m-1">Returns</p>
                                        <div className="d-flex justify-content-between mt-1 arrow-image-container">
                                            <img
                                                src={CONSTANT.ARROW_PATH_ICON}
                                                alt="arrow icon"
                                                className="arrow-image-icon"
                                            />
                                            <p className="present-text">3.32%</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div />
                </div>
            </div>
        </Modal>
    );
};

export default SearchInput;
