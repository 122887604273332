import React from 'react';
import { Col } from 'react-bootstrap';
import Search from '../../DashBoard/Search/Search';
import LowerSearch from 'Components/reusable/lowerSearch';
import './style.scss';

const WelcomeSection = ({ id, influencer, isVisible }) => {
    return (
        <div className="welcome-section">
            <Col className="d-flex justify-content-between middle-flex pt-5">
                <div className="flex-middle-lower-flex">
                    <div className="header-text-content">
                        <p className="header-welcome">Welcome to</p>
                        <h1 className="header-finance mt-3">Parrot Finance</h1>
                        <div>
                            {influencer && (
                                <p className="influencer-name">
                                    {influencer?.firstName} {influencer?.lastName}
                                </p>
                            )}
                        </div>
                    </div>

                    {isVisible && <LowerSearch />}
                </div>

                <div className="search-bar">
                    <Search id={id} />
                </div>
            </Col>
        </div>
    );
};

export default WelcomeSection;
