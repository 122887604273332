import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const MarketOrder = ({ isOpened = false, onModelClose, setModalTradeOptionMarket, setSelectCondition }) => {
    const secModalClose = () => {
        onModelClose(false);
    };

    const handleReview = () => {
        setModalTradeOptionMarket(false);
        setSelectCondition(true);
    };

    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={secModalClose}
            style={customStyles}
            contentLabel="Trade Option Market Order Modal">
            <div className="p-3 trade-option-market-order">
                <div className="d-flex justify-content-center heading-content">
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            fontStyle: 'normal',
                            fontSize: '18px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                        }}>
                        Trade Options
                    </p>
                    <p
                        style={{
                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #64A2FF)',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            marginLeft: '50px',
                        }}
                        className="mt-1">
                        Market Order
                    </p>
                </div>

                <hr style={{ opacity: 0.10000000149011612 }} />
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="d-flex">
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                $ 190.00 |
                            </p>

                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    marginLeft: '5px',
                                }}>
                                10 Apr{' '}
                                <span
                                    style={{
                                        color: '#71C901',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginLeft: '5px',
                                    }}>
                                    CALL
                                </span>
                            </p>
                        </div>
                        <div className="d-flex">
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    opacity: 0.5,
                                    bottom: '10px',
                                }}>
                                EXP
                            </p>
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',

                                    bottom: '10px',
                                    marginLeft: '5px',
                                }}>
                                15 JUN
                            </p>
                        </div>
                    </div>
                    <div>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}>
                            $ 20.90
                        </p>
                        <p
                            style={{
                                color: '#FF5445',
                                fontStyle: 'normal',
                                fontSize: '11px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                letterSpacing: '0.55px',
                                bottom: '10px',
                                marginLeft: '20px',
                            }}>
                            2.46%
                        </p>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <p
                        style={{
                            color: '#1F1F1F',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}>
                        No. of contracts
                    </p>
                </div>

                <div className="d-flex justify-content-center">
                    <p
                        style={{
                            color: '#1F1F1F',
                            fontStyle: 'normal',
                            fontSize: '40px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}>
                        1
                    </p>
                </div>

                <div className="mt-5 p-3 robesHood">
                    <div>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <img
                                    src="/assets/stockdetails/robinhood_green.png"
                                    alt="robinhood green"
                                    style={{ width: '38px', height: '38px' }}
                                />
                                <div style={{ marginLeft: '10px' }}>
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontStyle: 'normal',
                                            fontSize: '18px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                        }}>
                                        Robinhood
                                        <p
                                            style={{
                                                color: '#1F1F1F',
                                                fontStyle: 'normal',
                                                fontSize: '10px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}>
                                            $ 1289.5 Available
                                        </p>
                                    </p>
                                </div>
                            </div>
                            <div>
                                <i class="fas fa-angle-down" style={{ opacity: 0.5 }}></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                    <button
                        style={{
                            width: '350px',
                            height: '52px',
                            borderRadius: '54px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            letterSpacing: '0.7px',
                        }}
                        onClick={handleReview}>
                        REVIEW
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default MarketOrder;
