import React, { useEffect, useState, Suspense, lazy } from 'react';
import MediaQuery from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from 'Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import LoaderModal from 'pages/Login/LoaderModal';
import ReferAndEarn from 'Components/reusable/model/ReferAndEarn/ReferAndEarn';
import ComponentLoading from 'Components/reusable/loading/Loading';
import * as CONSTANT from './constant';

// API Calls
// import { brokeragesRobinHoodPortFolio, getBrokerages, pendingOrderAPI } from 'Api/portfolio';
// import { getAPIFrontUserChart, getBuyingPower, getRefferal } from 'Api/influencerDashboard';
import { brokerageDetails, fetchUserFollowingApi } from 'Api/setting';
// import { pendingOrderCancel } from 'Api/portfolio';
// import CommonModal from 'Components/reusable/settings/commonModal';
// import { fetchProfile } from 'Api/dashboard';

// get aggregate portfolio
// get portfolio details
// get transactions history
// get chart data
import { getAggregatePortfolio, getUserChartDataForPeriod } from 'Api/front';
import {
    getTransactionHistory,
    getPortfolioDetails,
    getMe,
    getBrokerages,
    pendingOrderCancel,
    getPortfolioPositions,
} from 'Api/portfolio';
import { getNotification } from 'Api/notification';

import CancerOrder from 'Components/reusable/model/cancerOrder';
import { getUserId } from 'pages/influencerDetails/selector';
import { getBrokage_id, getToken } from './selector';
import { userLoggedIn } from 'globalStore/actions/login';
import TradesCards from 'Components/reusable/influencer/cards/recentTrade';
import FollowersUserCard from 'Components/reusable/portfolio/cards/followersUserCard';
import BrockragesGraph from 'Components/reusable/portfolio/brockragesGraph';
import HeadingWrapper from 'Components/reusable/portfolio/headingWrapper';
import TopHolding from 'Components/reusable/influencer/cards/topHolding';
import PendingOrder from 'Components/reusable/portfolio/cards/pendingOrder';
import './style.scss';
import CommonModal from 'Components/reusable/settings/commonModal';

const DiversityDonut = lazy(() => import('Components/reusable/graph/DiversityDonut'));
const ChartLine = lazy(() => import('Components/reusable/graph/lineChart'));

const Portfolio = () => {
    const [fetchStocksPortfolioData, setFetchStocksPortfolioData] = useState([]);
    const [fetchStocksPortfolioDataTrade, setFetchStocksPortfolioDataTrade] = useState([]);

    const [tradeHistoryData, setTradeHistoryData] = useState([]);

    // const [pendingOrders, setPendingOrders] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [fetchTopHolding, setFetchTopHolding] = useState([]);
    const [modalTradeHistory, setModalTradeHistory] = useState(false);
    const [modalCancelOrder, setModalCancelOrder] = useState(false);
    const [fetchBuyingPower, setFetchBuyPower] = useState([]);
    // const [inputValueRefer, setInputValueRefer] = useState('');
    const [modalReferAndEarn, setModalReferAndEarn] = useState(false);
    const [toggleDays, setToggleDays] = useState(CONSTANT.TOGGLE_DAYS);
    const [acValueGraph, setAcValueGraph] = useState([]);
    const [profitLoss, setProfitLoss] = useState();
    const [performanceValue, setPerformanceValue] = useState(0);
    const [getTransId, setGetTransId] = useState('');
    const [brokerDropdown, setBrokerDropdown] = useState([]);
    const [selectDropdown, setSelectDropDown] = useState('');
    const [defaultBrokerage, setgetDefaultBrokerage] = useState([]);
    const [orderCancel, setOrderCancel] = useState(false);
    const [orderCancelErr, setOrderCancelErr] = useState(false);
    const [userProfile, setUserProfile] = useState();
    const [singleTradeHistory, setSingleTradeHistory] = useState({});
    const token = useSelector(getToken);
    const id = useSelector(getUserId);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getProfile = async () => {
        const response = await getMe();
        setUserProfile(response);
    };

    const keyProps = (image, name) => ({
        className: 'mb-0 broker-dropdown-item',
        children: [
            <span key="icon">
                <img src={image} alt="icon" />
            </span>,
            ' ',
            name,
        ],
    });

    const getDefaultBroker = async () => {
        const resp = await getBrokerages();
        setgetDefaultBrokerage(resp[0]);
    };

    const [portfolioValue, setPortfolioValue] = useState({});

    const getPortFolioBrokerages = async () => {
        try {
            const response = await getPortfolioPositions(selectDropdown);
            setFetchStocksPortfolioData(response.allocations);
            setFetchTopHolding(response);
            setPortfolioValue(response.value.content);
        } catch (err) {
            setError(err);
        }
    };

    const getUserBrokerages = async (id) => {
        const transactionHistoryResp = await getTransactionHistory(id);
        console.log('trade history:', transactionHistoryResp);
        setTradeHistoryData(transactionHistoryResp.content.transactions);
    };

    // const getPendingOrder = async (id) => {
    //     // setLoading(true);
    //     try {
    //         const response = await pendingOrderAPI(id);
    //         const filterResponse = response?.content?.transactions?.filter((data) => data.status === 'inProgress');

    //         setPendingOrders(filterResponse);
    //         // setLoading(false);
    //     } catch (error) {
    //         // setLoading(false);
    //         console.error(error);
    //     }
    // };

    const getUserFollowingApi = async () => {
        const response = await fetchUserFollowingApi();
        setFetchUserFollowing(response);
    };

    // const getUserReFeral = async () => {
    //     try {
    //         // setLoading(true);
    //         const response = await getRefferal();
    //         setInputValueRefer(response.referralCode);
    //     } catch (err) {
    //         console.error(err);
    //     }

    //     // getRefferal()
    //     //     .then((response) => {
    //     //         setInputValueRefer(response.referralCode);
    //     //     })
    //     //     .catch((error) => {
    //     //         console.error(error);
    //     //     });
    // };

    const refreshBrokerageDetails = async () => {
        const response = await brokerageDetails();

        const brokerages = response?.map((data) => ({
            title: <p {...keyProps('/assets/images2/portfolio_icon.png', data.brokerName)} />,
            value: data.id,
        }));
        setBrokerDropdown(brokerages);
        if (brokerages.length > 0) {
            setSelectDropDown(brokerages[0].value);
        } else {
            setError(true);
        }
    };

    const getFrontUserChart = async (input) => {
        try {
            const response = await getUserChartDataForPeriod(input);

            setProfitLoss(response?.profitLoss);

            const chartFormat = response?.chartData?.map((data) => ({
                timestamp: data?.createdAt?._seconds,
                value: data.equitiesValue,
            }));

            setAcValueGraph(chartFormat);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (selectDropdown) {
            getPortFolioBrokerages();
            getUserBrokerages(selectDropdown);
        }
    }, [selectDropdown]);

    useEffect(() => {
        dispatch(
            userLoggedIn({
                active: 'Portfolio',
            })
        );
        getDefaultBroker();
        // eslint-disable-next-line

        getUserFollowingApi();
        refreshBrokerageDetails();
        getProfile();
        getFrontUserChart('day');
    }, []);

    function secModalClose() {
        setModalTradeHistory(false);
    }

    const customStyles3 = CONSTANT.CUSTOM_STYLES3;

    const influencer = {
        firstName: 'Portfolio',
    };

    if (loading) {
        return <LoaderModal />;
    }

    const handleBrokerage = () => {
        const newTab = window.open(`${process.env.REACT_APP_FRONT_INTEGRATION_URL}?ephemeral=${token}`, '_blank');
        if (newTab) {
            newTab.focus();
        }
    };

    const buyingPower = fetchBuyingPower && fetchBuyingPower[0] && fetchBuyingPower[0].buyingPower;

    const username = localStorage.getItem('username');

    const secModalCloseRefer = () => {
        setModalReferAndEarn(false);
    };

    const handleCancelPendingModal = (transactionId) => {
        setGetTransId(transactionId);
        setModalCancelOrder(true);
    };

    const handleCancelPendingOrder = async () => {
        try {
            await pendingOrderCancel(getTransId);
            setOrderCancel(true);
        } catch (error) {
            setOrderCancelErr(false);
        }
    };

    const handleSelectChange = (e) => {
        console.log(e, 'event');
        setSelectDropDown(e.target.value);
    };

    return (
        <>
            <div className="portfolio-influencer">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="row row-container">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar setModalReferAndEarn={setModalReferAndEarn} />
                        </div>
                    </MediaQuery>
                    <div className="col-xl-8 col-lg-9 col-md-12 middle-container">
                        <FollowersUserCard
                            imageUrl={userProfile?.user?.imageUrl}
                            username={username}
                            followerCount={
                                fetchUserFollowing?.followers?.followerCount ?? fetchUserFollowing.followers?.length
                            }
                            followingCount={
                                fetchUserFollowing?.following?.followingCount ?? fetchUserFollowing?.following?.length
                            }
                            profitLoss={portfolioValue?.totalPerformance?.toFixed(3)}
                        />

                        <BrockragesGraph
                            brokerDropdown={brokerDropdown}
                            setContainer={(data) => {
                                getUserBrokerages(data);
                                getPortFolioBrokerages(data);
                                setgetDefaultBrokerage({ accountId: data });
                                setSelectDropDown(data);
                            }}
                            selectDropdown={selectDropdown}
                            onChangeSelect={(e) => handleSelectChange(e)}
                            buyingPower={buyingPower}
                            performanceValue={performanceValue}
                            acValueGraph={acValueGraph}
                            setPerformanceValue={setPerformanceValue}
                            toggleDays={toggleDays}
                            onClickToggleDays={(data, index) => {
                                toggleDays.forEach((data, index) => {
                                    toggleDays[index].active = false;
                                });
                                toggleDays[index].active = true;

                                getFrontUserChart(data.value);
                                setToggleDays(() => [...toggleDays]);
                            }}
                        />

                        {/* <HeadingWrapper
                            labelHeading="Pending Orders"
                            label="View All"
                            onClickViewAll={() => {
                                dispatch(
                                    userLoggedIn({
                                        brokage_id: defaultBrokerage?.accountId,
                                    })
                                );
                                navigate(`/dashboard/portfolio/pending-order`);
                            }}>
                            {!Boolean(pendingOrders?.length) && (
                                <div className="text-center">No data found for pending orders!!</div>
                            )}

                            {pendingOrders?.slice(0, 2).map((tradeData, index) => (
                                <PendingOrder
                                    key={index}
                                    logoUrl={tradeData?.logoUrl}
                                    symbol={tradeData?.symbol}
                                    amount={tradeData?.amount}
                                    orderType={tradeData?.orderType}
                                    updatedTimestamp={tradeData.updatedTimestamp}
                                    status={tradeData?.status}
                                    onClickCancel={() => {
                                        handleCancelPendingModal(tradeData.id);
                                    }}
                                />
                            ))}
                        </HeadingWrapper> */}

                        <div className="d-flex justify-content-between holding-flex">
                            <div className="holding-margin w-100 mb-3">
                                <HeadingWrapper
                                    labelHeading="All Holdings"
                                    label="View All"
                                    onClickViewAll={() => {
                                        dispatch(
                                            userLoggedIn({
                                                brokage_id: defaultBrokerage?.accountId,
                                            })
                                        );
                                        navigate(`/dashboard/portfolio/holding-stocks`);
                                    }}>
                                    <div className="d-flex justify-content-between hsbc-flex w-100">
                                        {fetchTopHolding?.status === 404 ? (
                                            <div className="m-4 text-center">
                                                <h3 className="no-trades-found">No trades found for this influencer</h3>
                                            </div>
                                        ) : (
                                            <div className="d-flex w-100 justify-content-center all-holdings-contain">
                                                {fetchTopHolding?.positions &&
                                                Array.isArray(fetchTopHolding?.positions) &&
                                                fetchTopHolding?.positions.length > 0 ? (
                                                    fetchTopHolding?.positions.slice(0, 2).map((trade, index) => (
                                                        <TopHolding
                                                            key={index}
                                                            symbol={trade && trade.symbol}
                                                            name={trade && trade.name}
                                                            logoUrl={
                                                                trade?.logoUrl
                                                                    ? trade?.logoUrl
                                                                    : '/assets/images2/portfolio_icon.png'
                                                            }
                                                            amount={trade?.amount}
                                                            position={trade}>
                                                            <Suspense fallback={<ComponentLoading />}>
                                                                <ChartLine chartData={trade?.chartData?.data} />
                                                            </Suspense>
                                                        </TopHolding>
                                                    ))
                                                ) : (
                                                    <div className="m-4 text-center">
                                                        <p>No Top Holdings Found</p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </HeadingWrapper>
                            </div>

                            <HeadingWrapper
                                labelHeading="Trade History"
                                label="View All"
                                onClickViewAll={() => {
                                    dispatch(
                                        userLoggedIn({
                                            brokage_id: defaultBrokerage?.accountId,
                                        })
                                    );
                                    navigate(`/dashboard/portfolio/trending-stocks`);
                                }}>
                                <div>
                                    {tradeHistoryData &&
                                        tradeHistoryData.length > 0 &&
                                        tradeHistoryData.slice(0, 3).map((tradesData, index) => {
                                            const handleModalTradeHistory = () => {
                                                setSingleTradeHistory(tradesData);
                                                setModalTradeHistory(true);
                                            };

                                            return (
                                                <TradesCards
                                                    key={index}
                                                    logoUrl={tradesData?.logoUrl}
                                                    ticker={tradesData?.symbol}
                                                    side={tradesData?.side}
                                                    amount={tradesData?.amount}
                                                    price={tradesData?.price}
                                                    updatedAt={tradesData.updatedTimestamp}
                                                    onclickTrade={handleModalTradeHistory}
                                                    tradeData={tradesData}
                                                />
                                            );
                                        })}
                                    {(!tradeHistoryData || tradeHistoryData.length === 0) && (
                                        <div className="m-4 text-center">
                                            <p>No Trade History Found</p>
                                        </div>
                                    )}
                                </div>
                            </HeadingWrapper>
                        </div>

                        <Suspense fallback={<div>Loading...</div>}>
                            <DiversityDonut chartData={fetchStocksPortfolioData} />
                        </Suspense>
                    </div>
                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div className="scroll-sideNotification side-notification">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>
            <Modal
                isOpen={modalTradeHistory}
                onRequestClose={secModalClose}
                style={customStyles3}
                contentLabel="Example Modal">
                <div className="p-3 portfolio-trade-history-model">
                    <div className="text-center">
                        <p className="trade-history-label">Trade History</p>
                    </div>
                    <hr className="portfolio-hr-separator" />
                    <div className="d-flex align-items-center w-100">
                        <div className="trade-history-stock-info">
                            <img
                                src={
                                    singleTradeHistory?.logoUrl
                                        ? singleTradeHistory?.logoUrl
                                        : '/assets/images2/portfolio_icon.png'
                                }
                                alt="Portfolio Stocks Logo"
                                className="trade-history-stock-logo"
                            />
                        </div>
                        <div className="trade-history-left-margin">
                            <p className="d-flex mb-0 trade-history-symbol">
                                {singleTradeHistory?.symbol}

                                <span className="trade-history-price">${singleTradeHistory?.price}</span>
                                <p className="mb-0 trade-history-assetType">{singleTradeHistory?.assetType}</p>
                                <span className="trade-history-date">
                                    {moment(singleTradeHistory.updatedTimestamp).format('MM/HH/YYYY')}
                                </span>
                                <span className="trade-history-side">{singleTradeHistory?.side}</span>
                            </p>

                            <p className="mt-1 mb-0 trade-history-name">{singleTradeHistory?.name}</p>
                        </div>
                    </div>
                    <hr className="portfolio-hr-separator" />
                    <div className="d-flex mt-4 trade-history-left-margin-40px">
                        <div>
                            <div>
                                <p className="trade-history-order-label">Type</p>
                                <p className="trade-history-order-value">{singleTradeHistory?.orderType}</p>
                            </div>
                            <div>
                                <p className="trade-history-order-label">Time in Force</p>
                                <p className="trade-history-order-value">{singleTradeHistory?.timeInForce}</p>
                            </div>
                            <div>
                                <p className="trade-history-order-label">Quantity</p>
                                <p className="trade-history-order-value">{singleTradeHistory?.amount}</p>
                            </div>
                            <div>
                                <p className="trade-history-order-label">Filled</p>
                                <p className="trade-history-order-value">
                                    {moment(singleTradeHistory?.updatedTimestamp).format('DD/MM/YYYY HH:MM a')}
                                </p>
                            </div>
                        </div>
                        <div className="trade-history-left-margin-40px">
                            <div>
                                <p className="trade-history-order-label">Position Effect</p>
                                <p className="trade-history-order-value">{singleTradeHistory?.status}</p>
                            </div>
                            <div>
                                <p className="trade-history-order-label">Filled Quantity</p>
                                <p className="trade-history-order-value">
                                    {singleTradeHistory?.amount} contract at ${singleTradeHistory?.price}
                                </p>
                            </div>
                            <div>
                                <p className="trade-history-order-label">Limit Price</p>
                                <p className="trade-history-order-value">$0</p>
                            </div>
                            <div>
                                <p className="trade-history-order-label">Submitted</p>
                                <p className="trade-history-order-value">
                                    {moment(singleTradeHistory?.createdTimestamp).format('DD/MM/YYYY HH:MM a')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <CancerOrder
                isOpened={modalCancelOrder}
                onModelClose={setModalCancelOrder}
                onYes={handleCancelPendingOrder}
                onNO={() => {
                    setModalCancelOrder(false);
                }}
            />
            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={orderCancel}
                    onModelClose={() => setOrderCancel(false)}
                    text={'Order cancel successful'}
                    onYes={() => setOrderCancel(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>
            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={orderCancelErr}
                    onModelClose={() => setOrderCancelErr(false)}
                    text={'Order cancel failed !'}
                    onYes={() => setOrderCancelErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>
            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={error}
                    onModelClose={() => setError(false)}
                    text={'No broker found! Please link a broker to access portfolio.'}
                    onYes={handleBrokerage}
                    btn1Text={'link brokerage'}
                    onNo={() => navigate(`/dashboard`)}
                    btn2Text={'Cancel'}
                />
            </Suspense>
            {/* <ReferAndEarn
                inputValueRefer={inputValueRefer}
                handleInputChangeRefer={handleInputChangeRefer}
                handleCopyClick={handleCopyClick}
                modalReferAndEarn={modalReferAndEarn}
                secModalCloseRefer={secModalCloseRefer}
            />{' '} */}
            {loading && <LoaderModal />}
        </>
    );
};

export default React.memo(Portfolio);
