import React, { useState, useLayoutEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ct from 'classnames';
import { useMediaQuery } from 'react-responsive';
import * as CONSTANT from './constant';
import LoginSlider from 'Components/reusable/LoginSlider';
import { registerUserName } from 'Api/registration';
import { getAuthFlowToken } from './selector';
import { signUpCredential } from 'globalStore/actions/signUp';

const SignUpUsername = () => {
    const [skipAction, setSkipAction] = useState(false);
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const id = useSelector(getAuthFlowToken);
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const handleSkip = () => {
        setSkipAction(true);
    };

    const handlePassword = (e) => {
        e.preventDefault();
        const data = {
            username: username,
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + id,
            },
        };

        registerUserName(data, config)
            .then((response) => {
                setUsername(response);
                dispatch(
                    signUpCredential({
                        authFlowToken: response.authFlowToken,
                    })
                );
                navigate(`/signup/code/email/username/profilePic`);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <>
            <Row className="text-center user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container m-auto', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                            </Col>

                            <div className="form-group mt-5">
                                <input
                                    className="form-control otp-input"
                                    type="text"
                                    placeholder="Enter Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>

                            <div className="mt-4">
                                <button
                                    className="btn border border-secondary rounded-pill m-2 confirm-btn"
                                    onClick={handlePassword}>
                                    CONFIRM
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default SignUpUsername;
