import React from 'react';
import Modal from 'react-modal';

const LimitOrderConfirmation = ({
    modalReviewIsOpenLimitOrder,
    secModalClose,
    customStyles13,
    setModalReviewIsOpenLimitOrder,
    secModalCloseLimitMarketModal,
    fetchStocksData,
    brokerageName,
    LimitOrderInput,
    setLimitOrderInput,
    limitOrderDisable,
    estimateAmount,
    setModalFinalIsOpenLimitOrder,
}) => {
    const secModalCloseConfirmation = () => {
        setModalReviewIsOpenLimitOrder(false);
    };

    return (
        <div>
            <Modal
                isOpen={modalReviewIsOpenLimitOrder}
                onRequestClose={secModalCloseConfirmation}
                style={customStyles13}
                contentLabel="Example Modal">
                <div
                    style={{
                        width: '490px',
                        height: '171px',
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className="p-3 ">
                    <div className="d-flex justify-content-between mt-2 mb-4 ">
                        <img
                            src="/assets/stockdetails/arrow_left_black.png"
                            style={{ width: '24px', height: '24px', textAlign: 'left', left: '1rem' }}
                            alt="img"
                            onClick={() => {
                                if (secModalCloseLimitMarketModal) {
                                    setModalReviewIsOpenLimitOrder(false);
                                    secModalCloseLimitMarketModal(true);
                                }
                            }}
                        />
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                                marginLeft: '3rem',
                            }}>
                            Order Confirmation
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-additional-sunset-ff-5445, #FF5445)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                right: '2rem',
                                cursor: 'pointer',
                            }}
                            onClick={() => setModalReviewIsOpenLimitOrder(false)}>
                            Cancel
                        </p>
                    </div>

                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="mt-1">
                            <div>
                                <div
                                    className="d-flex  justify-content-between "
                                    style={{ paddingLeft: '1.7rem', paddingRight: '2rem' }}>
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="m-1">
                                        Stock
                                    </p>
                                    <div className="d-flex">
                                        <img
                                            src={fetchStocksData?.details?.logoUrl}
                                            style={{ width: '25px', height: '25px' }}
                                            alt="img"
                                        />
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1">
                                            {fetchStocksData?.details?.ticker}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="d-flex  justify-content-between "
                                    style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '1rem' }}>
                                    <div className="">
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                            }}>
                                            Broker
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        {' '}
                                        <img
                                            src="/assets/images2/Robinhoodimg.png"
                                            alt="Robinhood"
                                            style={{ width: '17px', height: '23px' }}
                                        />
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '20px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginLeft: '5px',
                                            }}>
                                            {brokerageName !== '' ? brokerageName : 'Robinhood'}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: '430px',
                                    height: '121px',
                                    borderRadius: '14px',
                                    border: 'none',
                                    marginLeft: '15px',
                                }}
                                className="p-3">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        {' '}
                                        <p
                                            style={{
                                                color: '#8f8f8f',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                            }}>
                                            Limit Price
                                        </p>
                                    </div>

                                    <div className="d-flex">
                                        {' '}
                                        <p
                                            style={{
                                                color: '#1F1F1F',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                                top: '2px',
                                                marginLeft: '5%',
                                            }}>
                                            $
                                        </p>
                                        <input
                                            type="text"
                                            value={LimitOrderInput}
                                            onChange={(e) => setLimitOrderInput(e.target.value)}
                                            style={{ width: '60px', border: 'none', bottom: '5px' }}
                                            disabled={limitOrderDisable}
                                        />
                                    </div>
                                </div>
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Execute during market hours
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}>
                                        Expire in 90 days
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        className="m-4 mt-4 p-3"
                        style={{
                            width: '400px',
                            height: '85px',
                            borderRadius: '14px',
                            background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #F5F8F8)',
                            marginLeft: '',
                        }}>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '20px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                    className="text-center">
                                    $ {LimitOrderInput}
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="mt-1">
                                        Estimated cost
                                    </p>
                                </p>
                            </div>

                            <img src="/assets/Navbar/Middle_line.png" style={{ height: '48px' }} alt="img" />

                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '20px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                    className="text-center">
                                    {estimateAmount}
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="mt-1">
                                        Estimated shares
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-2">
                        <button
                            style={{
                                width: '351px',
                                height: '52px',
                                color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                borderRadius: '54px',
                                letterSpacing: '0.7px',
                                backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            }}
                            onClick={() => {
                                setModalReviewIsOpenLimitOrder(false);
                                setModalFinalIsOpenLimitOrder(true);
                            }}>
                            CONFIRM
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default LimitOrderConfirmation;
