import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import usePageVisibility from 'util/usePageVisibility';
import { deleteBrokerage, getBrokerages } from 'Api/portfolio';
import { setDefaultBrokerage } from 'Api/setting';
import './style.scss';

const BrokerInfo = ({ setLoading, setDefaultBrokerModal, setModalMsg }) => {
    const [getDefaultBrokerage, setgetDefaultBrokerage] = useState({});
    const [userBrokerages, setUserBrokerages] = useState([]);
    const [isAddingBroker, setIsAddingBroker] = useState(false);
    const [token, setToken] = useState('');
    const pageVisibleHook = usePageVisibility();

    useEffect(() => {
        const storedToken = JSON.parse(localStorage.getItem('userData'));
        if (storedToken) {
            setToken(storedToken);
        }
    }, []);

    const getUserBrokerages = async () => {
        const resp = await getBrokerages();
        const findDefault = resp.find((ele) => ele.isDefault === true);
        setgetDefaultBrokerage(findDefault);
        setUserBrokerages(resp);
        console.log(resp);
    };

    const handleDeleteBrokerage = async (brokerage) => {
        try {
            await deleteBrokerage(brokerage.id);
            getUserBrokerages();
            swal('Success', 'Successfully unlinked brokerage', 'success');
        } catch (err) {
            swal('Something went wrong', 'Unable to update brokerages.', 'error');
        }
    };

    const openParrotFrontWeb = () => {
        setIsAddingBroker(true);
        const newTab = window.open(
            `https://${process.env.REACT_APP_FRONT_INTEGRATION_URL}?ephemeral=${token}`,
            '_blank'
        );
        if (newTab) {
            newTab.focus();
        }
    };

    const handleMakeCardDefault = async (e, id) => {
        e.preventDefault();
        setLoading(true);
        try {
            await setDefaultBrokerage(id);
            setLoading(false);

            getUserBrokerages();
            setDefaultBrokerModal(true);
        } catch (error) {
            setLoading(false);
            setModalMsg('Set default brokerage failed');
        }
    };

    useEffect(() => {
        getUserBrokerages();
    }, []);

    useEffect(() => {
        if (isAddingBroker && pageVisibleHook) {
            getUserBrokerages();
            setIsAddingBroker(false);
        }
    }, [pageVisibleHook]);

    return (
        <div className="influencer-broker">
            {getDefaultBrokerage?.length && (
                <div className="d-flex justify-content-between mt-3 pb-4 default-container">
                    <div className="d-flex">
                        <img src="/assets/Influencer/Diamond.png" className="diamond-picture" alt="img" />
                        <div className="broker-name-margin">
                            <p className="mb-1 stock-label common-label-color">{getDefaultBrokerage?.brokerName}</p>
                            <div className="d-flex align-items-center">
                                <p className="mb-0 stock-account-name common-label-color">
                                    {getDefaultBrokerage?.accountName}
                                </p>
                                <div className="broker-name-margin">|</div>
                                <p className="mb-0 connected-label common-label-color stock-account-name">Connected</p>
                            </div>
                        </div>
                    </div>
                    <p className="mb-0">Default</p>
                </div>
            )}

            <div className="mt-4 mb-4" />
            <div className="d-flex justify-content-between ">
                <p className="broker-light-heading common-label-color heading-common-font">Brokers</p>
                <p className="new-broker-heading common-label-color heading-common-font" onClick={openParrotFrontWeb}>
                    + ADD NEW BROKER
                </p>
            </div>

            {userBrokerages?.map((ele) => {
                return (
                    <>
                        <div className="d-flex justify-content-between mt-3">
                            <div className="d-flex">
                                <img src="/assets/Influencer/Diamond.png" className="diamond-picture" alt="img" />
                                <div className="broker-name-margin">
                                    <p className="mb-1 stock-label common-label-color">
                                        {ele?.brokerName} {ele?.isDefault === true && '[Default]'}
                                    </p>
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0 stock-account-name common-label-color">{ele?.accountName}</p>
                                        <div style={{ marginLeft: '7px' }}>|</div>
                                        <p className="mb-0 connected-label color-green stock-account-name">Connected</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                {ele?.isDefault !== true && (
                                    <>
                                        <span
                                            className="set-default color-blue"
                                            onClick={(e) => handleMakeCardDefault(e, ele?.accountId)}>
                                            Set Default
                                        </span>
                                        {' | '}
                                    </>
                                )}
                                <span className="set-default color-red" onClick={(e) => handleDeleteBrokerage(ele)}>
                                    Delete
                                </span>
                            </div>
                        </div>
                    </>
                );
            })}
        </div>
    );
};

export default BrokerInfo;
