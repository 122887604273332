import React, { useEffect, useState, Suspense, lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import * as CONSTANT from './constant';
import { getTopWeeklyPerformers } from 'Api/front';
import {
    InfluencerFollowing,
    InfluencerSubscriber,
    getAPIFrontUserChart,
    getBuyingPower,
    getInfluencerByID,
    getInfluencerGraph,
    getRefferal,
    getTopInfluencer,
    getTopSpotlight,
} from '../../Api/influencerDashboard';
import { brokerageDetails } from 'Api/setting';
import ReferAndEarn from 'Components/reusable/model/ReferAndEarn/ReferAndEarn';
import LoaderModal from 'pages/Login/LoaderModal';
import { PerformersList } from 'Components/reusable/normalUser/cards/PerformersOfTheWeek/PerformersOfTheWeek';
import { userLoggedIn } from 'globalStore/actions/login';
import { getUserId } from 'pages/analytics/slector';
import HeadingWrapper from 'Components/reusable/portfolio/headingWrapper';
import InfluencerWrapper from 'Components/reusable/influencer/headingWrapper';
import BrockragesGraph from 'Components/reusable/portfolio/brockragesGraph';
import SubscribeCard from 'Components/reusable/analytics/subscribeCard';
import WinRateCard from 'Components/reusable/influencer/cards/winRate';
import ComponentLoading from 'Components/reusable/loading/Loading';
import Spotlight from 'Components/reusable/influencer/cards/spotlight';
import VerifiedTrader from 'Components/reusable/influencer/cards/verifiedTrader';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.scss';

const SubscriptionEarnings = lazy(() => import('Components/reusable/analytics/subscriptionEarnings'));

const InfluencerDashBoard = () => {
    const [fechInfluencer, setFetchInfluencer] = useState([]);
    const [fechInfluencerAll, setFetchInfluencerAll] = useState([]);
    const [modalReferAndEarn, setModalReferAndEarn] = useState(false);
    const [fetchTradeSpotlight, setFetchTradeSpotlight] = useState([]);
    const [inputValueRefer, setInputValueRefer] = useState('');
    const [fetchSubscriber, setFetchSubscriber] = useState([]);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [subscriptionEarning, setSubscriptionEarning] = useState({});
    const [fetchBuyingPower, setFetchBuyPower] = useState([]);
    const [isYearly, setIsYearly] = useState(false);
    const [earnings, setEarnings] = useState(0);
    const [toggleDays, setToggleDays] = useState(CONSTANT.TOGGLE_DAYS);
    const [acValueGraph, setAcValueGraph] = useState([]);
    const [performanceValue, setPerformanceValue] = useState(0);
    const [brokerDropdown, setBrokerDropdown] = useState([]);
    const [topWeeklyPerformers, setTopWeeklyPerformers] = useState([]);
    const [fetchTradeSpotlightClick, setfetchTradeSpotlightClick] = useState(true);
    const id = useSelector(getUserId);
    const navigate = useNavigate();
    const influencerId = window.localStorage.getItem('userId');
    const [selectDropdown, setSelectDropDown] = useState('');
    const [isLoading, setIsLoading] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.setItem('influencerId', id);
    }, [id]);

    useEffect(() => {
        fetchPortfolioMetrics();
    }, []);

    const getApiInfo = async () => {
        try {
            const response = await Promise.allSettled([
                InfluencerSubscriber(),
                InfluencerFollowing(),
                getTopSpotlight(),
                getTopInfluencer(),
                getInfluencerByID(id),
                getRefferal(),
                getInfluencerGraph(influencerId),
            ]);

            setFetchSubscriber(response[0].value);
            setFetchUserFollowing(response[1].value);
            setFetchTradeSpotlight(response[2].value);
            setFetchInfluencerAll(response[3].value);
            setFetchInfluencer(response[4].value);
            setInputValueRefer(response[5].value.referralCode);
            setSubscriptionEarning({
                monthlyData: response[6].value?.monthlyData,
                weeklyData: response[6].value?.weeklyData,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getUserBrokerages = () => {
        setIsLoading(true);
        getBuyingPower(selectDropdown)
            .then((response) => {
                setFetchBuyPower(response.balance);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const fetchPortfolioMetrics = async () => {
        setIsLoading(true);
        const resp = await getTopWeeklyPerformers();
        setIsLoading(false);

        setTopWeeklyPerformers(resp);
    };

    const getFrontUserChart = async (input) => {
        try {
            const response = await getAPIFrontUserChart(input);

            const chartFormat = response?.chartData?.map((data) => ({
                timestamp: data?.createdAt?._seconds,
                value: data.actualPortfolioPerformance,
            }));

            setAcValueGraph(chartFormat);
        } catch (err) {
            console.warn(err);
        }
    };

    useEffect(() => {
        getApiInfo();
        getFrontUserChart('day');
        getUserBrokerages();
        // eslint-disable-next-line
    }, [selectDropdown]);

    const influencer = {
        firstName: 'Home',
    };

    const buyingPower = fetchBuyingPower && fetchBuyingPower[0] && fetchBuyingPower[0].buyingPower;

    const handleInputChangeRefer = (event) => {
        setInputValueRefer(event.target.value);
    };

    const handleCopyClick = () => {
        if (inputValueRefer) {
            navigator.clipboard
                .writeText(inputValueRefer)
                .then(() => {
                    console.log('Copied to clipboard:', inputValueRefer);
                })
                .catch((error) => {
                    console.error('Copy failed:', error);
                });
        }
    };

    const secModalCloseRefer = () => {
        setModalReferAndEarn(false);
    };

    const keyProps = (image, name) => ({
        className: 'mb-0 broker-dropdown-item',
        children: [
            <span key="icon">
                <img src={image} alt="icon" />
            </span>,
            ' ',
            name,
        ],
    });

    useEffect(() => {
        (async () => {
            const response = await brokerageDetails();
            const brockrages = response?.map((data) => ({
                title: <p {...keyProps('/assets/images2/portfolio_icon.png', data.brokerName)} />,
                value: data.id,
            }));
            setBrokerDropdown(brockrages);
            if (brockrages.length > 0) {
                setSelectDropDown(brockrages[0].value);
            }
        })();
    }, []);

    const handleSelectChange = (e) => {
        setSelectDropDown(e.target.value);
    };

    const getInfluencerId = (id) => {
        if (id) {
            dispatch(
                userLoggedIn({
                    influencer_id: id,
                })
            );
        }
    };

    const handleFetchTradeSpotlight = () => {
        navigate(`/dashboard/explore-inflencer`, {
            state: { fetchTradeSpotlight, fetchTradeSpotlightClick },
        });
    };

    return (
        <div className="influencer-dashboard-pop">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-1">
                            <Navbar setModalReferAndEarn={setModalReferAndEarn} />
                        </div>
                    </MediaQuery>
                    <div className="col-xl-8 col-lg-10">
                        <HeadingWrapper
                            labelHeading="Portfolio Overview"
                            label="View All"
                            onClickViewAll={() => {
                                navigate(`/dashboard/portfolio`);
                            }}>
                            <BrockragesGraph
                                brokerDropdown={brokerDropdown}
                                setContainer={(data) => {
                                    getUserBrokerages(data);
                                    setSelectDropDown(data);
                                }}
                                selectDropdown={selectDropdown}
                                onChangeSelect={(e) => handleSelectChange(e)}
                                buyingPower={buyingPower}
                                performanceValue={performanceValue}
                                acValueGraph={acValueGraph}
                                setPerformanceValue={setPerformanceValue}
                                toggleDays={toggleDays}
                                onClickToggleDays={(data, index) => {
                                    toggleDays.forEach((data, index) => {
                                        toggleDays[index].active = false;
                                    });
                                    toggleDays[index].active = true;

                                    getFrontUserChart(data.value);
                                    setToggleDays(() => [...toggleDays]);
                                }}
                            />
                        </HeadingWrapper>

                        <HeadingWrapper
                            labelHeading="Analytics Overview"
                            label="View All"
                            onClickViewAll={() => {
                                navigate(`/dashboard/analytics`);
                            }}>
                            <div className="d-flex justify-content-between analytics-overview">
                                <div className="w-100 analytics-container">
                                    <div className="d-flex directionFlex">
                                        <SubscribeCard
                                            logo="/assets/Analytics/AnalyticsSubscriber.png"
                                            count={fetchSubscriber?.length}
                                            label={'Subscribers'}
                                            onClickRightIcon={() => navigate(`/dashboard/analytics/subscriber`)}
                                            isGray
                                        />

                                        <SubscribeCard
                                            logo="/assets/Analytics/AnalyticsFollowers.png"
                                            count={fetchUserFollowing?.followers?.length}
                                            label={'Followers'}
                                            onClickRightIcon={() => navigate(`/dashboard/followerpage`)}
                                            isGray
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <WinRateCard winRate={fechInfluencer?.influencer?.influencer?.winRate} />
                                    </div>
                                </div>

                                <div className="subscription-earning-dashboard-graph w-100">
                                    <Suspense fallback={<ComponentLoading />}>
                                        <SubscriptionEarnings
                                            earnings={earnings}
                                            subscription={
                                                !isYearly
                                                    ? subscriptionEarning?.monthlyData
                                                    : subscriptionEarning?.weeklyData
                                            }
                                            setEarnings={setEarnings}
                                            isYearly={isYearly}
                                            onclickMonthlyYearly={(value) => setIsYearly(value)}
                                            isDashboard
                                        />
                                    </Suspense>
                                </div>
                            </div>
                        </HeadingWrapper>

                        <div className="d-flex mt-3 spotlight">
                            <div className="w-100 me-3" style={{ marginRight: '1rem' }}>
                                <HeadingWrapper
                                    labelHeading="Traders Spotlight"
                                    label="View All"
                                    onClickViewAll={handleFetchTradeSpotlight}>
                                    <div className="d-flex">
                                        {fetchTradeSpotlight?.map((ele, index) => (
                                            <Spotlight
                                                key={index}
                                                profilePicture={ele.profilePicture}
                                                twitterFollowers={ele.twitterFollowers}
                                                firstName={ele.firstName}
                                                lastName={ele.lastName}
                                                returns={0}
                                            />
                                        ))}
                                    </div>
                                </HeadingWrapper>
                            </div>
                            <div className="w-100">
                                <HeadingWrapper labelHeading="Performers of the Week">
                                    <PerformersList data={topWeeklyPerformers} />
                                </HeadingWrapper>
                            </div>
                        </div>

                        <InfluencerWrapper
                            text={'Explore'}
                            lastText={' View All'}
                            onClickLastText={() => navigate(`/dashboard/explore-inflencer`)}
                            isShowVerified
                            trader={'Trader'}
                        />

                        <div className="d-flex justify-content-start image-gallery">
                            {fechInfluencerAll?.influencers?.map((ele, i) => {
                                return (
                                    <VerifiedTrader
                                        key={i}
                                        onClickLink={() => getInfluencerId(ele.id)}
                                        profilePicture={ele?.profilePicture}
                                        userName={ele.userName ?? ele?.username}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div className="scroll-sideNotification side-notification">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            </div>

            <ReferAndEarn
                inputValueRefer={inputValueRefer}
                handleInputChangeRefer={handleInputChangeRefer}
                handleCopyClick={handleCopyClick}
                modalReferAndEarn={modalReferAndEarn}
                secModalCloseRefer={secModalCloseRefer}
            />
            {isLoading && <LoaderModal />}
        </div>
    );
};

export default InfluencerDashBoard;
