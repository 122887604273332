import axios from 'axios';
import api from '../util/instance';
import { urls } from './Api';

export const forgetPassword = async (payload = {}) => await axios.post(urls.baseUrl + urls.forgetPassword, payload);

export const resetCode = async (payload = {}) => {
    return await axios.post(urls.baseUrl + urls.resetCode, payload);
};

export const resetPassword = async (payload = {}, config = {}) =>
    await axios.post(urls.baseUrl + urls.resetPassword, payload, config);
