import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { pendingOrderAPI, pendingOrderCancel } from 'Api/portfolio';
import Navbar from '../../Components/Navbar/Navbar';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import CancerOrder from 'Components/reusable/model/cancerOrder';
import CommonModal from 'Components/reusable/settings/commonModal';
import PendingOrderCard from 'Components/reusable/portfolio/cards/pendingOrder';
import { getUserId } from 'pages/analytics/slector';
import { getBrokage_id } from './selector';
import './PendingOrder.scss';

const PendingOrder = () => {
    const [fetchStocksPortfolioDataTrade, setFetchStocksPortfolioDataTrade] = useState([]);
    const [modalCancelOrder, setModalCancelOrder] = useState(false);
    const [getTransId, setGetTransId] = useState('');
    const [orderCancel, setOrderCancel] = useState(false);
    const id = useSelector(getUserId);
    const brokage_id = useSelector(getBrokage_id);

    const influencer = {
        firstName: 'Portfolio Pending Orders',
    };

    const handleCancelPendingModal = (transactionId) => {
        setGetTransId(transactionId);
        setModalCancelOrder(true);
    };

    const getPendingOrder = async (id) => {
        try {
            const response = await pendingOrderAPI(id);
            const filterResponse = response?.content?.transactions?.filter((data) => data.status === 'inProgress');
            setFetchStocksPortfolioDataTrade(filterResponse);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getPendingOrder(brokage_id);
    }, []);

    const handleCancelPendingOrder = async () => {
        try {
            await pendingOrderCancel(getTransId);
            setOrderCancel(true);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <div className="portfolio-holding-trades">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5">
                            <div className="mt-4">
                                <h2>Pending Orders</h2>
                            </div>
                            {fetchStocksPortfolioDataTrade?.map((tradeData, index) => (
                                <PendingOrderCard
                                    key={index}
                                    logoUrl={tradeData?.logoUrl}
                                    symbol={tradeData?.symbol}
                                    amount={tradeData?.amount}
                                    orderType={tradeData?.orderType}
                                    updatedTimestamp={tradeData.updatedTimestamp}
                                    status={tradeData?.status}
                                    onClickCancel={() => {
                                        handleCancelPendingModal(tradeData.id);
                                    }}
                                />
                            ))}
                        </div>
                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="scroll-sideNotification side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>

            <CancerOrder
                isOpened={modalCancelOrder}
                onModelClose={setModalCancelOrder}
                onYes={handleCancelPendingOrder}
                onNO={() => {
                    setModalCancelOrder(false);
                }}
            />

            <CommonModal
                isOpen={orderCancel}
                onModelClose={() => setOrderCancel(false)}
                text={'Order Cancel Successful'}
                onYes={() => setOrderCancel(false)}
                btn1Text={'Ok'}
            />
        </div>
    );
};

export default PendingOrder;
