import api from '../util/instance';
import { urls } from './Api';

export const userBrokerages = async () => await api.get(`${urls.userBrokerages}`);

export const postBrokerageAuthDetails = async (payload) => {
    try {
        const resp = await api.post(`/api/front/brokerage/details`, payload);

        return resp.data;
    } catch (error) {
        console.error(error);
        console.log(error);
        return [];
    }
};
