import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';

const MarketOrderModal = ({
    selectMarketOrderModal,
    customStyles11,
    setModalBuyIsOpen,
    setSelectMarketOrderModal,
    fetchBrokerageByIdDetails,
    setModalSetTimeMarketOrder,
    setSelectOrder,
    selectOrder,
    setModalTradeOptionMarket,
}) => {
    const secModalClose = () => {
        setSelectMarketOrderModal(false);
    };

    const handleOpenSelectOrderModal = () => {
        setSelectMarketOrderModal(false);

        if (selectOrder === 'market' || selectOrder === 'stoporder' || selectOrder === 'limitorder') {
            setModalSetTimeMarketOrder(true);
        }
    };
    return (
        <div>
            {' '}
            <Modal
                isOpen={selectMarketOrderModal}
                onRequestClose={secModalClose}
                style={customStyles}
                contentLabel="Example Modal">
                <div
                    style={{
                        width: '100%',
                        borderRadius: '25px 25px 0px 0px',
                        backgroundColor: '#FFF',
                    }}
                    className="px-4">
                    <div className="d-flex justify-content-between" style={{ marginTop: '2rem' }}>
                        <img
                            src="/assets/stockdetails/arrow_left_black.png"
                            style={{ width: '24px', height: '24px', textAlign: 'left' }}
                            alt="img"
                            onClick={() => {
                                if (setModalBuyIsOpen) {
                                    setSelectMarketOrderModal(false);
                                    setModalBuyIsOpen(true);
                                }
                                if (setModalTradeOptionMarket) {
                                    setSelectMarketOrderModal(false);
                                    setModalTradeOptionMarket(true);
                                }
                            }}
                        />
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>
                            Select Conditions
                        </p>
                        <p
                            style={{
                                color: '#FF5445',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                marginLeft: '50px',
                            }}
                            className="mt-1"
                            onClick={() => setSelectMarketOrderModal(false)}>
                            Cancel
                        </p>
                    </div>

                    <hr style={{ opacity: 0.10000000149011612 }} />
                    <div className="d-flex justify-content-between " style={{ width: '400px' }}>
                        <div
                            style={{
                                opacity: !fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType?.supported
                                    ? '0.7'
                                    : '',
                            }}>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                Market Order
                            </p>

                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    opacity: 0.5,
                                }}>
                                Order will be placed at market price
                            </p>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="market-order"
                                name="order-type"
                                value="market"
                                onChange={(e) => setSelectOrder(e.target.value)}
                                disabled={!fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType?.supported}
                                style={{
                                    zoom: '1.5',
                                }}
                                checked
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ width: '400px' }}>
                        <div
                            style={{
                                opacity: !fetchBrokerageByIdDetails?.cryptocurrencyOrders?.stopLossType?.supported
                                    ? '0.7'
                                    : '',
                            }}>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                Stop Order
                            </p>

                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    opacity: 0.5,
                                }}>
                                If MSFT rises to a fixed stop price order will be placed at market price
                            </p>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="stop-order"
                                name="order-type"
                                value="stoporder"
                                onChange={(e) => setSelectOrder(e.target.value)}
                                disabled={!fetchBrokerageByIdDetails?.cryptocurrencyOrders?.stopLossType?.supported}
                                style={{
                                    zoom: '1.5',
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between " style={{ width: '400px' }}>
                        <div
                            style={{
                                opacity: !fetchBrokerageByIdDetails?.cryptocurrencyOrders?.limitType?.supported
                                    ? '0.7'
                                    : '',
                            }}>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}>
                                Limit Order
                            </p>

                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    opacity: 0.5,
                                }}>
                                If MSFT rises to a fixed stop price order will be placed at market price
                            </p>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="limit-order"
                                name="order-type"
                                value="limitorder"
                                onChange={(e) => setSelectOrder(e.target.value)}
                                disabled={!fetchBrokerageByIdDetails?.cryptocurrencyOrders?.limitType?.supported}
                                style={{
                                    zoom: '1.5',
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-3">
                        <button
                            style={{
                                width: '80%',
                                height: '52px',
                                borderRadius: '54px',
                                backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                letterSpacing: '0.7px',
                                marginBottom: '2rem',
                            }}
                            onClick={handleOpenSelectOrderModal}>
                            CONTINUE
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MarketOrderModal;
