import axios from 'axios';
import { urls } from 'Api/Api';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

let isRefreshing = false;
let refreshSubscribers = [];

api.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('userData'));

    if (token) {
        config.headers['Content-Type'] = 'application/json';
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

api.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                try {
                    await waitForRefresh();
                    return api(originalRequest);
                } catch (refreshError) {
                    return Promise.reject(refreshError);
                }
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refresh = JSON.parse(localStorage.getItem('refresh'));
            const username = localStorage.getItem('username');

            try {
                const response = await api.post(urls.refreshToken, {
                    username: username,
                    refresh,
                });

                originalRequest.headers.Authorization = `Bearer ${response.id}`;
                localStorage.setItem('refresh', JSON.stringify(response.refresh));
                localStorage.setItem('userData', JSON.stringify(response.id));
                localStorage.setItem('userAccessToken', JSON.stringify(response.access));

                window.location.reload();
                return api(originalRequest);
            } catch (refreshError) {
                // window.location.href('/');
                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
                refreshSubscribers.forEach((callback) => callback());
                refreshSubscribers = [];
            }
        }

        return Promise.reject(error);
    }
);
function waitForRefresh() {
    return new Promise((resolve, reject) => {
        refreshSubscribers.push(() => {
            resolve();
        });
    });
}

export default api;
