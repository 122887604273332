import React from 'react';
import Modal from 'react-modal';
import * as CONSTANT from './constant';
import { customStyles } from './style';
import './style.scss';

const PaymentSuccess = ({ isOpened = false, onModelClose, setCongratulationOpen }) => {
    const handleModel = () => {
        setCongratulationOpen(false);
    };

    return (
        <Modal isOpen={isOpened} onRequestClose={onModelClose} style={customStyles} contentLabel="payment success">
            <div className="payment-success">
                <span className="back-icon">
                    <i class="fas fa-angle-left"></i>
                </span>
                <div className="d-flex justify-content-center mt-5">
                    <p className="success-text">Payment Successful</p>
                </div>

                <div className="d-flex justify-content-center">
                    <p className="congrats-text">Congratulations!</p>
                </div>

                <div className="d-flex justify-content-center">
                    <img src={CONSTANT.INFLUENCER_IMAGE} alt="Influencer Congratulation" className="influencer-image" />
                    <img src="/assets/images/orange_icon.png" alt="orange icon" className="orange-icon" />
                </div>

                <div className="d-flex justify-content-center mt-2">
                    <p className="subscribed-text">You're Subscribed</p>
                </div>

                <div className="d-flex justify-content-center">
                    <p className="user-name">Jane Disoza</p>
                </div>

                <div class=" d-flex justify-content-center px-2  mt-4  ">
                    <span class="join">
                        <button className="btn btn-sm m-2 complete-btn" onClick={handleModel}>
                            DONE
                        </button>
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(PaymentSuccess);
