import React, { useState, useEffect, useMemo, Suspense } from 'react';
import moment from 'moment';
import Mimicking from 'Components/reusable//model/mimicking';
import './sideNotification.scss';
import swal from 'sweetalert';
import LoaderModal from '../../../pages/Login/LoaderModal';
import { getNotification } from 'Api/notification';
import { fractionalTrade, stockDetail } from 'Api/stocksDetails';
import MarketOrderModal from 'Components/reusable/model/MarketOrderModal/MarketOrderModal';
import { BrokeragesById } from 'Api/stocksDetails';
import ModalSetTimeMarketOrder from 'Components/reusable/model/ModalSetTimeMarketOrder/ModalSetTimeMarketOrder';
import LimitOrderModal from 'Components/reusable/model/LimitOrderModal/LimitOrderModal';
import { brokerageDetails } from 'Api/setting';
import LimitOrderConfirmation from 'Components/reusable/model/LimitOrderConfirmation/LimitOrderConfirmation';
import MimicLimitOrderFinalModal from 'Components/reusable/model/MimicLimitOrderFinalModal/MimicLimitOrderFinalModal';
import MimicFinalQueued from 'Components/reusable/model/MimicFinalQueued/MimicFinalQueued';
import ComponentLoading from 'Components/reusable/loading/Loading';
import CommonModal from 'Components/reusable/settings/commonModal';
import { getBrokerageId } from 'pages/RecentTrades/selector';
import { useSelector } from 'react-redux';

const SideNotificationComponent = () => {
    const [modalTradeOptionMarket, setModalTradeOptionMarket] = useState(false);
    const [selectUnit, setSelectUnit] = useState('');
    const [shareData, setShareData] = useState(0);
    const [fetchNotification, setFetchNotification] = useState([]);
    const [modalTradeOptionConformation, setModalTradeConformation] = useState(false);
    const [modalTradeOptionSuccess, setModalTradeSuccess] = useState(false);
    const [modalTradeOptionQueued, setModalTradeueued] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [getAmt, setGetAmt] = useState('');
    const [getSymbol, setGetSymbol] = useState('');
    const [getPrice, setGetPrice] = useState('');
    const [getStocksDetails, setGetStocksDetails] = useState('');
    const [getName, setGetName] = useState('');
    const [getImg, setGetImg] = useState('');
    const [selectMarketOrderModal, setSelectMarketOrderModal] = useState(false);
    const [modalBuyIsOpen, setModalBuyIsOpen] = useState(false);
    const [modalSetTimeMarketOrder, setModalSetTimeMarketOrder] = useState(false);
    const [selectOrder, setSelectOrder] = useState('');
    const [fetchBrokerageById, setFetchBrokerageById] = useState([]);
    const [fetchBrokerageByIdDetails, setFetchBrokerageByIdDetails] = useState([]);
    const [getBrokerageDropdownData, setGetBrokerageDropdownData] = useState('Robinhood_0001');
    const [modalBuyIsOpenLimitOrder, setModalBuyIsOpenLimitOrder] = useState(false);
    const [fetchStocksData, setFetchStocksData] = useState([]);
    const [limitOrderDisable, setLimitOrderDisable] = useState(true);
    const [LimitOrderInput, setLimitOrderInput] = useState('');
    const [brokerDropdown, setBrokerDropdown] = useState([]);
    const [modalReviewIsOpenLimitOrder, setModalReviewIsOpenLimitOrder] = useState('');
    const [fetchStocksData52Weeks, setFetchStocksData52Weeks] = useState([]);
    const [getCurrentValue, setGetCurrentValue] = useState({});
    const [modalFinalIsOpenLimitOrder, setModalFinalIsOpenLimitOrder] = useState(false);
    const [modalQueuedIsOpenLimitOrder, setModalQueuedIsOpenLimitOrder] = useState(false);
    const [modalMsg, setModalMsg] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenErr, setModalOpenErr] = useState(false);
    const brokerageId = useSelector(getBrokerageId);

    const customStyles4 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '385px',
            height: '351px',
            borderRadius: '25px',
            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
            padding: '0px',
        },
    };

    const customStyles13 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '490px',
            height: '505px',
            borderRadius: '25px',
            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
            padding: '0px',
            position: 'absolute',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
    };

    const customStyles5 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '385px',
            height: '475px',
            borderRadius: '25px',
            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
            padding: '0px',
        },
    };

    const customStyles11 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '460px',
            height: '431px',
            borderRadius: '25px',
            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
            padding: '0px',
            position: 'absolute',
        },
        overlay: {
            backgroundColor: 'rgba(31, 31, 31, 0.8)',
        },
    };

    const customStyles12 = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '460px',
            height: '328px',
            borderRadius: '25px',
            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
            padding: '0px',
            position: 'absolute',
        },
        overlay: {
            backgroundColor: 'rgba(31, 31, 31, 0.8)',
        },
    };

    const currBid = fetchStocksData?.lastQuote?.results?.P;

    const onchangeUnits = (e) => {
        setSelectUnit(e.target.value);
    };

    const Brokeragecash = fetchBrokerageById[0]?.cash;

    const getStockDetail = () => {
        stockDetail(getSymbol)
            .then((response) => {
                setFetchStocksData(response);
                setFetchStocksData52Weeks(response);

                setGetCurrentValue({
                    closed: response?.snapshot?.ticker?.day?.c,
                    perc: response?.snapshot?.ticker?.todaysChangePerc,
                });
                setLimitOrderInput(response?.snapshot?.ticker?.day?.c);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    useEffect(() => {
        getStockDetail();
    }, []);

    useEffect(() => {
        getNotification()
            .then((response) => {
                const notificationData = response?.reduce(
                    (crr, next) => {
                        const yourDate = moment.unix(next.createdTimestamp);
                        const currentDate = moment();
                        const daysDifference = currentDate.diff(yourDate, 'days');

                        if (daysDifference === 0) {
                            crr[0] = {
                                ...crr[0],
                                data: crr[0]?.data?.concat([next]),
                            };
                            return crr;
                        } else if (daysDifference === 1) {
                            crr[1] = {
                                ...crr[1],
                                data: crr[1]?.data?.concat([next]),
                            };
                            return crr;
                        } else {
                            crr[2] = {
                                ...crr[2],
                                data: crr[2]?.data?.concat([next]),
                            };
                            return crr;
                        }
                    },
                    [
                        {
                            title: 'today',
                            data: [],
                        },
                        {
                            title: 'Yesterday',
                            data: [],
                        },
                        {
                            title: 'Older',
                            data: [],
                        },
                    ]
                );
                setFetchNotification(notificationData);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    }, []);

    useEffect(() => {
        (async () => {
            const response = await brokerageDetails();
            setBrokerDropdown(response);
        })();
    }, [getBrokerageDropdownData]);

    const secModalCloseLimitMarketModal = () => {
        setModalBuyIsOpenLimitOrder(false);
    };

    const handleBrokerageDropdown = (e) => {
        localStorage.setItem('getBrokerageId', getBrokerageDropdownData);

        setGetBrokerageDropdownData(e.target.value);
    };

    const handleMiicModal = (amount, symbol, name, img, price, stockdetail) => {
        console.log(amount, symbol, name, img, price, 'function');
        console.log(price, 'price');
        setGetAmt(amount);
        setGetName(name);
        setGetSymbol(symbol);
        setGetImg(img);
        setModalTradeOptionMarket(true);
        setGetStocksDetails(stockdetail?.snapshot?.ticker?.lastQuote?.P);
        setGetPrice(price);
        setShareData(price);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '490px',
            height: '713px',
            borderRadius: '25px',
            backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
            padding: '0px',
        },
        overlay: {
            backgroundColor: 'rgba(31, 31, 31, 0.8)',
        },
    };

    const getBrokerageById = () => {
        const brokerageId = localStorage.getItem('getBrokerageDropdownData');
        console.log(brokerageId, 'brokerageId');
        BrokeragesById(brokerageId ? brokerageId : 'Robinhood_0001')
            .then((response) => {
                setFetchBrokerageById(response.balance);
                setFetchBrokerageByIdDetails(response.supportedOrderFeatures);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    const brokerageName = brokerageId;

    useEffect(() => {
        getBrokerageById();
        // eslint-disable-next-line
    }, [getBrokerageDropdownData]);

    const secModalClose = () => {
        setSelectMarketOrderModal(false);
    };

    const estimateAmount = localStorage.getItem('estimateAmount');

    const isShowNotification = fetchNotification.every((notification) => !Boolean(notification?.data?.length));

    const handleBuyCostLimitOrder = (e) => {
        setIsLoading(true);
        e.preventDefault();
        setModalQueuedIsOpenLimitOrder(false);

        const data = JSON.stringify({
            symbol: fetchStocksData.details.ticker,
            amount: 1,
            extendedHours: false,
            orderType: 'limit',
            timeInForce: 'goodTillCanceled',
            brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
            side: 'buy',
            fractional: false,
            price: Number(LimitOrderInput),
        });

        fractionalTrade(data)
            .then((response) => {
                setModalMsg('Your order was placed and will be executed after market opening.');
                setModalOpen(true);
            })
            .catch((error) => {
                setModalMsg('Sorry ! Your order failed.');
                setModalOpenErr(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return (
        <div>
            {fetchNotification?.map((notification, index) => (
                <div key={index}>
                    {Boolean(notification?.data?.length) && (
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '125%',
                                paddingLeft: '16px',
                                top: '7px',
                            }}>
                            {notification?.title}
                        </p>
                    )}

                    {notification?.data?.map((ele, indexValue) => {
                        const timestamp = ele.createdTimestamp;
                        const utcDate = new Date(timestamp * 1000);
                        // setGetSymbol(ele.symbol);
                        return (
                            <div
                                key={indexValue}
                                style={{
                                    background: '',
                                    borderRadius: '10px',
                                    width: '350px',
                                    paddingTop: '10px',
                                    // paddingLeft: '10px',
                                    paddingRight: '5px',
                                }}>
                                <div className="d-flex">
                                    <div className="m-1">
                                        <img
                                            src={ele.influencerDetails.profilePicture}
                                            alt="Profile"
                                            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                        />
                                        <img
                                            src="/assets/images/blue_icon.png"
                                            alt="blue icon"
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                postion: 'absolute',
                                                right: '12px',
                                                top: '15px',
                                            }}
                                        />
                                    </div>
                                    <div className="m-1">
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontSize: '15px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '20px',
                                            }}>
                                            @{ele.influencerDetails.firstName} made trade of
                                            <div className="d-flex">
                                                <p
                                                    style={{
                                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #64A2FF)',
                                                        fontSize: '15px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '600',
                                                        lineHeight: '20px',
                                                    }}>
                                                    {ele.symbol}
                                                </p>
                                                <img
                                                    src="/assets/dashboardImg/uparrow.png"
                                                    alt="up arrow"
                                                    style={{
                                                        width: '8px',
                                                        height: '8px',
                                                        marginLeft: '5px',
                                                        marginTop: '5px',
                                                    }}
                                                />
                                                <p
                                                    style={{
                                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #71C901)',
                                                        fontSize: '14px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '600',
                                                        marginLeft: '5px',
                                                        letterSpacing: '0.7px',
                                                    }}>
                                                    {ele.amount}
                                                </p>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                                <div className=" mb-4" style={{ bottom: '20px', textAlign: 'end' }}>
                                    <p
                                        className="mb-0"
                                        style={{
                                            color: '#8F8F8F',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                            marginLeft: '44px',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            left: '2.5rem',
                                        }}>
                                        {moment(utcDate, 'YYYYMMDD').fromNow()}
                                    </p>

                                    <button
                                        style={{
                                            width: '100px',
                                            color: '#FFFFFF',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            padding: '8px 14px',
                                            borderRadius: '40px',
                                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                            bottom: '5px',
                                        }}
                                        onClick={() =>
                                            handleMiicModal(
                                                ele.amount,
                                                ele.symbol,
                                                ele.name,
                                                ele?.influencerDetails?.profilePicture,
                                                ele?.price,
                                                ele?.stockDetails
                                            )
                                        }>
                                        Mimic
                                        <img
                                            src="/assets/dashboardImg/mimic_white.png"
                                            alt="mimic"
                                            style={{ width: '14px', height: '14px', marginLeft: '5px' }}
                                        />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}

            {isShowNotification && <h5 className="p-3">Notifications not available!</h5>}

            <Mimicking
                modalTradeOptionMarket={modalTradeOptionMarket}
                onModelClose={setModalTradeOptionMarket}
                setModalTradeConformation={setModalTradeConformation}
                setSelectUnit={setSelectUnit}
                selectUnit={selectUnit}
                shareData={shareData}
                setShareData={setShareData}
                fetchNotification={fetchNotification}
                getAmt={getAmt}
                getName={getName}
                getSymbol={getSymbol}
                getPrice={getPrice}
                getStocksDetails={getStocksDetails}
                getImg={getImg}
                modalTradeOptionConformation={modalTradeOptionConformation}
                customStyles13={customStyles13}
                setModalTradeSuccess={setModalTradeSuccess}
                modalTradeOptionSuccess={modalTradeOptionSuccess}
                customStyles4={customStyles4}
                setModalTradeueued={setModalTradeueued}
                modalTradeOptionQueued={modalTradeOptionQueued}
                customStyles5={customStyles5}
                setIsLoading={setIsLoading}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                getBrokerageDropdownData={getBrokerageDropdownData}
                setGetBrokerageDropdownData={setGetBrokerageDropdownData}
                fetchStocksData={fetchStocksData}
                setFetchStocksData={setFetchStocksData}
                isLoading={isLoading}
            />

            {/* Market order Modal */}

            {/* Select Condition */}

            <MarketOrderModal
                selectMarketOrderModal={selectMarketOrderModal}
                secModalClose={secModalClose}
                customStyles11={customStyles11}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                fetchBrokerageByIdDetails={fetchBrokerageByIdDetails}
                setModalSetTimeMarketOrder={setModalSetTimeMarketOrder}
                setSelectOrder={setSelectOrder}
                selectOrder={selectOrder}
                setModalTradeOptionMarket={setModalTradeOptionMarket}
            />

            <ModalSetTimeMarketOrder
                modalSetTimeMarketOrder={modalSetTimeMarketOrder}
                setModalSetTimeMarketOrder={setModalSetTimeMarketOrder}
                customStyles12={customStyles12}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                setModalBuyIsOpenLimitOrder={setModalBuyIsOpenLimitOrder}
                selectOrder={selectOrder}
                fetchBrokerageByIdDetails={fetchBrokerageByIdDetails}
            />

            <LimitOrderModal
                modalBuyIsOpenLimitOrder={modalBuyIsOpenLimitOrder}
                secModalCloseLimitMarketModal={secModalCloseLimitMarketModal}
                customStyles={customStyles}
                setSelectMarketOrderModal={setSelectMarketOrderModal}
                currBid={currBid}
                onchangeUnits={onchangeUnits}
                selectUnit={selectUnit}
                limitOrderDisable={limitOrderDisable}
                setLimitOrderDisable={setLimitOrderDisable}
                LimitOrderInput={LimitOrderInput}
                setLimitOrderInput={setLimitOrderInput}
                handleBrokerageDropdown={handleBrokerageDropdown}
                brokerDropdown={brokerDropdown}
                Brokeragecash={Brokeragecash}
                setModalBuyIsOpenLimitOrder={setModalBuyIsOpenLimitOrder}
                setModalReviewIsOpenLimitOrder={setModalReviewIsOpenLimitOrder}
            />

            <LimitOrderConfirmation
                modalReviewIsOpenLimitOrder={modalReviewIsOpenLimitOrder}
                setModalReviewIsOpenLimitOrder={setModalReviewIsOpenLimitOrder}
                secModalCloseLimitMarketModal={secModalCloseLimitMarketModal}
                fetchStocksData={fetchStocksData}
                brokerageName={brokerageName}
                LimitOrderInput={LimitOrderInput}
                setLimitOrderInput={setLimitOrderInput}
                limitOrderDisable={limitOrderDisable}
                estimateAmount={estimateAmount}
                setModalFinalIsOpenLimitOrder={setModalFinalIsOpenLimitOrder}
                customStyles13={customStyles13}
            />

            <MimicLimitOrderFinalModal
                modalFinalIsOpenLimitOrder={modalFinalIsOpenLimitOrder}
                setModalFinalIsOpenLimitOrder={setModalFinalIsOpenLimitOrder}
                setModalReviewIsOpenLimitOrder={setModalReviewIsOpenLimitOrder}
                fetchStocksData={fetchStocksData}
                LimitOrderInput={LimitOrderInput}
                estimateAmount={estimateAmount}
                setModalQueuedIsOpenLimitOrder={setModalQueuedIsOpenLimitOrder}
                customStyles4={customStyles4}
            />

            <MimicFinalQueued
                modalQueuedIsOpenLimitOrder={modalQueuedIsOpenLimitOrder}
                setModalQueuedIsOpenLimitOrder={setModalQueuedIsOpenLimitOrder}
                customStyles5={customStyles5}
                fetchStocksData={fetchStocksData}
                LimitOrderInput={LimitOrderInput}
                handleBuyCostLimitOrder={handleBuyCostLimitOrder}
            />

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalOpen}
                    onModelClose={() => setModalOpen(false)}
                    text={modalMsg}
                    onYes={() => setModalOpen(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>
            {isLoading && <LoaderModal />}
        </div>
    );
};

export default SideNotificationComponent;
