import React from 'react';
import './style.scss';

const TotalChange = ({ equitiesValue, actualPortfolioPerformance }) => {
    return (
        <div className="d-flex justify-content-between w-100 end-user-card-change">
            <div className="total-change-card">
                <div className="d-flex justify-content-evenly">
                    <div className="pt-2">
                        <p className="portfolio-equitiesValue">
                            {equitiesValue}
                            <p className="portfolio-equities-label">Portfolio</p>
                        </p>
                    </div>
                    <div className="separator-line">
                        <img src="/assets/Navbar/Middle_line.png" alt="line" />
                    </div>

                    <div className="pt-2">
                        <p className="portfolio-equitiesValue">
                            {actualPortfolioPerformance}
                            <p className="portfolio-equities-label">Total Change</p>
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center upload-icon-top-trades">
                <img src="/assets/dashboardImg/UploadIcon.png" alt="icon" />
            </div>
        </div>
    );
};

export default TotalChange;
