import { Tooltip } from 'react-bootstrap';

export default (
    <Tooltip id="tooltip" className="tooltip-buying-power">
        Your buying power is low. Please go to your
        <br /> linked brokerage app and deposit more funds to
        <br />
        continue trading on Parrot
    </Tooltip>
);
