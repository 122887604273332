import React, { useState, useEffect } from 'react';

function ToDoItem({ linkObj, handlePlatformChange, setSocialLinks, socialLinks }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [InstragramImg, setInstragramImg] = useState(false);
    const [FaceBookImg, setFaceBookImg] = useState(false);
    const [TwitterImg, setTwitterImg] = useState(false);
    const [EscortImg, setEscortImg] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleRemoveLink = (id) => {
        const updatedLinks = socialLinks.filter((link) => link.id !== id);
        setSocialLinks(updatedLinks);
    };

    useEffect(() => {
        switch (linkObj?.platform) {
            case 'discord':
                setEscortImg(true);
                setFaceBookImg(false);
                setTwitterImg(false);
                setInstragramImg(false);
                setIsDropdownOpen(false);
                break;
            case 'instagram':
                setEscortImg(false);
                setFaceBookImg(false);
                setTwitterImg(false);
                setInstragramImg(true);
                setIsDropdownOpen(false);
                break;
            case 'facebook':
                setEscortImg(false);
                setFaceBookImg(true);
                setTwitterImg(false);
                setInstragramImg(false);
                setIsDropdownOpen(false);
                break;
            case 'twitter':
                setEscortImg(false);
                setFaceBookImg(false);
                setTwitterImg(true);
                setInstragramImg(false);
                setIsDropdownOpen(false);
                break;
            default:
                setEscortImg(true);
                setFaceBookImg(false);
                setTwitterImg(false);
                setInstragramImg(false);
                setIsDropdownOpen(false);
        }
    }, [linkObj?.platform]);

    return (
        <div className="mt-2">
            <div className="dropdown">
                <button
                    className={`btn dropdown-toggle${isDropdownOpen ? ' show' : ''}`}
                    type="button"
                    onClick={handleDropdownToggle}>
                    <img
                        src={
                            InstragramImg
                                ? '/assets/settings/instragram.jpeg'
                                : FaceBookImg
                                ? '/assets/settings/facebook.png'
                                : TwitterImg
                                ? '/assets/images2/twitter_icon.png'
                                : EscortImg
                                ? '/assets/images2/purpleSmile2.png'
                                : '/assets/images2/purpleSmile2.png'
                        }
                        style={{ width: '20px', height: '20px' }}
                        className="mt-1"
                        alt=""
                    />
                </button>
                <div className={`dropdown-menu dropdown-menu-right${isDropdownOpen ? ' show' : ''}`}>
                    <div className="d-flex">
                        <button
                            className={`dropdown-item${linkObj.platform === 'instagram' ? ' active' : ''}`}
                            onClick={() => handlePlatformChange(linkObj.id, 'instagram')}>
                            <img
                                src="/assets/settings/instragram.jpeg"
                                style={{ width: '100%' }}
                                alt="instagram"
                                onClick={() => {
                                    setEscortImg(false);
                                    setFaceBookImg(false);
                                    setTwitterImg(false);
                                    setInstragramImg(true);
                                    setIsDropdownOpen(false);
                                }}
                            />
                        </button>
                        <button
                            className={`dropdown-item${linkObj.platform === 'facebook' ? ' active' : ''}`}
                            onClick={() => handlePlatformChange(linkObj.id, 'facebook')}>
                            <img
                                src="/assets/settings/facebook.png"
                                style={{ width: '100%' }}
                                alt=""
                                onClick={() => {
                                    setEscortImg(false);
                                    setTwitterImg(false);
                                    setInstragramImg(false);
                                    setFaceBookImg(true);
                                    setIsDropdownOpen(false);
                                }}
                            />
                        </button>

                        <button
                            className={`dropdown-item${linkObj.platform === 'twitter' ? ' active' : ''}`}
                            onClick={() => handlePlatformChange(linkObj.id, 'twitter')}>
                            <img
                                src="/assets/images2/twitter_icon.png"
                                style={{ width: '100%' }}
                                alt=""
                                onClick={() => {
                                    setEscortImg(false);
                                    setInstragramImg(false);
                                    setFaceBookImg(false);
                                    setTwitterImg(true);
                                    setIsDropdownOpen(false);
                                }}
                            />
                        </button>

                        <button
                            className={`dropdown-item${linkObj.platform === 'discord' ? ' active' : ''}`}
                            onClick={() => handlePlatformChange(linkObj.id, 'discord')}>
                            <img
                                src="/assets/images2/purpleSmile2.png"
                                style={{ width: '100%' }}
                                alt=""
                                onClick={() => {
                                    setInstragramImg(false);
                                    setFaceBookImg(false);
                                    setTwitterImg(false);
                                    setEscortImg(true);
                                    setIsDropdownOpen(false);
                                }}
                            />
                        </button>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <input
                    style={{
                        border: 'none',
                        borderBottom: '1px solid #8F8F8F',
                    }}
                    className="mt-2"
                    placeholder="Enter social link"
                    value={linkObj.link}
                    onChange={(e) => {
                        const updatedLinks = socialLinks.map((link) => {
                            if (link.id === linkObj.id) {
                                return { ...link, link: e.target.value };
                            }
                            return link;
                        });
                        setSocialLinks(updatedLinks);
                    }}
                />

                <p
                    style={{
                        color: '#FF5445',
                        fontSize: '14px',
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        right: '10px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleRemoveLink(linkObj.id)}>
                    Remove
                </p>
            </div>
        </div>
    );
}

export default ToDoItem;
