import React from 'react';
import './style.scss';

const DEFAULT_LOGO_URL = '/assets/dashboardImg/logoCrop512 2.png';
const ordinalSuffixes = ['st', 'nd', 'rd', 'th', 'th'];

const PerformersOfTheWeek = ({
    rank,
    userName,
    profilePicture,
    latestEquitiesValue,
    profitLoss,
    profitLossPercentage,
}) => {
    const getProfitLossPercentageDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        return isPositive ? `+${value?.toFixed(2)}%` : `${value?.toFixed(2)}%`;
    };

    return (
        <div className="performer-item">
            <div className="d-flex justify-content-between performer-container">
                <div className="d-flex justify-content-between performer-info">
                    <p className="performer-rank">
                        {rank}
                        <sup>{ordinalSuffixes[rank - 1]}</sup>
                    </p>

                    <div className="separator-performance-of-week"></div>

                    <div className="d-flex performer-user">
                        <img src={profilePicture ?? DEFAULT_LOGO_URL} alt="performer" className="performer-avatar" />
                        <img src="/assets/images/orange_icon.png" alt="orange icon" className="orange-icon" />
                        <p className="performer-username">@{userName}</p>
                    </div>
                </div>
                <div className="performer-stats">
                    <p className="equities-value">{getProfitLossPercentageDisplay(profitLossPercentage)}</p>

                    <p className="profit-label">Weekly</p>
                </div>
            </div>
        </div>
    );
};

export const PerformersList = ({ data }) => {
    return (
        <div className="performers-list">
            {data?.slice(0, 5).map((item, index) => (
                <PerformersOfTheWeek
                    key={index}
                    rank={index + 1}
                    userName={item.userName}
                    profilePicture={item.profilePicture}
                    latestEquitiesValue={item.latestEquitiesValue}
                    profitLoss={item.profitLoss}
                    profitLossPercentage={item.profitLossPercentage}
                />
            ))}
        </div>
    );
};

export default PerformersOfTheWeek;
