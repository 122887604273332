import React from 'react';
import './style.scss';
import { useMemo } from 'react';

const AccountPrivacyCard = ({
    text,
    isBlockedUser,
    isToggleShow,
    onTextClick,
    hideStockStatus,
    handleHideStocks,
    isRedText,
}) => {
    const constantStyle = useMemo(() => {
        if (Boolean(isRedText)) return { color: 'red' };
        else return {};
    }, []);

    return (
        <div className="d-flex justify-content-between mt-5">
            <p className="accountPrivacyText" onClick={onTextClick} style={constantStyle}>
                {text}
            </p>
            {isBlockedUser && <i className="fas fa-angle-right mt-1 right-arrow-fa"></i>}

            {Boolean(isToggleShow) &&
                (hideStockStatus === false ? (
                    <>
                        <img
                            src="/assets/settings/slide_checkbox_on.png"
                            className="hide-stocks"
                            alt="img"
                            onClick={handleHideStocks}
                        />
                    </>
                ) : (
                    <>
                        <img
                            src="/assets/settings/slide_checkbox.png"
                            className="hide-stocks"
                            alt="img"
                            onClick={handleHideStocks}
                        />
                    </>
                ))}
        </div>
    );
};

export default AccountPrivacyCard;
