import React from 'react';
import './style.scss';
import { createStripePortalSession } from 'Api/influencerDetails';

const PaymentCard = ({ handleCard }) => {
    const handleManageSubscriptions = async () => {
        const response = await createStripePortalSession();

        console.log('portal session resp', response);

        window.location.href = response.url;
    };
    return (
        <div className="d-flex justify-content-between mt-4 left-margin">
            <div className="d-flex">
                <button
                    onClick={() => handleManageSubscriptions()}
                    style={{
                        width: '300px',
                        color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                        fontSize: '16px',
                        fontFamily: 'Figtree',
                        fontWeight: '500',
                        padding: '8px 14px',
                        borderRadius: '40px',
                        backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                        borderWidth: 0,
                        border: 'none',
                    }}>
                    Manage Subscriptions
                </button>
                <img src="/assets/settings/credit-card.png" className="card-icon" alt="card-icon" />
                <p className="mt-2 card-detail common-color">Card Details</p>
            </div>
            <i class="fa-solid fa-angle-right right-arrow-fa" />
        </div>
    );
};

export default PaymentCard;
