import React, { useState, useEffect, Suspense } from 'react';
import { getSubscription } from 'Api/UserSubscription';
import { fetchUserFollowingApi, unsubscribeUser } from 'Api/setting';
import { createStripePortalSession, createSubscriptionCheckoutSession, userFollow } from 'Api/influencerDetails';
import ComponentLoading from 'Components/reusable/loading/Loading.js';
import UnFollowInfo from 'Components/reusable/model/unFollowInfo';
import SureModel from '../sureModel';
import CommonModal from '../commonModal';
import Card from './card';
import './style.scss';

const SubscriptionFollowing = ({ setLoading }) => {
    const [subscriptionChangeDefault, setSubscriptionChangeDefault] = useState(true);
    const [FollowingChangeDefault, setFollowingChangeDefault] = useState(false);
    const [fetchSubscriber, setFetchSubscriber] = useState([]);
    const [isOpenUnsubscribeModel, setIsOpenUnsubscribeModel] = useState(false);
    const [unsubscribeModal, setUnsubscribeModal] = useState(false);
    const [UnSubscriptionModal, setUnSubscriptionModal] = useState(false);
    const [unsubscribeModalErr, setUnsubscribeModalErr] = useState(false);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [isOpenFollowModel, setIsOpenFollowModel] = useState(false);
    const [followInfo, setFollowInfo] = useState({});
    const [unfollowModal, setUnfollowModal] = useState(false);
    const [unfollowModalErr, setUnfollowModalErr] = useState(false);
    const [UnFollowingModal, setUnFollowingModal] = useState(false);
    const [subscribeInfo, setSubscribeInfo] = useState({});

    const handleManageSubscriptions = async () => {
        const response = await createStripePortalSession();

        console.log('portal session resp', response);

        window.location.href = response.url;
    };

    const secModalClose = () => {
        setIsOpenUnsubscribeModel(false);
        setUnsubscribeModal(false);
        setUnsubscribeModalErr(false);
        setIsOpenFollowModel(false);
        setUnfollowModal(false);
        setUnfollowModalErr(false);
    };

    const getSubscriber = () => {
        getSubscription()
            .then((response) => {
                setFetchSubscriber(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const userFollowing = () => {
        fetchUserFollowingApi()
            .then((response) => {
                setFetchUserFollowing(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleUnSubscribe = () => {
        setLoading(true);
        setIsOpenUnsubscribeModel(false);

        const subscription_id = subscribeInfo?.subscriptionId;
        const subscriptioName = subscribeInfo?.influencerUserName;

        const data = {
            influencerUserName: subscriptioName,
        };
        unsubscribeUser(subscription_id, data)
            .then((response) => {
                setUnsubscribeModal(true);
                setLoading(false);
                getSubscriber();
                setUnSubscriptionModal(false);
            })
            .catch((error) => {
                setUnsubscribeModalErr(true);
                setLoading(false);
            });
    };

    const handleUnFollow = () => {
        setLoading(true);
        setIsOpenFollowModel(false);
        secModalClose();

        const follower_id = followInfo?.followingId ? followInfo?.followingId : followInfo?.userSecondId;

        const data = JSON.stringify({
            follow: false,
        });

        userFollow(follower_id, data)
            .then((response) => {
                setLoading(false);
                setUnfollowModal(true);
                userFollowing();
            })
            .catch((error) => {
                setLoading(false);
                setUnfollowModalErr(true);
            });
        setLoading(false);
        setUnFollowingModal(false);
    };

    const modalOpenOnFollow = (ele) => {
        setUnFollowingModal(true);
        setFollowInfo(ele);
        setSubscribeInfo(ele);
    };

    useEffect(() => {
        userFollowing();
        getSubscriber();
    }, []);

    return (
        <div>
            <div className="p-3 w-100 subscription-following">
                <div className="d-flex justify-content-evenly w-100">
                    <div className="w-100">
                        <p
                            className="header-text"
                            style={{
                                color: subscriptionChangeDefault ? '#64A2FF' : '#8F8F8F',
                            }}
                            onClick={() => {
                                setFollowingChangeDefault(false);
                                setSubscriptionChangeDefault(!subscriptionChangeDefault);
                            }}>
                            Subscriptions
                            <hr
                                className="hr-line"
                                style={{
                                    color: subscriptionChangeDefault ? '#64A2FF' : '#8F8F8F',
                                }}
                            />
                        </p>
                    </div>

                    <div className="w-100">
                        <p
                            className="header-text"
                            style={{
                                color: FollowingChangeDefault ? '#64A2FF' : '#8F8F8F',
                            }}
                            onClick={() => {
                                setSubscriptionChangeDefault(false);
                                setFollowingChangeDefault(!FollowingChangeDefault);
                            }}>
                            Followings
                            <hr
                                className="hr-line"
                                style={{
                                    color: FollowingChangeDefault ? '#64A2FF' : '#8F8F8F',
                                }}
                            />
                        </p>
                    </div>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <p className="total-text">total</p>
                    <img src="/assets/settings/Searchgrey.png" className="search-icon" alt="img" />
                </div>

                <div>
                    <button
                        onClick={() => handleManageSubscriptions()}
                        style={{
                            width: '300px',
                            color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                            fontSize: '16px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            padding: '8px 14px',
                            borderRadius: '40px',
                            backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            borderWidth: 0,
                            border: 'none',
                        }}>
                        Manage Subscriptions
                    </button>
                    {subscriptionChangeDefault &&
                        (Boolean(fetchSubscriber?.length) ? (
                            fetchSubscriber?.map((ele, index) => (
                                <Card
                                    index={index}
                                    image={ele?.influencerImage}
                                    onClick={() => {
                                        setUnSubscriptionModal(true);
                                        setSubscribeInfo(ele);
                                    }}
                                    name={ele.influencerUserName}
                                />
                            ))
                        ) : (
                            <div className="d-flex justify-content-center align-items-center">
                                <p>{fetchSubscriber && fetchSubscriber.message}</p>
                            </div>
                        ))}
                </div>

                <div>
                    {FollowingChangeDefault &&
                        (fetchUserFollowing?.following?.length > 0 ? (
                            fetchUserFollowing.following.map((ele, index) => (
                                <Card
                                    index={index}
                                    image={ele?.influencerImage ? ele?.influencerImage : ele?.userSecondImageUrl}
                                    onClick={() => {
                                        modalOpenOnFollow(ele);
                                    }}
                                    name={ele.followingUserName}
                                />
                            ))
                        ) : fetchUserFollowing?.message ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <p>{fetchUserFollowing.message}</p>
                            </div>
                        ) : (
                            ''
                        ))}
                </div>
            </div>
            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={isOpenUnsubscribeModel}
                    onModelClose={() => {
                        setIsOpenUnsubscribeModel(false);
                    }}
                    text="Are you sure you want to unsubscribe this influencer ?"
                    onYes={handleUnSubscribe}
                    onNo={() => {
                        setIsOpenUnsubscribeModel(false);
                    }}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={unsubscribeModal}
                    onModelClose={() => setUnsubscribeModal(false)}
                    text={'Influencer unsubscribe successful.'}
                    onYes={() => setUnsubscribeModal(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={unsubscribeModalErr}
                    onModelClose={() => setUnsubscribeModalErr(false)}
                    text={'You have not subscribed to this influencer.'}
                    onYes={() => setUnsubscribeModalErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={unfollowModal}
                    onModelClose={() => setUnfollowModal(false)}
                    text={'Influencer unfollowed successfully.'}
                    onYes={() => setUnfollowModal(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={unfollowModalErr}
                    onModelClose={() => setUnfollowModalErr(false)}
                    text={'You have not subscribed to this influencer.'}
                    onYes={() => setUnfollowModalErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={isOpenFollowModel}
                    onModelClose={() => {
                        setIsOpenFollowModel(false);
                    }}
                    text="Are you sure you want to unfollow this influencer ?"
                    onYes={handleUnFollow}
                    onNo={() => {
                        setIsOpenFollowModel(false);
                    }}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <UnFollowInfo
                    isOpened={UnSubscriptionModal}
                    onModelClose={() => setUnSubscriptionModal(false)}
                    image={subscribeInfo?.influencerImage}
                    name={subscribeInfo?.influencerUserName}
                    buttonText={'UNSUBSCRIBE'}
                    buttonClick={() => {
                        setUnSubscriptionModal(false);
                        setIsOpenUnsubscribeModel(true);
                    }}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <UnFollowInfo
                    isOpened={UnFollowingModal}
                    onModelClose={() => setUnFollowingModal(false)}
                    image={followInfo?.influencerImage ? followInfo?.influencerImage : followInfo?.userSecondImageUrl}
                    name={followInfo?.followingUserName}
                    buttonText={'UNFOLLOW'}
                    buttonClick={() => {
                        setUnFollowingModal(false);
                        setIsOpenFollowModel(true);
                    }}
                />
            </Suspense>
        </div>
    );
};

export default SubscriptionFollowing;
