import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import LoaderModal from '../Login/LoaderModal';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from '../../Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { fetchTrendingStocks } from 'Api/stocksDetails';
import { getTopInfluencer, getTopSpotlight } from 'Api/influencerDashboard';
import { getSubscription } from 'Api/UserSubscription';
import { fetchUserFollowingApi } from 'Api/setting';
import { fetchProfile } from 'Api/dashboard';
import ComponentLoading from 'Components/reusable/loading/Loading';
import TopTradesCard from 'Components/reusable/endUserProfile/cards/topTrades';
import { getPortfolioMetrics, getUtilHomeData } from 'Api/util';
import PortfolioBarGraph from 'Components/reusable/BarGraph/PortfolioBarGraph';
import { getAggregatePortfolio, getTopWeeklyPerformers, getUserChartDataForPeriod } from 'Api/front';
import { PerformersList } from 'Components/reusable/normalUser/cards/PerformersOfTheWeek/PerformersOfTheWeek';
import TrendingStocksPreview from 'Components/reusable/normalUser/cards/trendingStockPreview';
import { userLoggedIn } from 'globalStore/actions/login';
import FollowersUserCard from 'Components/reusable/portfolio/cards/followersUserCard';
import { getUserName } from './selector';
import GraphCard from 'Components/reusable/influencer/cards/graphCard';
import * as CONSTANT from './constant';
import TotalChange from 'Components/reusable/endUserProfile/cards/totalChange';
import HeadingWrapper from 'Components/reusable/portfolio/headingWrapper';
import MyFollowingsCard from 'Components/reusable/normalUser/cards/myFollowingsCard';
import Spotlight from 'Components/reusable/influencer/cards/spotlight';
import InfluencerWrapper from 'Components/reusable/influencer/headingWrapper';
import VerifiedTrader from 'Components/reusable/influencer/cards/verifiedTrader';
import { getUserId } from 'pages/analytics/slector';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.scss';

const NormalUserDashboard = () => {
    const [trendingStocks, setTrendingStocks] = useState([]);
    const [ftechInfluencerAll, setFetchInfluencerAll] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeIconHome, setActiveIconHome] = useState(false);
    const [fetchSubscriber, setFetchSubscriber] = useState([]);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [profileImg, setProfileImg] = useState('');
    const [fetchTradeSpotlight, setFetchTradeSpotlight] = useState([]);
    const fetchTradeSpotlightClick = true;
    const navigate = useNavigate();
    const id = useSelector(getUserId);
    const [portfolioMetrics, setPortfolioMetrics] = useState({});
    const [aggregatedPortfolio, setAggregatedPortfolio] = useState({});
    const [topTrades, setTopTrades] = useState([]);
    const [pAndLIsPercentage, setPandLIsPercentage] = useState(true);
    const [topWeeklyPerformers, setTopWeeklyPerformers] = useState([]);
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('totalMetrics');
    const dispatch = useDispatch();
    const userName = useSelector(getUserName);

    useEffect(() => {
        fetchHomeData();
        fetchPortfolioMetrics();
        fetchAggregatedPortfolio();
        fetchTopWeeklyPerformers();
        fetchChartDataByPeriod('day');
        fetchChartDataByPeriod('week');
        fetchChartDataByPeriod('month');
        fetchChartDataByPeriod('year');
    }, []);

    useEffect(() => {
        setActiveIconHome('Home');
        dispatch(
            userLoggedIn({
                active: 'Home',
            })
        );
    }, []);

    const fetchHomeData = async () => {
        await getUtilHomeData();
    };

    const fetchPortfolioMetrics = async () => {
        setIsLoading(true);
        const resp = await getTopWeeklyPerformers();
        setIsLoading(false);

        setTopWeeklyPerformers(resp);
    };

    const fetchTopWeeklyPerformers = async () => {
        setIsLoading(true);
        const resp = await getPortfolioMetrics();
        setIsLoading(false);

        setPortfolioMetrics(resp);
    };

    const fetchAggregatedPortfolio = async () => {
        setIsLoading(true);
        const resp = await getAggregatePortfolio();
        const equityPositions = resp.content.equityPositions;
        const sortedEquities = [...equityPositions].sort((a, b) => b.returnPercentage - a.returnPercentage);
        const topEquities = sortedEquities.slice(0, 3);

        setIsLoading(false);

        setTopTrades(topEquities);

        setAggregatedPortfolio(resp.content);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await fetchTrendingStocks();
            console.log('trendingStocks', trendingStocks);
            setTrendingStocks(response);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const [dayChartData, setDayChartData] = useState({});
    const [weekChartData, setWeekChartData] = useState({});
    const [monthChartData, setMonthChartData] = useState({});
    const [yearChartData, setYearChartData] = useState({});

    const fetchChartDataByPeriod = async (period) => {
        const resp = await getUserChartDataForPeriod(period);

        console.log('chartdata', resp);

        switch (period) {
            case 'day':
                setDayChartData(resp.chartData);
                break;
            case 'week':
                setWeekChartData(resp.chartData);
                break;
            case 'month':
                setMonthChartData(resp.chartData);
                break;
            case 'year':
                setYearChartData(resp.chartData);
                break;
            default:
                break;
        }
    };

    const fetchInfluencer = () => {
        setIsLoading(true);
        getTopInfluencer()
            .then((response) => {
                setFetchInfluencerAll(response);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });
    };

    const fetchUserSubscriber = () => {
        setIsLoading(true);
        getSubscription()
            .then((response) => {
                setIsLoading(false);
                setFetchSubscriber(Boolean(response.length) ? response : []);
                setFetchSubscriber(response);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
            });
    };

    const fetchAllUserFollowing = () => {
        fetchUserFollowingApi().then((response) => {
            setFetchUserFollowing(response);
        });
    };

    const getProfile = () => {
        setIsLoading(true);
        fetchProfile(userId)
            .then((response) => {
                setIsLoading(false);
                setProfileImg(response.user.imageUrl);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error, 'catch error');
            });
    };

    const fetchTopSpotlight = () => {
        setIsLoading(true);
        getTopSpotlight()
            .then((response) => {
                setIsLoading(false);
                setFetchTradeSpotlight(response);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error, 'catch error');
            });
    };

    useEffect(() => {
        fetchData();
        fetchInfluencer();
        fetchUserSubscriber();
        fetchAllUserFollowing();
        getProfile();
        fetchTopSpotlight();
        // eslint-disable-next-line
    }, []);

    const influencer = {
        firstName: 'Home',
    };

    const userId = id;

    const getProfitLossDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        const formatted = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);

        return isPositive ? `+${formatted}` : `${formatted}`;
    };

    const getProfitLossPercentageDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        return isPositive ? `+${value?.toFixed(2)}%` : `${value?.toFixed(2)}%`;
    };

    const getColorBasedOnValue = (value) => (value > 0 ? 'green' : 'red');

    const getInfluencerId = (id) => {
        if (id) {
            dispatch(
                userLoggedIn({
                    influencer_id: id,
                })
            );
        }
    };

    const handleFetchTradeSpotlight = () => {
        navigate(`/dashboard/explore-inflencer`, {
            state: { fetchTradeSpotlight, fetchTradeSpotlightClick },
        });
    };

    return (
        <>
            <div className="user-dashboard">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>

                {isLoading ? (
                    <div className="d-flex justify-content-center align-item-center">
                        {isLoading && <LoaderModal />}
                    </div>
                ) : (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <MediaQuery minWidth={769}>
                                    <div className="col-xl-1 col-lg-2">
                                        <Navbar activeIconHome={activeIconHome} />
                                    </div>
                                </MediaQuery>
                                <div className="col-xl-8 col-lg-9 middle-container">
                                    <FollowersUserCard
                                        imageUrl={profileImg}
                                        username={userName}
                                        followerCount={
                                            fetchUserFollowing &&
                                            fetchUserFollowing.following &&
                                            fetchUserFollowing.following.length
                                        }
                                        followingCount={
                                            fetchUserFollowing &&
                                            fetchUserFollowing.following &&
                                            fetchUserFollowing.following.length
                                        }
                                        profitLoss={
                                            pAndLIsPercentage && portfolioMetrics?.past24hrMetrics
                                                ? portfolioMetrics?.past24hrMetrics?.profitLossPercentage?.toFixed(2)
                                                : portfolioMetrics?.past24hrMetrics?.profitLoss?.toFixed(2)
                                        }
                                        onClickSwitch={() => fetchPortfolioMetrics()}
                                        isShowPercentage
                                        onClickPercentageBtn={() => setPandLIsPercentage(!pAndLIsPercentage)}
                                        pAndLIsPercentage={pAndLIsPercentage ? '%' : '$'}
                                    />

                                    <div style={{ marginTop: '2rem', paddingRight: '5px' }}>
                                        <div className="pt-2 position-trader-container">
                                            <div>
                                                <div className="pb-3 d-flex top-trades">
                                                    <div className="w-100">
                                                        <GraphCard
                                                            selectMonthYearPerformance={selectedTimeFrame}
                                                            handleChange={(e) => setSelectedTimeFrame(e.target.value)}
                                                            profitLoss={getProfitLossDisplay(
                                                                portfolioMetrics[selectedTimeFrame]?.profitLoss
                                                            )}
                                                            profitLossColor={getColorBasedOnValue(
                                                                portfolioMetrics[`${selectedTimeFrame}`]?.profitLoss
                                                            )}
                                                            profitLossPercentage={getProfitLossPercentageDisplay(
                                                                portfolioMetrics[`${selectedTimeFrame}`]
                                                                    ?.profitLossPercentage
                                                            )}
                                                            profitLossPercentageColor={getColorBasedOnValue(
                                                                portfolioMetrics[`${selectedTimeFrame}`]
                                                                    ?.profitLossPercentage
                                                            )}
                                                            isCardNotHighlight
                                                            options={CONSTANT.OPTIONS}
                                                            label="Performance P&L">
                                                            <Suspense fallback={<ComponentLoading />}>
                                                                {(selectedTimeFrame === 'dayMetrics' ||
                                                                    selectedTimeFrame === 'past24hrMetrics') &&
                                                                    dayChartData &&
                                                                    dayChartData.length > 0 && (
                                                                        <PortfolioBarGraph dataJson={dayChartData} />
                                                                    )}
                                                                {selectedTimeFrame === 'weekMetrics' &&
                                                                    weekChartData &&
                                                                    weekChartData.length > 0 && (
                                                                        <PortfolioBarGraph dataJson={weekChartData} />
                                                                    )}
                                                                {selectedTimeFrame === 'monthMetrics' &&
                                                                    monthChartData &&
                                                                    monthChartData.length > 0 && (
                                                                        <PortfolioBarGraph dataJson={monthChartData} />
                                                                    )}
                                                                {(selectedTimeFrame === 'yearMetrics' ||
                                                                    selectedTimeFrame === 'totalMetrics' ||
                                                                    selectedTimeFrame === 'ytdMetrics') &&
                                                                    yearChartData &&
                                                                    yearChartData.length > 0 && (
                                                                        <PortfolioBarGraph dataJson={yearChartData} />
                                                                    )}
                                                            </Suspense>
                                                        </GraphCard>
                                                    </div>

                                                    <div className="w-100 stock-list px-3 pt-4">
                                                        <TotalChange
                                                            equitiesValue={new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                            }).format(aggregatedPortfolio?.equitiesValue)}
                                                            actualPortfolioPerformance={getProfitLossPercentageDisplay(
                                                                aggregatedPortfolio?.actualPortfolioPerformance
                                                            )}
                                                        />

                                                        <HeadingWrapper
                                                            labelHeading="Top Trades"
                                                            label="View All"
                                                            onClickViewAll={() =>
                                                                navigate(`/dashboard/trending-stocks`)
                                                            }>
                                                            <div className="w-100">
                                                                {Array.isArray(topTrades) &&
                                                                    topTrades
                                                                        .slice(0, 3)
                                                                        .map((ele, index) => (
                                                                            <TopTradesCard
                                                                                key={index}
                                                                                logoUr={ele?.stockDetails?.logoUrl}
                                                                                symbol={ele.symbol}
                                                                                amount={ele?.amount}
                                                                                totalReturn={ele?.totalReturn}
                                                                                returnPercentage={ele?.returnPercentage}
                                                                            />
                                                                        ))}
                                                            </div>
                                                        </HeadingWrapper>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between followers_subscribers">
                                        {fetchUserFollowing?.status === 404 && fetchSubscriber?.subscriptions === 0 ? (
                                            <div className="d-flex justify-content-between w-100">
                                                <p className="notFollowinAndSubscribing mt-2">
                                                    You are not following or subscribing any traders yet
                                                </p>
                                                <button
                                                    className="explore-trade-btn"
                                                    onClick={() => navigate(`/dashboard/explore-inflencer`)}>
                                                    EXPLORE TRADERS
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                <MyFollowingsCard
                                                    errorMsg="You are not subscribing any traders yet"
                                                    label="My Subscriptions"
                                                    status={fetchSubscriber?.subscriptions === 0 && 404}
                                                    following={fetchSubscriber}
                                                    onClickFollowing={() => navigate(`/dashboard/subscription/${id}`)}
                                                />
                                                <MyFollowingsCard
                                                    errorMsg="You are not following any traders yet"
                                                    label="My Followings"
                                                    status={fetchUserFollowing?.status}
                                                    following={fetchUserFollowing?.following}
                                                    onClickFollowing={() => navigate(`/dashboard/following/${id}`)}
                                                />
                                            </>
                                        )}
                                    </div>

                                    <div className="d-flex justify-content-between mt-3 spotlight">
                                        <HeadingWrapper
                                            labelHeading="Traders Spotlight"
                                            label="View All"
                                            onClickViewAll={handleFetchTradeSpotlight}>
                                            <div className="d-flex">
                                                {fetchTradeSpotlight?.map((ele, index) => (
                                                    <Spotlight
                                                        key={index}
                                                        profilePicture={ele.profilePicture}
                                                        twitterFollowers={ele.twitterFollowers}
                                                        firstName={ele.firstName}
                                                        lastName={ele.lastName}
                                                        returns={0}
                                                    />
                                                ))}
                                            </div>
                                        </HeadingWrapper>

                                        <div className="ps-3 w-100">
                                            <div className="d-flex justify-content-start">
                                                <HeadingWrapper labelHeading="Performers of the Week">
                                                    <PerformersList data={topWeeklyPerformers} />
                                                </HeadingWrapper>
                                            </div>
                                        </div>
                                    </div>

                                    <TrendingStocksPreview data={trendingStocks} />

                                    <InfluencerWrapper
                                        text={'Explore'}
                                        lastText={' View All'}
                                        onClickLastText={() => navigate(`/dashboard/explore-inflencer`)}
                                        isShowVerified
                                        trader={'Trader'}
                                    />

                                    <div className="d-flex justify-content-start image-gallery">
                                        {ftechInfluencerAll?.influencers?.map((ele, i) => (
                                            <VerifiedTrader
                                                key={i}
                                                onClickLink={() => getInfluencerId(ele.id)}
                                                profilePicture={ele?.profilePicture}
                                                userName={ele.userName}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <MediaQuery minWidth={1025}>
                                    <div className="col-xl-3 col-lg-3 pe-0">
                                        <div className="scroll-sideNotification side-notification">
                                            <SideNotificationComponent />
                                        </div>
                                    </div>
                                </MediaQuery>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default NormalUserDashboard;
