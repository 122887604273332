import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import ComponentLoading from 'Components/reusable/loading/Loading';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import ReferAndEarn from 'Components/reusable/model/ReferAndEarn/ReferAndEarn';
import Navbar from '../../Components/Navbar/Navbar';
import AnalyticsWithdrawEarning from '../../Components/DashBoard/AnalyticsWithdrawEarning';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import AnalyticsBarGraph from 'Components/reusable/graph/AnalyticsBarGraph';
import { averageTrades } from 'Api/influencerDetails';
import { churnRate } from 'Api/analyst';
import {
    InfluencerFollowing,
    InfluencerSubscriber,
    getInfluencerByID,
    getInfluencerGraph,
    getRefferal,
} from 'Api/influencerDashboard';
import { fetchUserFollowingApi } from 'Api/setting';
import SeparationCard from 'Components/reusable/influencer/separationCard';
import WinRateCard from 'Components/reusable/influencer/cards/winRate';
import AverageGain from 'Components/reusable/influencer/cards/averageGain';
import LargestWin from 'Components/reusable/influencer/cards/largestWin';
import Mimicked from 'Components/reusable/analytics/mimicked';
import SubscribeCard from 'Components/reusable/analytics/subscribeCard';
import { getUserId } from './slector';
import './style.scss';

const SubscriptionEarnings = lazy(() => import('Components/reusable/analytics/subscriptionEarnings'));

const Analytics = () => {
    const [getChurnRate, setGetChurnRate] = useState([]);
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const [fetchSubscriber, setFetchSubscriber] = useState([]);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [subscriptionEarning, setSubscriptionEarning] = useState({});
    const [isYearly, setIsYearly] = useState(false);
    const [earnings, setEarnings] = useState(0);
    const [subscriberList, setSubscriberList] = useState([]);
    const [subscriberCount, setSubscriberCount] = useState(0);
    const [mimickedTrades, setMimickedTrades] = useState([]);
    const [activeMimicked, setActiveMimicked] = useState({});
    const [getAvgTrade, setGetAvgTrade] = useState([]);
    const [inputValueRefer, setInputValueRefer] = useState('');
    const [modalReferAndEarn, setModalReferAndEarn] = useState(false);
    const navigate = useNavigate();
    const id = useSelector(getUserId);

    const getAverageTrades = () => {
        averageTrades(id)
            .then((response) => {
                setGetAvgTrade(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getAllChurnRate = () => {
        churnRate()
            .then((response) => {
                setGetChurnRate(response);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    const influencerByID = () => {
        getInfluencerByID(id)
            .then((response) => {
                setFetchInfluencer(response);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    const getInfluencerFollowing = () => {
        InfluencerFollowing()
            .then((response) => {
                setFetchUserFollowing(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getInfluencerSubscriber = () => {
        InfluencerSubscriber()
            .then((response) => {
                setFetchSubscriber(response);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    const getUserFollowing = () => {
        fetchUserFollowingApi()
            .then((response) => {
                setFetchUserFollowing(response);
            })
            .catch((error) => {
                console.error(false);
            });
    };

    const getAllInfluencerGraph = async () => {
        const influencerId = `"${id}"`;
        try {
            const response = await getInfluencerGraph(influencerId);

            setSubscriptionEarning({
                monthlyData: response?.monthlyData,
                weeklyData: response?.weeklyData,
            });
            setSubscriberList(response?.subscriberActiveInactive);
            setMimickedTrades(response?.mimickedTrades);
        } catch (error) {
            console.warn('getInfluencerGraph', error);
        }
    };

    const getAllReFeral = () => {
        getRefferal()
            .then((response) => {
                setInputValueRefer(response.referralCode);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getAverageTrades();
        getAllChurnRate();
        influencerByID();
        getInfluencerFollowing();
        getInfluencerSubscriber();
        getUserFollowing();
        getAllInfluencerGraph();
        getAllReFeral();
        // eslint-disable-next-line
    }, []);

    const influencer = {
        firstName: 'Analytics',
    };

    const handleInputChangeRefer = (event) => {
        setInputValueRefer(event.target.value);
    };

    const handleCopyClick = () => {
        if (inputValueRefer) {
            navigator.clipboard
                .writeText(inputValueRefer)
                .then(() => {
                    console.warn('Copied to clipboard:', inputValueRefer);
                })
                .catch((error) => {
                    console.error('Copy failed:', error);
                });
        }
    };

    const secModalCloseRefer = () => {
        setModalReferAndEarn(false);
    };

    return (
        <div className="analytics-board">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-2">
                            <Navbar setModalReferAndEarn={setModalReferAndEarn} />
                        </div>
                    </MediaQuery>

                    <div className="col-xl-4 col-lg-5 middle-container">
                        <SeparationCard
                            fistHeading={'YTD Return%'}
                            firstHeadingImg={'/assets/images2/Outline.png'}
                            firstText={'68%'}
                            secondHeading={'Total Trades'}
                            secondHeadingImg={'/assets/images2/fire.png'}
                            secondText={'624'}
                        />
                        <div className="mt-3 subscription-earning-main">
                            <div className="">
                                <Suspense fallback={<ComponentLoading />}>
                                    <SubscriptionEarnings
                                        earnings={earnings}
                                        onClickWithdraw={() => setWithdrawModalOpen(true)}
                                        subscription={
                                            !isYearly
                                                ? subscriptionEarning?.monthlyData
                                                : subscriptionEarning?.weeklyData
                                        }
                                        setEarnings={setEarnings}
                                        isYearly={isYearly}
                                        onclickMonthlyYearly={(value) => setIsYearly(value)}
                                    />
                                </Suspense>

                                <div className="mt-3 analytics-double-bar-graph">
                                    <div className="subscriber-text-graph">
                                        <h6>Subscribers</h6>
                                        <h4>{subscriberCount}</h4>
                                    </div>
                                    <div className="bar-double-graph">
                                        <AnalyticsBarGraph
                                            subscriber={subscriberList}
                                            subscriberCount={setSubscriberCount}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 middle-container">
                        <SeparationCard
                            fistHeading={'Discord Followers'}
                            firstHeadingImg={'/assets/images2/purpleSmile2.png'}
                            firstText={
                                ftechInfluencer &&
                                ftechInfluencer.influencer &&
                                ftechInfluencer.influencer.influencer &&
                                ftechInfluencer.influencer.influencer.discordFollowers
                            }
                            secondHeading={'TikTok Followers'}
                            secondHeadingImg={'/assets/images2/tik-tok (1) 1.png'}
                            secondText={
                                ftechInfluencer &&
                                ftechInfluencer.influencer &&
                                ftechInfluencer.influencer.influencer &&
                                ftechInfluencer.influencer.influencer.tiktok
                            }
                        />

                        <Mimicked
                            mimic={activeMimicked?.mimic}
                            month={activeMimicked?.month}
                            mimickedTrades={mimickedTrades}
                            setActiveMimicked={setActiveMimicked}
                        />

                        <div className="d-flex justify-content-evenly mt-3 subscriber-follower-container">
                            <SubscribeCard
                                logo="/assets/Analytics/AnalyticsSubscriber.png"
                                count={fetchSubscriber?.length}
                                label={'Subscribers'}
                                onClickRightIcon={() => navigate(`/dashboard/analytics/subscriber`)}
                            />

                            <SubscribeCard
                                logo="/assets/Analytics/AnalyticsFollowers.png"
                                count={fetchUserFollowing?.followers?.length || 0}
                                label={'Followers'}
                            />
                        </div>

                        <div className="mt-3 churn-rate-container">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="p-4 churn-rate-label mb-0">Total Churn Rate</p>
                                <p className="p-4 churn-rate-count mb-0">{getChurnRate && getChurnRate.churnRate} %</p>
                            </div>
                        </div>
                    </div>
                    {withdrawModalOpen ? (
                        <>
                            <AnalyticsWithdrawEarning setWithdrawModalOpen={setWithdrawModalOpen} />
                        </>
                    ) : (
                        <>
                            <MediaQuery minWidth={1025}>
                                <div className="col-xl-3 col-lg-3 pe-0">
                                    <div className="scroll-sideNotification side-notification">
                                        <SideNotificationComponent />
                                    </div>
                                </div>
                            </MediaQuery>
                        </>
                    )}
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-1 col-lg-2" />
                    <div className="col-xl-8 col-lg-9 middle-container">
                        <div className="mt-3">
                            <img
                                src="/assets/Analytics/StaticsTitlewithLine.png"
                                className="static-title-with-line mb-3"
                                alt="title"
                            />
                        </div>

                        <div className="d-flex w-100 statistics-wrap">
                            <div className="w-100" style={{ marginRight: '1rem' }}>
                                <WinRateCard winRate={ftechInfluencer?.influencer?.influencer?.winRate} />
                                <div className="mt-3">
                                    <AverageGain
                                        averageGain={`${getAvgTrade?.averageTrades?.toFixed(2)}`}
                                        longestWinStrake={ftechInfluencer?.influencer?.influencer?.winStreak?.length}
                                        lossRate={`${ftechInfluencer?.influencer?.influencer?.lossRate}`}
                                    />
                                </div>
                            </div>

                            <LargestWin
                                largestWin={
                                    ftechInfluencer &&
                                    ftechInfluencer.influencer &&
                                    ftechInfluencer.influencer.influencer &&
                                    ftechInfluencer.influencer.influencer.largestWin
                                }
                                breakevenTrades={
                                    ftechInfluencer &&
                                    ftechInfluencer.influencer &&
                                    ftechInfluencer.influencer.influencer &&
                                    ftechInfluencer.influencer.influencer.breakevenTrades
                                }
                                getAvgTrade={getAvgTrade?.averageTrades}
                                largestLoss={
                                    ftechInfluencer &&
                                    ftechInfluencer.influencer &&
                                    ftechInfluencer.influencer.influencer &&
                                    ftechInfluencer.influencer.influencer.largestLoss
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ReferAndEarn
                inputValueRefer={inputValueRefer}
                handleInputChangeRefer={handleInputChangeRefer}
                handleCopyClick={handleCopyClick}
                modalReferAndEarn={modalReferAndEarn}
                secModalCloseRefer={secModalCloseRefer}
            />{' '}
        </div>
    );
};

export default Analytics;
