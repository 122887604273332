import React, { useEffect, useState, Suspense } from 'react';
import isValidHttpsUrl from 'util/isValidHttpsUrl';
import { getInfluencer } from 'Api/influencerDetails';
import { manageRemoveAnalyst } from 'Api/setting';
import ComponentLoading from 'Components/reusable/loading/Loading.js';
import SureModel from '../sureModel';
import UnFollowInfo from 'Components/reusable/model/unFollowInfo';
import './style.scss';

const ManageAnalyst = ({
    id,
    setLoading,
    setModalMsg,
    setModalOpen,
    setModalErr,
    setSelectContainer,
    setGetManageAnalystId,
}) => {
    const [fetchAnalystList, setFetchAnalystList] = useState([]);
    const [RemoveAnalystModal, setRemoveAnalystModal] = useState(false);
    const [getAnalystInfo, setGetAnalystInfo] = useState({});
    const [analystImg, setAnalystImg] = useState('');
    const [isOpenRemoveMangeAnalystModel, setIsOpenRemoveMnageAnalystModel] = useState(false);

    const handleAnalyst = (img, name, id) => {
        setRemoveAnalystModal(true);
        setGetAnalystInfo({ analystUsername: name, analystId: id });
        setAnalystImg(img);
    };

    const getAllInfluencer = () => {
        getInfluencer(id)
            .then((response) => {
                setFetchAnalystList(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleMnageRemoveAnalyst = async () => {
        try {
            manageRemoveAnalyst(id, getAnalystInfo?.analystId).then((response) => {
                setLoading(false);
                setModalMsg('Analyst removed');
                setModalOpen(true);
                setRemoveAnalystModal(false);
                setIsOpenRemoveMnageAnalystModel(false);
                getAllInfluencer();
            });
        } catch (error) {
            setModalMsg(error?.response?.data?.errors?.message || 'An error occurred');
            setModalErr(true);
            setRemoveAnalystModal(false);
            setIsOpenRemoveMnageAnalystModel(false);
        }
    };

    useEffect(() => {
        getAllInfluencer();
    }, []);

    return (
        <div className="manage-analyst-settings">
            <div className=" mt-3" style={{ cursor: 'pointer' }}>
                {fetchAnalystList?.map((analyst, index) => {
                    const isHttpsUrl = isValidHttpsUrl(analyst.analystImage);
                    return (
                        <div className="d-flex justify-content-between" key={index}>
                            <div
                                className="d-flex"
                                onClick={() => {
                                    handleAnalyst(analyst?.analystImage, analyst?.analystUsername, analyst?.analystId);
                                }}>
                                <img
                                    src={isHttpsUrl ? analyst.analystImage : '/assets/images/profile_img.png'}
                                    className="analyst-img"
                                    alt="img"
                                />

                                <p className="mt-3 analyst-username">@{analyst?.analystUsername}</p>
                            </div>

                            <button
                                className="manage-analyst-trade-btn"
                                onClick={() => {
                                    setSelectContainer('manageAnalystTradeList');
                                    setGetManageAnalystId(analyst?.analystId);
                                }}>
                                Trade
                            </button>
                            <button
                                className="manage-analyst-remove-btn"
                                onClick={() => {
                                    setGetAnalystInfo({
                                        analystUsername: analyst?.analystUsername,
                                        analystId: analyst?.analystId,
                                    });
                                    setIsOpenRemoveMnageAnalystModel(true);
                                }}>
                                Remove
                            </button>
                        </div>
                    );
                })}
            </div>

            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={isOpenRemoveMangeAnalystModel}
                    onModelClose={() => {
                        setIsOpenRemoveMnageAnalystModel(false);
                    }}
                    text="Are you sure you want to remove the analyst?"
                    onYes={handleMnageRemoveAnalyst}
                    onNo={() => {
                        setIsOpenRemoveMnageAnalystModel(false);
                    }}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <UnFollowInfo
                    isOpened={RemoveAnalystModal}
                    onModelClose={() => setRemoveAnalystModal(false)}
                    image={analystImg}
                    name={getAnalystInfo?.analystUsername}
                    buttonText={'REMOVE ANALYST'}
                    buttonClick={handleMnageRemoveAnalyst}
                />
            </Suspense>
        </div>
    );
};

export default ManageAnalyst;
