import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeadingWrapper from 'Components/reusable/portfolio/headingWrapper';
import './style.scss';

const DEFAULT_LOGO_URL = '/assets/dashboardImg/logoCrop512 2.png';

export const TrendingStocksPreview = ({ data }) => {
    const navigate = useNavigate();

    const getProfitLossDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        const formatted = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);

        return isPositive ? `+${formatted}` : `${formatted}`;
    };

    const getProfitLossPercentageDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        return isPositive ? `+${value.toFixed(2)}%` : `${value.toFixed(2)}%`;
    };

    const getColorBasedOnValue = (value) => (value > 0 ? 'green' : 'red');
    return (
        <div className="trending-stock-container">
            <HeadingWrapper
                labelHeading="Trending Stocks"
                label="View All"
                onClickViewAll={() => navigate(`/dashboard/trending-stocks`)}>
                <div>
                    {Array.isArray(data) &&
                        data.slice(0, 5).map((item, index) => (
                            <div key={index} className="trending-container">
                                <div className="d-flex" style={{ width: '80%' }}>
                                    <div className="logo-container">
                                        <img
                                            src={item?.logoUrl || DEFAULT_LOGO_URL}
                                            alt="Logo"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = DEFAULT_LOGO_URL;
                                            }}
                                        />
                                    </div>
                                    <div className="title-side">
                                        <p className="ticker">{item?.symbol ?? item?.ticker_root ?? item?.ticker}</p>
                                        <p className="sort-name">{item?.name ?? item?.shortName}</p>
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex flex-column align-items-end">
                                        <p
                                            style={{
                                                color: getColorBasedOnValue(item?.regularMarketChange),
                                                fontSize: '15px',
                                            }}>
                                            {getProfitLossDisplay(item?.regularMarketChange)}
                                        </p>
                                        <p
                                            style={{
                                                color: getColorBasedOnValue(item?.regularMarketChangePercent),
                                                fontSize: '12px',
                                            }}>
                                            {getProfitLossPercentageDisplay(item?.regularMarketChangePercent)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </HeadingWrapper>
        </div>
    );
};

export default TrendingStocksPreview;
