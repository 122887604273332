import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from 'Components/reusable/settings/commonModal';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';
import LoaderModal from 'pages/Login/LoaderModal';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import Navbar from 'Components/Navbar/Navbar';
import WelcomeSection from 'Components/reusable/welcomeSection';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import Subscription from 'Components/reusable/model/subscription';
import NotAddedPaymentMethod from 'Components/reusable/model/notAddedPaymentMethod';
import SubscriptionPlan from 'Components/reusable/model/subscriptionPlan';
import PaymentSuccess from 'Components/reusable/model/paymentSuccess';
import PaymentConfirmation from 'Components/reusable/model/paymentConfirmation';
import Investment from 'Components/reusable/model/investment';
import ComponentLoading from 'Components/reusable/loading/Loading';
import { getProfitLossGraphUser } from 'Api/UserDashboard';
import {
    averageTrades,
    getInfluencer,
    influencerReview,
    influencerTopHolding,
    publicTrade,
    showTrades,
    userFollow,
    userSubscribe,
    getYTDandTotalTrade,
    createSubscriptionCheckoutSession,
} from 'Api/influencerDetails';
import { getInfluencerByID } from 'Api/influencerDashboard';
import { getInfluencerId, getUserId } from './selector';
import { userLoggedIn } from 'globalStore/actions/login';
import SeparationCard from 'Components/reusable/influencer/separationCard';
import Profile from 'Components/reusable/influencer/profile';
import WinRate from 'Components/reusable/influencer/cards/winRate';
import LargestWin from 'Components/reusable/influencer/cards/largestWin';
import Reviews from 'Components/reusable/influencer/reviews';
import Analyst from 'Components/reusable/influencer/cards/analyst';
import GraphCard from 'Components/reusable/influencer/cards/graphCard';
import HeadingWrapper from 'Components/reusable/influencer/headingWrapper';
import RecentTrades from 'Components/reusable/influencer/cards/recentTrade';
import TopHolding from 'Components/reusable/influencer/cards/topHolding';
import { customStyles } from './style';
import AverageGain from 'Components/reusable/influencer/cards/averageGain';
import './style.scss';

const ChartLine = lazy(() => import('Components/reusable/graph/lineChart/index'));
const AllPositionProfitLossGraph = lazy(() =>
    import('Components/reusable/graph/AllpositionProfitLossGraph/AllPositionProfitLossGraph')
);

const InfluencerDetail = () => {
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const [getFollowState, setGetFollowState] = useState([]);
    const [getSubscribeState, setGetSubscribeState] = useState([]);
    const [fetchTrades, setFetchTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchReview, setFetchReview] = useState([]);
    const [getAvgTrade, setGetAvgTrade] = useState([]);
    const [fetchRecentTrades, setFetchRecentTrades] = useState([]);
    const [fetchRecentPublicTrade, setFetchRecentPublicTrades] = useState([]);
    const [modalSubscriptionIsOpen, setModalSubscriptionsIsOpen] = useState(false);
    const [modalSubscriptionGotoWebOpen, setModalSubscriptionGotoWebOpen] = useState(false);
    const [fetchAnalyst, setFetchAnalyst] = useState([]);
    const [modalPaymentMethodOpen, setModalPaymentMethodIsOpen] = useState(false);
    const [modalPaymentCardOpen, setModalPaymentCardOpen] = useState(false);
    const [modalConfirmPaymentOpen, setModalConfirmPaymentOpen] = useState(false);
    const [modalCongratulationOpen, setModalCongratulationOpen] = useState(false);
    const [modalInformationType, setModalInformationType] = useState(false);
    const [fetchYTDandTotalTrade, setFetchYTDandTotalTrade] = useState([]);
    const [selectMonthYearPerformance, setSelectMonthYearPerformance] = useState('');
    const [currentIndexYTD, setCurrentIndexYTD] = useState(0);
    const [fetchProfitLossGraphData, setFetchProfitLossGraphData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [reviewCount, setReviewCount] = useState(0);
    const id = useSelector(getInfluencerId);
    const currentUserId = useSelector(getUserId);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    useEffect(() => {
        const getYtdAndTotalTrade = () => {
            setLoading(true);
            getYTDandTotalTrade()
                .then((response) => {
                    setFetchYTDandTotalTrade(response);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        };

        getYtdAndTotalTrade();
    }, []);

    useEffect(() => {
        const numericValue = parseFloat(fetchYTDandTotalTrade?.ytdMetrics?.profitLossPercentage);
        const defaultPercentage = 0;
        const percentageValue = isNaN(numericValue) ? defaultPercentage : (numericValue * 100).toFixed(2);

        setCurrentIndexYTD(percentageValue);
    }, [fetchYTDandTotalTrade]);

    const handleSwitchClick = () => {
        if (!isNaN(currentIndexYTD)) {
            let value = fetchYTDandTotalTrade?.yearMetrics?.profitLossPercentag.toFixed();
            setCurrentIndexYTD(value);
        } else {
            setCurrentIndexYTD(0);
        }
    };

    const getAPIData = async () => {
        setLoading(true);

        try {
            const response = await Promise.allSettled([
                showTrades(id),
                getInfluencer(id),
                getInfluencerByID(id),
                averageTrades(id),
                publicTrade(id),
                influencerTopHolding(id),
                influencerReview(id),
            ]);

            setFetchRecentTrades(response[0]?.value);
            setFetchAnalyst(response[1]?.value);
            setGetAvgTrade(response[3]?.value);
            if (response[2]?.value) {
                setFetchInfluencer(response[2]?.value);
                setGetFollowState(response[2]?.value?.followerSubscriber?.isFollowing);
                setGetSubscribeState(response[2]?.value?.followerSubscriber?.isSubscribed);
                dispatch(
                    userLoggedIn({
                        InfluencerAmt: response[2]?.value?.influencer?.influencer?.amount,
                    })
                );
            }
            setFetchTrades(response[5]?.value);
            setReviewCount(response[6]?.value?.reviewCount);
            setFetchReview(response[6]?.value?.reviews || []);

            let tradeBluerCount = response[4]?.value.stocks?.length;
            let holdingBluerCount = response[5]?.value?.length;

            if (!response[2]?.value?.followerSubscriber?.isFollowing) {
                tradeBluerCount = 2;
            }
            if (!response[2]?.value?.followerSubscriber?.isSubscribed) {
                holdingBluerCount = 1;
            }

            const trade = response[4]?.value.stocks?.map((data, index) => ({
                ...data,
                isBluer: index < tradeBluerCount ? false : true,
            }));

            const holdings = response[5]?.value.map((data, index) => ({
                ...data,
                isBluer: index < holdingBluerCount ? false : true,
            }));

            setFetchRecentPublicTrades({ stocks: trade || [] });
            setFetchTrades(holdings);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) getAPIData();
        // eslint-disable-next-line
    }, [id]);

    const handleFollow = () => {
        setLoading(true);
        const data = {
            follow: true,
        };
        userFollow(id, data)
            .then((response) => {
                setLoading(false);
                setModalMsg('You are now following this influencer.');
                setModalOpen(true);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleSubscribe = () => {
        setModalSubscriptionsIsOpen(true);
    };

    const handleSubscribepayment = () => {
        setModalSubscriptionsIsOpen(false);
        setModalSubscriptionGotoWebOpen(true);
    };

    function secModalClose() {
        setModalSubscriptionsIsOpen(false);
    }

    // const handlePaymentSubscription = () => {
    //     setModalConfirmPaymentOpen(false);
    //     setLoading(true);

    //     const data = {
    //         amount: ftechInfluencer?.influencer?.influencer?.amount,
    //     };

    //     userSubscribe(id, data)
    //         .then((response) => {
    //             window.location.href = response.createSubscription.data.subscription.url;
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             console.warn(error);
    //         });
    // };

    const handlePaymentSubscription = async () => {
        // setIsLoading(true);

        // const data = {
        //     amount: ftechInfluencer?.influencer?.influencer?.amount,
        // };

        const influencerId = ftechInfluencer?.influencer?.influencer?.id;

        console.log('influencerId:', influencerId, 'userId:', id, 'InfluencerId:', currentUserId);

        const response = await createSubscriptionCheckoutSession(currentUserId, influencerId);

        console.log('subscription resp', response);

        window.location.href = response.url;

        // userSubscribe(ftechInfluencer?.influencer?.influencer?.id, data)
        //     .then((response) => {
        //         setIsLoading(false);

        //     })
        //     .catch((error) => {
        //         console.warn(error);
        //     });
    };

    const handleEditInfluencer = () => {
        navigate(`/dashboard/setting`);
    };

    const handleInformationType = () => {
        setModalInformationType(true);
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectMonthYearPerformance(selectedValue);
    };
    const influencer = {
        firstName: (
            <>
                <i class="fa-solid fa-chevron-left" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}></i>{' '}
                &nbsp; &nbsp;
                {ftechInfluencer?.influencer?.influencer?.firstName} {ftechInfluencer?.influencer?.influencer?.lastName}
            </>
        ),
    };
    const fetchDataweek = async () => {
        try {
            const response = await getProfitLossGraphUser(selectMonthYearPerformance);

            setFetchProfitLossGraphData(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataweek();

        return () => {
            setFetchProfitLossGraphData([]);
        };
    }, [selectMonthYearPerformance]);

    useEffect(() => {
        setSelectMonthYearPerformance('week');
        return () => {
            setSelectMonthYearPerformance('');
        };
    }, []);

    const isSameUser = id === currentUserId;

    return (
        <>
            <div className="influencer">
                <div className="influencer-container">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection
                        id={id}
                        influencer={isSameUser ? ftechInfluencer?.influencer?.influencer : influencer}
                        isVisible
                    />
                </div>
                <div className="container-fluid middle-container">
                    <div className="row">
                        <div className="col-xl-1 col-lg-1">
                            <MediaQuery minWidth={769}>
                                <Navbar />
                            </MediaQuery>
                        </div>

                        <div className="col-xl-4 col-lg-5">
                            <SeparationCard
                                handleSwitchClick={handleSwitchClick}
                                fistHeading={'YTD Return%'}
                                firstHeadingImg={'/assets/images2/Outline.png'}
                                firstText={currentIndexYTD}
                                secondHeading={'Total Trades'}
                                secondHeadingImg={'/assets/images2/fire.png'}
                                secondText={fetchYTDandTotalTrade?.totalTrades}
                            />

                            <Profile
                                profilePicture={ftechInfluencer?.influencer?.influencer?.profilePicture}
                                userName={ftechInfluencer?.influencer?.influencer?.userName}
                                email={ftechInfluencer?.influencer?.influencer?.email}
                                getFollowState={getFollowState}
                                handleFollow={handleFollow}
                                investmentPattern={ftechInfluencer?.influencer?.influencer?.investmentPattern}
                                onClickInvestmentPattern={handleInformationType}
                                amount={ftechInfluencer?.influencer?.influencer?.amount}
                                getSubscribeState={getSubscribeState}
                                handleSubscribe={handleSubscribe}
                                handleEditInfluencer={handleEditInfluencer}
                                isSameUser={isSameUser}
                            />
                        </div>

                        <div className="col-xl-4 col-lg-6">
                            <SeparationCard
                                fistHeading={'Discord Followers'}
                                firstHeadingImg={'/assets/images2/purpleSmile2.png'}
                                firstText={
                                    ftechInfluencer?.influencer?.influencer?.discordFollowers
                                        ? `${ftechInfluencer?.influencer?.influencer?.discordFollowers}K`
                                        : '-'
                                }
                                secondHeading={'TikTok Followers'}
                                secondHeadingImg={'/assets/images2/tik-tok (1) 1.png'}
                                secondText={
                                    ftechInfluencer?.influencer?.influencer?.tiktok
                                        ? `${ftechInfluencer?.influencer?.influencer?.tiktok}K`
                                        : '-'
                                }
                                isDiscord
                            />

                            <GraphCard
                                selectMonthYearPerformance={selectMonthYearPerformance}
                                handleChange={handleChange}>
                                <Suspense fallback={<ComponentLoading />}>
                                    <AllPositionProfitLossGraph
                                        fetchProfitLossGraphData={fetchProfitLossGraphData}
                                        selectMonthYearPerformance={selectMonthYearPerformance}
                                    />
                                </Suspense>
                            </GraphCard>

                            {isSameUser ? (
                                <>
                                    <div>
                                        <HeadingWrapper
                                            text={'Recent Trades'}
                                            isShowVerified
                                            lastText={' View All'}
                                            onClickLastText={() =>
                                                navigate(`/dashboard/explore-inflencer/influencer-detail/recentTrades`)
                                            }
                                        />

                                        {fetchRecentPublicTrade?.stocks &&
                                            Array.isArray(fetchRecentPublicTrade.stocks) &&
                                            fetchRecentPublicTrade.stocks.length > 0 &&
                                            fetchRecentPublicTrade?.stocks
                                                ?.slice(0, 2)
                                                .map((ele, index) => (
                                                    <RecentTrades
                                                        key={index}
                                                        ticker={ele.symbol}
                                                        side={ele.side}
                                                        amount={ele.amount}
                                                        price={ele.price}
                                                        updatedAt={ele?.stockDetails?.updatedAt}
                                                    />
                                                ))}

                                        {fetchRecentTrades?.slice(0, 1).map((ele, index) => (
                                            <RecentTrades
                                                key={index}
                                                ticker={ele.symbol}
                                                side={ele.side}
                                                amount={ele.amount}
                                                price={ele.price}
                                                updatedAt={ele?.stockDetails?.updatedAt}
                                            />
                                        ))}
                                    </div>

                                    {getFollowState ? (
                                        <>
                                            <div className="w-100">
                                                <div className="d-flex w-100">
                                                    <div className="w-100">
                                                        <HeadingWrapper
                                                            text={'Top Holdings'}
                                                            lastText={' View All'}
                                                            onClickLastText={() =>
                                                                navigate(
                                                                    `/dashboard/explore-inflencer/influencer-detail/holdings`
                                                                )
                                                            }
                                                        />

                                                        {fetchTrades?.status === 404 ? (
                                                            <div className="m-4 text-center">
                                                                <h3 className="no-public-trade">
                                                                    No public trades to show
                                                                </h3>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex w-100">
                                                                {fetchTrades &&
                                                                fetchTrades &&
                                                                Array.isArray(fetchTrades) &&
                                                                fetchTrades.length > 0 ? (
                                                                    fetchTrades.slice(0, 2).map((trade, index) => (
                                                                        <TopHolding
                                                                            key={index}
                                                                            symbol={trade?.symbol}
                                                                            name={trade?.detail?.name}
                                                                            logoUrl={trade?.details?.logoUrl}
                                                                            amount={trade?.amount}
                                                                            position={trade}>
                                                                            {/* <Suspense fallback={<ComponentLoading />}>
                                                                                <ChartLine
                                                                                    chartData={trade?.chartData?.data}
                                                                                />
                                                                            </Suspense> */}
                                                                        </TopHolding>
                                                                    ))
                                                                ) : (
                                                                    <div className="m-4 text-center"></div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <div className="d-flex w-100">
                                                    <div className="w-100">
                                                        <HeadingWrapper
                                                            text={'Top Holdings'}
                                                            lastText={' View All'}
                                                            onClickLastText={() =>
                                                                navigate(
                                                                    `/dashboard/explore-inflencer/influencer-detail/holdings`
                                                                )
                                                            }
                                                        />

                                                        {fetchTrades?.error === 404 ? (
                                                            <div className="m-4 text-center">
                                                                <h3 className="no-public-trade">
                                                                    No public trades to show
                                                                </h3>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex ">
                                                                {fetchTrades &&
                                                                fetchTrades &&
                                                                Array.isArray(fetchTrades) &&
                                                                fetchTrades.length > 0 ? (
                                                                    fetchTrades.slice(0, 2).map((trade, index) => (
                                                                        <>
                                                                            <TopHolding
                                                                                key={index}
                                                                                symbol={trade?.symbol}
                                                                                name={trade?.detail?.name}
                                                                                logoUrl={trade?.details?.logoUrl}
                                                                                amount={trade?.amount}
                                                                                position={trade}>
                                                                                {/* <Suspense
                                                                                    fallback={<ComponentLoading />}>
                                                                                    <ChartLine
                                                                                        chartData={
                                                                                            trade?.chartData?.data
                                                                                        }
                                                                                    />
                                                                                </Suspense> */}
                                                                            </TopHolding>
                                                                        </>
                                                                    ))
                                                                ) : (
                                                                    <div className="m-4 text-center"></div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <div clssName="w-100 " style={{ marginTop: '6%' }}>
                                        <HeadingWrapper
                                            text={'Recent Trades'}
                                            isShowVerified
                                            lastText={' View All'}
                                            onClickLastText={() =>
                                                getSubscribeState
                                                    ? navigate(
                                                          `/dashboard/explore-inflencer/influencer-detail/recentTrades`
                                                      )
                                                    : null
                                            }
                                        />

                                        {getFollowState === true && getSubscribeState === false ? (
                                            <>
                                                {fetchRecentPublicTrade &&
                                                    fetchRecentPublicTrade.stocks &&
                                                    Array.isArray(fetchRecentPublicTrade.stocks) &&
                                                    fetchRecentPublicTrade.stocks.length > 0 &&
                                                    fetchRecentPublicTrade.stocks
                                                        .slice(0, 2)
                                                        .map((ele, index) => (
                                                            <RecentTrades
                                                                key={index}
                                                                ticker={ele.symbol}
                                                                side={ele.side}
                                                                amount={ele.amount}
                                                                price={ele.price}
                                                                updatedAt={ele?.stockDetails?.updatedAt}
                                                                isBlur={ele.isBluer}
                                                            />
                                                        ))}
                                            </>
                                        ) : (
                                            <>
                                                {fetchRecentPublicTrade &&
                                                    fetchRecentPublicTrade.stocks &&
                                                    Array.isArray(fetchRecentPublicTrade.stocks) &&
                                                    fetchRecentPublicTrade.stocks.length > 0 &&
                                                    fetchRecentPublicTrade.stocks
                                                        .slice(0, 2)
                                                        .map((ele, index) => (
                                                            <RecentTrades
                                                                key={index}
                                                                ticker={ele.symbol}
                                                                side={ele.side}
                                                                amount={ele.amount}
                                                                price={ele.price}
                                                                updatedAt={ele?.stockDetails?.updatedAt}
                                                                isBlur={ele.isBluer}
                                                            />
                                                        ))}
                                            </>
                                        )}

                                        {getSubscribeState === true && getFollowState === true ? (
                                            <>
                                                {fetchRecentTrades?.slice(0, 1).map((ele, index) => (
                                                    <RecentTrades
                                                        key={index}
                                                        ticker={ele.symbol}
                                                        side={ele.side}
                                                        amount={ele.amount}
                                                        price={ele.price}
                                                        updatedAt={ele?.stockDetails?.updatedAt}
                                                    />
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {fetchRecentTrades?.slice(0, 1).map((ele, index) => (
                                                    <RecentTrades
                                                        key={index}
                                                        ticker={ele.symbol}
                                                        side={ele.side}
                                                        amount={ele.amount}
                                                        price={ele.price}
                                                        updatedAt={ele?.stockDetails?.updatedAt}
                                                        isBlur={true}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </div>

                                    {getFollowState === true && getSubscribeState === false ? (
                                        <>
                                            <div className="w-100">
                                                <div className="d-flex media-center-flex">
                                                    <div className="w-100">
                                                        <HeadingWrapper
                                                            text={'Top Holdings'}
                                                            lastText={' View All'}
                                                            onClickLastText={() =>
                                                                navigate(
                                                                    `/dashboard/explore-inflencer/influencer-detail/holdings`
                                                                )
                                                            }
                                                        />

                                                        {fetchTrades?.status === 404 ? (
                                                            <div className="m-4 text-center">
                                                                <h3 className="no-public-trade">
                                                                    No public trades to show
                                                                </h3>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex ">
                                                                {fetchTrades &&
                                                                fetchTrades &&
                                                                Array.isArray(fetchTrades) &&
                                                                fetchTrades.length > 0 ? (
                                                                    fetchTrades.slice(0, 2).map((trade, index) => (
                                                                        <TopHolding
                                                                            key={index}
                                                                            symbol={trade?.symbol}
                                                                            name={trade?.detail?.name}
                                                                            logoUrl={trade?.details?.logoUrl}
                                                                            amount={trade?.amount}
                                                                            isBluer={trade?.isBluer}>
                                                                            <Suspense fallback={<ComponentLoading />}>
                                                                                <ChartLine
                                                                                    chartData={trade?.chartData?.data}
                                                                                />
                                                                            </Suspense>
                                                                        </TopHolding>
                                                                    ))
                                                                ) : (
                                                                    <div className="m-4 text-center"></div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <div className="d-flex w-100">
                                                    <div className="w-100">
                                                        <HeadingWrapper
                                                            text={'Top Holdings'}
                                                            lastText={' View All'}
                                                            onClickLastText={() =>
                                                                navigate(
                                                                    `/dashboard/explore-inflencer/influencer-detail/holdings`
                                                                )
                                                            }
                                                        />
                                                        {fetchTrades?.error === 404 ? (
                                                            <div className="m-4 text-center">
                                                                <h3 sclassName="no-public-trade">
                                                                    No public trades to show
                                                                </h3>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex">
                                                                {fetchTrades &&
                                                                    fetchTrades &&
                                                                    Array.isArray(fetchTrades) &&
                                                                    fetchTrades.length > 0 &&
                                                                    fetchTrades.slice(0, 2).map((trade, index) => (
                                                                        <>
                                                                            <TopHolding
                                                                                key={index}
                                                                                symbol={trade?.symbol}
                                                                                name={trade?.detail?.name}
                                                                                logoUrl={trade?.details?.logoUrl}
                                                                                amount={trade?.amount}
                                                                                isBluer={trade?.isBluer}>
                                                                                <Suspense
                                                                                    fallback={<ComponentLoading />}>
                                                                                    <ChartLine
                                                                                        chartData={
                                                                                            trade?.chartData?.data
                                                                                        }
                                                                                    />
                                                                                </Suspense>
                                                                            </TopHolding>
                                                                        </>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}

                            <div className="d-flex">
                                <div className="mt-4" style={{ width: '100%', height: '100px' }}>
                                    <WinRate winRate={ftechInfluencer?.influencer?.influencer?.winRate} />

                                    <LargestWin
                                        largestWin={ftechInfluencer?.influencer?.influencer?.largestWin}
                                        breakevenTrades={ftechInfluencer?.influencer?.influencer?.breakevenTrades}
                                        getAvgTrade={getAvgTrade}
                                        largestLoss={ftechInfluencer?.influencer?.influence?.largestLoss}
                                    />

                                    <AverageGain />

                                    <Analyst fetchAnalyst={fetchAnalyst} fetchYTDandTotalTrade={fetchYTDandTotalTrade}>
                                        <GraphCard
                                            selectMonthYearPerformance={selectMonthYearPerformance}
                                            handleChange={handleChange}>
                                            <Suspense fallback={<ComponentLoading />}>
                                                <AllPositionProfitLossGraph
                                                    fetchProfitLossGraphData={fetchProfitLossGraphData}
                                                    selectMonthYearPerformance={selectMonthYearPerformance}
                                                />
                                            </Suspense>
                                        </GraphCard>
                                    </Analyst>

                                    <Reviews
                                        reviewCount={reviewCount}
                                        clickToNavigate={() =>
                                            navigate(`/dashboard/explore-inflencer/influencer-detail/review`)
                                        }
                                        fetchReview={fetchReview}
                                    />
                                </div>
                            </div>
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 pe-0">
                                <div className="scroll-sideNotification side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>

                        {loading && <LoaderModal />}
                    </div>
                </div>
            </div>

            <Subscription
                isOpened={modalSubscriptionIsOpen}
                onModelClose={secModalClose}
                handleContinue={handleSubscribepayment}
                influencer={ftechInfluencer?.influencer?.influencer}
            />

            <Modal
                isOpen={modalSubscriptionGotoWebOpen}
                onRequestClose={secModalClose}
                style={customStyles}
                contentLabel="Example Modal">
                <div className="">
                    <div class="mt-3">
                        <p className="mt-2 pending-payment-text">Pending in-app payments</p>
                    </div>

                    <div class="mt-3 p-2">
                        <p className="mt-2 pending-payment-description">
                            In the meantime please go to the Parrot web page and pay for your subscription
                        </p>
                    </div>

                    <div class=" d-flex justify-content-center px-2 rounded mt-4 date ">
                        <button className="btn btn-sm m-2 go-to-webpage-btn" onClick={handlePaymentSubscription}>
                            GO TO WEB PAGE
                        </button>
                    </div>
                </div>
            </Modal>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalOpen}
                    onModelClose={() => setModalOpen(false)}
                    text={modalMsg}
                    onYes={() => setModalOpen(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <NotAddedPaymentMethod
                isOpened={modalPaymentMethodOpen}
                onModelClose={secModalClose}
                setCloseModel={setModalPaymentMethodIsOpen}
                setPaymentCardOpen={setModalPaymentCardOpen}
            />

            <SubscriptionPlan
                isOpened={modalPaymentCardOpen}
                onModelClose={secModalClose}
                influencer={ftechInfluencer?.influencer?.influencer}
                isConfirmModelOpened={setModalConfirmPaymentOpen}
                setPaymentCardOpen={setModalPaymentCardOpen}
            />

            <PaymentConfirmation
                isOpened={modalConfirmPaymentOpen}
                onModelClose={secModalClose}
                handlePaymentSubscription={handlePaymentSubscription}
            />

            <PaymentSuccess
                isOpened={modalCongratulationOpen}
                onModelClose={secModalClose}
                setCongratulationOpen={setModalCongratulationOpen}
            />

            <Investment
                isOpened={modalInformationType}
                onModelClose={secModalClose}
                setCloseModel={setModalInformationType}
            />
        </>
    );
};

export default InfluencerDetail;
