import React from 'react';
import ct from 'classnames';

const PasswordEye = ({ isVisible = false, setIsVisible }) => {

    return (
        <>
            {isVisible ? (
                <i class={ct('fa-regular', 'fa-eye')} onClick={() => setIsVisible(!isVisible)} />
            ) : (
                <i class={ct('fa-regular', 'fa-eye-slash')} onClick={() => setIsVisible(!isVisible)} />
            )}
        </>
    );
};

export default React.memo(PasswordEye);
