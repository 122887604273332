import React, { useState, useLayoutEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import swal from 'sweetalert';
import ct from 'classnames';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from '../../pages/SignUp/constant.js';
import PasswordEye from 'Components/reusable/PasswordEye';
import { resetPassword } from 'Api/password';
import '../../pages/SignUp/signUp.scss';

const ResetPassword = () => {
    const [pass, setPass] = useState('');
    const [skipAction, setSkipAction] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const handleSkip = () => {
        setSkipAction(true);
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            password: pass,
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + id,
            },
        };

        resetPassword(data, config)
            .then((response) => {
                swal('Your Password has been Reset Successfully', '', 'success');
                navigate('/');
            })
            .catch((error) => {
                swal('Sorry', error.response.data.errors.message, 'error');
            });
    };

    return (
        <>
            <Row className="user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                            </Col>

                            <div className="form-group mt-5">
                                <Col className={ct('password-field')}>
                                    <input
                                        className="form-control otp-input"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter Password"
                                        value={pass}
                                        onChange={(e) => setPass(e.target.value)}
                                    />
                                    <PasswordEye isVisible={showPassword} setIsVisible={setShowPassword} />
                                </Col>
                            </div>

                            <div className="mt-4">
                                <button
                                    className="btn border border-secondary rounded-pill m-2 confirm-btn"
                                    onClick={(e) => onSubmit(e)}>
                                    CONFIRM
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default ResetPassword;
