import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';
import LoaderModal from '../Login/LoaderModal';
import Navbar from '../../Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import { getSubscription, unsubscribeSubscription } from '../../Api/UserSubscription';
import UnsubscribeSubscription from 'Components/reusable/model/UnsubscribeSubscription/UnsubscribeSubscription';
import './style.scss';

const Subscription = () => {
    const [fetchSubscriber, setGetFetchSubscriber] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [UnSubscriptionModal, setUnSubscriptionModal] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    }, []);

    const influencer = {
        firstName: 'User Subscription',
    };

    useEffect(() => {
        const handleUserSubscription = async () => {
            setIsLoading(true);

            try {
                const response = await getSubscription();

                if (response) {
                    setGetFetchSubscriber(response);
                    setIsLoading(false);
                }
                return response;
            } catch (error) {
                console.error(error);
                console.warn(error, 'catch error');
                setIsLoading(false);
            }
        };
        handleUserSubscription();
    }, []);

    const handleUnSubscribe = (uname, userid) => {
        setIsLoading(true);

        const subscription_id = localStorage.getItem('SubscriptionId');
        const subscriptioName = localStorage.getItem('SubscriptionName');

        const data = {
            influencerUserName: subscriptioName,
        };

        swal({
            title: 'Are you sure you want to unsubscribe this influencer ?',
            icon: 'warning',
            buttons: {
                yes: {
                    text: 'YES',
                    value: 'yes',
                },
                no: {
                    text: 'No',
                    value: 'no',
                },
            },
        }).then((value) => {
            if (value === 'yes') {
                unsubscribeSubscription(subscription_id, data)
                    .then((response) => {
                        setIsLoading(false);
                        setUnSubscriptionModal(false);
                        window.location.reload();
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        swal('you have not subscribed to this influencer', '', 'error');
                    });
            } else {
                setUnSubscriptionModal(false);
                setIsLoading(false);
            }
        });
    };

    const secModalClose = () => {
        setUnSubscriptionModal(false);
    };

    return (
        <div>
            <div className="portfolio-trade-history">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5 mt-5">
                            {fetchSubscriber?.length > 0 ? (
                                <>
                                    {fetchSubscriber.length > 0 && (
                                        <>
                                            {fetchSubscriber?.map((ele) => (
                                                <div className="d-flex mt-3 subscription-div p-3">
                                                    <div>
                                                        <img
                                                            src="/assets/Influencer/InfluencerCongratulation.png"
                                                            alt="img"
                                                            className="congratulation-img"
                                                        />
                                                        <img
                                                            src="/assets/images/orange_icon.png"
                                                            alt="img"
                                                            className="subscription-orange-icon"
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: '10px' }} className="mt-2">
                                                        <p
                                                            className="mt-2 subscription-influencer-name"
                                                            onClick={() => {
                                                                setUnSubscriptionModal(true);
                                                                localStorage.setItem(
                                                                    'SubscriptionName',
                                                                    ele.influencerUserName
                                                                );
                                                                localStorage.setItem(
                                                                    'SubscriptionId',
                                                                    ele.subscriptionId
                                                                );
                                                            }}>
                                                            @{ele.influencerUserName}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center">
                                    <p>You are not subscribing any traders yet</p>
                                </div>
                            )}
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>
            <UnsubscribeSubscription
                handleUnSubscribe={handleUnSubscribe}
                secModalClose={secModalClose}
                UnSubscriptionModal={UnSubscriptionModal}
            />
            {isLoading && <LoaderModal />}
        </div>
    );
};

export default Subscription;
