import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { IoChevronDown } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import LoaderModal from 'pages/Login/LoaderModal';
import Navbar from 'Components/Navbar/Navbar';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import { influencerTopHolding } from 'Api/influencerDetails';
import WelcomeSection from 'Components/reusable/welcomeSection';
import InfluencerHoldingsFiltersModal from 'Components/reusable/model/InfluencerHoldingFiltersModal/InfluencerHoldingsFiltersModal';
import { getInfluencerId } from '../influencerDetails/selector';
import './style.scss';

const TopHolding = () => {
    const [fetchTrades, setFetchTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsSecOpenLink, setSecIsOpenLink] = useState(false);

    const id = useSelector(getInfluencerId);

    useEffect(() => {
        const handleTopHolding = async (id) => {
            setLoading(true);

            try {
                const response = await influencerTopHolding(id);

                if (response) {
                    setFetchTrades(response);
                    setLoading(false);
                }
                return response;
            } catch (error) {
                console.error(error);
                console.warn(error, 'catch error');
                setLoading(false);
            }
        };
        handleTopHolding(id);
    }, [id]);

    function secModalClose() {
        setSecIsOpenLink(false);
    }

    const influencer = {
        firstName: 'Influencer Top Holding',
    };

    return (
        <>
            <div className="portfolio-trade-history top-holding-main">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5">
                            <div className="inflenecer-holding-heading-parent d-flex justify-content-between mt-3">
                                <div className="inflenecer-holding-heading">All Holdings</div>
                                <div className="change-btn">
                                    <p onClick={() => setSecIsOpenLink(true)}>
                                        Change <IoChevronDown />
                                    </p>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                {fetchTrades?.map((trade, index) => (
                                    <div key={index} className="col-sm-12 col-md-12 col-lg-4 col-12 text-center">
                                        <div className="text-center ">
                                            <div className="holding-parent-div mt-4">
                                                <div className="">
                                                    <div className="pt-3 ">
                                                        <div className="d-flex justify-content-center">
                                                            <img
                                                                src={trade && trade.details.logoUrl}
                                                                className="mt-1 trade-logourl"
                                                                alt="trade-logo"
                                                            />
                                                        </div>
                                                        <p className="d-flex justify-content-center mt-2 align-items-center trade-symbol-holdings">
                                                            {trade && trade.symbol}
                                                            <p className="d-flex justify-content-center mb-0 trade-detail-holding">
                                                                {trade && trade.details.name}
                                                            </p>
                                                        </p>
                                                    </div>
                                                    <div className="d-flex d-flex justify-content-center padding-left-10px">
                                                        <p className="color-green">Amount :{trade.amount}</p>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-center ">
                                                    <p className="type-text-holding">
                                                        Type : {trade && trade.details.type}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>

            {loading && <LoaderModal />}
            <InfluencerHoldingsFiltersModal modalIsSecOpenLink={modalIsSecOpenLink} secModalClose={secModalClose} />
        </>
    );
};

export default TopHolding;
