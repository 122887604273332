import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ct from 'classnames';
import { useMediaQuery } from 'react-responsive';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from './constant';
import { registerOTP } from 'Api/registration';
import { getPhoneNumber, getSessionToken } from './selector';
import './signUp.scss';
import { signUpCredential } from 'globalStore/actions/signUp';

const SignUpCode = () => {
    const [skipAction, setSkipAction] = useState(false);
    const [code, setCode] = useState('');
    const [storedPhoneNumber, setStoredPhoneNumber] = useState('');
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const phoneNumber = useSelector(getPhoneNumber);
    const id = useSelector(getSessionToken);
    const dispatch = useDispatch();

    useEffect(() => {
        setStoredPhoneNumber(phoneNumber);
    }, []);

    const handleSkip = () => {
        setSkipAction(true);
    };

    const handleCode = (e) => {
        e.preventDefault();
        const data = {
            confirmationCode: code,
            phoneNumber: '+' + storedPhoneNumber,
            sessionToken: id,
        };

        registerOTP(data)
            .then((response) => {
                dispatch(
                    signUpCredential({
                        authFlowToken: response.authFlowToken,
                    })
                );
                navigate(`/signup/code/email`);
            })
            .catch((error) => {
                if (error?.response?.status === 429) {
                    swal(error?.response?.data?.errors?.message, '', 'warning');
                } else {
                    swal('otp code verification failed', '', 'warning');
                }
            });
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <>
            <Row className="text-center user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container m-auto', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                            </Col>

                            <div className="form-group mt-5">
                                <input
                                    className="form-control otp-input"
                                    type="number"
                                    placeholder="Enter code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>

                            <div className="mt-4">
                                <button
                                    className="btn border border-secondary rounded-pill m-2 confirm-btn"
                                    onClick={handleCode}>
                                    CONFIRM
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default SignUpCode;
