import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import './style.scss';
// import ProfitAndLossBox from 'Components/DashBoard/profitAndLossBox';

const FollowersUserCard = ({
    imageUrl,
    username,
    followerCount,
    followingCount,
    profitLoss,
    followUser,
    onclickFollowBtn,
    isShowFollowBtn,
    onClickSwitch,
    isShowPercentage,
    onClickPercentageBtn,
    pAndLIsPercentage,
}) => {
    return (
        <div className="d-flex justify-content-between p-3 person-followers">
            <div className="d-flex mt-1">
                <div>
                    {imageUrl ? (
                        <img src={imageUrl} alt="dashboard" className="person-followers-user-image" />
                    ) : (
                        <FaUserCircle size={52} color={'#000'} />
                    )}
                </div>
                <div className="mt-1 portfolio-username-container">
                    <p className="portfolio-username">
                        @{username}
                        <p className="user-follower-count">{followerCount || 0}</p>
                        <p className="user-follower-label">Followers</p>
                    </p>
                </div>
                <div className="middle-line-container">
                    <img src="/assets/Navbar/Middle_line.png" alt="Middle Line" />
                </div>
                <div className="d-flex following-count">
                    <div className="user-follower-count">{followingCount || 0}</div>
                    <div className="user-follower-label">Following</div>
                </div>
            </div>

            {isShowFollowBtn &&
                (followUser ? (
                    <>
                        <button className="follow-btn" onClick={() => onclickFollowBtn(true)}>
                            FOLLOW
                        </button>
                    </>
                ) : (
                    <>
                        <button className="follow-btn" onClick={() => onclickFollowBtn(false)}>
                            UNFOLLOW
                        </button>
                    </>
                ))}

            <div className="todays-p-l">
                <div className="d-flex justify-content-between p-3">
                    <div className="d-flex justify-content-between">
                        <div
                            className="d-flex justify-content-center align-items-center user-follower-switch"
                            onClick={onClickSwitch}>
                            <img src="/assets/dashboardImg/Outline.png" alt="Outline" />
                        </div>
                        <p className="todaysPL-label">Todays P & L $</p>
                    </div>
                    <div>
                        <p className="profit-loss-count">
                            {!isShowPercentage && '$'} {profitLoss}
                        </p>
                    </div>
                    {isShowPercentage && (
                        <button className="btn btn-info m-2 percentage-btn-switch" onClick={onClickPercentageBtn}>
                            {pAndLIsPercentage}
                        </button>
                    )}
                </div>
            </div>
            {/* <ProfitAndLossBox  /> */}
        </div>
    );
};

export default FollowersUserCard;
