import React from 'react';

import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const parseChartData = (data) => {
    const labels = data.map((item) => {
        const date = new Date(item.createdAt._seconds * 1000);
        return `${date.getMonth() + 1}/${date.getDate()}`;
    });

    const equitiesValue = data.map((item) => item.equitiesValue.toFixed(2));
    const actualPortfolioPerformance = data?.map((item) => item.actualPortfolioPerformance?.toFixed(2));

    return {
        labels,
        datasets: [
            {
                label: 'Equities Value',
                data: equitiesValue,
                backgroundColor: '#D3FF5B',

                borderWidth: 0,
                hoverData: actualPortfolioPerformance,
            },
        ],
    };
};

const PortfolioBarGraph = ({ dataJson }) => {
    const data = parseChartData(dataJson);

    console.log('parsed data:', data);
    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const equitiesValue = context.dataset.data[context.dataIndex];
                        const performanceValue = context.dataset.hoverData[context.dataIndex];
                        return `Equities Value: ${equitiesValue}, Performance: ${performanceValue}%`;
                    },
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default PortfolioBarGraph;
