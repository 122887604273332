import React from 'react';
import './style.scss';

const AverageGain = ({ averageGain, longestWinStrake, lossRate }) => {
    return (
        <div className="average-section w-100">
            <div className="d-flex justify-content-evenly mt-3">
                <div>
                    <p className="average-gain-number">{averageGain || 0}%</p>
                    <p className="average-gain-label">Average Gain</p>
                </div>

                <div>
                    <p className="average-gain-number">{longestWinStrake || 0}</p>
                    <p className="average-gain-label">Longest Win Straek</p>
                </div>

                <div>
                    <p className="average-gain-number">{lossRate || 0} %</p>
                    <p className="average-gain-label">Loss Rate</p>
                </div>
            </div>
        </div>
    );
};

export default AverageGain;
