import React from 'react';
import './style.scss';

const WinRateCard = ({ winRate }) => {
    return (
        <div className="d-flex justify-content-between align-items-center px-5 win-rate-back">
            <div className="">
                <p className="mb-0 win-rate-number">{winRate || 0}%</p>
                <p className="mb-0 win-rate-text">Win Rate</p>
            </div>
            <div>
                <img src="/assets/images2/influencerStaticGraph.png" className="win-rate-img" alt="graph" />
            </div>
        </div>
    );
};

export default WinRateCard;
