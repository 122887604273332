import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ct from 'classnames';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from './constant';

const Linkbrokerage = () => {
    const [skipAction, setSkipAction] = useState(false);

    const handleSkip = () => {
        setSkipAction(true);
    };

    return (
        <div>
            {' '}
            <Row className="user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />

                                <h5 className="mt-5">Connect Brokerage</h5>
                            </Col>

                            <div className="mt-5">
                                <button className="btn border border-secondary rounded-pill m-2 confirm-btn">
                                    CONNECT NOW
                                </button>

                                <h6 className="text-center mt-3 skipStyle">Not now</h6>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default Linkbrokerage;
