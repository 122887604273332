export const customStyles5 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '356px',
        height: '604px',
        borderRadius: '20px',
        backgroundColor: '#FFF',
        padding: '0px',
        zIndex: 9999,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9998,
    },
};
