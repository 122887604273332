import React from 'react';

const OptionsCallNext = () => {
    return (
        <>
            <div class="container mt-4 mb-4 p- d-flex justify-content-center">
                <div class="card p-4" style={{ backgroundColor: 'black', color: 'white' }}>
                    <div class=" image d-flex flex-column justify-content-center align-items-center">
                        <div
                            class="card"
                            style={{
                                width: '50rem',
                                backgroundColor: 'black',
                                color: 'white',
                                border: '1px solid grey',
                            }}>
                            <div class="card-header text-center">
                                <h2 className="mt-2">Options</h2>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-center m-5">
                                <p className="m-4 text-center" style={{ fontSize: '20px' }}>
                                    Apple
                                </p>
                                <p className="m-4 text-center" style={{ fontSize: '20px' }}>
                                    $164.44 (4.4%)
                                </p>
                            </div>
                            <div
                                className=" m-4"
                                style={{
                                    borderTopRightRadius: '28px',
                                    borderTopLeftRadius: '28px',
                                    border: '1px solid grey',
                                }}>
                                <div className="text-center mt-2">
                                    <h3>The Greeks</h3>
                                </div>
                                <div className="d-flex justify-content-evenly m-4">
                                    <p className=" m-2">
                                        -0.5421
                                        <div>Delta</div>
                                    </p>
                                    <p className=" m-2">
                                        0.01245
                                        <div>Gamma</div>
                                    </p>
                                    <p className=" m-2">
                                        -0.3245
                                        <div>Theta</div>
                                    </p>
                                </div>
                                <div className="d-flex justify-content-around m-4">
                                    <p className=" m-2">
                                        -0.0456
                                        <div>Vega</div>
                                    </p>
                                    <p className=" m-2">
                                        -0.0456
                                        <div>Rho</div>
                                    </p>
                                </div>
                            </div>
                            <div style={{ border: '1px solid grey' }}>
                                <div className="d-flex justify-content-around m-4">
                                    <div className="m-2">
                                        <div style={{ color: 'green' }}>$162.36</div>
                                        <p>TELA Market Price</p>
                                    </div>
                                    <div style={{ color: 'red' }}>|</div>

                                    <div className="m-2">
                                        <div style={{ color: 'red' }}>$16442.36</div>
                                        <p>Estimated Cost</p>
                                    </div>
                                </div>
                                <div className="text-center mb-4">
                                    <p style={{ fontSize: '20px' }}>Number of Lot</p>
                                    <p style={{ fontSize: '20px' }}>1Lot = 60 shares</p>
                                    <p style={{ fontSize: '20px' }}>
                                        <span className="m-2">- </span> <span className="m-2">20</span>{' '}
                                        <span className="m-2">+</span>{' '}
                                    </p>
                                </div>
                                <div className="text-center" style={{ backgroundColor: 'black' }}>
                                    <button
                                        className="btn btn-sm m-2 mb-3"
                                        style={{
                                            width: '45rem',
                                            backgroundColor: 'black',
                                            color: '#C0FF02',
                                            borderRadius: '28px',
                                            height: '50px',
                                            border: '1px solid grey',
                                        }}
                                        //  onClick={handleSubmit}
                                    >
                                        Next
                                    </button>
                                    <button
                                        className="btn btn-sm m-2 mb-3"
                                        style={{
                                            width: '45rem',
                                            backgroundColor: 'black',
                                            color: '#C0FF02',
                                            borderRadius: '28px',
                                            height: '50px',
                                            border: '1px solid grey',
                                        }}
                                        //  onClick={handleSubmit}
                                    >
                                        Conditional Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OptionsCallNext;
