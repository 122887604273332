import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';

const TradeListing = ({ stocksData = [] }) => {
    const DEFAULT_LOGO_URL = '/assets/dashboardImg/logoCrop512 2.png';

    return stocksData?.map((tradesData, index) => {
        const formattedDate = tradesData?.updatedTimestamp
            ? moment(tradesData.updatedTimestamp).format('MM/HH/YYYY HH:MM a')
            : 'N/A';
        return (
            <Link
                key={index}
                to={`/dashboard/trending-stocks/stocks-details/${tradesData?.ticker}`}
                style={{ textDecoration: 'none' }}>
                <div
                    className="d-flex justify-content-between flex-wrap mb-2"
                    style={{
                        borderRadius: '18px',
                        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                        border: '1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ECF2F1)',
                    }}>
                    <div>
                        <div className="d-flex align-items-center">
                            {' '}
                            <img
                                src={tradesData?.stockDetails?.logoUrl || DEFAULT_LOGO_URL}
                                alt="Profile"
                                width="120"
                                height="120"
                                className="m-4"
                                style={{ borderRadius: '287px', width: '34px', height: '34px' }}
                            />
                            <p
                                className="mb-0"
                                style={{
                                    color: '#1F1F1F',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    fontStyle: 'normal',
                                    lineHeight: '125%',
                                }}>
                                {tradesData?.symbol}
                                <p
                                    style={{
                                        color: '#1F1F1F',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        cursor: 'pointer',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                    }}
                                    className="mb-1">
                                    {tradesData?.amount} Shares @ ${tradesData?.lastPrice}
                                </p>
                            </p>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center trending-card-middle p-3">
                        <div className="">
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                }}
                                className="m-1">
                                $ {numeral(tradesData?.totalReturn).format('$0,0.[000]')}
                            </p>
                            <p
                                style={{
                                    color: '#8F8F8F',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                    fontStyle: 'normal',
                                    lineHeight: '125%',
                                }}
                                className="m-2">
                                {numeral(tradesData?.returnPercentage).format('$0,0.[000]')}
                            </p>
                        </div>
                    </div>
                </div>
            </Link>
        );
    });
};

export default React.memo(TradeListing);
