import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import LoaderModal from 'pages/Login/LoaderModal';
import { submitTradeList, getAnalystTradeList } from 'Api/setting';
import './style.scss';

const ManageAnalystTradeList = ({ getManageAnalystId }) => {
    const [fetchTrades, setFtechTrades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTrades, setSelectedTrades] = useState([]);

    useEffect(() => {
        setLoading(true);
        const getAllTrades = async () => {
            getAnalystTradeList(getManageAnalystId)
                .then((response) => {
                    setFtechTrades(response.content.transactions);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
        };

        getAllTrades();
        // eslint-disable-next-line
    }, []);

    const handleSelectedTrade = (trade) => {
        const isTradeSelected = selectedTrades.some((selected) => selected.id === trade.id);

        if (isTradeSelected) {
            setSelectedTrades((prevSelectedTrades) =>
                prevSelectedTrades.filter((selected) => selected.id !== trade.id)
            );
        } else {
            setSelectedTrades((prevSelectedTrades) => [...prevSelectedTrades, trade]);
        }
    };

    const handleSubmitSelectedTrade = async () => {
        try {
            const data = {
                trade: selectedTrades,
            };

            const response = await submitTradeList(getManageAnalystId, data);

            localStorage.setItem('stocksHide', true);
            swal({
                title: 'Analyst public stocks added successfully',
                icon: 'success',
                timer: 2000,
                buttons: false,
            });

            return response;
        } catch (error) {
            console.warn(error);
            swal(error?.response?.data?.errors?.message || 'An error occurred', '', 'error');
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-start">
                <p className="mt-1 manage-analyst-trade-heading">Manage Analyst Trade List</p>
            </div>
            {fetchTrades?.map((trade) => {
                const dateTime = new Date(trade.updatedTimestamp * 1000);
                const formattedDateTime = dateTime.toLocaleString();
                const isSelected = selectedTrades.some((selected) => selected.id === trade.id);
                return (
                    <div
                        key={trade.id}
                        className={`m-4 p-1 ${isSelected ? 'selected-trade' : ''} analyst-trade-list-inner-parent`}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <div>{trade.name}</div>
                                <div>{trade.price} $USD</div>
                                <div>{formattedDateTime}</div>
                            </div>

                            <input type="radio" value={isSelected} onChange={() => handleSelectedTrade(trade)} />
                        </div>
                    </div>
                );
            })}
            <div className="d-flex justify-content-center mt-5">
                <button onClick={(e) => handleSubmitSelectedTrade(e)} className="add-analyst-trade-btn">
                    Add AnalystTo Public Stocks
                </button>
            </div>
            {loading && <LoaderModal />}
        </div>
    );
};

export default ManageAnalystTradeList;
