import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import SetTimeInForce from '../setTimeInForce';

const ModalSetTimeMarketOrder = ({
    modalSetTimeMarketOrder,
    setModalSetTimeMarketOrder,
    customStyles12,
    setSelectMarketOrderModal,
    setModalBuyIsOpenLimitOrder,
    selectOrder,
    fetchBrokerageByIdDetails,
}) => {
    const secModalClose = () => {
        setModalSetTimeMarketOrder(false);
    };

    const item = fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType?.supportedTimeInForceList;
    const item2 = fetchBrokerageByIdDetails?.cryptocurrencyOrders?.stopLossType?.supportedTimeInForceList;
    const item3 = fetchBrokerageByIdDetails?.cryptocurrencyOrders?.limitType?.supportedTimeInForceList;

    return (
        <div>
            {selectOrder === 'market' && (
                <SetTimeInForce
                    isOpened={modalSetTimeMarketOrder}
                    onModelClose={setModalSetTimeMarketOrder}
                    setNextOpen={setModalBuyIsOpenLimitOrder}
                    item={item}
                    item2={item2}
                    item3={item3}
                    fetchBrokerageByIdDetails={fetchBrokerageByIdDetails}
                    setPreviousOpen={setSelectMarketOrderModal}
                />

                // <Modal
                //     isOpen={modalSetTimeMarketOrder}
                //     onRequestClose={secModalClose}
                //     style={customStyles12}
                //     contentLabel="Example Modal">
                //     <div
                //         style={{
                //             width: '490px',
                //             height: '171px',
                //             borderRadius: '25px 25px 0px 0px',
                //             backgroundColor: '#FFF',
                //         }}
                //         className="p-3 ">
                //         <div
                //             className="d-flex justify-content-center"
                //             style={{ marginLeft: '6rem', marginTop: '1rem' }}>
                //             <img
                //                 src="/assets/stockdetails/arrow_left_black.png"
                //                 style={{ width: '24px', height: '24px', textAlign: 'left', right: '9rem' }}
                //                 alt="img"
                //                 onClick={() => {
                //                     if (setSelectMarketOrderModal) {
                //                         setModalSetTimeMarketOrder(false);
                //                         setSelectMarketOrderModal(true);
                //                     }
                //                 }}
                //             />
                //             <p
                //                 style={{
                //                     color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                //                     fontStyle: 'normal',
                //                     fontSize: '18px',
                //                     fontFamily: 'Figtree',
                //                     fontWeight: '500',
                //                     lineHeight: '24px',
                //                     textAlign: 'center',
                //                     right: '2rem',
                //                 }}>
                //                 Set Time In Force
                //             </p>
                //             <p
                //                 style={{
                //                     color: '#FF5445',
                //                     fontStyle: 'normal',
                //                     fontSize: '14px',
                //                     fontFamily: 'Figtree',
                //                     fontWeight: '600',
                //                     lineHeight: 'normal',
                //                     marginLeft: '50px',
                //                 }}
                //                 className="mt-1"
                //                 onClick={() => setModalSetTimeMarketOrder(false)}>
                //                 Cancel
                //             </p>
                //         </div>

                //         <div
                //             className="d-flex justify-content-between p-3"
                //             style={{
                //                 width: '400px',
                //                 marginTop: '1rem',
                //                 opacity:
                //                     fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType
                //                         ?.supportedTimeInForceList?.supportedTimeInForceList !== 'goodTillCanceled' ||
                //                     fetchBrokerageByIdDetails?.cryptocurrencyOrders?.marketType
                //                         ?.supportedTimeInForceList?.supportedTimeInForceList?.length === 0
                //                         ? 0.7
                //                         : '',
                //             }}>
                //             <div>
                //                 <p
                //                     style={{
                //                         color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                //                         fontStyle: 'normal',
                //                         fontSize: '16px',
                //                         fontFamily: 'Figtree',
                //                         fontWeight: '400',
                //                         lineHeight: 'normal',
                //                     }}>
                //                     4:00 PM Today
                //                 </p>

                //                 <p
                //                     style={{
                //                         color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                //                         fontStyle: 'normal',
                //                         fontSize: '12px',
                //                         fontFamily: 'Figtree',
                //                         fontWeight: '400',
                //                         lineHeight: 'normal',
                //                         opacity: 0.5,
                //                         bottom: '10px',
                //                     }}>
                //                     Order will be open till market close
                //                 </p>
                //             </div>
                //             <div>
                //                 <input
                //                     type="radio"
                //                     id="order-will-open"
                //                     name="order-will-open"
                //                     disabled={item2 === 'goodTillCanceled' ? false : true}
                //                 />
                //             </div>
                //         </div>

                //         <div
                //             className="d-flex justify-content-between p-3"
                //             style={{
                //                 width: '400px',
                //                 bottom: '2rem',
                //                 opacity: item === 'goodTillCanceled' ? 0.7 : 1,
                //             }}>
                //             <div>
                //                 <p
                //                     style={{
                //                         color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                //                         fontStyle: 'normal',
                //                         fontSize: '16px',
                //                         fontFamily: 'Figtree',
                //                         fontWeight: '400',
                //                         lineHeight: 'normal',
                //                     }}>
                //                     Oct 9, 2023
                //                 </p>

                //                 <p
                //                     style={{
                //                         color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                //                         fontStyle: 'normal',
                //                         fontSize: '12px',
                //                         fontFamily: 'Figtree',
                //                         fontWeight: '400',
                //                         lineHeight: 'normal',
                //                         opacity: 0.5,
                //                         bottom: '10px',
                //                     }}>
                //                     Good till canceled (Up to 90 days)
                //                 </p>
                //             </div>
                //             <div>
                //                 <input
                //                     type="radio"
                //                     id="good-till-cancel"
                //                     name="good-till-cancel"
                //                     disabled={item2 !== 'goodTillCanceled' ? false : true}
                //                 />
                //             </div>
                //         </div>

                //         <div className="d-flex justify-content-center mt-5">
                //             <button
                //                 style={{
                //                     width: '350px',
                //                     height: '52px',
                //                     borderRadius: '54px',
                //                     backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                //                     color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                //                     fontSize: '14px',
                //                     fontFamily: 'Figtree',
                //                     fontStyle: 'normal',
                //                     fontWeight: '600',
                //                     letterSpacing: '0.7px',
                //                     right: '1rem',
                //                     bottom: '5rem',
                //                 }}
                //                 onClick={() => {
                //                     setModalSetTimeMarketOrder(false);
                //                     setModalBuyIsOpenLimitOrder(true);
                //                 }}>
                //                 CONTINUE
                //             </button>
                //         </div>
                //     </div>
                // </Modal>
            )}
            {selectOrder === 'stoporder' && (
                <Modal
                    isOpen={modalSetTimeMarketOrder}
                    onRequestClose={secModalClose}
                    style={customStyles12}
                    contentLabel="Example Modal">
                    <div
                        style={{
                            width: '490px',
                            height: '171px',
                            borderRadius: '25px 25px 0px 0px',
                            backgroundColor: '#FFF',
                        }}
                        className="p-3 ">
                        <div
                            className="d-flex justify-content-center"
                            style={{
                                marginLeft: '6rem',
                                marginTop: '1rem',
                            }}>
                            <img
                                src="/assets/stockdetails/arrow_left_black.png"
                                style={{ width: '24px', height: '24px', textAlign: 'left', right: '9rem' }}
                                alt="img"
                                onClick={() => {
                                    if (setSelectMarketOrderModal) {
                                        setModalSetTimeMarketOrder(false);
                                        setSelectMarketOrderModal(true);
                                    }
                                }}
                            />
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '18px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    right: '2rem',
                                }}>
                                Set Time In Force
                            </p>
                            <p
                                style={{
                                    color: '#FF5445',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    marginLeft: '50px',
                                }}
                                className="mt-1"
                                onClick={() => setModalSetTimeMarketOrder(false)}>
                                Cancel
                            </p>
                        </div>

                        <div
                            className="d-flex justify-content-between p-3"
                            style={{
                                width: '400px',
                                marginTop: '1rem',
                                opacity:
                                    fetchBrokerageByIdDetails?.cryptocurrencyOrders?.stopLossType
                                        ?.supportedTimeInForceList?.supportedTimeInForceList !== 'goodTillCanceled' ||
                                    fetchBrokerageByIdDetails?.cryptocurrencyOrders?.stopLossType
                                        ?.supportedTimeInForceList?.supportedTimeInForceList?.length === 0
                                        ? 0.7
                                        : '',
                            }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    4:00 PM Today
                                </p>

                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                        bottom: '10px',
                                    }}>
                                    Order will be open till market close
                                </p>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="order-will-open"
                                    name="order-will-open"
                                    disabled={item2 === 'goodTillCanceled' ? false : true}
                                />
                            </div>
                        </div>

                        <div
                            className="d-flex justify-content-between p-3"
                            style={{ width: '400px', bottom: '2rem', opacity: item2 === 'goodTillCanceled' ? 0.7 : 1 }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    Oct 9, 2023
                                </p>

                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                        bottom: '10px',
                                    }}>
                                    Good till canceled (Up to 90 days)
                                </p>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="good-till-cancel"
                                    name="good-till-cancel"
                                    disabled={item2 !== 'goodTillCanceled' ? false : true}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                            <button
                                style={{
                                    width: '350px',
                                    height: '52px',
                                    borderRadius: '54px',
                                    backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    letterSpacing: '0.7px',
                                    right: '1rem',
                                    bottom: '5rem',
                                }}
                                onClick={() => {
                                    setModalSetTimeMarketOrder(false);
                                    setModalBuyIsOpenLimitOrder(true);
                                }}>
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </Modal>
            )}

            {selectOrder === 'limitorder' && (
                <Modal
                    isOpen={modalSetTimeMarketOrder}
                    onRequestClose={secModalClose}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div
                        style={{
                            width: '100%',
                            borderRadius: '25px 25px 0px 0px',
                            backgroundColor: '#FFF',
                            padding: '2rem',
                        }}>
                        <div
                            className="d-flex justify-content-between"
                            style={{
                                marginTop: '1rem',
                            }}>
                            <img
                                src="/assets/stockdetails/arrow_left_black.png"
                                style={{ width: '24px', height: '24px' }}
                                alt="img"
                                onClick={() => {
                                    if (setSelectMarketOrderModal) {
                                        setModalSetTimeMarketOrder(false);
                                        setSelectMarketOrderModal(true);
                                    }
                                }}
                            />

                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '18px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                }}>
                                Set Time In Force
                            </p>
                            <p
                                style={{
                                    color: '#FF5445',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    marginLeft: '50px',
                                }}
                                className="mt-1"
                                onClick={() => setModalSetTimeMarketOrder(false)}>
                                Cancel
                            </p>
                        </div>

                        <div
                            className="d-flex justify-content-between"
                            style={{
                                width: '100%',
                                marginTop: '1rem',
                                opacity:
                                    fetchBrokerageByIdDetails?.cryptocurrencyOrders?.limitType?.supportedTimeInForceList
                                        ?.supportedTimeInForceList !== 'goodTillCanceled' ||
                                    fetchBrokerageByIdDetails?.cryptocurrencyOrders?.limitType?.supportedTimeInForceList
                                        ?.supportedTimeInForceList?.length === 0
                                        ? 0.7
                                        : '',
                            }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    4:00 PM Today
                                </p>

                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Order will be open till market close
                                </p>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="order-will-open"
                                    name="order-will-open"
                                    disabled={item3 === 'goodTillCanceled' ? false : true}
                                    style={{
                                        zoom: '1.5',
                                    }}
                                />
                            </div>
                        </div>

                        <div
                            className="d-flex justify-content-between"
                            style={{ width: '100%', opacity: item3 === 'goodTillCanceled' ? 0.7 : 1 }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}>
                                    Oct 9, 2023
                                </p>

                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '12px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        opacity: 0.5,
                                    }}>
                                    Good till canceled (Up to 90 days)
                                </p>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="good-till-cancel"
                                    name="good-till-cancel"
                                    disabled={item3 !== 'goodTillCanceled' ? false : true}
                                    style={{
                                        zoom: '1.5',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="d-flex justify-content-center ">
                            <button
                                style={{
                                    width: '80%',
                                    height: '52px',
                                    borderRadius: '54px',
                                    backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    letterSpacing: '0.7px',
                                    marginBottom: '0rem',
                                    marginTop: '1rem',
                                }}
                                onClick={() => {
                                    setModalSetTimeMarketOrder(false);
                                    setModalBuyIsOpenLimitOrder(true);
                                }}>
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ModalSetTimeMarketOrder;
