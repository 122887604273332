import DashBoard from './Components/DashBoard/DashBoard';
import FetchInfliencer from './pages/exploreInfluencer';
import News from './Components/reusable/News/News';
import Profile from './Components/reusable/Profile/Profile';
import Search from './Components/DashBoard/Search/Search';
import Setting from '../src/pages/Setting';
import StocksDetails from './Components/DashBoard/TrendingStocks/StocksDetail/StocksDetails';
import TrendingStocks from './Components/DashBoard/TrendingStocks/TrendingStocks';
import ResetPassword from './Components/ForgetPassword/ResetPassword';
import Login from './pages/Login/Login';
import SignUpScreen from './pages/SignUp/SignUpScreen';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import InfluencerDetail from 'pages/influencerDetails';
import TierPlan from './Components/DashBoard/TierPlan';
import Analytics from './pages/analytics';
import BrokerageDetails from './Components/DashBoard/BrokerageDetails';
import Review from 'pages/review';
import OptionCall from './Components/DashBoard/TrendingStocks/StocksDetail/OptionCall';
import OptionsCallNext from './Components/DashBoard/TrendingStocks/StocksDetail/OptionsCallNext';
import Portfolio from './pages/Portfolio';
import AddBrokerage from './Components/DashBoard/AddBrokerage';
import SignUpUsername from './pages/SignUp/SignUpUsername';
import SignUpPassword from './pages/SignUp/SignUpPassword';
import SignUpCode from './pages/SignUp/SignUpCode';
import TopHolding from 'pages/topHoldings';
import RecentTrades from 'pages/RecentTrades';
import Support from './Components/DashBoard/Support';
import PortfolioWithdrawAmt from './Components/DashBoard/TrendingStocks/PortfolioWithdrawAmt';
import SignupEmail from './pages/SignUp/SignupEmail';
import PortfolioTrendingStocks from './pages/PortfolioTrendingStocks';
import ResetCode from './Components/ForgetPassword/ResetCode';
import PortfolioHoldingStocks from './pages/PortfolioHoldingStocks';
import ProfilePic from './pages/SignUp/ProfilePic';
import ProfilePicGallery from './pages/SignUp/ProfilePicGallery';
import FollowAccounts from './pages/SignUp/FollowAccounts';
import Linkbrokerage from './pages/SignUp/Linkbrokerage';
import SubscriberPageAnalytics from './pages/analytics/SubscriberPageAnalytics';
import FollowerPage from './pages/InfluencerDashBoard/FollowerPage';
import EndUserProfile from './pages/InfluencerDashBoard/EndUserProfile';
import TradeHistory from 'Components/DashBoard/TrendingStocks/StocksDetail/TradeHistory';
import Subscription from 'pages/normalUserDashboard/Subscription';
import Following from 'pages/normalUserDashboard/Following';
import ManageAnalystTradeList from 'pages/manageAnalystTradingList/ManageAnalystTradeList';
import PendingOrder from 'pages/Portfolio/PendingOrder';
import TradeSpotlight from 'pages/tradeSpotlight';
import Statistic from 'pages/statistic';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/signup" element={<SignUpScreen />} />
                <Route path="/signup/code/email/username/" element={<SignUpUsername />} />
                <Route
                    path="/signup/code/email/username/profilePic/profilegallery/password"
                    element={<SignUpPassword />}
                />
                <Route path="/signup/code" element={<SignUpCode />} />
                <Route path="/signup/code/email" element={<SignupEmail />} />
                <Route path="/signup/code/email/username/profilePic" element={<ProfilePic />} />
                <Route path="/signup/code/email/username/profilePic/profilegallery" element={<ProfilePicGallery />} />
                <Route
                    path="/signup/code/email/username/password/profilePic/profilegallery/followaccounts/:id"
                    element={<FollowAccounts />}
                />
                <Route
                    path="/signup/code/email/username/password/profilePic/profilegallery/followaccounts/linkbrokerage/:id"
                    element={<Linkbrokerage />}
                />
                <Route path="/dashboard" element={<DashBoard />} />
                <Route path="/dashboard/followerpage" element={<FollowerPage />} />
                <Route path="/dashboard/followerpage/enduserprofile" element={<EndUserProfile />} />
                <Route path="/reset-code/:id" element={<ResetCode />} />
                <Route path="/reset-password/:id" element={<ResetPassword />} />
                <Route path="/dashboard/explore-inflencer" element={<FetchInfliencer />} />
                <Route path="/dashboard/support" element={<Support />} />
                <Route path="/dashboard/trde-spotlight" element={<TradeSpotlight />} />
                <Route path="/dashboard/explore-inflencer/influencer-detail" element={<InfluencerDetail />} />
                <Route path="/dashboard/explore-inflencer/influencer-detail/holdings" element={<TopHolding />} />
                <Route path="/dashboard/explore-inflencer/influencer-detail/recentTrades" element={<RecentTrades />} />
                <Route path="/dashboard/explore-inflencer/influencer-detail/review" element={<Review />} />
                <Route
                    path="/dashboard/explore-inflencer/influencer-detail/statistic-view-all"
                    element={<Statistic />}
                />
                <Route path="/dashboard/trending-stocks" element={<TrendingStocks />} />
                <Route path="/dashboard/news" element={<News />} />
                <Route path="/dashboard/subscription/:id" element={<Subscription />} />
                <Route path="/dashboard/following/:id" element={<Following />} />
                <Route path="/dashboard/search/:id" element={<Search />} />
                <Route path="/dashboard/brokerage/:id" element={<BrokerageDetails />} />
                <Route path="/dashboard/trending-stocks/stocks-details/:id" element={<StocksDetails />} />
                <Route path="/dashboard/trending-stocks/stocks-details/:id/trade-history" element={<TradeHistory />} />
                <Route
                    path="/dashboard/trending-stocks/stocks-details/:id/options/option-call"
                    element={<OptionCall />}
                />
                <Route
                    path="/dashboard/trending-stocks/stocks-details/:id/options/option-call/next"
                    element={<OptionsCallNext />}
                />
                <Route path="/dashboard/profile/:id" element={<Profile />} />
                <Route path="/dashboard/setting" element={<Setting />} />
                <Route path="/dashboard/setting/manage-analyst-trade-list/:id" element={<ManageAnalystTradeList />} />
                <Route path="/dashboard/tier-plan/:id" element={<TierPlan />} />
                <Route path="/dashboard/analytics" element={<Analytics />} />
                <Route path="/dashboard/analytics/subscriber" element={<SubscriberPageAnalytics />} />
                <Route path="/dashboard/portfolio" element={<Portfolio />} />
                <Route path="/dashboard/portfolio/trending-stocks" element={<PortfolioTrendingStocks />} />
                <Route path="/dashboard/portfolio/holding-stocks" element={<PortfolioHoldingStocks />} />
                <Route path="/dashboard/portfolio/:id/withdrawAmt" element={<PortfolioWithdrawAmt />} />
                <Route path="/dashboard/portfolio/pending-order" element={<PendingOrder />} />
                <Route path="/dashboard/addBrokerage/:id" element={<AddBrokerage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
