export const SLIDER_IMAGES = {
    SLIDE1: '/assets/images/login_slider/slide1.png',
    SLIDE2: '/assets/images/login_slider/slide2.png',
    SLIDE3: '/assets/images/login_slider/slide3.png',
    SLIDE4: '/assets/images/login_slider/slide4.png',
};

export const IMAGE_SLIDES = [
    {
        id: 1,
        image: SLIDER_IMAGES.SLIDE1,
        image_slide_text: 'first slide',
        text: 'Join your Favorite Flock',
        sub_text: 'Subscribe to a community of like-minded investors',
    },
    {
        id: 2,
        image: SLIDER_IMAGES.SLIDE2,
        image_slide_text: 'second slide',
        text: 'Never trade alone',
        sub_text: 'Mimic trades and receive real-time notifications',
    },
    {
        id: 2,
        image: SLIDER_IMAGES.SLIDE3,
        mage_slide_text: 'third slide',
        text: 'Link your broker',
        sub_text: 'Trade without leaving Parrot',
    },
    {
        id: 2,
        image: SLIDER_IMAGES.SLIDE4,
        mage_slide_text: 'fourth slide',
        text: 'Diversify your Portfolio',
        sub_text: 'Trade stocks, ETFs, and options',
    },
];
