import React, { useState } from 'react';
import Modal from 'react-modal';
import * as CONSTANT from '../filterDropdown/constant';
import './style.scss';

const InfluencerHoldingsFiltersModal = ({ modalIsSecOpenLink, secModalClose, customStyles2 }) => {
    const [blueTickClickPercentChange, setBlueTickClickPercentChange] = useState(false);
    const [blueTickClickAvgCost, setBlueTickClickAvgCost] = useState(false);
    const [blueTickClickLowestLastPrice, setBlueTickClickLastPrice] = useState(false);
    const [blueTickClickYourEquity, setBlueTickClickYourEquity] = useState(false);
    const [blueTickClickTodaysReturn, setBlueTickClickTodaysReturn] = useState(false);
    const [blueTickClickTotalReturn, setBlueTickClickTotalReturn] = useState(false);
    const [blueTickClickTotalPercentageChange, setBlueTickClickTotalPercentageChange] = useState(false);

    return (
        <Modal
            isOpen={modalIsSecOpenLink}
            onRequestClose={secModalClose}
            style={customStyles2}
            contentLabel="Example Modal">
            <div className="container">
                <div className="">
                    <div className="d-flex justify-content-between mt-1">
                        <div className="update-dispaly-heading">Update Display Data</div>
                        <div className="update-dispaly-heading-cancel" onClick={secModalClose}>
                            Cancel
                        </div>
                    </div>
                </div>
                <hr />

                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div className="mt-3 " onClick={() => setBlueTickClickPercentChange(!blueTickClickPercentChange)}>
                        Percent Change
                    </div>
                    {blueTickClickPercentChange ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon" alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>

                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div className="mt-3" onClick={() => setBlueTickClickAvgCost(!blueTickClickAvgCost)}>
                        Avg. Cost
                    </div>
                    {blueTickClickAvgCost ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon" alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>

                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div className="mt-3" onClick={() => setBlueTickClickLastPrice(!blueTickClickLowestLastPrice)}>
                        Last Price
                    </div>
                    {blueTickClickLowestLastPrice ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon" alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>

                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div className="mt-3" onClick={() => setBlueTickClickYourEquity(!blueTickClickYourEquity)}>
                        Your Equity
                    </div>
                    {blueTickClickYourEquity ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon" alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>

                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div className="mt-3" onClick={() => setBlueTickClickTodaysReturn(!blueTickClickTodaysReturn)}>
                        Today's Return
                    </div>
                    {blueTickClickTodaysReturn ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon " alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>

                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div className="mt-3" onClick={() => setBlueTickClickTotalReturn(!blueTickClickTotalReturn)}>
                        Total Return
                    </div>
                    {blueTickClickTotalReturn ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon " alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>
                <div className="d-flex justify-content-between mt-3" style={{ cursor: 'pointer' }}>
                    <div
                        className="mt-3"
                        onClick={() => setBlueTickClickTotalPercentageChange(!blueTickClickTotalPercentageChange)}>
                        Total Percent Change
                    </div>
                    {blueTickClickTotalPercentageChange ? (
                        <img src={CONSTANT.BLUE_TIK_ICON} className="blue-tik-icon" alt="verify icon" />
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default InfluencerHoldingsFiltersModal;
