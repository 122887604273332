import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getInfluencerByID } from 'Api/influencerDashboard';
import '@fortawesome/fontawesome-free/css/all.css';
import './Profile.css';

const Profile = () => {
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const influencerByID = () => {
        getInfluencerByID(id)
            .then((response) => {
                setFetchInfluencer(response);
            })
            .catch((error) => {
                console.error(error, 'catch error');
            });
    };

    useEffect(() => {
        influencerByID();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="">
                <div className="">
                    <div className="">
                        <div
                            className="card user-card-full d-flex justify-contant-center"
                            style={{
                                borderBottomLeftRadius: '80px',
                                borderBottomRightRadius: '80px',
                                width: '90rem',
                                backgroundColor: '#DAE5E3',
                            }}>
                            <div className="">
                                <div className="col-sm-12 ">
                                    <div className="card-block text-center text-white">
                                        <div className="m-b-25  ">
                                            <img
                                                src="https://img.icons8.com/bubbles/100/000000/user.png"
                                                className="img-radius"
                                                alt="User-Profile"
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <i
                                                className="fas fa-edit "
                                                style={{ fontSize: '24px', color: 'black', cursor: 'pointer' }}
                                                onClick={() => navigate(`/dashboard/setting/edit-profile/${id}`)}></i>
                                        </div>
                                        <h6 className="f-w-600" style={{ color: 'black' }}>
                                            {ftechInfluencer.influencer &&
                                                ftechInfluencer.influencer.influencer.firstName}{' '}
                                            {ftechInfluencer.influencer &&
                                                ftechInfluencer.influencer.influencer.lastName}
                                            <span style={{ color: 'orange', fontFamily: 'initial' }}>
                                                {' '}
                                                {ftechInfluencer.influencer &&
                                                    ftechInfluencer.influencer.influencer.email}
                                            </span>
                                        </h6>
                                        <p style={{ color: 'black', fontFamily: 'serif' }}>
                                            Investing Style: Aggressive
                                        </p>
                                        <p style={{ color: 'black', fontFamily: 'serif' }}>Primarily Trades : Stocks</p>
                                        <div className="d-flex justify-content-center">
                                            <div className="m-2">
                                                <i
                                                    className="fab fa-twitter"
                                                    style={{ fontSize: '24px', color: '#33bbff' }}></i>
                                                <span style={{ color: 'orange' }}>
                                                    @
                                                    {ftechInfluencer.influencer &&
                                                        ftechInfluencer.influencer.influencer.firstName}
                                                    #45k
                                                </span>
                                            </div>

                                            <div className="m-2">
                                                <i className="fab fa-tiktok" style={{ color: 'black' }}></i>
                                                <span style={{ color: 'orange' }}>
                                                    @
                                                    {ftechInfluencer.influencer &&
                                                        ftechInfluencer.influencer.influencer.firstName}
                                                    #105k
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-block text-center">
                                    <div className="d-flex justify-content-center">
                                        <div className="">
                                            <button
                                                className="m-b-10 f-w-600 m-2"
                                                onClick={() => navigate(`/dashboard/profile/followers/${id}`)}
                                                style={{
                                                    borderRadius: '28px',
                                                    backgroundColor: 'black',
                                                    color: '#C0FF02',
                                                    height: '30px',
                                                    width: '10rem',
                                                }}>
                                                Following
                                            </button>
                                        </div>
                                        <div className=" m-2">
                                            <button
                                                className="m-b-10 f-w-600"
                                                onClick={() => navigate(`/dashboard/profile/subscribers/${id}`)}
                                                style={{
                                                    borderRadius: '28px',
                                                    backgroundColor: 'black',
                                                    color: '#C0FF02',
                                                    height: '30px',
                                                    width: '10rem',
                                                }}>
                                                Subscribed
                                            </button>
                                        </div>
                                    </div>
                                    <ul className="social-link list-unstyled m-t-40 m-b-10">
                                        <li>
                                            <a
                                                href="#!"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title=""
                                                data-original-title="facebook"
                                                data-abc="true">
                                                <i
                                                    className="mdi mdi-facebook feather icon-facebook facebook"
                                                    aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#!"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title=""
                                                data-original-title="twitter"
                                                data-abc="true">
                                                <i
                                                    className="mdi mdi-twitter feather icon-twitter twitter"
                                                    aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#!"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title=""
                                                data-original-title="instagram"
                                                data-abc="true">
                                                <i
                                                    className="mdi mdi-instagram feather icon-instagram instagram"
                                                    aria-hidden="true"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
