import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

const AnalyticsBarGraph = ({ subscriber, subscriberCount }) => {
    const subscriberList = useMemo(
        () =>
            subscriber?.map(({ month, leftSubscribers, joinSubscribers }) => ({
                month: monthArr[month],
                Unsubscribed: leftSubscribers,
                Subscribed: joinSubscribers,
            })),
        [subscriber]
    );

    const RenderCustomToolTip = ({ active, payload, label }) => {
        const payloads = payload && payload[0]?.payload;
        if (payloads?.Subscribed) subscriberCount(payloads?.Subscribed);
        return '';
    };

    return (
        <ResponsiveContainer>
            <BarChart
                width={500}
                height={300}
                data={subscriberList}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}>
                <XAxis
                    dataKey="month"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: 12, color: 'gray' }}
                    width={400}
                />
                <Tooltip
                    contentStyle={{ backgroundColor: 'black' }}
                    itemStyle={{ color: 'rgba(113, 201, 1, 1)' }}
                    cursor={false}
                    content={<RenderCustomToolTip />}
                />
                <YAxis hide axisLine={false} tickLine={false} />
                <Legend wrapperStyle={{ top: 0, left: 150 }} />
                <Bar dataKey="Subscribed" fill="rgba(211, 255, 91, 1)" barSize={15} radius={10} />
                <Bar dataKey="Unsubscribed" fill="rgba(218, 229, 227, 1)" barSize={15} radius={10} />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default AnalyticsBarGraph;
