import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';
import LoaderModal from '../Login/LoaderModal';
import Navbar from '../../Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import { getFollowing, unfollowing } from '../../Api/UserSubscription';
import FollowingModal from 'Components/reusable/model/Following/FollowingModal';
import './style.scss';

const Following = () => {
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [UnFollowingModal, setUnFollowingModal] = useState(false);
    const [followInfo, setFollowInfo] = useState({});
    const { id } = useParams();

    const influencer = {
        firstName: 'User Following',
    };

    useEffect(() => {
        const handleUserFollwing = async () => {
            setIsLoading(true);

            try {
                const response = await getFollowing();

                if (response) {
                    setFetchUserFollowing(response);
                    setIsLoading(false);
                }
                return response;
            } catch (error) {
                console.error(error);
                console.warn(error, 'catch error');
                setIsLoading(false);
            }
        };
        handleUserFollwing();
    }, []);

    const handleUnFollow = (uname, userid) => {
        setIsLoading(true);

        const follower_id = followInfo?.followingId ? followInfo?.followingId : followInfo?.userSecondId;

        const data = {
            follow: false,
        };

        swal({
            title: 'Are you sure you want to unfollow this influencer ?',
            icon: 'warning',
            buttons: {
                yes: {
                    text: 'YES',
                    value: 'yes',
                },
                no: {
                    text: 'No',
                    value: 'no',
                },
            },
        }).then((value) => {
            if (value === 'yes') {
                unfollowing(follower_id, data)
                    .then((response) => {
                        setIsLoading(false);
                        setUnFollowingModal(false);
                        window.location.reload();
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        swal('you have not subscribed to this influencer', '', 'error');
                    });
            } else {
                setUnFollowingModal(false);
                setIsLoading(false);
            }
        });
    };

    const secModalClose = () => {
        setUnFollowingModal(false);
    };

    const modalOpenOnFollow = (ele) => {
        setUnFollowingModal(true);
        setFollowInfo(ele);
        localStorage.setItem('followingName', ele.followingUserName);
        localStorage.setItem('followingId', ele.followingId);
    };
    return (
        <div>
            <div className="portfolio-trade-history">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5 mt-5">
                            {fetchUserFollowing?.following?.length > 0 ? (
                                <>
                                    {fetchUserFollowing.following.map((ele, index) => (
                                        <div className="d-flex mt-3 subscription-div p-3" key={index}>
                                            <div>
                                                <img
                                                    src={
                                                        ele?.influencerImage
                                                            ? ele?.influencerImage
                                                            : ele?.userSecondImageUrl || '/assets/images/image (1).png'
                                                    }
                                                    className="congratulation-img"
                                                    alt="img"
                                                />
                                                <img
                                                    src="/assets/images/orange_icon.png"
                                                    alt="img"
                                                    className="subscription-orange-icon"
                                                />
                                            </div>
                                            <div style={{ marginLeft: '10px' }} className="mt-2">
                                                <p
                                                    className="mt-2 subscription-influencer-name"
                                                    onClick={() => {
                                                        modalOpenOnFollow(ele);
                                                    }}>
                                                    @{ele.followingUserName}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                fetchUserFollowing?.message && (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <p>You are not following any traders yet</p>
                                    </div>
                                )
                            )}
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>
            <FollowingModal
                UnFollowingModal={UnFollowingModal}
                handleUnFollow={handleUnFollow}
                secModalClose={secModalClose}
                followInfo={followInfo}
            />

            {isLoading && <LoaderModal />}
        </div>
    );
};

export default Following;
