import api from '../util/instance';
import { urls } from './Api';

export const getPortfolioDetails = async (brokerageId) => {
    try {
        const response = await api.get(`${urls.brokerageDetails}/${brokerageId}/portfolio`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};

export const getPortfolioPositions = async (brokerageId) => {
    try {
        const response = await api.get(`${urls.brokerageDetails}/${brokerageId}/portfolio/positions`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};

export const getMe = async () => {
    try {
        const response = await api.get(`/api/users/me`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};

export const brokeragesRobinHoodPortFolio = async (getBrokerageId) => {
    try {
        const response = await api.get(`${urls.brokerageDetails}/${getBrokerageId}/portfolio`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};

export const getBrokerages = async () => {
    try {
        const response = await api.get(`${urls.brokerageDetails}`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};

export const deleteBrokerage = async (brokerageId) => {
    try {
        const response = await api.delete(`${urls.deleteBrokerage}/${brokerageId}`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};

export const pendingOrderCancel = async (getTransId, payload = {}) =>
    await api.post(`${urls.pendingOrderCanelApi}/${getTransId}/cancel`, payload);

// export const pendingOrderAPI = async (id) => await api.get(`${urls.getBrokerageById}/${id}/transactions?count=1000`);

export const pendingOrderAPI = async (brokerageId) =>
    await api.get(`${urls.getBrokerageById}/${brokerageId}/transactions?count=5`);

export const getTransactionHistory = async (brokerageId) =>
    await api.get(`${urls.getBrokerageById}/${brokerageId}/transactions/v2?count=5`);

export const Allbrokerages = async () => {
    try {
        const response = await api.get(`${urls.brokerageDetails}`);
        return response;
    } catch (error) {
        console.error('An error occurred while fetching data:', error);
        throw error;
    }
};
