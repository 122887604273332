import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import * as signUP from 'globalStore/actions/signUp';
import { forgetPassword } from 'Api/password';
import 'react-phone-input-2/lib/style.css';
import './forgetpassword.scss';

const MyModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();
    const handleClose = () => setShowModal(false);
    const dispatch = useDispatch();

    const handleShow = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    const onSubmit = () => {
        const data = {
            phoneNumber: `+${phoneNumber}`,
        };

        forgetPassword(data)
            .then((response) => {
                dispatch(signUP.forgotPassWord({ ForgetphoneNumber: phoneNumber }));
                swal('A verification code has been sent to your phone.', '', 'success');
                navigate(`/reset-code/${response.data.sessionToken}`);
            })
            .catch((error) => {
                console.log(JSON.stringify(error));
                swal('Sorry!', error.response.data.errors.message, 'error');
            });
    };

    return (
        <>
            <a className="forgot" href="/" onClick={(e) => handleShow(e)}>
                Forgot Password?
            </a>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Recover Password</Modal.Title>
                </Modal.Header>
                <PhoneInput
                    country={'us'}
                    onlyCountries={['us']}
                    prefix="+"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    placeholder="Enter phone number"
                    className="form-control"
                    inputStyle={{ width: '100%', height: '40px' }}
                    buttonStyle={{ height: '40px', top: '6px' }}
                />
                <Modal.Footer>
                    <button className="modal-close-btn rounded-pill" onClick={handleClose}>
                        Close
                    </button>
                    <button className="modal-submit-btn rounded-pill" onClick={onSubmit}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MyModal;
