import React from 'react';
import './style.scss';

const dropdownOptions = [
    { name: 'All Time', value: 'week' },
    { name: 'year', value: 'year' },
    { name: 'All Time', value: 'week' },
];

const GraphCard = ({
    selectMonthYearPerformance,
    handleChange,
    children,
    profitLoss,
    profitLossPercentage,
    profitLossColor,
    profitLossPercentageColor,
    isCardNotHighlight,
    options = dropdownOptions,
    label = 'Performance',
}) => {
    return (
        <div
            className="graph-card"
            style={{
                boxShadow: isCardNotHighlight && 'unset',
                marginTop: isCardNotHighlight && '0px',
                border: isCardNotHighlight && '0px',
            }}>
            <div className="d-flex justify-content-between p-2">
                <div className="m-2">
                    <p className="performance-text">{label}</p>
                    <p className="performance-value" style={{ color: profitLossColor }}>
                        {profitLoss || '0%'}
                    </p>

                    {profitLossPercentage && (
                        <p className="performance-profit-lossPercentage" style={{ color: profitLossPercentageColor }}>
                            {profitLossPercentage || '0%'}
                        </p>
                    )}
                </div>
                <div className="m-3">
                    <select className="performance-select" value={selectMonthYearPerformance} onChange={handleChange}>
                        {options.map((data, index) => (
                            <option value={data.value} key={index}>
                                {data.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {children}
        </div>
    );
};

export default GraphCard;
