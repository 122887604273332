export const TOGGLE_DAYS = [
    {
        active: true,
        key: '1D',
        value: 'day',
    },
    {
        active: false,
        key: '1W',
        value: 'week',
    },
    {
        active: false,
        key: '1M',
        value: 'month',
    },
    {
        active: false,
        key: '1Y',
        value: 'year',
    },
    {
        active: false,
        key: 'All',
        value: 'year',
    },
];
