import React, { useState, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import swal from 'sweetalert';
import ct from 'classnames';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from '../../pages/SignUp/constant.js';
import { useMediaQuery } from 'react-responsive';
import { forgetPassword, resetCode } from 'Api/password';
import '../../pages/SignUp/signUp.scss';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPhoneNumber } from './selector';

const ResetCode = () => {
    const [code, setCode] = useState('');
    const [skipAction, setSkipAction] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const [seconds, setSeconds] = useState(60); // otp 30 seconds countdown
    const [isActive, setIsActive] = useState(false);
    const [seshToken, setSeshToken] = useState('');
    const phoneNumber = useSelector(getPhoneNumber);

    useEffect(() => {
        console.log('seshToken->', seshToken);
    }, [seshToken]);

    useEffect(() => {
        setSeshToken(id);
    }, [id]);

    const handleTimeout = () => {
        // swal('Timed Out', 'Please click "Resend Verification Code" and try again.', 'error');
        // You can reset the timer or any other logic you need here
        setSeconds(60);
        setIsActive(false);
    };

    useEffect(() => {
        setIsActive(true);
    }, []);

    useEffect(() => {
        let interval;

        if (isActive && seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        } else if (seconds === 0) {
            handleTimeout(); // Trigger the function when countdown reaches 0
        }

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    const handleSkip = () => {
        setSkipAction(true);
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    const onResendCode = (e) => {
        setIsActive(false);
        e.preventDefault();
        const data = {
            phoneNumber: `+${phoneNumber}`,
        };
        console.log('Req data->', data);

        forgetPassword(data)
            .then((response) => {
                console.log(JSON.stringify(response));
                setSeshToken(response.data.sessionToken);
                console.log(JSON.stringify(response));
                swal('A new verification code has been sent to your phone.', '', 'success');
                setSeconds(60);
                setIsActive(true);
                // navigate(`/reset-password/${response.authFlowToken}`);
            })
            .catch((error) => {
                // alert(JSON.stringify(error));
                console.log(JSON.stringify(error));

                swal('Sorry', error.message, 'error');
            });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            phoneNumber: `+${phoneNumber}`,
            confirmationCode: code,
            sessionToken: seshToken,
        };
        console.log('Req data->', data);

        resetCode(data)
            .then((response) => {
                swal('Success', 'Please enter a new password', 'success');
                console.log('Successful reset code', JSON.stringify(response));
                navigate(`/reset-password/${response.data.authFlowToken}`);
            })
            .catch((error) => {
                // alert(JSON.stringify(error));
                console.log(JSON.stringify(error));

                if (error.response.status == 401 || error.message == 'Request failed with status code 401') {
                    swal('Wrong Code', 'Incorrect verification code. Please try again.', 'error');
                }
                if (error.response.status == 404 || error.message == 'Request failed with status code 404') {
                    swal('Timed Out', 'Please click "Resend Verification Code" and try again.', 'error');
                } else {
                    swal('Sorry', error.status, 'error');
                }
            });
    };

    return (
        <>
            <Row className="text-center user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                            </Col>

                            <div className="form-group mt-5">
                                <input
                                    className="form-control otp-input"
                                    type="number"
                                    placeholder="Enter code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>

                            <div className="mt-4">
                                <button
                                    className="btn border rounded-pill m-2 confirm-btn"
                                    onClick={(e) => onSubmit(e)}>
                                    CONFIRM
                                </button>
                                <button
                                    className="btn border  rounded-pill m-2 resend-btn"
                                    style={{ color: 'black !important' }}
                                    onClick={(e) => onResendCode(e)}>
                                    Resend Verification Code
                                </button>
                                {isActive ? (
                                    <p>Code Expires in: {seconds} seconds</p>
                                ) : (
                                    <p>Code has expired. Please resend code.</p>
                                )}
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default ResetCode;
