import api from '../util/instance';
import { urls } from './Api';

export const getCatalogLink = async () => {
    try {
        const resp = await api.get(`/api/front/auth`);

        return resp;
    } catch (error) {
        console.log(JSON.stringify(error));
        return [];
    }
};
export const getLinkToken = async () => {
    try {
        const resp = await api.get(`/api/front/linktoken`);

        return resp;
    } catch (error) {
        console.log(JSON.stringify(error));
        return [];
    }
};
export const postBrokerageAuthDetails = async (payload) => {
    try {
        const resp = await api.post(`/api/front/brokerage/details`, payload);

        return resp;
    } catch (error) {
        console.error(error);
        console.log(error);
        return [];
    }
};

export const getUserChartDataForPeriod = async (range) => {
    // range =  - day, week, month, year
    try {
        const resp = await api.get(`/api/front/user/chart?range=${range}`);

        return resp;
    } catch (error) {
        console.error(error);
        console.log(error);
        return [];
    }
};

export const getAggregatePortfolio = async () => {
    try {
        const resp = await api.get(`/api/front/user/portfolio/aggregate`);

        return resp;
    } catch (error) {
        console.error(error);
        console.log(error);
        return [];
    }
};

export const getPortfolioDetails = async (brokerageId) => {
    try {
        const resp = await api.get(`/api/front/user/brokerages/${brokerageId}/portfolio`);

        return resp;
    } catch (error) {
        console.error(error);
        console.log(error);
        return [];
    }
};

export const getTopWeeklyPerformers = async () => {
    try {
        const resp = await api.get(`/api/influencers/top/weekly`);

        return resp;
    } catch (error) {
        console.error(error);
        console.log(error);
        return [];
    }
};
