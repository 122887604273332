import React from 'react';

const PortfolioWithdrawAmt = () => {
    return (
        <>
            <div className="container">
                <div className="card">
                    <div className="card-header d-flex justify-content-center">
                        <h1>Withdraw Amount</h1>
                    </div>

                    <div
                        style={{
                            border: '1px solid #8F8F8F',
                            borderRadius: '10px',
                            backgroundColor: '#F5F8F8',
                        }}
                        className="m-3">
                        <div className="d-flex justify-content-between m-4">
                            <div className="d-flex ">
                                <div>
                                    <img src="/assets/images2/Robinhoodimg.png" alt="logo" style={{ width: '2rem' }} />{' '}
                                </div>
                                <div style={{ marginLeft: '25px' }} className="d-flex ">
                                    <div className="">
                                        <p className="" style={{ color: 'black', fontFamily: 'Figtree', opacity: 0.5 }}>
                                            Withdraw from
                                        </p>
                                        <h7
                                            className=""
                                            style={{
                                                color: 'black',
                                                marginLeft: '5px',
                                                fontFamily: 'Figtree',
                                            }}>
                                            Robinhood
                                        </h7>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginLeft: '50px' }}>
                            <i class="fas fa-angle-down "></i>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 mb-4 text-center">
                        <h2>$ 315.26</h2>
                        <p>Available Balance $ 1230.58</p>
                    </div>

                    <div
                        style={{
                            border: '1px solid #8F8F8F',
                            borderRadius: '10px',
                            backgroundColor: '#F5F8F8',
                        }}
                        className="m-3 ">
                        <div className="d-flex justify-content-between m-4">
                            <div className="d-flex ">
                                <div>
                                    <img src="/assets/images2/allybank.png" alt="logo" style={{ width: '2rem' }} />{' '}
                                </div>
                                <div style={{ marginLeft: '25px' }} className="d-flex ">
                                    <div className="">
                                        <div>
                                            <div className="d-flex">
                                                <h5
                                                    className=""
                                                    style={{ color: 'black', fontFamily: 'Figtree', opacity: 0.5 }}>
                                                    3265251203
                                                </h5>
                                                <div>
                                                    <p style={{ color: '#64A2FF', marginLeft: '5px' }}>Default</p>
                                                </div>
                                            </div>
                                        </div>

                                        <p
                                            className=""
                                            style={{
                                                color: 'black',
                                                marginLeft: '5px',
                                                fontFamily: 'Figtree',
                                            }}>
                                            John Doe | Ally Bank
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginLeft: '50px' }}>
                                <input type="radio" id="css" name="fav_language"  />
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: '1px solid #8F8F8F',
                            borderRadius: '10px',
                            backgroundColor: '#F5F8F8',
                        }}
                        className="m-3 ">
                            <h5 style={{opacity:0.5}} className='mt-2 text-center'>Other Linked Banks</h5>
                        <div className="d-flex justify-content-between m-4">
                            <div className="d-flex ">
                                <div>
                                    <img src="/assets/images2/hsbcbank.png" alt="logo" style={{ width: '2rem' }} />{' '}
                                </div>
                                <div style={{ marginLeft: '25px' }} className="d-flex ">
                                    <div className="">
                                        <div>
                                            <div className="d-flex">
                                                <h5
                                                    className=""
                                                    style={{ color: 'black', fontFamily: 'Figtree', opacity: 0.5 }}>
                                                    6542551203
                                                </h5>
                                               
                                            </div>
                                        </div>

                                        <p
                                            className=""
                                            style={{
                                                color: 'black',
                                                marginLeft: '5px',
                                                fontFamily: 'Figtree',
                                            }}>
                                            John Doe | HSBC Bank
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginLeft: '50px' }}>
                                <input type="radio" id="react" name="fav_language"  />
                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            border: '1px solid #8F8F8F',
                            borderRadius: '10px',
                            backgroundColor: '#F5F8F8',
                        }}
                        className="m-3 ">
                        <div className="d-flex justify-content-between m-4">
                            <div className="d-flex ">
                                <div>
                                    <img src="/assets/images2/citibank.png" alt="logo" style={{ width: '2rem' }} />{' '}
                                </div>
                                <div style={{ marginLeft: '25px' }} className="d-flex ">
                                    <div className="">
                                        <div>
                                            <div className="d-flex">
                                                <h5
                                                    className=""
                                                    style={{ color: 'black', fontFamily: 'Figtree', opacity: 0.5 }}>
                                                    8865251203
                                                </h5>
                                                
                                            </div>
                                        </div>

                                        <p
                                            className=""
                                            style={{
                                                color: 'black',
                                                marginLeft: '5px',
                                                fontFamily: 'Figtree',
                                            }}>
                                            Sam Monic | City Bank
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginLeft: '50px' }}>
                                <input type="radio" id="html" name="fav_language"  />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 mb-4">
                                <button
                                    className="btn tex-center"
                                    style={{
                                        width: '20rem',
                                        borderRadius: '15px',
                                        color: 'black',
                                        backgroundColor: '#D3FF5B',
                                    }}
                                  
                                    >
                                  Withdraw
                                </button>
                            </div>
                </div>
            </div>
        </>
    );
};

export default PortfolioWithdrawAmt;
