import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: {},
};

const authSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        userLoggedIn: (state, action) => {
            const { payload } = action;
            const { userInfo } = state;
            state.userInfo = {
                ...userInfo,
                isLoggedIn: true,
                ...payload,
            };
        },
        userLoggedOut: (state) => {
            state.userInfo = {};
        },
    },
});

export const { userLoggedIn, userLoggedOut } = authSlice.actions;

export default authSlice.reducer;
