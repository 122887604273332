export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '637px',
        height: '540px',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        padding: '0px',
        position: 'absolute',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    },
};
