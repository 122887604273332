import React from 'react';
import Modal from 'react-modal';
import * as CONSTANT from 'pages/Portfolio/constant';

const CancerOrder = ({ isOpened = false, onModelClose, onYes, onNO }) => {
    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={() => onModelClose(false)}
            style={CONSTANT.CUSTOM_STYLES4}
            contentLabel="Order-Cancel">
            <div
                style={{
                    borderRadius: '25px 25px 0px 0px',
                    backgroundColor: '#FFF',
                }}
                className="p-3 mt-3">
                <div
                    className=""
                    style={{
                        marginLeft: '8rem',
                    }}>
                    <img
                        src="/assets/Portfolio/card-remove.png"
                        alt="card remove"
                        style={{
                            width: '51px',
                            height: '51px',
                        }}
                    />
                </div>
                <div
                    className="text-center mt-3 "
                    style={{
                        marginLeft: '2rem',
                    }}>
                    <p
                        style={{
                            color: '#1F1F1F',
                            fontStyle: 'normal',
                            fontSize: '20px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            width: '230px',
                        }}>
                        Are you sure you want to cancel order ?
                    </p>
                </div>
                <div
                    className=" mt-3 "
                    style={{
                        marginLeft: '1rem',
                    }}>
                    <button
                        style={{
                            width: '275px',
                            color: '#1F1F1F',
                            backgroundColor: '#FFF',
                            borderRadius: '54px',
                            height: '46px',
                            border: '1px solid #1F1F1F',
                            textTransform: 'uppercase',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            letterSpacing: '0.7px',
                            cursor: 'pointer',
                        }}
                        onClick={onNO}>
                        NO
                    </button>
                </div>
                <div
                    className=" mt-3 "
                    style={{
                        marginLeft: '1rem',
                    }}>
                    <button
                        style={{
                            width: '275px',
                            color: '#FFF',
                            backgroundColor: '#FF5445',
                            borderRadius: '54px',
                            height: '46px',
                            border: 'none',
                            textTransform: 'uppercase',
                            fontStyle: 'normal',
                            fontSize: '14px',
                            fontFamily: 'Figtree',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            letterSpacing: '0.7px',
                            cursor: 'pointer',
                        }}
                        onClick={onYes}>
                        YES
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CancerOrder;
