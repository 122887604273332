import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import './style.scss';

const NotAddedPaymentMethod = ({ isOpened, onModelClose, setCloseModel, setPaymentCardOpen }) => {
    return (
        <Modal
            isOpen={isOpened}
            onRequestClose={onModelClose}
            style={customStyles}
            contentLabel="not added payment method">
            <div className="not-added-payment-method p-3">
                <div class="mt-2 text-center">
                    <img src="/assets/Influencer/BankHouse_icon.png" className="bank-house-icon" alt="BankHouse" />
                    <p className="text mt-2">You haven’t added any payment method yet please add one to subscribe</p>
                </div>

                <div class=" d-flex justify-content-center px-2 rounded mt-4 date ">
                    <span class="join">
                        <button className="btn btn-sm m-2 cancel-btn" onClick={() => setCloseModel(false)}>
                            CANCEL
                        </button>

                        <button
                            className="btn btn-sm m-2 connect-btn"
                            onClick={() => {
                                setCloseModel(false);
                                setPaymentCardOpen(true);
                            }}>
                            CONNECT
                        </button>
                    </span>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(NotAddedPaymentMethod);
