import React from 'react';
import Modal from 'react-modal';

const MimicFinalQueued = ({
    modalQueuedIsOpenLimitOrder,
    setModalQueuedIsOpenLimitOrder,
    customStyles5,
    fetchStocksData,
    LimitOrderInput,
    handleBuyCostLimitOrder,
}) => {
    const secModalClose = () => {
        setModalQueuedIsOpenLimitOrder(false);
    };
    return (
        <div>
            {' '}
            <Modal
                isOpen={modalQueuedIsOpenLimitOrder}
                onRequestClose={secModalClose}
                style={customStyles5}
                contentLabel="Example Modal">
                <div
                    style={{
                        width: '490px',
                        height: '171px',
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className="p-3 ">
                    <div className="d-flex justify-content-center" style={{ marginTop: '10px' }}>
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',

                                right: '2.5rem',
                            }}>
                            Order Queued
                        </p>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                                width: '310px',
                                right: '3.5rem',
                            }}>
                            Your order to buy ${LimitOrderInput} of MSFT has been queued until market open
                        </p>
                    </div>

                    <div style={{ top: '1rem' }}>
                        <div style={{ backgroundColor: '#FFFFFF' }}>
                            <div className=" " style={{ marginTop: '25px' }}>
                                <div className="d-flex justify-content-evenly " style={{ right: '2.5rem' }}>
                                    <div className="">
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                                right: '8px',
                                            }}
                                            className="m-1">
                                            Stock
                                        </p>
                                    </div>

                                    <div className="d-flex" style={{ marginLeft: '9rem' }}>
                                        <img
                                            src={fetchStocksData?.details?.logoUrl}
                                            style={{ width: '25px', height: '25px' }}
                                            alt="img"
                                        />
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1">
                                            {fetchStocksData?.details?.ticker}
                                        </p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mt-4" style={{ left: '1.5rem' }}>
                                    <div className="" style={{ marginLeft: '2px' }}>
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                                // marginLeft: '5px',
                                                right: '8px',
                                            }}>
                                            Broker
                                        </p>
                                    </div>

                                    <div className="d-flex" style={{ right: '8.3rem' }}>
                                        {' '}
                                        <img
                                            src="/assets/images2/Robinhoodimg.png"
                                            style={{ width: '17px', height: '23px' }}
                                            alt="img"
                                        />
                                        <p
                                            style={{
                                                color: '#1F1F1F',
                                                fontStyle: 'normal',
                                                fontSize: '20px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginLeft: '5px',
                                            }}>
                                            Robinhood
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-evenly mt-2" style={{ left: '0.5rem' }}>
                            <div>
                                <p
                                    style={{
                                        color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                        fontStyle: 'normal',
                                        fontSize: '16px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                        right: '4.5rem',
                                    }}>
                                    Limit Price
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            top: '5px',
                                        }}>
                                        Execute during market hours
                                    </p>
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '12px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                            bottom: '7px',
                                        }}>
                                        Expire in 90 days
                                    </p>
                                </p>
                            </div>
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '16px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    right: '2.3rem',
                                }}>
                                $ {LimitOrderInput}
                            </p>
                        </div>
                    </div>

                    <div className="d-flex justify-content-evenly " style={{ right: '2.5rem', marginTop: '40px' }}>
                        <button
                            style={{
                                width: '150px',
                                height: '52px',
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                borderRadius: '54px',
                                letterSpacing: '0.7px',
                                backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
                                left: '5px',
                            }}>
                            EDIT
                        </button>

                        <button
                            style={{
                                width: '150px',
                                height: '52px',
                                color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                borderRadius: '54px',
                                letterSpacing: '0.7px',
                                backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                right: '18px',
                            }}
                            onClick={(e) => handleBuyCostLimitOrder(e)}>
                            DONE
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MimicFinalQueued;
