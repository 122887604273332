import api from '../util/instance';
import { urls } from './Api';

export const getUtilHomeData = async () => {
    try {
        const resp = await api.get(`/api/util/home`);

        return resp;
    } catch (error) {
        console.log(JSON.stringify(error));
        return [];
    }
};

export const getPortfolioMetrics = async () => {
    try {
        const resp = await api.get(`/api/front/user/portfolio/metrics`);

        return resp;
    } catch (error) {
        console.log(JSON.stringify(error));
        return [];
    }
};
