import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';

const CommonModal = ({ isOpen, onModelClose, text, onYes, onNo, btn1Text, btn2Text }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onModelClose} style={customStyles} contentLabel="Example Modal">
            <div className="container">
                <div className="row">
                    <div className="text-center ">
                        <div className="text-center ">
                            <p className=" w-100 mt-5 accept-para">{text}</p>

                            {onYes ? (
                                <button
                                    className=" mt-3"
                                    style={{
                                        width: '180px',
                                        height: '46px',
                                        textDecoration: 'none',
                                        textTransform: 'uppercase',
                                        backgroundColor: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                        borderRadius: '54px',
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',

                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        letterSpacing: '0.7px',
                                        cursor: 'pointer',
                                        border: 'none',
                                    }}
                                    onClick={onYes}>
                                    {btn1Text}
                                </button>
                            ) : (
                                ''
                            )}

                            {onNo ? (
                                <button
                                    className=" mt-3"
                                    style={{
                                        width: '180px',
                                        height: '46px',
                                        textDecoration: 'none',
                                        textTransform: 'uppercase',
                                        backgroundColor: '#FFF',
                                        borderRadius: '54px',
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        border: '1px solid  #1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        letterSpacing: '0.7px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onNo}>
                                    {btn2Text}
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(CommonModal);
