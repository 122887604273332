import React from 'react';
import './style.scss';

const LargestWin = ({ largestWin, breakevenTrades, getAvgTrade, largestLoss }) => {
    return (
        <div className="mt-3 w-100 influencer-detail-largest-win">
            <div className="d-flex breaking-trade-info w-100">
                <div className="media-bottom-margin w-100 m-2">
                    <div className="p-3 largest-win-block">
                        <div>
                            <p className="largest-win-label">
                                {largestWin || 0}%
                                <div className="d-flex">
                                    <img src="/assets/images2/TRENDINGuP.png" className="grow-icon" alt="trending" />
                                    <p className="largest-win-text">Largest Win</p>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="p-3 breakeven-trades">
                            <div className="">
                                <img className="calender-icon" src="/assets/images2/calender_icon.png" alt="calender" />
                            </div>
                            <div className="mt-1">
                                <p className="breakeven-number">
                                    {breakevenTrades || 0}
                                    <p className="breakeven-trades-label">Breakeven Trades</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100 m-2">
                    <div className="p-3 breakeven-trades">
                        <div>
                            <img src="/assets/images2/white_symbol.png" className="calender-icon" alt="whitesymbol" />
                        </div>
                        <div className="mt-1">
                            <p className="breakeven-number">
                                {getAvgTrade?.length === 0 ? (
                                    <>
                                        <p className="avgtrade-text">no trades to show for this influencer</p>
                                    </>
                                ) : (
                                    getAvgTrade?.averageTrades?.toFixed(2) || 0
                                )}
                                <p className="breakeven-trades-label">Avg. Trades / week</p>
                            </p>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="p-3 largest-win-block">
                            <div>
                                <p className="largest-win-label">
                                    {largestLoss || 0}%
                                    <div className="d-flex">
                                        <img
                                            src="/assets/images2/trendingDown.png"
                                            className="grow-icon"
                                            alt="trendingdown"
                                        />
                                        <p className="largest-win-text">Largest Loss</p>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LargestWin;
