import React from 'react';
import ProfilePicture from './profilePicture';
import './style.scss';

const NormalUserProfile = ({
    profileImg,
    setModalUpdatePhotoOpen,
    fname,
    setFname,
    lname,
    setLname,
    username,
    setUsername,
    email,
    setEmail,
    updateProfilePic,
}) => {
    return (
        <div>
            <ProfilePicture
                picture={profileImg}
                modelOpen={() => {
                    setModalUpdatePhotoOpen(true);
                }}
            />

            <div className="mt-5">
                <div className="d-flex justify-content-between mt-3">
                    <input
                        type="text"
                        className="form-control me-3 input-control"
                        placeholder="First Name"
                        value={fname || ''}
                        onChange={(e) => setFname(e.target.value)}
                    />

                    <input
                        type="text"
                        className="form-control ms-3 input-control"
                        placeholder="Last Name"
                        value={lname || ''}
                        onChange={(e) => setLname(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        className="form-control mt-4 input-control"
                        placeholder="User Name"
                        value={username || ''}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <input
                        type="text"
                        className="form-control mt-4 input-control"
                        placeholder="Email"
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <button
                    style={{
                        marginTop: '10rem',
                    }}
                    className="save-button"
                    onClick={() => updateProfilePic()}>
                    SAVE
                </button>
            </div>
        </div>
    );
};

export default NormalUserProfile;
