import api from '../util/instance';
import { urls } from './Api';

const getBrokerageId = localStorage.getItem('getBrokerageId');

export const InfluencerSubscriber = async () => {
    return await api.get(urls.InfluencersubscriberApi);
};

export const InfluencerFollowing = async () => {
    return await api.get(urls.InfluencerfollowerApi);
};
export const getNews = async () => {
    return await api.get(urls.fetchNews);
};

export const getTopSpotlight = async () => {
    return await api.get(urls.TopSpotlight);
};

export const getTopInfluencer = async () => {
    return await api.get(urls.fetchInfluencer);
};

export const getInfluencerByID = async (id) => {
    return await api.get(`${urls.fetchInfluencerById}/${id}`);
};

export const getBuyingPower = async (id) => {
    return await api.get(`${urls.brokerageDetails}/${id}`);
};

export const getRefferal = async () => {
    return await api.post(urls.CreateReferal);
};

export const getInfluencerGraph = async (influencerId) => {
    return await api.get(`${urls.getInfluencerGraph}/${JSON.parse(influencerId)}`);
};

export const getAPIFrontUserChart = async (input) => {
    return await api.get(`${urls.frontUserChart}?range=${input}`);
};
