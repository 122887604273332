import React, { useState, useLayoutEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import ct from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from './constant';
import { getAuthFlowToken } from './selector';
import { registerEmail } from 'Api/registration';
import { signUpCredential } from 'globalStore/actions/signUp';

const SignupEmail = () => {
    const [skipAction, setSkipAction] = useState(false);
    const [email, setEmail] = useState('');
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const id = useSelector(getAuthFlowToken);
    const dispatch = useDispatch();

    const handleSkip = () => {
        setSkipAction(true);
    };

    const navigate = useNavigate();

    const handleEmail = (e) => {
        e.preventDefault();
        const regex = /^[^0-9][^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(email)) {
            swal('Please Enter Valid Email Id.', '', 'error');
            return;
        }
        const data = {
            email: email,
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + id,
            },
        };

        registerEmail(data, config)
            .then((response) => {
                setEmail(response);
                dispatch(
                    signUpCredential({
                        authFlowToken: response.authFlowToken,
                    })
                );
                navigate(`/signup/code/email/username`);
            })
            .catch((error) => {
                swal(error.response.data.errors.message, '', 'error');
            });
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <>
            <Row className="user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container m-auto', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                            </Col>

                            <div className="form-group mt-5">
                                <input
                                    className="form-control otp-input"
                                    type="text"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>

                            <div className="mt-4">
                                <button
                                    className="btn border border-secondary rounded-pill m-2 confirm-btn"
                                    onClick={handleEmail}>
                                    CONFIRM
                                </button>
                            </div>
                        </Form>
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export default SignupEmail;
