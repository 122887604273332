import React from 'react';
import Modal from 'react-modal';
import { customStyles8 } from './Style.js';
import './style.scss';

const FollowingModal = ({ UnFollowingModal, secModalClose, followInfo, handleUnFollow }) => {
    return (
        <div>
            <Modal
                isOpen={UnFollowingModal}
                onRequestClose={secModalClose}
                style={customStyles8}
                contentLabel="Example Modal">
                <div
                    style={{
                        height: '171px',
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className="p-3 ">
                    <div className="d-flex justify-content-between" style={{ width: '330px' }}>
                        <div className="d-flex justify-content-between mt-3" style={{ marginLeft: '15px' }}>
                            <div>
                                <img
                                    src={
                                        followInfo?.influencerImage
                                            ? followInfo?.influencerImage
                                            : followInfo?.userSecondImageUrl || '/assets/images/image (1).png'
                                    }
                                    style={{ width: '48px', height: '48px', borderRadius: '48px' }}
                                    alt="img"
                                />
                            </div>
                            <div style={{ marginLeft: '10px' }} className="mt-2">
                                <p className="following-username">{followInfo.followingUserName}</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <button className="btn-block">BLOCK</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <button
                            onClick={() => {
                                const localFollowingId = localStorage.getItem('followingId');
                                handleUnFollow(localFollowingId);
                            }}
                            className="unfollow-btn">
                            UNFOLLOW
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FollowingModal;
