import React from 'react';
import ct from 'classnames';
import Carousel from 'react-bootstrap/Carousel';
import * as CONSTANT from './constant';
import './carousal.scss';

const LoginSlider = () => {
    return (
        <>
            <Carousel className={ct('login-carousal')}>
                {CONSTANT.IMAGE_SLIDES?.map((slide) => (
                    <Carousel.Item key={slide?.id}>
                        <img
                            className={ct('w-100', 'h-50', 'object-fit-contain')}
                            src={slide?.image}
                            alt={slide?.image_slide_text}
                        />
                        <Carousel.Caption>
                            <h2>{slide?.text}</h2>
                            <p>{slide?.sub_text}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
};

export default React.memo(LoginSlider);
