import React, { useEffect, useState, lazy, Suspense } from 'react';
import MediaQuery from 'react-responsive';
import swal from 'sweetalert';
import MobileNavBarWithInfluencer from '../../Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoaderModal from '../Login/LoaderModal';
import Navbar from '../../Components/Navbar/Navbar';
import SideNotificationComponent from '../../Components/DashBoard/sideNotification/SideNotificationComponent';
import { fetchUserFollowingApi } from 'Api/setting';
import { updateFollow } from 'Api/dashboard';
import { getAggregatePortfolio } from 'Api/front';
import { getPortfolioMetrics } from 'Api/util';
import ComponentLoading from 'Components/reusable/loading/Loading';
import { getProfitLossGraphUser } from 'Api/UserDashboard';
import FollowersUserCard from 'Components/reusable/portfolio/cards/followersUserCard';
import GraphCard from 'Components/reusable/influencer/cards/graphCard';
import TotalChange from 'Components/reusable/endUserProfile/cards/totalChange';
import HeadingWrapper from 'Components/reusable/portfolio/headingWrapper';
import TopTradesCard from 'Components/reusable/endUserProfile/cards/topTrades';
import './EndUserProfile.scss';

const AllPositionProfitLossGraph = lazy(() =>
    import('Components/reusable/graph/AllpositionProfitLossGraph/AllPositionProfitLossGraph')
);

const EndUserProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [fetchUserFollowing, setFetchUserFollowing] = useState([]);
    const [followUser, setFollowUser] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [aggregatedPortfolio, setAggregatedPortfolio] = useState({});
    const [topTrades, setTopTrades] = useState([]);
    const [portfolioMetrics, setPortfolioMetrics] = useState({});
    const [selectMonthYearPerformance, setSelectMonthYearPerformance] = useState('week');
    const [fetchProfitLossGraphData, setFetchProfitLossGraphData] = useState([]);
    const { state } = location;
    const selectedTimeFrame = 'totalMetrics';
    const followerName = state?.followerName;
    const follwerid = state?.id;

    const fetchTopWeeklyPerformers = async () => {
        setIsLoading(true);
        const resp = await getPortfolioMetrics();
        setIsLoading(false);

        setPortfolioMetrics(resp);
    };

    useEffect(() => {
        fetchTopWeeklyPerformers();
    }, []);

    const fetchAggregatedPortfolio = async () => {
        setIsLoading(true);
        const resp = await getAggregatePortfolio();
        const equityPositions = resp.content.equityPositions;
        const sortedEquities = [...equityPositions].sort((a, b) => b.returnPercentage - a.returnPercentage);
        const topEquities = sortedEquities.slice(0, 3);

        setIsLoading(false);

        setTopTrades(topEquities);

        setAggregatedPortfolio(resp.content);
    };

    const fetchDataweek = async () => {
        try {
            const response = await getProfitLossGraphUser(selectMonthYearPerformance);

            setFetchProfitLossGraphData(response);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDataweek();

        return () => {
            setFetchProfitLossGraphData([]);
        };
    }, [selectMonthYearPerformance]);

    useEffect(() => {
        fetchAggregatedPortfolio();
    }, []);
    const influencer = {
        firstName: 'Home',
    };

    const getUserFollowing = () => {
        fetchUserFollowingApi()
            .then((response) => {
                setFetchUserFollowing(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getUserFollowing();
    }, []);

    const getProfitLossPercentageDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        return isPositive ? `+${value?.toFixed(2)}%` : `${value?.toFixed(2)}%`;
    };

    const handleFollowBtn = async (value) => {
        setFollowUser(!followUser);

        const data = {
            follow: value,
        };

        try {
            const response = await updateFollow(follwerid, data);
            swal(!value ? 'you are now unfollowing this user' : 'you are now following this user', '', 'success');
            return response;
        } catch (error) {
            swal(error?.response?.data?.errors?.message || 'An error occurred', '', 'error');
        }
    };

    const getColorBasedOnValue = (value) => (value > 0 ? 'green' : 'red');

    const getProfitLossDisplay = (value) => {
        if (isNaN(value)) {
            return '';
        }
        const isPositive = value > 0;
        const formatted = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(value);

        return isPositive ? `+${formatted}` : `${formatted}`;
    };

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectMonthYearPerformance(selectedValue);
    };
    return (
        <div>
            <div className="user-dashboard">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>

                {isLoading ? (
                    <div className="d-flex justify-content-center align-item-center  ">
                        {isLoading && <LoaderModal />}
                    </div>
                ) : (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <MediaQuery minWidth={769}>
                                    <div className="col-xl-1 col-lg-2">
                                        <Navbar activeIconHome={false} />
                                    </div>
                                </MediaQuery>
                                <div className="col-xl-8 col-lg-9 middle-container">
                                    <div className="w-100">
                                        <FollowersUserCard
                                            username={followerName}
                                            followerCount={fetchUserFollowing?.following?.length}
                                            followingCount={fetchUserFollowing?.following?.length}
                                            profitLoss={0}
                                            isShowFollowBtn
                                            followUser={followUser}
                                            onclickFollowBtn={(value) => {
                                                handleFollowBtn(value);
                                            }}
                                        />
                                    </div>

                                    <div className="mt-4">
                                        <div className="pt-2 position-trader-container h-auto pb-3">
                                            <div>
                                                <div className="mt-4 d-flex top-trades">
                                                    <div className="w-100 me-2">
                                                        <GraphCard
                                                            selectMonthYearPerformance={selectMonthYearPerformance}
                                                            handleChange={handleChange}
                                                            profitLoss={getProfitLossDisplay(
                                                                portfolioMetrics[selectedTimeFrame]?.profitLoss
                                                            )}
                                                            profitLossColor={getColorBasedOnValue(
                                                                portfolioMetrics[`${selectedTimeFrame}`]?.profitLoss
                                                            )}
                                                            profitLossPercentage={getProfitLossPercentageDisplay(
                                                                portfolioMetrics[`${selectedTimeFrame}`]
                                                                    ?.profitLossPercentage
                                                            )}
                                                            profitLossPercentageColor={getColorBasedOnValue(
                                                                portfolioMetrics[`${selectedTimeFrame}`]
                                                                    ?.profitLossPercentage
                                                            )}
                                                            isCardNotHighlight>
                                                            <Suspense fallback={<ComponentLoading />}>
                                                                <AllPositionProfitLossGraph
                                                                    fetchProfitLossGraphData={fetchProfitLossGraphData}
                                                                    selectMonthYearPerformance={
                                                                        selectMonthYearPerformance
                                                                    }
                                                                />
                                                            </Suspense>
                                                        </GraphCard>
                                                    </div>

                                                    <div className="w-100 ms-2 stock-list">
                                                        <TotalChange
                                                            equitiesValue={new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                            }).format(aggregatedPortfolio?.equitiesValue)}
                                                            actualPortfolioPerformance={getProfitLossPercentageDisplay(
                                                                aggregatedPortfolio?.actualPortfolioPerformance
                                                            )}
                                                        />

                                                        <HeadingWrapper
                                                            labelHeading="Top Trades"
                                                            label="View All"
                                                            onClickViewAll={() =>
                                                                navigate(`/dashboard/trending-stocks`)
                                                            }>
                                                            <div className="w-100">
                                                                {Array.isArray(topTrades) &&
                                                                    topTrades
                                                                        .slice(0, 3)
                                                                        .map((ele, index) => (
                                                                            <TopTradesCard
                                                                                key={index}
                                                                                logoUr={ele?.stockDetails?.logoUrl}
                                                                                symbol={ele.symbol}
                                                                                amount={ele?.amount}
                                                                                totalReturn={ele?.totalReturn}
                                                                                returnPercentage={ele?.returnPercentage}
                                                                            />
                                                                        ))}
                                                            </div>
                                                        </HeadingWrapper>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <MediaQuery minWidth={1025}>
                                    <div className="col-xl-3 col-lg-3 pe-0">
                                        <div className="scroll-sideNotification side-notification">
                                            <SideNotificationComponent />
                                        </div>
                                    </div>
                                </MediaQuery>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EndUserProfile;
