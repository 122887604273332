import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const VerifiedTrader = ({ onClickLink, profilePicture, userName }) => {
    return (
        <div className="text-center m-3 verified-trader">
            <Link
                to={`/dashboard/explore-inflencer/influencer-detail`}
                onClick={onClickLink}
                className="verified-trader-link">
                <div className="card-body text-center">
                    <img
                        src={profilePicture || '/assets/images/image (1).png'}
                        className="img-radius verified-trader-profile-icon"
                        alt="img"
                    />

                    <img src="/assets/images/blue_icon.png" alt="blue icon" className="verified-blue-icon" />

                    <p className="verified-trader-username mt-2">{userName}</p>
                </div>
            </Link>
        </div>
    );
};

export default VerifiedTrader;
