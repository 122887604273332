import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CustomNavbar from './Navbar';
import MobileNotification from 'Components/reusable/model/mobileNotification';
import './style.scss';

function MobileNavbar() {
    return (
        <>
            {[false].map((expand) => (
                <Navbar key={expand} expand={expand} className="bg-body-tertiary mobile-nav">
                    <Container fluid className="navbar-container">
                        <Navbar.Brand href="#">
                            <div className="mb-3 ml-1">
                                <img
                                    src="/assets/dashboardImg/logoCrop512 2.png"
                                    alt="Profile"
                                    width="50"
                                    height="50"
                                    className="userImage"
                                />
                            </div>
                        </Navbar.Brand>

                        <Navbar.Brand className="navbar-notification">
                            <MobileNotification />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end">
                            <Offcanvas.Body>
                                <CustomNavbar />
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default MobileNavbar;
