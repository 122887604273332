import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import ct from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import LoginSlider from 'Components/reusable/LoginSlider';
import * as CONSTANT from './constant';
import { fetchAvatar } from 'Api/setting';
import { registerAvatar } from 'Api/registration';
import { getAuthFlowToken } from './selector';
import { signUpCredential } from 'globalStore/actions/signUp';

const ProfilePicGallery = () => {
    const [skipAction, setSkipAction] = useState(false);
    const [getAvtar, setGetAvtar] = useState([]);
    const [updatePic, setUpdatePic] = useState('');
    const [isPicUpdate, setIsPicUpdate] = useState(false);
    const id = useSelector(getAuthFlowToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const handleSkip = () => {
        setSkipAction(true);
    };

    useEffect(() => {
        fetchAvatar()
            .then((response) => {
                setGetAvtar(response);
            })
            .catch((error) => {
                console.error(error, 'catch error');
            });
    }, []);

    const handleProfilePic = (e) => {
        e.preventDefault();
        const data = {
            imageUrl: updatePic,
        };

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + id,
            },
        };

        registerAvatar(data, config)
            .then((response) => {
                swal('Image update successful', '', 'success');
                dispatch(
                    signUpCredential({
                        authFlowToken: response.authFlowToken,
                    })
                );
                navigate(`/signup/code/email/username/profilePic/profilegallery/password`);
            })
            .catch((error) => {
                swal('sorry', error.message, 'error');
            });
    };

    useLayoutEffect(() => {
        if (isTablet) {
            setSkipAction(true);
        }
    }, [isTablet]);

    return (
        <div>
            <Row className="user-registration-otp">
                <Col lg="6" className="first-block">
                    {!skipAction && (
                        <>
                            <LoginSlider />
                            <Button className={ct('skip-button')} onClick={handleSkip}>
                                Skip
                            </Button>
                        </>
                    )}
                </Col>

                <Col lg="6" md="12" className={ct('form-container', { isVisible: !skipAction })}>
                    <Col lg="7" md="10">
                        <Form>
                            <Col className="parrot-logo">
                                <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />

                                <h5 className="mt-5">Add a Profile Avatar</h5>
                            </Col>

                            <div className="form-group mt-5">
                                <Col className={ct('text-center')}>
                                    <img
                                        src={isPicUpdate ? updatePic : '/assets/images2/Rectangle 42824 (1).png'}
                                        className="sign-up-user-image"
                                        alt="Profile"
                                    />
                                </Col>
                            </div>
                        </Form>

                        <div className="mt-5 p-3 sign-up-scroller-profile-image">
                            <div className="d-flex justify-content-start mt-2">
                                <p className="sign-up-select-avatar-label">Select Avatar</p>
                            </div>

                            <div
                                className="d-flex justify-content-between scroll-avatar"
                                style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                {getAvtar &&
                                    getAvtar.map((ele, index) => {
                                        return (
                                            <div key={index} className="sign-up-map-image">
                                                <img
                                                    src={ele.imageUrl}
                                                    alt={`Avatar ${index}`}
                                                    onClick={() => {
                                                        setIsPicUpdate(true);
                                                        setUpdatePic(ele.imageUrl);
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>

                            <div class="text-center rounded mt-4 date">
                                <span class="join">
                                    <button
                                        className="btn btn-sm m-2 sign-in-update-avatar-btn"
                                        onClick={handleProfilePic}>
                                        UPDATE
                                    </button>
                                </span>
                            </div>
                        </div>
                    </Col>
                </Col>
            </Row>
        </div>
    );
};

export default ProfilePicGallery;
