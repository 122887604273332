import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import Navbar from 'Components/Navbar/Navbar';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import { getTopSpotlight } from 'Api/influencerDashboard';
import WelcomeSection from 'Components/reusable/welcomeSection';
import { getUserId } from 'pages/analytics/slector';
import Spotlight from 'Components/reusable/influencer/cards/spotlight';

const TradeSpotlight = () => {
    const [fetchTradeSpotlight, setFetchTradeSpotlight] = useState([]);
    const id = useSelector(getUserId);

    useEffect(() => {
        const handleTopHolding = async (id) => {
            try {
                const response = await getTopSpotlight();

                if (response) {
                    setFetchTradeSpotlight(response);
                }
                return response;
            } catch (error) {
                console.error(error);
            }
        };
        handleTopHolding(id);
    }, [id]);

    const influencer = {
        firstName: 'Trade Spotlight',
    };

    return (
        <div>
            <div className="portfolio-trade-history">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5">
                            <div className="row d-flex justify-content-center mt-3">
                                <div className="d-flex">
                                    {fetchTradeSpotlight?.influencers?.map((ele, index) => (
                                        <Spotlight
                                            key={index}
                                            profilePicture={ele.profilePicture}
                                            twitterFollowers={ele.twitterFollowers}
                                            firstName={ele.firstName}
                                            lastName={ele.lastName}
                                            returns={0}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TradeSpotlight;
