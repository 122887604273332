import React, { useState, useLayoutEffect, Suspense } from 'react';
import ct from 'classnames';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as CONSTANT from './constant';
import { urls } from 'Api/Api';
import ForgetPassword from '../../Components/ForgetPassword/ForgetPassword';
import LoaderModal from './LoaderModal';
import LoginSlider from 'Components/reusable/LoginSlider';
import messages from './messages';
import PasswordEye from 'Components/reusable/PasswordEye';
import api from '../../util/instance';
import './Login.scss';
import { isAllNumbers } from '../../util/strings.util';
import ComponentLoading from 'Components/reusable/loading/Loading';
import CommonModal from 'Components/reusable/settings/commonModal';
import { useEffect } from 'react';
import { userLoggedIn } from 'globalStore/actions/login';
import { useDispatch } from 'react-redux';

const Login = () => {
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skipAction, setSkipAction] = useState(false);
    const [UserNameError, setUserNameError] = useState(false);
    const [PasswordError, setPasswordError] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loggedInErr, setLoggedInErr] = useState(false);
    const [loginRes, setLoginRes] = useState({});
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        localStorage.clear();
    }, []);

    useEffect(() => {
        if (loggedIn) {
            handleLoggedIn(loginRes);
        }
    }, [loggedIn]);

    async function loginUser(credentials) {
        return await api.post(urls.Login, credentials);
    }

    const handleLoggedIn = (response) => {
        // TODO : Temp Data need to remove in future
        localStorage.setItem('role', response.user.role);
        localStorage.setItem('username', response.user.username);
        localStorage.setItem('firstname', response.user.firstname);
        localStorage.setItem('lastname', response.user.lastname);
        localStorage.setItem('email', response.user.email);
        localStorage.setItem('userData', JSON.stringify(response.id));
        localStorage.setItem('userAccessToken', JSON.stringify(response.access));
        localStorage.setItem('refresh', JSON.stringify(response.refresh));
        localStorage.setItem('userId', JSON.stringify(response.userId));
        localStorage.setItem('active', 'Home');

        dispatch(
            userLoggedIn({
                role: response.user.role,
                username: response.user.username,
                firstname: response.user.firstname,
                lastname: response.user.lastname,
                email: response.user.email,
                userData: response.id,
                userAccessToken: response.access,
                refresh: response.refresh,
                userId: response.userId,
                active: 'Home',
            })
        );

        navigate(`/dashboard`, { state: { loggedIn: true } });
        setLoggedIn(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (username === '') {
            setUserNameError(true);
            setIsLoading(false);
            return;
        }

        if (pass === '') {
            setPasswordError(true);
            setIsLoading(false);
            return;
        }

        try {
            const response = await loginUser({
                username: isAllNumbers(username) ? `+1${username}` : username,
                password: pass,
            });

            if (response.user && response.user.authFlowToken) {
                setLoggedIn(true);
                setLoginRes(response);
            } else {
                console.log(response?.errors);
                setLoggedInErr(true);
                setError(response?.errors?.message);
            }
        } catch (error) {
            console.log('2', error);
            if (error.response.data.statusCode === 400) {
                setLoggedInErr(true);
                setError('Incorrect username or password.');
            } else {
                setLoggedInErr(true);
                setError(error.response.data.errors.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSkip = () => {
        setSkipAction(true);
    };

    return (
        <>
            <Col className={ct('main-container-login')}>
                <Row>
                    <Col lg="6" className="first-block">
                        {!skipAction && (
                            <>
                                <LoginSlider />
                                <Button className={ct('skip-button')} onClick={handleSkip}>
                                    Skip
                                </Button>
                            </>
                        )}
                    </Col>

                    <Col lg="6" md="12" className={ct('form-container', { isVisible: !skipAction })}>
                        <Col lg="7" md="10">
                            <Form>
                                <Col className={ct('parrot-logo')}>
                                    <p className="welcome-title">Welcome to</p>
                                    <img src={CONSTANT.LOGIN_IMAGES.PARROT_LOGO} alt="parrot-logo" />
                                </Col>

                                <Form.Group className="mt-5">
                                    <Form.Control
                                        className={ct('form-input')}
                                        type="text"
                                        name="username"
                                        placeholder="Username / Mobile number"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <Form.Text>
                                        {UserNameError && (
                                            <p className={ct('error_message')}>{messages?.valid_username}</p>
                                        )}
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mt-4">
                                    <Col className={ct('password-field')}>
                                        <Form.Control
                                            className={ct('form-input')}
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            placeholder="Password"
                                            value={pass}
                                            onChange={(e) => setPass(e.target.value)}
                                        />
                                        <PasswordEye isVisible={showPassword} setIsVisible={setShowPassword} />
                                    </Col>
                                    <Form.Text>
                                        {PasswordError && (
                                            <p className={ct('error_message')}>{messages?.valid_password}</p>
                                        )}
                                    </Form.Text>
                                </Form.Group>

                                <Col className="mt-4 d-flex justify-content-start mb-5">
                                    <ForgetPassword show={modalShow} onHide={() => setModalShow(false)} />
                                </Col>

                                <Col className={ct('button-container')}>
                                    <button
                                        className="border rounded-pill m-2 login-btn"
                                        onClick={(e) => handleSubmit(e)}
                                        disabled={isLoading}>
                                        LOGIN
                                    </button>
                                </Col>

                                <Col>
                                    <button
                                        className="rounded-pill m-2 register-btn"
                                        type="button"
                                        onClick={() => navigate('/signup')}>
                                        REGISTER
                                    </button>
                                </Col>
                            </Form>
                        </Col>
                        {isLoading && <LoaderModal />}
                    </Col>
                </Row>
            </Col>

            {/* <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={loggedIn}
                    onModelClose={() => handleLoggedIn(loginRes)}
                    text="Login Successful"
                    onYes={() => handleLoggedIn(loginRes)}
                    btn1Text={'Ok'}
                />
            </Suspense> */}

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={loggedInErr}
                    onModelClose={() => setLoggedInErr(false)}
                    text={error}
                    onYes={() => setLoggedInErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>
        </>
    );
};

export default Login;
