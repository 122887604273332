import React, { useEffect, useState } from 'react';
import { updateReferralRedeem } from 'Api/setting';
import './style.scss';

const RedeemReferral = ({ id, referralCode, setModalMsg, setModalOpen, setModalErr }) => {
    const [redeemValue, setRedeemValue] = useState('');

    useEffect(() => {
        setRedeemValue(referralCode);
    }, [referralCode]);

    const handleRedeemCode = async () => {
        try {
            const response = await updateReferralRedeem(id, redeemValue);
            setModalMsg('Redeemed successfully.');
            setModalOpen(true);
            return response;
        } catch (error) {
            console.warn(error);
            setModalMsg(error?.response?.data?.errors?.message || 'An error occurred');
            setModalErr(true);
        }
    };
    return (
        <div className="redeem-parent-div mt-5">
            <div className="d-flex justify-content-center ">
                <input
                    type="text"
                    value={redeemValue}
                    onChange={(e) => setRedeemValue(e.target.value)}
                    placeholder="Refer Code"
                    className="mt-5 refer-input"
                />
            </div>
            <div className="d-flex justify-content-center mt-5">
                <button className="redeem-code-btn " onClick={handleRedeemCode}>
                    REDEEM CODE
                </button>
            </div>
        </div>
    );
};

export default RedeemReferral;
