import React from 'react';
import './style.scss';

const PendingOrder = ({ logoUrl, symbol, amount, orderType, updatedTimestamp, status, onClickCancel }) => {
    const formattedDate = updatedTimestamp
        ? new Date(updatedTimestamp).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
          })
        : 'N/A';

    return (
        <div className="order-info">
            <div className="d-flex justify-content-between order-info-contain">
                <div className="d-flex">
                    <img
                        src={logoUrl || '/assets/images2/portfolio_icon.png'}
                        alt="microsoft_logo"
                        className="pending-order-logo"
                    />
                    <div className="d-flex justify-content-between pending-order-margin-left">
                        <p className="pending-order-symbol">
                            {symbol}
                            <div className="d-flex entered-qty-top">
                                <p className="entered-qty-label">Entered Qty</p>
                                <p className="qty-separator">|</p>
                                <p className="qty-amount">{amount}</p>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="market-sell">
                    <p className="order-type-label">
                        Type
                        <p className="order-type-value">{orderType}</p>
                    </p>
                </div>
                <div className="submitted">
                    <p className="order-type-label">
                        Submitted
                        <p className="order-type-value">{formattedDate}</p>
                    </p>
                </div>
                <div>
                    <p className="order-type-label">
                        Status
                        <p className="pending-order-status">{status}</p>
                    </p>
                </div>
                <div>
                    <button className="pending-order-cancel-btn" onClick={onClickCancel}>
                        Cancel Order
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PendingOrder;
