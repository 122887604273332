import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const AnalyticsWithdrawEarning = ({ setWithdrawModalOpen }) => {
    const [modalAnalysisOpen, setModalAnalysisOpen] = useState(false);
    const [modalAnalysisConfirmOpen, setModalAnalysisConfirmOpen] = useState(false);

    function secModalClose() {
        setModalAnalysisOpen(false);
    }

    useEffect(() => {
        setModalAnalysisOpen(true);
    }, []);

    const customStyles = {
        content: {
            top: '70%',
            left: '87%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '325px',
            height: '315px',
            borderRadius: '25px',
            backgroundColor: '#FFF',
            boxShadow: '0px 30px 100px 0px rgba(0, 0, 0, 0.20)',
            padding: '0px',
            cursor: 'pointer',
        },
        overlay: {
            backgroundColor: 'transparent',
        },
    };
    return (
        <>
            <div style={{ width: '375px', height: '678px', borderRadius: '25px', top: '15px' }} className="mt-2 p-3">
                <div
                    style={{
                        width: '415px',
                        height: '153px',
                        borderRadius: '25px 0px 0px 25px',
                        background: '#FFF',
                        boxShadow: '0px 9px 20px 0px rgba(0, 0, 0, 0.06)',
                        bottom: '90px',
                        right: '15px',
                    }}>
                    <div className="d-flex p-2">
                        <div className="m-1">
                            {' '}
                            <img
                                src="/assets/images/image 63346436.png"
                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                alt="random"
                            />
                            <img
                                src="/assets/images/blue_icon.png"
                                alt="blue icon"
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    postion: 'absolute',
                                    right: '12px',
                                    top: '15px',
                                }}
                            />
                        </div>
                        <div className="m-1">
                            <p
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontSize: '15px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                }}>
                                Amanda Clark made trade of
                                <div className="d-flex ">
                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #64A2FF)',
                                            fontSize: '15px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                        }}>
                                        MSFT
                                    </p>
                                    <img
                                        src="/assets/dashboardImg/uparrow.png"
                                        alt="up arrow"
                                        style={{ width: '8px', height: '8px', marginLeft: '5px', marginTop: '5px' }}
                                    />

                                    <p
                                        style={{
                                            color: 'var(--pf-primary-black-1-f-1-f-1-f, #71C901)',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',
                                            marginLeft: '5px',
                                            letterSpacing: '0.7px',
                                        }}>
                                        0.26%
                                    </p>
                                </div>
                            </p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-around mb-4">
                        <p
                            style={{
                                color: '#8F8F8F',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}>
                            1 min ago
                        </p>

                        <button
                            style={{
                                width: '100px',
                                color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                padding: '8px 14px',
                                borderRadius: '40px',
                                backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            }}>
                            Mimic
                            <img
                                src="/assets/dashboardImg/mimic_img.png"
                                style={{ width: '14px', height: '14px', marginLeft: '5px' }}
                                alt="mimic"
                            />
                        </button>
                    </div>
                </div>

                <div
                    className="mt-5 p-3"
                    style={{
                        background: modalAnalysisOpen || modalAnalysisConfirmOpen ? 'rgba(0, 0, 0, 0.5)' : '#FFF',
                        right: '10px',
                        width: '415px',
                        height: '678px',
                        borderRadius: '25px 0px 0px 25px',
                        bottom: '110px',
                    }}>
                    <p
                        style={{
                            color: '#1F1F1F',
                            fontSize: '16px',
                            fontFamily: 'Figtree',
                            fontWeight: '500',

                            fontStyle: 'normal',
                            lineHeight: 'normal',
                        }}>
                        Withdraw earnings
                    </p>

                    <div
                        style={{
                            width: '325px',
                            height: '61px',
                            background:
                                modalAnalysisOpen || modalAnalysisConfirmOpen ? 'rgba(0, 0, 0, 0.5)' : '#F5F8F8',
                            borderRadius: '14px',
                        }}
                        className="p-3">
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '11px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',

                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                opacity: 0.5,
                            }}>
                            Withdraw from
                        </p>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                bottom: '10px',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}>
                            Subscription
                        </p>
                    </div>

                    <div className="mt-3 text-center">
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '40px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',

                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}>
                            $ 315.26
                        </p>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '400',

                                fontStyle: 'normal',
                                lineHeight: '125%',
                                opacity: 0.5,
                            }}>
                            Available Balance $ 1230.58
                        </p>
                    </div>
                    <div
                        style={{
                            width: '375px',
                            height: '405px',
                            background:
                                modalAnalysisOpen || modalAnalysisConfirmOpen ? 'rgba(0, 0, 0, 0.5)' : '#ECF2F1',
                            borderRadius: '0px 0px 25px 25px',
                            border:
                                modalAnalysisOpen || modalAnalysisConfirmOpen
                                    ? 'rgba(0, 0, 0, 0.5)'
                                    : '4px solid var(--pf-primary-white-ffffff, #FFF)',
                            right: '16px',
                            top: '43px',
                        }}
                        className="p-3">
                        <div className="d-flex justify-content-between ">
                            <div className="d-flex">
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: '41px', height: '41px', background: '#FFF', borderRadius: '50%' }}>
                                    <img
                                        src="/assets/images2/citibank.png"
                                        alt="bank"
                                        style={{ width: '29px', height: '19px' }}
                                    />
                                </div>
                                <div className="d-flex mt-2" style={{ marginLeft: '10px' }}>
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',

                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                        }}>
                                        35652 65789
                                        <span
                                            style={{
                                                color: '#64A2FF',
                                                fontSize: '14px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',

                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                marginLeft: '5px',
                                            }}>
                                            Default
                                        </span>
                                        <div className="d-flex">
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                }}>
                                                John Doe
                                            </p>
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '500',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: 0.5,
                                                    marginLeft: '5px',
                                                }}>
                                                City Bank
                                            </p>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <input type="radio" />
                        </div>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',

                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                opacity: 0.5,
                                marginLeft: '5px',
                            }}>
                            Other Linked Banks
                        </p>

                        <div className="d-flex justify-content-between mt-5">
                            <div className="d-flex">
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: '41px', height: '41px', background: '#FFF', borderRadius: '50%' }}>
                                    <img
                                        src="/assets/images2/allybank.png"
                                        alt="bank"
                                        style={{ width: '29px', height: '19px' }}
                                    />
                                </div>
                                <div className="d-flex mt-2" style={{ marginLeft: '10px' }}>
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',

                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                        }}>
                                        35652 65789
                                        <span
                                            style={{
                                                color: '#64A2FF',
                                                fontSize: '14px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',

                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                marginLeft: '5px',
                                            }}>
                                            Default
                                        </span>
                                        <div className="d-flex">
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                }}>
                                                John Doe
                                            </p>
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '500',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: 0.5,
                                                    marginLeft: '5px',
                                                }}>
                                                Ally Bank
                                            </p>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <input type="radio" />
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex">
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: '41px', height: '41px', background: '#FFF', borderRadius: '50%' }}>
                                    <img
                                        src="/assets/images2/hsbcbank.png"
                                        alt="bank"
                                        style={{ width: '29px', height: '19px' }}
                                    />
                                </div>
                                <div className="d-flex mt-2" style={{ marginLeft: '10px' }}>
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',

                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                        }}>
                                        35652 65789
                                        <span
                                            style={{
                                                color: '#64A2FF',
                                                fontSize: '14px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',

                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                marginLeft: '5px',
                                            }}>
                                            Default
                                        </span>
                                        <div className="d-flex">
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                }}>
                                                John Doe
                                            </p>
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '500',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: 0.5,
                                                    marginLeft: '5px',
                                                }}>
                                                HSBC Bank
                                            </p>
                                        </div>
                                    </p>
                                </div>
                            </div>

                            <input type="radio" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button
                                style={{
                                    width: '280px',
                                    height: '52px',
                                    borderRadius: '54px',
                                    backgroundColor: '#1F1F1F',
                                    color: '#D3FF5B',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    letterSpacing: '0.7px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                }}
                                onClick={() => setModalAnalysisConfirmOpen(true)}>
                                WITHDRAW
                            </button>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={modalAnalysisOpen}
                    onRequestClose={secModalClose}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div className="container  mt-3" style={{ width: '182px', textAlign: 'start' }}>
                        <div className="d-flex justify-content-center">
                            <img
                                src="/assets/images2/money-recive.png"
                                alt="money receive"
                                style={{ width: '51px', height: '51px' }}
                            />
                        </div>

                        <div className="d-flex justify-content-center mt-3 text-center">
                            <p
                                style={{
                                    color: '#1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '20px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}>
                                Are you sure you want to withdraw ?
                            </p>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div>
                                <button
                                    style={{
                                        width: '275px',
                                        height: '46px',
                                        borderRadius: '54px',
                                        backgroundColor: '#FFF',
                                        color: '#1F1F1F',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        letterSpacing: '0.7px',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                        border: '1px solid #1F1F1F',
                                    }}
                                    onClick={() => setModalAnalysisOpen(false)}>
                                    YES
                                </button>

                                <button
                                    style={{
                                        width: '280px',
                                        height: '52px',
                                        borderRadius: '54px',
                                        backgroundColor: '#1F1F1F',
                                        color: '#D3FF5B',
                                        fontSize: '14px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        letterSpacing: '0.7px',
                                        fontStyle: 'normal',
                                        lineHeight: 'normal',
                                    }}
                                    className="mt-2"
                                    onClick={() => setWithdrawModalOpen(false)}>
                                    NO
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={modalAnalysisConfirmOpen}
                    onRequestClose={secModalClose}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div className="container  mt-3 p-3">
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '20px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',

                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}>
                            Confirm Details
                        </p>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                opacity: 0.6000000238418579,
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                            }}>
                            Withdrawing to
                        </p>
                        <div className="d-flex justify-content-start ">
                            <div className="d-flex">
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ width: '41px', height: '41px', background: '#FFF', borderRadius: '50%' }}>
                                    <img
                                        src="/assets/images2/citibank.png"
                                        alt="bank"
                                        style={{ width: '29px', height: '19px' }}
                                    />
                                </div>
                                <div className="d-flex mt-2" style={{ marginLeft: '10px' }}>
                                    <p
                                        style={{
                                            color: '#1F1F1F',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '600',

                                            fontStyle: 'normal',
                                            lineHeight: 'normal',
                                        }}>
                                        35652 65789
                                        <div className="d-flex">
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '400',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                }}>
                                                John Doe
                                            </p>
                                            <p
                                                style={{
                                                    color: '#1F1F1F',
                                                    fontSize: '12px',
                                                    fontFamily: 'Figtree',
                                                    fontWeight: '500',

                                                    fontStyle: 'normal',
                                                    lineHeight: 'normal',
                                                    opacity: 0.5,
                                                    marginLeft: '5px',
                                                }}>
                                                City Bank
                                            </p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '12px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                opacity: 0.6000000238418579,
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                bottom: '15px',
                            }}>
                            Amount to be withdraw
                        </p>

                        <p
                            style={{
                                color: '#1F1F1F',
                                fontSize: '30px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                bottom: '15px',
                            }}>
                            $315.26
                        </p>
                        <div className="d-flex justify-content-center" style={{ bottom: '10px' }}>
                            <button
                                style={{
                                    width: '129px',
                                    height: '46px',
                                    borderRadius: '54px',
                                    backgroundColor: '#FFF',
                                    color: '#1F1F1F',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    letterSpacing: '0.7px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    border: '1px solid #1F1F1F',
                                }}
                                onClick={() => setModalAnalysisConfirmOpen(false)}>
                                CANCEL
                            </button>

                            <button
                                style={{
                                    width: '129px',
                                    height: '52px',
                                    borderRadius: '54px',
                                    backgroundColor: '#1F1F1F',
                                    color: '#D3FF5B',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    letterSpacing: '0.7px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    bottom: '12px',
                                    left: '5px',
                                }}
                                className="mt-2 m-1"
                                onClick={() => setModalAnalysisConfirmOpen(false)}>
                                CONTINUE
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default AnalyticsWithdrawEarning;
