import React, { useRef, useEffect } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
const StockChart = ({ data, chartId, color }) => {
    // const data = chartData.map((data) => {
    //     return {
    //         date: new Date(data.createdAt._seconds * 1000),
    //         value: data.equitiesValue,
    //     };
    // });

    // if (!data || !data.data) {
    //     return <></>;
    // }

    const options = {
        maintainAspectRatio: true, // Maintain the aspect ratio defined below
        aspectRatio: 3, // A lower value will make the chart "flatter"
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            tooltip: {
                enabled: false, // Disable tooltips
            },
        },
        scales: {
            x: {
                display: false, // Hide X-axis labels and gridlines
                grid: {
                    drawBorder: false, // Hide X-axis line
                    display: false,
                },
                ticks: {
                    display: false, // Hide X-axis ticks
                },
            },
            y: {
                display: false, // Hide Y-axis labels and gridlines
                // ... (other y-axis options)
                // suggestedMin: data?.data?.[0]?.v, // minimum value you want to show on the y-axis
                // suggestedMax: data?.data?.[0]?.v,
                grid: {
                    drawBorder: false, // Hide Y-axis line
                    display: false,
                },
                ticks: {
                    display: false, // Hide Y-axis ticks
                },
            },
        },
        elements: {
            point: {
                radius: 0, // Hide the points on the line
            },
        },
        // maintainAspectRatio: false can be used if you want to fully control the chart size
        maintainAspectRatio: false,
    };

    const chartRef = useRef(null);
    const canvasRef = useRef(null);

    console.log('chart data:', data);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        chartRef.current = new ChartJS(canvasRef.current, {
            type: 'line',
            data: {
                labels: data?.data?.map((d) => new Date(d?.t * 1000).toLocaleDateString()),
                datasets: [
                    {
                        label: 'Daily Stock Value',
                        data: data?.data?.map((d) => d.c),
                        fill: false,
                        borderColor: color,
                        tension: 0.1,
                    },
                ],
            },
            options: options,
        });

        // Cleanup on component unmount
        return () => {
            chartRef.current.destroy();
        };
    }, [data]);

    return <canvas ref={canvasRef} id={`chart-${chartId}`} />;
};

export default StockChart;
