import React, { useEffect, useState } from 'react';
import {
    createInfluencerSubscriptionProduct,
    createProduct,
    updatePriceInfluencerSubscriptionProduct,
    updateTierPlan,
} from 'Api/setting';
import './style.scss';
import CurrencyInput from 'react-currency-input-field';

const EditPricingContainer = ({
    amount,
    setLoading,
    setModalMsg,
    setModalOpen,
    influencerProducts,
    refreshInfluencerProducts,
    userId,
}) => {
    const [createPrice, setCreatePrice] = useState(0);
    const [updatePrice, setUpdatePrice] = useState(0);

    const [isUpdatingPrice, setIsUpdatingPrice] = useState(false);

    useEffect(() => {
        console.log(createPrice);
    }, [createPrice]);

    const handleCreateSubscription = async () => {
        setLoading(true);
        const resp = await createInfluencerSubscriptionProduct(createPrice);
        const refresh = await refreshInfluencerProducts();
        setLoading(false);
    };

    const handleUpdateSubscriptionPrice = async () => {
        setLoading(true);
        setUpdatePrice(0);
        setIsUpdatingPrice(false);
        const productId = influencerProducts[0].product?.id;
        const resp = await updatePriceInfluencerSubscriptionProduct(productId, updatePrice, userId);
        const refresh = await refreshInfluencerProducts();
        setLoading(false);
    };

    return (
        <div className="edit-pricing-container">
            {influencerProducts && influencerProducts[0] && influencerProducts[0].price ? (
                <>
                    <p>Existing Subscription Product:</p>
                    <div class="image d-flex justify-content-evenly mt-3">
                        <div class="d-flex w-100 mx-auto justify-content-center">
                            <div className="mt-2 p-4 per-month-container">
                                <img
                                    src="/assets/settings/EditPricingGreenDiamond.png"
                                    className="diamond-icon-container"
                                    alt="img"
                                />
                                <div className="mt-3">
                                    <p className="plan">{`${influencerProducts[0].product?.name}`}</p>
                                    <p className="description">{`${influencerProducts[0].product?.description}`}</p>
                                    <p className="prize">
                                        $ {(influencerProducts[0].price?.unit_amount / 100).toFixed(2) || 0}
                                    </p>
                                    <p className="plan">
                                        {`Per ${influencerProducts[0].price?.recurring?.interval}` || 'Per Month'}
                                    </p>
                                    {influencerProducts[0].price?.active ? (
                                        <p style={{ color: 'green', fontWeight: '600' }}>Active</p>
                                    ) : (
                                        <p style={{ color: 'red', fontWeight: '600' }}>Inactive</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isUpdatingPrice && (
                        <div className="d-flex justify-content-center p-3 mt-4 mx-auto input-connect">
                            <CurrencyInput
                                id="input-update"
                                name="input-update-price"
                                prefix="$"
                                className="edit-prizing-input"
                                placeholder="Enter new subscription price"
                                defaultValue={0}
                                decimalScale={2}
                                decimalsLimit={2}
                                onValueChange={(value, name) => setUpdatePrice(value)}
                            />
                        </div>
                    )}
                    <div className="d-flex justify-content-center mt-5">
                        {isUpdatingPrice && (
                            <button className="btn-update" onClick={() => setIsUpdatingPrice(false)}>
                                CANCEL
                            </button>
                        )}
                        <button
                            className="btn-update"
                            onClick={() =>
                                isUpdatingPrice ? handleUpdateSubscriptionPrice() : setIsUpdatingPrice(!isUpdatingPrice)
                            }>
                            {isUpdatingPrice ? 'CONFIRM UPDATED PRICE' : 'UPDATE PRICE'}
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <p>
                        Create new subscription product. Your subscribers will get access to all of your trades through
                        Parrot Finance.
                    </p>
                    <div className="d-flex justify-content-center p-3 mt-4 mx-auto input-connect">
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            prefix="$"
                            className="edit-prizing-input"
                            placeholder="Enter subscription price"
                            defaultValue={0}
                            decimalsLimit={2}
                            onValueChange={(value, name) => setCreatePrice(value)}
                        />
                    </div>
                    <div className="d-flex justify-content-center mt-5">
                        <button className="btn-update" onClick={() => handleCreateSubscription()}>
                            CREATE SUBSCRIPTION
                        </button>
                    </div>
                </>
            )}

            {/* <hr className="mt-5 hr-line" /> */}
        </div>
    );
};

export default EditPricingContainer;
