import React from 'react';
import './style.scss';

const SeparationCard = ({
    handleSwitchClick,
    fistHeading,
    firstHeadingImg,
    firstText,
    secondHeading,
    secondHeadingImg,
    secondText,
    isDiscord,
}) => {
    return (
        <div className="return-trade-info d-flex justify-content-between align-items-center">
            <div className="w-100 right-border-2px">
                <div className="d-flex justify-content-center">
                    <p className="first-heading-Text">{fistHeading}</p>

                    {!isDiscord && (
                        <img
                            src={'/assets/images2/Outline.png'}
                            className="switch-icon"
                            alt="outline"
                            onClick={handleSwitchClick}
                        />
                    )}
                </div>
                <div className="d-flex justify-content-center">
                    <img src={firstHeadingImg} className="first-heading-img" alt="union" />
                    <p className="mb-0 bottom-text">{firstText} %</p>
                </div>
            </div>

            <div className="w-100">
                <div>
                    <div className="d-flex justify-content-center">
                        <p className="first-heading-Text">{secondHeading}</p>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={secondHeadingImg} className="first-heading-img" alt="fire" />
                        <p className="mb-0 bottom-text">{secondText}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SeparationCard;
