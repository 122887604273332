import React from 'react';
import Modal from 'react-modal';
import { customStyles2 } from './Style';
import { sendInviteAnalyst } from 'Api/analyst';
import swal from 'sweetalert';
import './Style.scss';

const AnalyticsSubscriberConfirmation = ({
    modalConfirmAnalystIsOpen,
    secModalConfirmClose,
    setModalConfirmAnalystIsOpen,
    analystId,
}) => {
    const handleConfirmAnalyst = async (id) => {
        setModalConfirmAnalystIsOpen(false);

        try {
            const response = await sendInviteAnalyst(id);
            if (response.status === 200) {
                swal('Request sent successful', '', 'success');
            }
            return response;
        } catch (error) {
            console.error(error);
            swal(error?.response?.data?.errors?.message || 'An error occurred', '', 'error');
        }
    };
    return (
        <div>
            <Modal
                isOpen={modalConfirmAnalystIsOpen}
                onRequestClose={secModalConfirmClose}
                style={customStyles2}
                contentLabel="Example Modal">
                <div className="container">
                    <div className="row">
                        <div className="text-center ">
                            <div className="text-center ">
                                <p className=" w-100 mt-5 accept-para">
                                    Are you sure want to send analyst invitation ?
                                </p>

                                <button
                                    className=" mt-3 confirm-analyst-btn"
                                    style={{}}
                                    onClick={() => handleConfirmAnalyst(analystId)}>
                                    YES
                                </button>

                                <button
                                    className="confirm-analyst-btn mt-3"
                                    onClick={() => secModalConfirmClose(false)}>
                                    NO
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AnalyticsSubscriberConfirmation;
