import React from 'react';
import './style.scss';

const MyFollowingsCard = ({ status, following, onClickFollowing, errorMsg, label }) => {
    return status === 404 ? (
        <div className="ms-2 normal-user-follower-card">
            <div className="d-flex justify-content-center align-items-center mt-4">
                <p className="mt-3">{errorMsg}</p>
            </div>
        </div>
    ) : (
        <div className="ms-2 normal-user-follower-card">
            <div className="d-flex justify-content-between p-4">
                <div>
                    <div class="circles-gallery">
                        {Boolean(following?.length) &&
                            following?.map((data, index) => (
                                <img
                                    key={index}
                                    src={data?.influencerImage || '/assets/images/image (1).png'}
                                    alt="Some Architecture"
                                    className="client-gallery-images"
                                />
                            ))}
                    </div>
                </div>
                <div>
                    <p className="normal-user-following-count">
                        {following?.length}
                        <p className="normal-user-following-label">{label}</p>
                    </p>
                </div>

                <div className="d-flex justify-content-center align-items-center normal-user-right-icon">
                    <img
                        src="/assets/dashboardImg/arrowRight.png"
                        className="subscription-arrow-img"
                        alt="following-arrow"
                        onClick={onClickFollowing}
                    />
                </div>
            </div>
        </div>
    );
};

export default MyFollowingsCard;
