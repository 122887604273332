import React, { useState, useEffect } from 'react';
import { getNews } from 'Api/influencerDashboard';

const News = () => {
    const [fetchNews, setFetchNews] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchAllNews = () => {
        getNews()
            .then((response) => {
                setFetchNews(response);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchAllNews();
    }, []);

    return (
        <>
            <div className="container">
                <h1 style={{ color: 'white' }} className="text-center mb-5 mt-2">
                    View News
                </h1>
                {loading ? (
                    <div className="text-center">
                        <h1 style={{ color: '#C0FF02' }}>Loading...</h1>
                    </div>
                ) : (
                    <div className="image-container m-5">
                        {fetchNews &&
                            fetchNews.map((ele, i) => {
                                return (
                                    <div className="image-item" key={i}>
                                        <a href={ele.publisher.homepage_url} target="_blank" rel="noreferrer">
                                            <img src={ele.imageUrl} alt="Lights" />
                                            <div className="caption">
                                                <p>{ele.title}</p>
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </>
    );
};

export default News;
