import React from 'react';
import './style.scss';

const BecomeInfluencer = ({
    fname,
    setFname,
    lname,
    setLname,
    username,
    email,
    setEmail,
    twitterLink,
    setTwitterLink,
    facebookLink,
    setFaceBookLink,
    instragramLink,
    setInstragramLink,
    discordLink,
    setDiscordLink,
    handleBecomeInfluencer,
}) => {
    return (
        <div className="mt-3 become-influencer-setting">
            <hr />
            <div className="container " style={{ borderRadius: '25px' }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                    className="mt-5">
                    <div className="form-group mt-5">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control signupinput"
                                placeholder="Enter First Name"
                                style={{ color: 'black' }}
                                value={fname}
                                onChange={(e) => setFname(e.target.value)}
                            />
                        </div>
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>
                    <div className="form-group">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Last Name"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black' }}
                                value={lname}
                                onChange={(e) => setLname(e.target.value)}
                            />
                        </div>
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="form-group d-flex">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black' }}
                                value={username}
                            />
                        </div>
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Email"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control signupinput "
                                placeholder="Enter Twitter Link"
                                aria-label="twitterLink"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black', width: '320px' }}
                                value={twitterLink}
                                onChange={(e) => setTwitterLink(e.target.value)}
                            />
                        </div>

                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control signupinput"
                                placeholder="Enter Facebook Link"
                                aria-label="facebookLink"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black', width: '320px' }}
                                value={facebookLink}
                                onChange={(e) => setFaceBookLink(e.target.value)}
                            />
                        </div>

                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control signupinput "
                                placeholder="Enter Instragram Link"
                                aria-label="instragramLink"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black', width: '320px' }}
                                value={instragramLink}
                                onChange={(e) => setInstragramLink(e.target.value)}
                            />
                        </div>

                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="form-group">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className="form-control signupinput "
                                placeholder="Enter Discord Link"
                                aria-label="discordLink"
                                aria-describedby="basic-addon1"
                                style={{ color: 'black', width: '320px' }}
                                value={discordLink}
                                onChange={(e) => setDiscordLink(e.target.value)}
                            />
                        </div>

                        <div className="valid-feedback">Valid.</div>
                        <div className="invalid-feedback">Please fill out this field.</div>
                    </div>

                    <div className="d-flex justify-content-center mt-5">
                        <button className="save-btn" onClick={(e) => handleBecomeInfluencer(e)}>
                            SAVE
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BecomeInfluencer;
