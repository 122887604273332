import React, { useEffect, useState, lazy, Suspense } from 'react';
import * as CONSTANT from './constant';
import { useNavigate } from 'react-router-dom';
import { Accordion, Col } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import LoaderModal from 'pages/Login/LoaderModal.js';
import WelcomeSection from 'Components/reusable/welcomeSection/index.js';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer.js';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent.js';
import Navbar from 'Components/Navbar/Navbar';
import UpdatedTrades from 'Components/DashBoard/UpdatedTrades.js';
import ComponentLoading from 'Components/reusable/loading/Loading.js';
import ReferAndEarn from 'Components/reusable/model/ReferAndEarn/ReferAndEarn';
import SureModel from 'Components/reusable/settings/sureModel';
import {
    addPaymentCard,
    becomeInfluencer,
    createReferral,
    deleteAccount,
    fetchAvatar,
    fetchUserFollowingApi,
    getUserProfile,
    hideInfluencer,
    markDefaultCard,
    updateProfile,
    updateProfilePicture,
    getHideStockStatus,
    removeCardPost,
    getInfluencerProducts,
} from 'Api/setting';
import { getUserCards } from 'Api/influencerDetails';
import { getInfluencerByID } from 'Api/influencerDashboard';
import ManageAnalystTradeList from '../manageAnalystTradingList/ManageAnalystTradeList.js';
import CommonModal from 'Components/reusable/settings/commonModal';
import { FrontComponent } from 'Components/Front/Front';
import { postBrokerageAuthDetails } from 'Api/brokerageDetails';
import InfluencerProfile from 'Components/reusable/editProfile/influencerProfile.js';
import NormalUserProfile from 'Components/reusable/editProfile/normalUserProfile.js';
import BrokerInfo from 'Components/reusable/settings/broker/index.js';
import PaymentCard from 'Components/reusable/settings/paymentCard/paymentCard';
import SectionWrapper from 'Components/reusable/settings/sectionWrapper';
import SubscriptionFollowing from 'Components/reusable/settings/subscriptionFollowing';
import AccountPrivacyCard from 'Components/reusable/settings/accountPrivacyCard';
import ManageAnalyst from 'Components/reusable/settings/manageAnalyst';
import BecomeInfluencer from 'Components/reusable/settings/becomeInfluencer/becomeInfluencer';
import RedeemReferral from 'Components/reusable/settings/redeemReferral';
import EditPricingContainer from 'Components/reusable/settings/editPricing/editPricing';
import BecomeAnalyst from 'Components/reusable/settings/becomeAnalyst';
import SideBarHeading from 'Components/reusable/settings/sideBarHeading';
import { getInfluencerId } from 'pages/influencerDetails/selector';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Setting.scss';

const RemoveCard = lazy(() => import('Components/reusable/settings/removeCard'));
const CardDetail = lazy(() => import('Components/reusable/settings/cardDetail'));
const UpdateProfile = lazy(() => import('Components/reusable/settings/updateProfile'));

const Setting = () => {
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [profileImg, setProfileImg] = useState('');
    const [modalUpdatePhotoOpen, setModalUpdatePhotoOpen] = useState(false);
    const [selectContainer, setSelectContainer] = useState('profile');
    const [fetchCardData, setFetchCardData] = useState([]);
    const [addCard, setAddCard] = useState([]);
    const [getUserProfileData, setGetUserProfileData] = useState([]);
    const [activeIconSetting, setActiveIconSetting] = useState(false);
    const [hideStocksSlideClick, setHideStocksSlideClick] = useState(false);
    const [facebookLink, setFaceBookLink] = useState('');
    const [instragramLink, setInstragramLink] = useState('');
    const [discordLink, setDiscordLink] = useState('');
    const [twitterLink, setTwitterLink] = useState('');
    const [getAvtar, setGetAvtar] = useState([]);
    const [newImagePath, setNewImagePath] = useState('');
    const [removeListCardData, setRemoveListCardData] = useState([]);
    const [checkboxImages, setCheckboxImages] = useState({});
    const [defaultCheckboxImg, setDefaultCheckboxImg] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState('');
    const [socialLinks, setSocialLinks] = useState(CONSTANT.INITIAL_SOCIAL_LINKS);
    const [containerName, setContainerName] = useState('');
    const [inputValueRefer, setInputValueRefer] = useState('');
    const [modalReferAndEarn, setModalReferAndEarn] = useState(false);
    const [getManageAnalystId, setGetManageAnalystId] = useState('');
    const [hideStockStatus, setHideStockStatus] = useState([]);
    const [defaultBrokerModal, setDefaultBrokerModal] = useState(false);
    const [profileUpdate, setProfileUpdate] = useState(false);
    const [unfollowModalErr, setUnfollowModalErr] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalErr, setModalErr] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [loggedOut, setLoggedOut] = useState(false);
    const [linkToken, setLinkToken] = useState(null);
    const [error, setError] = useState(null);
    const [payload, setPayload] = useState(null);
    const [firstBlockMenu, setFirstBlockMenu] = useState(CONSTANT.FIRST_BLOCK_MENUS);
    const [secondBlockMenu, setSecondBlockMenu] = useState(CONSTANT.SECOND_BLOCK_MENUS);
    const [cardDefault, setCardDefault] = useState(false);
    const id = useSelector(getInfluencerId);
    const navigate = useNavigate();
    const options = CONSTANT.OPTIONS;
    const userId = JSON.parse(localStorage.getItem('userId'));

    // brady
    // get subscriptions for influencer
    const [influencerProducts, setInfluencerProducts] = useState([]);
    const refreshInfluencerProducts = async () => {
        const products = await getInfluencerProducts(userId);
        console.log('influencer products:', products);
        setInfluencerProducts(products);
    };
    useEffect(() => {
        if (getUserProfileData && getUserProfileData?.userType && getUserProfileData?.userType === 'influencer') {
            refreshInfluencerProducts();
        }
    }, []);

    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };

    useEffect(() => {
        if (payload) {
            const accountTokens = payload.accessToken?.accountTokens;
            accountTokens?.forEach((element) => {
                const accountId = element.account.accountId;
                const accountName = element.account.accountName;
                const accessToken = element.accessToken;
                const refreshToken = element.refreshToken;
                const refreshTokenExpiresInSeconds = payload.accessToken?.refreshTokenExpiresInSeconds;
                const expiresInSeconds = payload.accessToken?.expiresInSeconds;
                const brokerName = payload.accessToken?.brokerName;
                const brokerType = payload.accessToken?.brokerType;
                const body = {
                    accessToken,
                    accountId,
                    accountName,
                };
                if (refreshToken) {
                    body.refreshToken = refreshToken;
                }
                if (expiresInSeconds) {
                    body.expiresInSeconds = expiresInSeconds;
                }
                if (brokerName) {
                    body.brokerName = brokerName;
                }
                if (brokerType) {
                    body.brokerType = brokerType;
                }
                if (refreshTokenExpiresInSeconds) {
                    body.refreshTokenExpiresInSeconds = refreshTokenExpiresInSeconds;
                }
                if (!accessToken || !accountId || !accountName) {
                    setError('There was a problem retrieving your account. Please try again later.');
                } else {
                    console.log('submitting payload...');
                    submitPayload(body);
                }
            });
        }
    }, [payload]);

    const submitPayload = async (payload) => {
        await postBrokerageAuthDetails(payload);
    };

    const handleInputChange = (value) => {
        if (value.startsWith('@')) {
            value = value.slice(1);
        }
        setUsername(value);
    };

    const userFollowing = () => {
        fetchUserFollowingApi()
            .then((response) => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getInfluencerUsersByID = () => {
        getInfluencerByID(userId)
            .then((response) => {
                setFetchInfluencer(response);
                const influencer = response?.influencer?.influencer;
                if (influencer) {
                    const discord = !isNaN(parseFloat(influencer?.discordFollowers)) && {
                        id: 1,
                        link: influencer?.discordFollowers,
                        platform: influencer?.discordFollowers && 'discord',
                    };
                    const instagram = !isNaN(parseFloat(influencer?.instagramFollowers)) && {
                        id: 2,
                        link: influencer?.instagramFollowers,
                        platform: influencer?.instagramFollowers && 'instagram',
                    };

                    const facebook = !isNaN(parseFloat(influencer?.facebookFollowers)) && {
                        id: 3,
                        link: influencer?.facebookFollowers,
                        platform: influencer?.facebookFollowers && 'facebook',
                    };
                    const twitter = !isNaN(parseFloat(influencer?.twitterFollowers)) && {
                        id: 4,
                        link: influencer?.twitterFollowers,
                        platform: influencer?.twitterFollowers && 'twitter',
                    };
                    const getUpdatedSocialLinks = [discord, instagram, facebook, twitter]?.filter(
                        (info) => Boolean(info) && info?.link !== '0' && info?.link !== '0k'
                    );
                    setSocialLinks(getUpdatedSocialLinks);
                }
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };
    const onSubmit = (e) => {
        e.preventDefault();
        const counterTimeData = {};
        socialLinks.forEach((data) => {
            counterTimeData[data?.platform] = data?.link;
        });
        const data = {
            firstName: fname,
            lastName: lname,
            investmentPattern: selectedOptions?.value,
            discord: username,
            instagram: username,
            twitter: username,
            facebook: username,
            discordFollowers: counterTimeData?.discord ? counterTimeData?.discord : '0',
            instagramFollowers: counterTimeData?.instagram ? counterTimeData?.instagram : '0',
            twitterFollowers: counterTimeData?.twitter ? counterTimeData?.twitter : '0',
            facebookFollowers: counterTimeData?.facebook ? counterTimeData?.facebook : '0',
        };
        updateProfile(data)
            .then((response) => {
                getProfile();
                setProfileUpdate(true);
            })
            .catch((error) => {
                console.warn(error);
            });
    };
    const handleCheckbox = (id) => {
        if (checkboxImages[id]) {
            setCheckboxImages((prevState) => ({
                ...prevState,
                [id]: false,
            }));
        } else {
            setCheckboxImages((prevState) => ({
                ...prevState,
                [id]: true,
            }));
        }
    };
    const removeCardAPI = () => {
        const ids = Object.keys(checkboxImages).map((data, i) => checkboxImages[data] === true && data);
        ids.forEach((id) => {
            (async () => {
                try {
                    await removeCardPost(id);
                    getCards();
                } catch (error) {
                    console.error(error.response);
                }
            })();
        });
    };
    const getCards = async () => {
        getUserCards()
            .then((response) => {
                setFetchCardData(response);
                const removeItem = response?.data?.map((data) => ({
                    expiration: `EXP ${data.card.exp_month}/${data.card.exp_year}`,
                    id: data.id,
                    logo: data.card.brand === 'visa' ? '/assets/images/visa.png' : '/assets/images/master.png',
                    name: data.billing_details.name,
                    number: data.card.last4,
                }));

                setRemoveListCardData(removeItem);
            })
            .catch((error) => {
                console.warn(error);
            });
    };
    const handleMakeDefault = (id) => {
        setLoading(true);

        markDefaultCard(id)
            .then((response) => {
                setLoading(false);
                getCards();
                setCardDefault(true);
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getCards();
    }, [addCard]);

    const handleAddCard = () => {
        addPaymentCard()
            .then((response) => {
                setAddCard(response);
                getCards();
                setModalMsg('Card Added Successfully.');
                setModalOpen(true);
            })
            .catch((error) => {
                setModalMsg('Sorry! Something went wrong.');
                setModalErr(true);
            });
    };

    const getProfile = async () => {
        getUserProfile(userId)
            .then((response) => {
                setGetUserProfileData(response);
                setFname(response.user.firstname);
                setLname(response.user.lastname);
                setEmail(response.user.email);
                setUsername(response.user.username);
                setProfileImg(response.user.imageUrl);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    const getAvatar = () => {
        fetchAvatar()
            .then((response) => {
                setGetAvtar(response);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    useEffect(() => {
        getProfile();
        refreshInfluencerProducts();
        // eslint-disable-next-line
    }, [userId]);

    const handleBecomeInfluencer = (e) => {
        e.preventDefault();
        let data = {
            firstName: fname,
            lastName: lname,
            userName: username,
            email: email,
            twitter: twitterLink,
            discord: discordLink,
            instagram: instragramLink,
            facebook: facebookLink,
        };
        becomeInfluencer(data)
            .then(() => {
                setModalMsg('Your request has been received. You will receive confirmation shortly.');
                setModalOpen(true);
            })
            .catch((error) => {
                setModalMsg('Become influencer request is failed.');
                setModalErr(true);
            });
    };

    const handleDeleteAccount = () => {
        deleteAccount()
            .then((response) => {
                setModalMsg('Account deleted successfully.');
                setModalOpen(true);
                navigate('/');
            })
            .catch((error) => {
                setModalMsg('Account deletion failed.');
                setModalErr(true);
            });
    };

    const handleProfilePic = (e) => {
        e.preventDefault();
        const data = {
            imageUrl: newImagePath,
        };

        updateProfilePicture(data)
            .then((response) => {
                getProfile();
                setModalUpdatePhotoOpen(false);
                setModalMsg('Avatar updated successfully');
                setModalOpen(true);
            })
            .catch((error) => {
                setModalMsg('Sorry! Something went wrong.');
                setModalErr(true);
            });
    };

    const updateProfilePic = () => {};

    const handlePrivacyPolicy = () => {
        window.location.href = `https://www.parrotfinance.io/privacy-policy`;
    };

    const handleTermCondition = () => {
        window.location.href = `https://www.parrotfinance.io/terms-and-conditions`;
    };

    const handlePhoto = (newImage) => {
        setNewImagePath(newImage);
    };

    const influencer = {
        firstName: 'Settings',
    };

    const platFormArr = ['discord', 'instagram', 'facebook', 'twitter'];

    const handleAddLink = () => {
        const filtered = socialLinks.map((data) => data.platform);
        const newArr = platFormArr.filter((data) => !filtered.includes(data));
        const newLink = {
            id: Date.now(),
            link: '',
            platform: Boolean(newArr.length) ? newArr[0] : '',
        };

        if (socialLinks?.length <= 3) {
            setSocialLinks((prev) => [...prev, newLink]);
        }
    };

    const handlePlatformChange = (id, platform) => {
        const updateSocialLink = socialLinks.findIndex((data) => data.platform === platform);
        socialLinks.splice(1, updateSocialLink);
        const updatedLinks = socialLinks.map((link) => {
            if (link.id === id) {
                return { ...link, platform };
            }
            return link;
        });
        setSocialLinks(updatedLinks);
    };

    const handleHideStocks = async () => {
        setHideStocksSlideClick(!hideStocksSlideClick);
        try {
            const data = {
                value: hideStocksSlideClick,
            };
            const response = await hideInfluencer(id, data);
            setModalMsg('Success');
            window.location.reload();
            setModalOpen(true);
            return response;
        } catch (error) {
            console.warn(error);
            setModalMsg(error?.response?.data?.errors?.message || 'An error occurred');
            setModalErr(true);
        }
    };

    const handleHideStockStatus = async () => {
        getHideStockStatus(id)
            .then((response) => {
                setHideStockStatus(response.hideStockStatus);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getRefferal = () => {
        createReferral()
            .then((response) => {
                setInputValueRefer(response.referralCode);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleInputChangeRefer = (event) => {
        setInputValueRefer(event.target.value);
    };

    const handleCopyClick = () => {
        if (inputValueRefer) {
            navigator.clipboard
                .writeText(inputValueRefer)
                .then(() => {
                    console.error(inputValueRefer);
                })
                .catch((error) => {
                    console.error('Copy failed:', error);
                });
        }
    };

    const secModalCloseRefer = () => {
        setModalReferAndEarn(false);
    };

    useEffect(() => {
        userFollowing();
        getInfluencerUsersByID();
        getRefferal();
        getAvatar();
        handleHideStockStatus();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="settings-container">
            <div className="black-row">
                <MediaQuery maxWidth={769}>
                    <MobileNavBarWithInfluencer />
                </MediaQuery>
                <WelcomeSection id={id} influencer={influencer} />
            </div>

            <Col>
                <div className="row m-0">
                    <MediaQuery minWidth={769}>
                        <div className="col-xl-1 col-lg-1">
                            <Navbar
                                activeIconSetting={activeIconSetting}
                                setActiveIconSetting={setActiveIconSetting}
                                setModalReferAndEarn={setModalReferAndEarn}
                            />
                        </div>
                    </MediaQuery>

                    <MediaQuery minWidth={415}>
                        <div className="col-xl-3 col-lg-3 col-md-6" style={{ bottom: '16rem' }}>
                            <div>
                                {firstBlockMenu?.map((data, index) => (
                                    <SideBarHeading
                                        key={index}
                                        text={data.text}
                                        imageUrl={data.imageUrl}
                                        onclick={() => {
                                            setSelectContainer(data.value);
                                            firstBlockMenu.forEach((info) => {
                                                info.toggle = false;
                                            });
                                            firstBlockMenu[index].toggle = true;
                                            setFirstBlockMenu(() => [...firstBlockMenu]);
                                            secondBlockMenu.forEach((info) => {
                                                info.toggle = false;
                                            });
                                            setSecondBlockMenu(() => [...secondBlockMenu]);
                                        }}
                                        color={data.toggle && '#D3FF5B'}
                                    />
                                ))}

                                {getUserProfileData?.userType === 'user' && (
                                    <SideBarHeading
                                        text={'Become An Influencer'}
                                        imageUrl={<i class="fa fa-font sidebar-fa-icon" />}
                                        onclick={() => {
                                            setSelectContainer('becomeInfluencer');
                                            firstBlockMenu.forEach((info) => {
                                                info.toggle = false;
                                            });
                                            setFirstBlockMenu(() => [...firstBlockMenu]);
                                            secondBlockMenu.forEach((info) => {
                                                info.toggle = false;
                                            });
                                            setSecondBlockMenu(() => [...secondBlockMenu]);
                                        }}
                                        color={selectContainer === 'becomeInfluencer' && '#D3FF5B'}
                                    />
                                )}

                                <SideBarHeading
                                    text={'Become An Analyst'}
                                    imageUrl={<i class="fa fa-font sidebar-fa-icon" />}
                                    onclick={() => {
                                        setSelectContainer('becomeAnalyst');
                                        firstBlockMenu.forEach((info) => {
                                            info.toggle = false;
                                        });
                                        setFirstBlockMenu(() => [...firstBlockMenu]);
                                        secondBlockMenu.forEach((info) => {
                                            info.toggle = false;
                                        });
                                        setSecondBlockMenu(() => [...secondBlockMenu]);
                                    }}
                                    color={selectContainer === 'becomeAnalyst' && '#D3FF5B'}
                                />

                                {getUserProfileData?.userType === 'influencer' &&
                                    secondBlockMenu?.map((data, index) => (
                                        <SideBarHeading
                                            key={index}
                                            text={data.text}
                                            imageUrl={data.imageUrl}
                                            onclick={() => {
                                                setSelectContainer(data.value);
                                                secondBlockMenu.forEach((info) => {
                                                    info.toggle = false;
                                                });
                                                secondBlockMenu[index].toggle = true;
                                                setSecondBlockMenu(() => [...secondBlockMenu]);
                                                firstBlockMenu.forEach((info) => {
                                                    info.toggle = false;
                                                });
                                                setFirstBlockMenu(() => [...firstBlockMenu]);
                                            }}
                                            color={data.toggle && '#D3FF5B'}
                                        />
                                    ))}

                                <SideBarHeading
                                    text={'Account & Privacy'}
                                    imageUrl={<i class="fa fa-font sidebar-fa-icon" />}
                                    onclick={() => {
                                        setSelectContainer('AccountPrivacy');
                                        firstBlockMenu.forEach((info) => {
                                            info.toggle = false;
                                        });
                                        setFirstBlockMenu(() => [...firstBlockMenu]);
                                        secondBlockMenu.forEach((info) => {
                                            info.toggle = false;
                                        });
                                        setSecondBlockMenu(() => [...secondBlockMenu]);
                                    }}
                                    color={selectContainer === 'AccountPrivacy' && '#D3FF5B'}
                                />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-8 col-md-6">
                            {selectContainer === 'profile' && (
                                <SectionWrapper heading="Profile Settings">
                                    {getUserProfileData?.userType === 'influencer' ? (
                                        <>
                                            <InfluencerProfile
                                                getUserProfileImage={getUserProfileData.user.imageUrl}
                                                setModalUpdatePhotoOpen={setModalUpdatePhotoOpen}
                                                setFname={setFname}
                                                setLname={setLname}
                                                username={username}
                                                fname={fname}
                                                lname={lname}
                                                email={email}
                                                setEmail={setEmail}
                                                handleInputChange={handleInputChange}
                                                ftechInfluencer={ftechInfluencer}
                                                selectedOptions={selectedOptions}
                                                options={options}
                                                handleSelectChange={handleSelectChange}
                                                handleAddLink={handleAddLink}
                                                socialLinks={socialLinks}
                                                onSubmit={onSubmit}
                                                setSocialLinks={setSocialLinks}
                                                handlePlatformChange={handlePlatformChange}
                                            />
                                        </>
                                    ) : (
                                        <NormalUserProfile
                                            profileImg={profileImg}
                                            setModalUpdatePhotoOpen={setModalUpdatePhotoOpen}
                                            fname={fname}
                                            setFname={setFname}
                                            lname={lname}
                                            setLname={setLname}
                                            username={username}
                                            setUsername={setUsername}
                                            email={email}
                                            setEmail={setEmail}
                                            updateProfilePic={updateProfilePic}
                                        />
                                    )}
                                </SectionWrapper>
                            )}

                            {selectContainer === 'brokerages' && (
                                <>
                                    <SectionWrapper
                                        heading="Brokerages"
                                        isDropdownShow
                                        links={[
                                            { title: 'Remove Brokerages', value: 'removeBrokerages' },
                                            { title: 'Disconnect Brokerages', value: 'disconnectBrokerages' },
                                        ]}>
                                        <BrokerInfo
                                            setLoading={setLoading}
                                            setDefaultBrokerModal={setDefaultBrokerModal}
                                            setModalMsg={setModalMsg}
                                        />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'paymentMethod' && (
                                <>
                                    <SectionWrapper heading="Select payment method">
                                        <PaymentCard
                                            handleCard={() => {
                                                setSelectContainer('cardDetail');
                                            }}
                                        />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'cardDetail' && (
                                <Suspense fallback={<ComponentLoading />}>
                                    <CardDetail
                                        setSelectContainer={setSelectContainer}
                                        fetchCardData={fetchCardData?.data}
                                        handleMakeDefault={handleMakeDefault}
                                        handleAddCard={handleAddCard}
                                        setContainerName={setContainerName}
                                    />
                                </Suspense>
                            )}

                            {selectContainer === 'removeCard' && (
                                <Suspense fallback={<ComponentLoading />}>
                                    <RemoveCard
                                        setSelectContainer={setSelectContainer}
                                        setDefaultCheckboxImg={setDefaultCheckboxImg}
                                        RemoveListCardData={removeListCardData}
                                        defaultCheckboxImg={defaultCheckboxImg}
                                        handleCheckbox={handleCheckbox}
                                        checkboxImages={checkboxImages}
                                        containerName={containerName}
                                        handleAddCard={handleAddCard}
                                        removeCardAPI={removeCardAPI}
                                    />
                                </Suspense>
                            )}

                            {selectContainer === 'subscriptionFollowing' && (
                                <>
                                    <SubscriptionFollowing setLoading={setLoading} />
                                </>
                            )}

                            {selectContainer === 'AccountPrivacy' && (
                                <>
                                    <SectionWrapper heading="Account & Privacy">
                                        {getUserProfileData.userType === 'influencer' && (
                                            <AccountPrivacyCard text="Blocked Users" isBlockedUser />
                                        )}

                                        {getUserProfileData.userType === 'influencer' && (
                                            <AccountPrivacyCard
                                                text="Hide Stocks"
                                                isToggleShow
                                                hideStockStatus={hideStocksSlideClick || hideStockStatus}
                                                handleHideStocks={handleHideStocks}
                                            />
                                        )}
                                        <AccountPrivacyCard text="Two step verification" />
                                        <AccountPrivacyCard text="Privacy Policy" onTextClick={handlePrivacyPolicy} />
                                        <AccountPrivacyCard
                                            text="Term and Condition"
                                            onTextClick={handleTermCondition}
                                        />
                                        <AccountPrivacyCard
                                            text="Delete my account"
                                            onTextClick={() => setLoggedOut(true)}
                                            isRedText
                                        />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'manageAnalytics2' && (
                                <>
                                    <SectionWrapper heading="Manage Analysts">
                                        <ManageAnalyst
                                            id={id}
                                            setLoading={setLoading}
                                            setModalMsg={setModalMsg}
                                            setModalOpen={setModalOpen}
                                            setModalErr={setModalErr}
                                            setSelectContainer={setSelectContainer}
                                            setGetManageAnalystId={setGetManageAnalystId}
                                        />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'manageAnalystTradeList' && (
                                <div className="p-3 manage-analyst-trade-parent-div">
                                    <ManageAnalystTradeList getManageAnalystId={getManageAnalystId} />
                                </div>
                            )}

                            {selectContainer === 'becomeInfluencer' && (
                                <>
                                    <SectionWrapper heading="Become An Influencer">
                                        <BecomeInfluencer
                                            fname={fname}
                                            setFname={setFname}
                                            lname={lname}
                                            setLname={setLname}
                                            username={username}
                                            email={email}
                                            setEmail={setEmail}
                                            twitterLink={twitterLink}
                                            setTwitterLink={setTwitterLink}
                                            facebookLink={facebookLink}
                                            setFaceBookLink={setFaceBookLink}
                                            instragramLink={instragramLink}
                                            setInstragramLink={setInstragramLink}
                                            discordLink={discordLink}
                                            setDiscordLink={setDiscordLink}
                                            handleBecomeInfluencer={handleBecomeInfluencer}
                                        />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'becomeAnalyst' && (
                                <SectionWrapper heading="Analyst Request">
                                    <BecomeAnalyst
                                        id={id}
                                        setLoading={setLoading}
                                        setModalMsg={setModalMsg}
                                        setModalOpen={setModalOpen}
                                        setModalErr={setModalErr}
                                    />
                                </SectionWrapper>
                            )}

                            {selectContainer === 'updatePublicTrades' && (
                                <>
                                    <SectionWrapper heading="Update Public Trades">
                                        <UpdatedTrades />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'redeemReferal' && (
                                <>
                                    <SectionWrapper heading="Redeem Referral">
                                        <RedeemReferral
                                            id={id}
                                            referralCode={ftechInfluencer?.influencer?.influencer?.referralCode}
                                            setModalMsg={setModalMsg}
                                            setModalOpen={setModalOpen}
                                            setModalErr={setModalErr}
                                        />
                                    </SectionWrapper>
                                </>
                            )}

                            {selectContainer === 'editPricing' && (
                                <>
                                    <SectionWrapper heading="Edit Subscription or Pricing" isEditPricing>
                                        <EditPricingContainer
                                            userId={userId}
                                            influencerProducts={influencerProducts}
                                            refreshInfluencerProducts={refreshInfluencerProducts}
                                            amount={ftechInfluencer?.influencer?.influencer?.amount}
                                            setLoading={setLoading}
                                            setModalMsg={setModalMsg}
                                            setModalOpen={setModalOpen}
                                        />
                                    </SectionWrapper>
                                </>
                            )}
                        </div>
                    </MediaQuery>

                    {/* <MediaQuery minWidth={1025}>
                        <div className="col-xl-3 col-lg-3 pe-0">
                            <div className="scroll-sideNotification side-notification-settings">
                                <SideNotificationComponent />
                            </div>
                        </div>
                    </MediaQuery> */}

                    <MediaQuery maxWidth={414}>
                        <div className="col-md-12 mt-3 mb-3">
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Profile Settings'}
                                            imageUrl={'/assets/settings/Profile.png'}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SectionWrapper heading="Profile Settings" isAccordion>
                                            {getUserProfileData?.userType === 'influencer' ? (
                                                <InfluencerProfile
                                                    getUserProfileImage={getUserProfileData.user.imageUrl}
                                                    setModalUpdatePhotoOpen={setModalUpdatePhotoOpen}
                                                    setFname={setFname}
                                                    setLname={setLname}
                                                    username={username}
                                                    fname={fname}
                                                    lname={lname}
                                                    email={email}
                                                    setEmail={setEmail}
                                                    handleInputChange={handleInputChange}
                                                    ftechInfluencer={ftechInfluencer}
                                                    selectedOptions={selectedOptions}
                                                    options={options}
                                                    handleSelectChange={handleSelectChange}
                                                    handleAddLink={handleAddLink}
                                                    socialLinks={socialLinks}
                                                    onSubmit={onSubmit}
                                                    setSocialLinks={setSocialLinks}
                                                    handlePlatformChange={handlePlatformChange}
                                                />
                                            ) : (
                                                <NormalUserProfile
                                                    profileImg={profileImg}
                                                    setModalUpdatePhotoOpen={setModalUpdatePhotoOpen}
                                                    fname={fname}
                                                    setFname={setFname}
                                                    lname={lname}
                                                    setLname={setLname}
                                                    username={username}
                                                    setUsername={setUsername}
                                                    email={email}
                                                    setEmail={setEmail}
                                                    updateProfilePic={updateProfilePic}
                                                />
                                            )}
                                        </SectionWrapper>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Brokerages'}
                                            imageUrl={'/assets/settings/BrokerageLink.png'}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SectionWrapper
                                            isAccordion
                                            heading="Brokerages"
                                            isDropdownShow
                                            links={[
                                                { title: 'Remove Brokerages', value: 'removeBrokerages' },
                                                { title: 'Disconnect Brokerages', value: 'disconnectBrokerages' },
                                            ]}>
                                            <BrokerInfo
                                                setLoading={setLoading}
                                                setDefaultBrokerModal={setDefaultBrokerModal}
                                                setModalMsg={setModalMsg}
                                            />
                                        </SectionWrapper>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header onClick={() => setSelectContainer('paymentMethod')}>
                                        <SideBarHeading
                                            text={'Payment Methods'}
                                            imageUrl={'/assets/settings/BankHomeBlack.png'}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {selectContainer === 'paymentMethod' && (
                                            <SectionWrapper heading="Select payment method" isAccordion>
                                                <PaymentCard
                                                    handleCard={() => {
                                                        setSelectContainer('cardDetail');
                                                    }}
                                                />
                                            </SectionWrapper>
                                        )}

                                        {selectContainer === 'cardDetail' && (
                                            <Suspense fallback={<ComponentLoading />}>
                                                <CardDetail
                                                    setSelectContainer={setSelectContainer}
                                                    fetchCardData={fetchCardData?.data}
                                                    handleMakeDefault={handleMakeDefault}
                                                    handleAddCard={handleAddCard}
                                                    setContainerName={setContainerName}
                                                    isAccordion
                                                />
                                            </Suspense>
                                        )}

                                        {selectContainer === 'removeCard' && (
                                            <Suspense fallback={<ComponentLoading />}>
                                                <RemoveCard
                                                    setSelectContainer={setSelectContainer}
                                                    setDefaultCheckboxImg={setDefaultCheckboxImg}
                                                    RemoveListCardData={removeListCardData}
                                                    defaultCheckboxImg={defaultCheckboxImg}
                                                    handleCheckbox={handleCheckbox}
                                                    checkboxImages={checkboxImages}
                                                    containerName={containerName}
                                                    handleAddCard={handleAddCard}
                                                    removeCardAPI={removeCardAPI}
                                                    isAccordion
                                                />
                                            </Suspense>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Subscriptions & Followings'}
                                            imageUrl={<i className="fab fa-angellist sidebar-fa-icon" />}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SubscriptionFollowing setLoading={setLoading} />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Become An Influencer'}
                                            imageUrl={<i className="fas fa-splotch sidebar-fa-icon" />}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SectionWrapper heading="Become An Influencer" isAccordion>
                                            <BecomeInfluencer
                                                fname={fname}
                                                setFname={setFname}
                                                lname={lname}
                                                setLname={setLname}
                                                username={username}
                                                email={email}
                                                setEmail={setEmail}
                                                twitterLink={twitterLink}
                                                setTwitterLink={setTwitterLink}
                                                facebookLink={facebookLink}
                                                setFaceBookLink={setFaceBookLink}
                                                instragramLink={instragramLink}
                                                setInstragramLink={setInstragramLink}
                                                discordLink={discordLink}
                                                setDiscordLink={setDiscordLink}
                                                handleBecomeInfluencer={handleBecomeInfluencer}
                                            />
                                        </SectionWrapper>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Become An Analyst'}
                                            imageUrl={<i className="fas fa-splotch sidebar-fa-icon" />}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SectionWrapper heading="Analyst Request" isAccordion>
                                            <BecomeAnalyst
                                                id={id}
                                                setLoading={setLoading}
                                                setModalMsg={setModalMsg}
                                                setModalOpen={setModalOpen}
                                                setModalErr={setModalErr}
                                            />
                                        </SectionWrapper>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Edit Pricing'}
                                            imageUrl={<i className="fas fa-cut sidebar-fa-icon" />}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SectionWrapper heading="Edit Pricing" isEditPricing isAccordion>
                                            <EditPricingContainer
                                                amount={ftechInfluencer?.influencer?.influencer?.amount}
                                                setLoading={setLoading}
                                                setModalMsg={setModalMsg}
                                                setModalOpen={setModalOpen}
                                            />
                                        </SectionWrapper>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>
                                        <SideBarHeading
                                            text={'Account & Privacy'}
                                            imageUrl={'/assets/settings/Sheild.png'}
                                            isAccordion
                                        />
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <SectionWrapper heading="Account & Privacy" isAccordion>
                                            {getUserProfileData.userType === 'influencer' && (
                                                <AccountPrivacyCard text="Blocked Users" isBlockedUser />
                                            )}

                                            {getUserProfileData.userType === 'influencer' && (
                                                <AccountPrivacyCard
                                                    text="Hide Stocks"
                                                    isToggleShow
                                                    hideStockStatus={hideStocksSlideClick || hideStockStatus}
                                                    handleHideStocks={handleHideStocks}
                                                />
                                            )}
                                            <AccountPrivacyCard text="Two step verification" />
                                            <AccountPrivacyCard
                                                text="Privacy Policy"
                                                onTextClick={handlePrivacyPolicy}
                                            />
                                            <AccountPrivacyCard
                                                text="Term and Condition"
                                                onTextClick={handleTermCondition}
                                            />
                                            <AccountPrivacyCard
                                                text="Delete my account"
                                                onTextClick={() => setLoggedOut(true)}
                                                isRedText
                                            />
                                        </SectionWrapper>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </MediaQuery>
                </div>
            </Col>

            <Suspense fallback={<ComponentLoading />}>
                <UpdateProfile
                    isOpen={modalUpdatePhotoOpen}
                    onModelClose={() => setModalUpdatePhotoOpen(false)}
                    getAvtar={getAvtar}
                    handlePhoto={handlePhoto}
                    handleProfilePic={handleProfilePic}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={defaultBrokerModal}
                    onModelClose={() => setDefaultBrokerModal(false)}
                    text={'Default brokerage set successfully.'}
                    onYes={() => setDefaultBrokerModal(false)}
                    btn1Text={'ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={cardDefault}
                    onModelClose={() => setCardDefault(false)}
                    text={'Card marked as default successful'}
                    onYes={() => setCardDefault(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={unfollowModalErr}
                    onModelClose={() => setUnfollowModalErr(false)}
                    text={'You have not subscribed to this influencer.'}
                    onYes={() => setUnfollowModalErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={profileUpdate}
                    onModelClose={() => setProfileUpdate(false)}
                    text={'Profile updated successfully.'}
                    onYes={() => setProfileUpdate(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalOpen}
                    onModelClose={() => setModalOpen(false)}
                    text={modalMsg}
                    onYes={() => setModalOpen(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <CommonModal
                    isOpen={modalErr}
                    onModelClose={() => setModalErr(false)}
                    text={modalMsg}
                    onYes={() => setModalErr(false)}
                    btn1Text={'Ok'}
                />
            </Suspense>

            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={loggedOut}
                    onModelClose={() => {
                        setLoggedOut(false);
                    }}
                    text="Are you sure you want to delete this account ?"
                    onYes={handleDeleteAccount}
                    onNo={() => {
                        setLoggedOut(false);
                    }}
                />
            </Suspense>

            <ReferAndEarn
                inputValueRefer={inputValueRefer}
                handleInputChangeRefer={handleInputChangeRefer}
                handleCopyClick={handleCopyClick}
                modalReferAndEarn={modalReferAndEarn}
                secModalCloseRefer={secModalCloseRefer}
            />

            {loading && <LoaderModal />}

            <FrontComponent
                linkToken={linkToken}
                onBrokerConnected={(authData) => {
                    setPayload(authData);
                    setLinkToken(null);
                }}
                onExit={(err) => {
                    setLinkToken(null);
                }}
                onTransferFinished={(data) => {
                    // setTrasnferFinishedData(data);
                }}
            />
        </div>
    );
};

export default Setting;
