import api from '../util/instance';
import { urls } from './Api';

export const fetchProfile = async (id) => await api.get(`${urls.fetchProfile}/${id}/profile`);

export const updateFollow = async (id, payload = {}) => await api.post(`${urls.fetchProfile}/${id}/follow`, payload);

export const filterHighestReturn = async () => await api.get(urls.FilterHigestReturn);

export const filterHighestFollowers = async () => await api.get(urls.FilterHigestFollowers);

export const lowestSubscription = async () => await api.get(urls.LowestSubscription);

export const highestSubscription = async () => await api.get(urls.HighestSubscription);

export const searchData = async (term) => await api.get(`${urls.searchData}${term}`);

export const raiseSupportTicket = async (payload = {}) => await api.post(`${urls.RaiseSupportTicket}`, payload);

export const marketIndicates = async () => await api.get(urls.marketIndicates);
