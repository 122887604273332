import React from 'react';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import moment from 'moment';

const data = [
    {
        timestamp: 406000000,
        value: 4000,
    },
    {
        timestamp: 406000000,
        value: 3000,
    },
    {
        timestamp: 406000000,
        value: 2000,
    },
    {
        timestamp: 406000000,
        value: 2780,
    },
    {
        timestamp: 406000000,
        value: 1890,
    },
    {
        timestamp: 406000000,
        value: 2390,
    },
];

const InfluencerBroker = ({ graphData, setValue }) => {
    const graphValues = graphData || data;

    const RenderCustomToolTip = ({ active, payload, timestamp }) => {
        const payloads = payload && payload[0]?.payload;

        if (payloads) {
            setValue && setValue(payloads.value);
        }

        return (
            <div
                style={{
                    background: 'rgba(218, 229, 227, 1)',
                    width: '100px',
                    height: '40px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <p style={{ color: 'rgba(31, 31, 31, 1)', margin: 0 }}>
                    {moment(payloads?.timestamp).format('hh:mm A')}
                </p>
            </div>
        );
    };

    return (
        <ResponsiveContainer>
            <AreaChart data={graphValues}>
                <defs>
                    <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#75ca08" stopOpacity={0.4} />
                        <stop offset="75%" stopColor="#75ca08" stopOpacity={0.05} />
                    </linearGradient>
                </defs>
                <Tooltip
                    contentStyle={{ backgroundColor: 'black' }}
                    itemStyle={{ color: 'rgba(113, 201, 1, 1)' }}
                    content={<RenderCustomToolTip />}
                    cursor={false}
                />
                <YAxis dataKey="value" axisLine={false} tickLine={false} hide />
                <XAxis dataKey="timestamp" hide />

                <Area
                    type="monotone"
                    fillOpacity={7}
                    dataKey="value"
                    stroke="#75ca08"
                    fill="url(#color)"
                    activeDot={{ fill: 'rgb(31, 31, 31)', stroke: '#75ca08', strokeWidth: 2, r: 5 }}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default InfluencerBroker;
