import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';

const SureModel = ({ isOpen, onModelClose, text, onYes, onNo }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onModelClose} style={customStyles} contentLabel="Example Modal">
            <div className="container">
                <div className="row">
                    <div className="text-center ">
                        <div className="text-center ">
                            <p className=" w-100 mt-5 accept-para">{text}</p>

                            <button
                                className=" mt-3"
                                style={{
                                    width: '180px',
                                    height: '46px',
                                    textDecoration: 'none',
                                    backgroundColor: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    borderRadius: '54px',
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',

                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    letterSpacing: '0.7px',
                                    cursor: 'pointer',
                                    border: 'none',
                                }}
                                onClick={onYes}>
                                YES
                            </button>

                            <button
                                className=" mt-3"
                                style={{
                                    width: '180px',
                                    height: '46px',
                                    textDecoration: 'none',
                                    backgroundColor: '#FFF',
                                    borderRadius: '54px',
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    border: '1px solid  #1F1F1F',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    letterSpacing: '0.7px',
                                    cursor: 'pointer',
                                }}
                                onClick={onNo}>
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(SureModel);
