import React from 'react';
import './style.scss';

const SubscribeCard = ({ logo, count, label, onClickRightIcon, isGray }) => {
    return (
        <div
            className="p-3 subscribe-analytics-card"
            style={{
                background: isGray ? '#dae5e3' : '#dae5e3',
            }}>
            <div className="d-flex justify-content-center align-items-center subscriber-logo">
                <img src={logo} className="analytics" alt="analytics" />
            </div>
            <div className="d-flex justify-content-start mt-2 ">
                <p className="subscriber-count">{count}</p>
            </div>

            <div className="d-flex justify-content-start mt-2 ">
                <p className="subscriber-label">{label}</p>
                <img
                    src="/assets/Analytics/arrow-up-right/Outline.png"
                    onClick={onClickRightIcon}
                    className="arrow-up-right-outline"
                    alt="arrow-img"
                />
            </div>
        </div>
    );
};

export default SubscribeCard;
