import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sinUpInfo: {},
};

const signUp = createSlice({
    name: 'signUp',
    initialState,
    reducers: {
        forgotPassWord: (state, action) => {
            const { payload } = action;
            state.sinUpInfo = {
                ...state,
                ...payload,
            };
        },
        signUpCredential: (state, action) => {
            const { payload } = action;
            state.sinUpInfo = {
                ...state,
                ...payload,
            };
        },
    },
});

export const { forgotPassWord, signUpCredential } = signUp.actions;

export default signUp.reducer;
