// export const BUTTON_LABELS = [
//     { label: '1d', active: true },
//     { label: '2d', active: false },
//     { label: '1w', active: false },
//     { label: '1y', active: false },
//     { label: '2y', active: false },
// ];

export const BUTTON_LABELS = [
    { label: '1d', range: '1d', interval: '5m', active: true },
    { label: '5d', range: '5d', interval: '15m', active: false },
    { label: '3m', range: '3mo', interval: '1d', active: false },
    { label: '6m', range: '6mo', interval: '1d', active: false },
    { label: '1y', range: '1y', interval: '1wk', active: false },
    { label: '5y', range: '5y', interval: '1wk', active: false },
];
