export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: '95vh',
        borderRadius: '25px',
        backgroundColor: 'var(--pf-primary-white-ffffff, #FFF)',
        position: 'absolute',
        overflow: 'hidden',
        zIndex: '9999',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
};
