export const customStyles = {
    content: {
        inset: '40px auto auto auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: 'auto',
        transform: 'unset',
        width: '43%',
        borderRadius: '20px',
        backgroundColor: '#FFF',
        padding: '0px',
        boxShadow: '0px 21px 100px 0px rgba(0, 0, 0, 0.20)',
        zIndex: 9999,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9998,
    },
};
