import React, { lazy, Suspense } from 'react';
import ComponentLoading from 'Components/reusable/loading/Loading';
import { useLocation } from 'react-router-dom';

const DashBoard2 = lazy(() => import('../firstUserDashboard'));

const ExploreInfluencer = () => {
    const location = useLocation();
    const fetchTradeSpotlight = location?.state?.fetchTradeSpotlight;

    return (
        <Suspense fallback={<ComponentLoading />}>
            <DashBoard2 isExploreInfluencer fetchTradeSpotlight={fetchTradeSpotlight} />;
        </Suspense>
    );
};

export default React.memo(ExploreInfluencer);
