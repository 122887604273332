import api from '../util/instance';
import { urls } from './Api';

export const getSubscription = async () => {
    return await api.get(`${urls.fetchUserSubscriber}`);
};

export const getFollowing = async () => {
    return await api.get(`${urls.fetchUserFollwing}`);
};

export const unsubscribeSubscription = async (id, payload) => {
    return await api.post(`${urls.unsubscribeUser}/${id}`, payload);
};

export const unfollowing = async (id, payload) => {
    return await api.post(`${urls.unFollowingUser}/${id}`, payload);
};
