export const MASTER_CARD_LOGO = '/assets/Influencer/Mastercard-logo 1.png';
export const VISA_CARD_LOGO = '/assets/Influencer/Visacard-logo 1 (1).png';

export const CARD_DROPDOWN = [
    {
        id: 1,
        card_code: '2542 2369 8546 4786',
        expiry: 'Exp 08/28',
        user_name: 'John Doe',
        image: MASTER_CARD_LOGO,
    },
    {
        id: 2,
        card_code: '2542 2369 8546 4786',
        expiry: 'Exp 08/28',
        user_name: 'John Doe',
        image: VISA_CARD_LOGO,
    },
    {
        id: 3,
        card_code: '2542 2369 8546 4786',
        expiry: 'Exp 08/28',
        user_name: 'John Doe',
        image: MASTER_CARD_LOGO,
    },
];
