import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import LoaderModal from 'pages/Login/LoaderModal';
import BuyStocks from 'Components/reusable/model/buyStocks';
import OrderConfirmation from 'Components/reusable/model/orderConfirmation';
import OrderSuccess from 'Components/reusable/model/orderSuccess';
import OrderQueued from 'Components/reusable/model/orderQueued';
import Navbar from 'Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import { showTrades } from 'Api/influencerDetails';
import { fractionalTrade } from 'Api/stocksDetails';
import { BrokeragesById } from 'Api/stocksDetails';
import { getInfluencerId } from '../influencerDetails/selector';
import { getBrokerageId } from './selector';
import './style.scss';

const RecentTrades = () => {
    const [fetchRecentTrades, setFetchRecentTrades] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalBuyIsOpen, setModalBuyIsOpen] = useState(false);
    const [modalReviewIsOpen, setModalReviewIsIsOpen] = useState(false);
    const [selectUnit, setSelectUnit] = useState(true);
    const [modalFinalIsOpen, setModalFinalIsIsOpen] = useState(false);
    const [modalQueuedIsOpen, setModalQueuedIsOpen] = useState(false);
    const [getTicker, setGetTicker] = useState('');
    const [getPrice, setGetPrice] = useState('');
    const [getName, setGetName] = useState('');
    const [getLogo, setGetLogo] = useState('');
    const [InputPrice, setInputPrice] = useState(getPrice);
    const [fetchBrokerageById, setFetchBrokerageById] = useState([]);
    const id = useSelector(getInfluencerId);
    const brokerageId = useSelector(getBrokerageId);

    const getTrades = () => {
        showTrades(id)
            .then((response) => {
                setFetchRecentTrades(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getTrades();
        // eslint-disable-next-line
    }, []);

    const getBrokerageById = () => {
        // const brokerageId = localStorage.getItem('getBrokerageDropdownData');
        BrokeragesById(brokerageId)
            .then((response) => {
                setFetchBrokerageById(response.balance);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    useEffect(() => {
        getBrokerageById();
        // eslint-disable-next-line
    }, []);

    const handleBuyCost = () => {
        setIsLoading(true);
        setModalQueuedIsOpen(false);

        const data = {
            symbol: getTicker,
            amount: 0,
            extendedHours: false,
            orderType: 'market',
            timeInForce: 'goodTillCanceled',
            brokerageId: 'Robinhood_0001',
            side: 'buy',
            fractional: true,
            fractionalAmountInUsd: Number(localStorage.getItem('inputPriceRecentTrade')),
        };

        fractionalTrade(data)
            .then((response) => {
                setIsLoading(false);
                swal('Your order was placed and will be executed after market opening.', '', 'success');
            })
            .catch((error) => {
                setIsLoading(false);
                swal('Sorry ! Your order failed.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleOpenModal = (ticker, price, name, logourl) => {
        setModalBuyIsOpen(true);
        setModalQueuedIsOpen(false);
        setGetTicker(ticker);
        setGetPrice(price);
        setGetName(name);
        setGetLogo(logourl);
    };

    const influencer = {
        firstName: 'Influencer Recent Trade',
    };

    return (
        <>
            <div className="portfolio-trade-history recent-trade-main">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5">
                            <div className="row d-flex justify-content-center">
                                {fetchRecentTrades?.map((trade, index) => (
                                    <div key={index} className="col-sm-12 col-md-12 col-lg-6 col-12 m-3 idle-box">
                                        <div className="d-flex justify-content-center align-item-center mt-4">
                                            <img
                                                src="/assets/images/Front_img.png"
                                                className="userImage"
                                                alt="frontImg"
                                            />
                                        </div>
                                        <div className="d-flex justify-content-center mt-2">
                                            <img
                                                src="/assets/images2/twitter_icon.png"
                                                className="m-1 twitter-img"
                                                alt="frontImg"
                                            />
                                            <span className="m-1 twitter-count">8.8K</span>
                                        </div>

                                        <div className="d-flex justify-content-center">
                                            <p className="m-1 stock-ticker">{trade?.stockDetails?.ticker}</p>
                                            <span className="m-1">
                                                <img
                                                    src="/assets/images2/verify 1.png"
                                                    alt="frontImg"
                                                    className="verify-img"
                                                />
                                            </span>
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className="m-1 recent-trade-stocks-name">{trade?.stockDetails?.name}</p>
                                            <span className="m-1">
                                                <p className="m-1 recent-trade-stocks-amt">{trade.amount}</p>
                                            </span>
                                        </div>
                                        <div
                                            className="d-flex justify-content-center"
                                            onClick={() =>
                                                handleOpenModal(
                                                    trade?.stockDetails?.ticker,
                                                    trade.price,
                                                    trade?.stockDetails?.name,
                                                    trade?.stockDetails?.logoUrl
                                                )
                                            }>
                                            <div className="recentrade-modal-div text-center">
                                                <img
                                                    src="/assets/Influencer/import_modal_icon.png"
                                                    alt="modal"
                                                    className="modal-import-img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>

            {/* Buy stock modal */}

            <BuyStocks
                isOpened={modalBuyIsOpen}
                onModelClose={setModalBuyIsOpen}
                setModalReviewIsIsOpen={setModalReviewIsIsOpen}
                selectUnit={selectUnit}
                setSelectUnit={setSelectUnit}
                logoUrl={getLogo}
                ticker={getTicker}
                InputPrice={InputPrice}
                setInputPrice={setInputPrice}
                name={getName}
                currBidPrice={getPrice}
                fetchBrokerageById={fetchBrokerageById}
                setIsLoading={setIsLoading}
            />

            {/* Review Modal */}
            <OrderConfirmation
                isOpened={modalReviewIsOpen}
                onModelClose={setModalReviewIsIsOpen}
                setModalFinalIsIsOpen={setModalFinalIsIsOpen}
                InputPrice={InputPrice}
                logoUrl={getLogo}
                ticker={getTicker}
            />

            {/* final Modal */}
            <OrderSuccess
                isOpened={modalFinalIsOpen}
                onModelClose={setModalFinalIsIsOpen}
                setModalQueuedIsOpen={setModalQueuedIsOpen}
                InputPrice={InputPrice}
                logoUrl={getLogo}
                ticker={getTicker}
            />

            {/* Queued Modal */}

            <OrderQueued
                isOpened={modalQueuedIsOpen}
                onModelClose={setModalQueuedIsOpen}
                handleBuyCost={handleBuyCost}
                InputPrice={InputPrice}
                logoUrl={getLogo}
                ticker={getTicker}
                handleOpenModal={handleOpenModal}
                getName={getName}
            />

            {isLoading && <LoaderModal />}
        </>
    );
};

export default RecentTrades;
