import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import Navbar from 'Components/Navbar/Navbar';
import MobileNavBarWithInfluencer from 'Components/Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import SideNotificationComponent from 'Components/DashBoard/sideNotification/SideNotificationComponent';
import { averageTrades, getInfluencer } from 'Api/influencerDetails';
import LoaderModal from 'pages/Login/LoaderModal';
import { getInfluencerId } from '../influencerDetails/selector';
import './style.scss';

const Statistic = () => {
    const [getAvgTrade, setGetAvgTrade] = useState([]);
    const [ftechInfluencer, setFetchInfluencer] = useState([]);
    const [loading, setLoading] = useState(true);
    const id = useSelector(getInfluencerId);

    useEffect(() => {
        const handleTopHolding = async (id) => {
            setLoading(true);

            try {
                const response = await averageTrades(id);

                if (response) {
                    setGetAvgTrade(response);
                    setLoading(false);
                }
                return response;
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        handleTopHolding(id);
    }, [id]);

    useEffect(() => {
        const handleTopHolding = async (id) => {
            setLoading(true);

            try {
                const response = await getInfluencer(id);

                if (response) {
                    setFetchInfluencer(response);
                    setLoading(false);
                }
                return response;
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        handleTopHolding(id);
    }, [id]);

    const influencer = {
        firstName: 'All Statistic',
    };

    const winrate = ftechInfluencer.map((ele) => {
        return ele.winRate;
    });

    return (
        <div>
            <div className="portfolio-trade-history statistics-main">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5 mt-5">
                            <div className="win-rate-section w-100">
                                <div className="d-flex justify-content-between px-5 pt-3 pb-3 align-items-center">
                                    <div className="">
                                        <p className="mb-0 win-rate-main-count">
                                            {winrate} %<p className="mb-0 win-rate-main-text">Win Rate</p>
                                        </p>
                                    </div>
                                    <div>
                                        <img
                                            src="/assets/images2/influencerStaticGraph.png"
                                            className="tuner-graph"
                                            alt="graph"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3 w-100">
                                <div className="d-flex breaking-trade-info w-100">
                                    <div className="media-bottom-margin w-100 m-2">
                                        <div className="p-3 largest-win-main">
                                            <div>
                                                <p className="largest-win-main-count">
                                                    325%
                                                    <div className="d-flex">
                                                        <img
                                                            src="/assets/images2/TRENDINGuP.png"
                                                            className="green-grow-img"
                                                            alt="trending"
                                                        />
                                                        <p className="largest-win-main-label">Largest Win</p>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className="p-3 breakeven-main">
                                                <div className="">
                                                    <img
                                                        className="breakeven-main-calender"
                                                        src="/assets/images2/calender_icon.png"
                                                        alt="calender"
                                                    />
                                                </div>
                                                <div className="mt-1">
                                                    <p className="breakeven-main-count">
                                                        17
                                                        <p className="breakeven-main-label">Breakeven Trades</p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 m-2">
                                        <div className="p-3 breakeven-main">
                                            <div>
                                                <img
                                                    src="/assets/images2/white_symbol.png"
                                                    className="breakeven-main-calender"
                                                    alt="whitesymbol"
                                                />
                                            </div>
                                            <div className="mt-1">
                                                <p className="not-trade-found">
                                                    {getAvgTrade?.length === 0 ? (
                                                        <>
                                                            <p className="avgtrade-text">
                                                                no trades to show for this influencer
                                                            </p>
                                                        </>
                                                    ) : (
                                                        getAvgTrade?.averageTrades.toFixed(2)
                                                    )}
                                                    <p className="breakeven-main-label">Avg. Trades / week</p>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className="p-3 largest-win-main">
                                                <div>
                                                    <p className="largest-win-main-count">
                                                        -100%
                                                        <div className="d-flex">
                                                            <img
                                                                src="/assets/images2/trendingDown.png"
                                                                className="green-grow-img"
                                                                alt="trendingdown"
                                                            />
                                                            <p className="largest-win-main-label">Largest Loss</p>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="average-section-statictis w-100">
                                    <div className="d-flex justify-content-evenly mt-3">
                                        <div>
                                            <p className="average-gain-main-count">35.89%</p>
                                            <p className="average-gain-main-label">Average Gain</p>
                                        </div>

                                        <div>
                                            <p className="average-gain-main-count">6</p>
                                            <p className="average-gain-main-label">Longest Win Straek</p>
                                        </div>

                                        <div>
                                            <p className="average-gain-main-count">55 %</p>
                                            <p className="average-gain-main-label">Loss Rate</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>
            {loading && <LoaderModal />}
        </div>
    );
};

export default Statistic;
