import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import LoaderModal from '../../../../pages/Login/LoaderModal';
import Navbar from '../../../Navbar/Navbar';
import MobileNavBarWithInfluencer from '../../../Navbar/MobileNavBarWithInfluencer';
import WelcomeSection from 'Components/reusable/welcomeSection';
import SideNotificationComponent from '../../sideNotification/SideNotificationComponent';
import { getTradeHistory } from '../../../../Api/TradeHistoryStock';
import './style.scss';

const TradeHistory = () => {
    const [fetchTradeHistory, setFetchTradeHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();

    const handleTradeHistory = async () => {
        setIsLoading(true);
        try {
            const response = await getTradeHistory(id);
            if (response) {
                setFetchTradeHistory(response);
                setIsLoading(false);
            }
            return response;
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        handleTradeHistory();
        // eslint-disable-next-line
    }, []);

    const influencer = {
        firstName: 'Trade History',
    };
    return (
        <div>
            <div className="portfolio-trade-history">
                <div className="black-row">
                    <MediaQuery maxWidth={769}>
                        <MobileNavBarWithInfluencer />
                    </MediaQuery>
                    <WelcomeSection id={id} influencer={influencer} />
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <MediaQuery minWidth={769}>
                            <div className="col-xl-1 col-lg-2">
                                <Navbar />
                            </div>
                        </MediaQuery>
                        <div className="col-xl-8 col-lg-5">
                            {fetchTradeHistory?.history?.map((trade, index) => {
                                const dateTime = new Date(trade.updatedTimestamp * 1000);
                                const formattedDateTime = dateTime.toLocaleString();
                                return (
                                    <>
                                        <div className="p-3 trade-history-parent-div mt-4" key={index}>
                                            <div className="d-flex">
                                                <div className="trade-img-div">
                                                    <img
                                                        src="/assets/images2/portfolio_icon.png"
                                                        style={{ width: '34px', height: '34px' }}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <p className="trade-symbl">
                                                        {trade.symbol}
                                                        <span className="trade-side-text-tradehistory">
                                                            {trade.side}
                                                        </span>
                                                        <div className="d-flex">
                                                            <p className="mt-1 share-text">
                                                                {trade?.amount || 0} Shares
                                                            </p>
                                                            <p className="mt-1 at-symbl">@</p>

                                                            <p className="mt-1 trade-price">${trade.price}</p>
                                                        </div>
                                                    </p>
                                                </div>

                                                <div>
                                                    <p className="trade-amt">
                                                        $ {trade.value || 0}
                                                        <div className="d-flex">
                                                            <p className="mt-1 trade-date">{formattedDateTime}</p>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>

                        <MediaQuery minWidth={1025}>
                            <div className="col-xl-3 col-lg-3 pe-0">
                                <div className="side-notification">
                                    <SideNotificationComponent />
                                </div>
                            </div>
                        </MediaQuery>
                    </div>
                </div>
            </div>
            {isLoading && <LoaderModal />}
        </div>
    );
};

export default TradeHistory;
