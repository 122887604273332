export const OPTIONS = [
    { value: 'Aggressive', label: 'Aggressive' },
    { value: 'Balanced', label: 'Balanced' },
    { value: 'Conservative', label: 'Conservative' },
];

export const INITIAL_SOCIAL_LINKS = [{ id: 1, link: '', platform: 'discord' }];

export const FIRST_BLOCK_MENUS = [
    {
        text: 'Profile Settings',
        imageUrl: '/assets/settings/Profile.png',
        value: 'profile',
        toggle: true,
    },
    {
        text: 'Brokerages',
        imageUrl: '/assets/settings/BrokerageLink.png',
        value: 'brokerages',
        toggle: false,
    },
    {
        text: 'Payment Methods',
        imageUrl: '/assets/settings/BankHomeBlack.png',
        value: 'paymentMethod',
        toggle: false,
    },
    {
        text: 'Subscriptions & Followings',
        imageUrl: <i className="fab fa-angellist sidebar-fa-icon" />,
        value: 'subscriptionFollowing',
        toggle: false,
    },
    {
        text: 'Manage Analysts',
        imageUrl: <i className="fas fa-database sidebar-fa-icon" />,
        value: 'manageAnalytics2',
        toggle: false,
    },
];

export const SECOND_BLOCK_MENUS = [
    {
        text: 'Redeem Referral',
        imageUrl: <i class="fa fa-font sidebar-fa-icon" />,
        value: 'redeemReferal',
        toggle: false,
    },
    {
        text: 'Update Public Trades',
        imageUrl: <i class="fa fa-font sidebar-fa-icon" />,
        value: 'updatePublicTrades',
        toggle: false,
    },
    {
        text: 'Edit Subscription or Pricing',
        imageUrl: <i className="fas fa-cut sidebar-fa-icon" />,
        value: 'editPricing',
        toggle: false,
    },
];
