import React, { useEffect, useState } from 'react';
import { userBrokerages } from 'Api/brokerageDetails';

const BrokerageDetails = () => {
    const [getBrokerageData, setGetBrokerageData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await userBrokerages();
                setGetBrokerageData(response);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="container mt-4 mb-4 p- d-flex justify-content-center">
                <div className="card p-4" style={{ backgroundColor: 'black' }}>
                    <div className="image d-flex flex-column justify-content-center align-items-center">
                        <div className="card" style={{ width: '50rem' }}>
                            <div
                                className="card-header text-center"
                                style={{ backgroundColor: 'black', color: 'white' }}>
                                <h2>Brokerage Details</h2>
                            </div>
                            <div className="" style={{ backgroundColor: 'black' }}>
                                {loading ? (
                                    <div className="text-center w-100">
                                        <h1 style={{ color: '#C0FF02' }}>Loading...</h1>
                                    </div>
                                ) : (
                                    <>
                                        {!getBrokerageData?.details &&
                                            getBrokerageData?.details?.equityPositions?.map((ele) => (
                                                <div
                                                    className="d-flex justify-content-between m-3"
                                                    style={{
                                                        borderRadius: '28px',
                                                        backgroundColor: '#d9ffb3',
                                                        height: '10rem',
                                                    }}
                                                    key={ele.symbol}>
                                                    <img
                                                        src="/assets/images/image (1).png"
                                                        alt="Profile"
                                                        width="120"
                                                        height="120"
                                                        className="m-4"
                                                        style={{ borderRadius: '90%' }}
                                                    />
                                                    <div className="m-4">
                                                        <h3>Equality Position</h3>
                                                        <p>{ele.symbol}</p>
                                                        <span style={{ color: 'orange' }}>amount: {ele.amount}</span>
                                                        <p style={{ color: 'orange' }}>costBasis: $ {ele.costBasis}</p>
                                                    </div>
                                                    <div className="m-4">
                                                        <button
                                                            className="btn btn-sm m-2"
                                                            style={{
                                                                backgroundColor: 'black',
                                                                color: '#C0FF02',
                                                                width: '150px',
                                                                borderRadius: '25px',
                                                            }}></button>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BrokerageDetails;
