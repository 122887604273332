import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import LoaderModal from '../../pages/Login/LoaderModal';
import ComponentLoading from 'Components/reusable/loading/Loading';
import { useDispatch, useSelector } from 'react-redux';
import SureModel from 'Components/reusable/settings/sureModel';
import { fetchProfile } from 'Api/dashboard';
import { userLoggedOut } from 'globalStore/actions/login';
import { getUserId } from 'pages/analytics/slector';
import '@fortawesome/fontawesome-free/css/all.css';

const Navbar = ({ setModalSupportOpen, setModalReferAndEarn }) => {
    const [sideNavWidth, setSideNavWidth] = useState(93);
    const [displayCross, setDisplayCross] = useState(false);
    const [getUserProfileData, setGetUserProfileData] = useState([]);
    const [loggedOut, setLoggedOut] = useState(false);
    const location = useLocation();
    const userId = useSelector(getUserId);
    const dispatch = useDispatch();

    const handleSignOut = () => {
        dispatch(userLoggedOut({}));
        window.localStorage.clear();
        window.location.href = '/';
    };

    const getProfile = () => {
        fetchProfile(userId)
            .then((response) => {
                setGetUserProfileData(response);
            })
            .catch((error) => {
                console.error(error, 'catch error');
            });
    };

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line
    }, []);

    const handleNav = () => {
        setDisplayCross(true);
        setSideNavWidth(370);
    };

    const handlecloseNav = () => {
        setDisplayCross(false);
        setSideNavWidth(93);
    };

    const isVisibleMenu = useMemo(() => {
        if (sideNavWidth >= 300) return true;
        else return false;
    }, [sideNavWidth]);

    return (
        <div>
            <div
                id="mySidenav"
                className="sidenav"
                style={{
                    width: sideNavWidth,
                    backgroundColor: '#1F1F1F',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                {displayCross === true ? (
                    <Link className="mb-2">
                        <img
                            src="/assets/images/logo512.png"
                            alt="Profile"
                            className=""
                            style={{ borderRadius: '85px 0px 85px 85px', width: '200px', marginLeft: '-12px' }}
                            onClick={handlecloseNav}
                        />
                    </Link>
                ) : (
                    <div className=" mb-3" style={{ marginLeft: '1rem' }}>
                        <img
                            src="/assets/dashboardImg/logoCrop512 2.png"
                            alt="Profile"
                            width="45"
                            height="45"
                            className="small-logo"
                            onClick={handleNav}
                        />
                    </div>
                )}

                <div
                    style={{
                        backgroundColor: '#343434',
                        width: '80%',
                        height: '1px',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}></div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '69px',
                    }}>
                    <Link
                        to={`/dashboard`}
                        style={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '50%',
                            background: 'rgb(56 56 56)',
                            display: 'flex!important',
                            justifyContent: 'end',
                            alignItems: 'center',
                        }}
                        className="d-flex">
                        <img
                            src="/assets/dashboardImg/link.png"
                            style={{ width: '22px', height: '22px', right: 1 }}
                            alt="Link"
                        />
                    </Link>
                </div>

                <div
                    style={{
                        backgroundColor: '#343434',
                        width: '80%',
                        height: '1px',
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}></div>

                <Link
                    title="Home"
                    to={`/dashboard`}
                    style={{ backgroundColor: '', color: 'black' }}
                    className="d-flex  ">
                    <div>
                        <img
                            onMouseOver={() => {}}
                            src={
                                location.pathname === `/dashboard`
                                    ? '/assets/dashboardImg/Home 2.png'
                                    : '/assets/Navbar/HomeLight.png'
                            }
                            alt="Home"
                            style={{ width: '26px', height: '26px' }}
                        />
                    </div>
                    {isVisibleMenu && (
                        <p
                            style={{
                                // fontFamily: 'Figtree',
                                // alignSelf: 'center',
                                // padding: '12px',
                                fontSize: '18pt',
                                marginLeft: '15px',
                                marginTop: '5px',
                                color: '#FFF',
                            }}>
                            Home
                        </p>
                    )}
                </Link>

                <Link
                    title="Profile"
                    to={`/dashboard/portfolio`}
                    style={{ backgroundColor: '', color: 'black' }}
                    className="d-flex  ">
                    <div>
                        <img
                            src={
                                location.pathname === `/dashboard/portfolio`
                                    ? '/assets/Navbar/portfolioActive2.png'
                                    : '/assets/Navbar/Profile.png'
                            }
                            className={
                                location.pathname === `/dashboard/portfolio`
                                    ? 'portfolio-icon-active'
                                    : 'portfolio-icon'
                            }
                            alt="icon"
                        />
                    </div>
                    {isVisibleMenu && (
                        <p
                            style={{
                                fontFamily: 'Figtree',
                                fontSize: '18pt',
                                marginLeft: '15px',
                                marginTop: '5px',
                                color: '#FFF',
                            }}>
                            Profile
                        </p>
                    )}
                </Link>

                <Link
                    title="Settings"
                    to={`/dashboard/setting`}
                    style={{ color: 'black', cursor: 'pointer' }}
                    className="d-flex">
                    <div>
                        <img
                            src={
                                location.pathname === `/dashboard/setting`
                                    ? '/assets/Navbar/Settings 2.png'
                                    : '/assets/Navbar/Settings 2 2.png'
                            }
                            className="setting-icon"
                            alt="setting"
                        />
                    </div>
                    {isVisibleMenu && (
                        <p
                            style={{
                                fontFamily: 'Figtree',
                                fontSize: '18pt',
                                marginLeft: '15px',
                                marginTop: '5px',
                                color: '#FFF',
                            }}>
                            Settings
                        </p>
                    )}
                </Link>

                <Link to={`/dashboard/setting`} style={{ color: 'black' }} className="d-flex ">
                    <img src="/assets/Navbar/Share.png" alt="Share" style={{ width: '26px', height: '26px' }} />
                </Link>

                {getUserProfileData.userType === 'user'
                    ? ''
                    : getUserProfileData.userType === 'influencer' && (
                          <Link
                              to={`/dashboard/analytics`}
                              style={{ backgroundColor: '', color: 'black' }}
                              className="d-flex ">
                              <div>
                                  <img
                                      src={
                                          location.pathname === `/dashboard/analytics`
                                              ? '/assets/Navbar/Analytics.png'
                                              : '/assets/Navbar/Analytics_white.png'
                                      }
                                      className="setting-icon"
                                      alt="analytics"
                                  />
                                  <p
                                      style={{
                                          fontFamily: 'Figtree',
                                          marginLeft: '20px',
                                          paddingLeft: '20px',
                                          color: '#FFF',
                                      }}>
                                      Settings
                                  </p>
                              </div>
                          </Link>
                      )}

                {getUserProfileData.userType === 'user' ? (
                    ''
                ) : getUserProfileData.userType === 'influencer' ? (
                    <Link
                        style={{ backgroundColor: '', color: 'black' }}
                        className="d-flex "
                        onClick={() => {
                            setModalReferAndEarn(true);
                        }}>
                        <div>
                            <i
                                className="fas fa-wallet"
                                style={{ color: '#D3FF5B', opacity: 0.5 }}
                                title="Refer & Earn"></i>
                        </div>
                        {isVisibleMenu && (
                            <div
                                style={{
                                    fontFamily: 'Figtree',
                                    marginLeft: '20px',
                                    paddingLeft: '20px',
                                    color: '#FFF',
                                }}>
                                Refer & Earn
                            </div>
                        )}
                    </Link>
                ) : (
                    <LoaderModal />
                )}

                <div className="bottom-down-content">
                    <Link className=" d-flex " style={{ backgroundColor: '#1F1F1F' }}>
                        <div
                            style={{
                                width: '40px',
                                height: '40px',
                                background: '#353B26',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '10px',
                            }}>
                            <img
                                src="/assets/Navbar/Night.png"
                                alt="Night"
                                style={{
                                    width: '26px',
                                    height: '26px',
                                }}
                            />
                        </div>
                    </Link>

                    <Link className=" d-flex" style={{ backgroundColor: '#1F1F1F' }}>
                        <div
                            style={{
                                width: '40px',
                                height: '40px',
                                background: '#3C2624',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <img
                                src="/assets/Navbar/logout.png"
                                onClick={() => setLoggedOut(true)}
                                style={{
                                    width: '26px',
                                    height: '26px',
                                }}
                                alt="navbarlogout"
                            />
                        </div>
                    </Link>
                </div>
            </div>

            <Suspense fallback={<ComponentLoading />}>
                <SureModel
                    isOpen={loggedOut}
                    onModelClose={() => {
                        setLoggedOut(false);
                    }}
                    text="Are you sure you want to logout ?"
                    onYes={handleSignOut}
                    onNo={() => {
                        setLoggedOut(false);
                    }}
                />
            </Suspense>
        </div>
    );
};

export default Navbar;
