import api from '../util/instance';
import { urls } from './Api';

export const getUserCards = async () => await api.get(urls.getUsersCards);

export const showTrades = async (id) => await api.get(`${urls.showTrades}/${id}?page=1`);

export const getInfluencer = async (id) => await api.get(`${urls.getInfluencer}/${id}`);

export const publicTrade = async (id) => await api.get(`${urls.fetchInfluencer}/${id}/trades/public`);

export const showSelectedTrades = async (id) => await api.get(`${urls.showSelectedTrades}/${id}`);

export const averageTrades = async (id) => await api.get(`${urls.averageTrades}/${id}?interval=w`);

export const influencerTopHolding = async (id) => await api.get(`${urls.InfluencerTopHolding}/${id}?page=1`);

export const influencerReview = async (id) => await api.get(`${urls.influencerReview}/${id}`);

export const userFollow = async (id, payload = {}) => await api.post(`${urls.userFollow}/${id}`, payload);

export const userSubscribe = async (id, payload = {}) => await api.post(`${urls.userSubscribe}/${id}`, payload);

export const createSubscriptionCheckoutSession = async (userId, influencerId) =>
    await api.post(`${urls.stripeSubscribeCheckoutSession}`, { userId, influencerId });

export const createStripePortalSession = async () => await api.post(`${urls.stripePortalSession}`);

export const getYTDandTotalTrade = async () => await api.get(`${urls.getYTDandTotalTradeApi}`);

export const userReview = async (id, payload = {}) => await api.post(`${urls.userReview}/${id}`, payload);

export const getInfluencerProfitLossPerformanceGraph = async () =>
    await api.get(`${urls.InfluencerProfitLossPerformanceGraph}`);

export const getInfluencerProfitLossPerformanceGraphYear = async () =>
    await api.get(`${urls.InfluencerProfitLossPerformanceGraphYear}`);
