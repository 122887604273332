import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import { brokerageDetails } from 'Api/setting';
import { fractionalTrade, getStockDetailGraph, stockDetail, BrokeragesById } from 'Api/stocksDetails';
import { getStockConfirmModalSimulate } from 'Api/stocksDetails';
import './style.scss';
import swal from 'sweetalert';
import { getBrokerageId } from 'pages/RecentTrades/selector';
import { useSelector } from 'react-redux';

const Mimicking = ({
    modalTradeOptionMarket,
    onModelClose,
    setModalTradeConformation,
    setShareData,
    shareData,
    fetchNotification,
    getAmt,
    getName,
    getSymbol,
    getImg,
    modalTradeOptionConformation,
    customStyles13,
    setModalTradeSuccess,
    modalTradeOptionSuccess,
    customStyles4,
    setModalTradeueued,
    modalTradeOptionQueued,
    customStyles5,
    setModalTradeOptionMarket,
    selectUnit,
    setSelectUnit,
    setIsLoading,
    getPrice,
    getStocksDetails,
    setSelectMarketOrderModal,
    getBrokerageDropdownData,
    setGetBrokerageDropdownData,
    fetchStocksData,
    setFetchStocksData,
    isLoading,
}) => {
    const [brokerDropdown, setBrokerDropdown] = useState([]);
    const [fetchBrokerageById, setFetchBrokerageById] = useState([]);
    const [changeOptionBuy, setChangeOptionBuy] = useState(false);
    const [getReviewData, setGetReviewData] = useState([]);
    const brokerageId = useSelector(getBrokerageId);

    const handleBuyCost = (amt, symbol) => {
        setIsLoading(true);
        setModalTradeueued(false);
        let data;
        if (selectUnit === 'USD' || selectUnit === '') {
            data = {
                symbol: symbol,
                amount: 0,
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: 'buy',
                fractionalAmountInUsd: Number(shareData),
                fractional: true,
            };
        } else {
            data = {
                symbol: symbol,
                amount: Number(shareData),
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: 'buy',
                fractional: false,
            };
        }

        fractionalTrade(data)
            .then((response) => {
                setIsLoading(false);
                swal('Your order was placed and will be executed after market opening.', '', 'success');
            })
            .catch((error) => {
                setIsLoading(false);
                swal('Sorry ! Your order failed.');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const getStockDetail = () => {
        stockDetail(getSymbol)
            .then((response) => {
                setFetchStocksData(response);
            })
            .catch((error) => {
                console.warn(error, 'catch error');
            });
    };

    useEffect(() => {
        getStockDetail();
        // eslint-disable-next-line
    }, [getSymbol]);

    const secModalClose = () => {
        onModelClose(false);
    };

    const getBrokerageById = () => {
        BrokeragesById(getBrokerageDropdownData)
            .then((response) => {
                setFetchBrokerageById(response.balance);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    useEffect(() => {
        getBrokerageById();
        // eslint-disable-next-line
    }, [getBrokerageDropdownData]);

    useEffect(() => {
        (async () => {
            const response = await brokerageDetails();
            setBrokerDropdown(response);
        })();
    }, [getBrokerageDropdownData]);

    useEffect(() => {
        const fetchBrokerageId = fetchBrokerageById?.map((ele) => ele.cash);
        localStorage.setItem('Brokeragecash', fetchBrokerageId);
        localStorage.setItem('brokerageName', getBrokerageDropdownData);

        // eslint-disable-next-line
    }, [getBrokerageDropdownData, fetchBrokerageById]);

    const Brokeragecash = localStorage.getItem('Brokeragecash');

    const amount = Number(getReviewData?.content?.amount).toFixed(2);
    const estimatedUnitPrice = Number(getReviewData?.content?.estimatedUnitPrice);

    if (isNaN(amount) || isNaN(estimatedUnitPrice)) {
    } else {
        const estimateCost = parseFloat((amount * estimatedUnitPrice).toFixed(2));

        localStorage.setItem('estimateCost', estimateCost);
        localStorage.setItem('estimateAmount', amount);
    }

    const handleReview = async () => {
        onModelClose(false);
        setModalTradeConformation(true);
        let data;

        if (selectUnit === 'USD' || selectUnit === '') {
            data = {
                symbol: getSymbol,
                amount: 0,
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: 'buy',
                fractionalAmountInUsd: Number(shareData),
                fractional: true,
            };
        } else {
            data = {
                symbol: getSymbol,
                amount: Number(shareData),
                extendedHours: false,
                orderType: 'market',
                timeInForce: 'goodForDay',
                brokerageId: getBrokerageDropdownData !== '' ? getBrokerageDropdownData : 'Robinhood_0001',
                side: 'buy',
                fractional: false,
            };
        }

        try {
            const response = await getStockConfirmModalSimulate(data);
            if (response) {
                // setIsLoading(false);
                setGetReviewData(response);
            }
            return response;
        } catch (error) {
            console.warn(error);
        }
    };

    const onChangeUnit = (e) => {
        setSelectUnit(e.target.value);
    };

    const username = localStorage.getItem('username');
    const secModalCloseConfirametion = () => {
        setModalTradeConformation(false);
    };

    const secModalCloseSuccess = () => {
        setModalTradeSuccess(false);
    };

    const secModalCloseQueued = () => {
        setModalTradeueued(false);
    };

    const handleQueuedEdit = () => {
        setModalTradeueued(false);
        onModelClose(true);
    };

    const handleBrokerageDropdown = (e) => {
        localStorage.setItem('getBrokerageId', getBrokerageDropdownData);

        setGetBrokerageDropdownData(e.target.value);
    };

    const brokerageName = brokerageId;

    const estimateCost = localStorage.getItem('estimateCost');

    const estimateAmount = localStorage.getItem('estimateAmount');

    const handleConfirm = () => {
        setModalTradeConformation(false);
        setModalTradeSuccess(true);
    };

    return (
        <>
            <Modal
                isOpen={modalTradeOptionMarket}
                onRequestClose={secModalClose}
                style={customStyles}
                contentLabel="Mimicking Notification Modal">
                <div className="mimic-modal-parent-div">
                    <div className="modal1-parent-div">
                        <div className="d-flex justify-content-between pt-4">
                            <p className="mimicking-para">Mimicking</p>
                            <p
                                className="mt-1 market-order"
                                onClick={() => {
                                    setSelectMarketOrderModal(true);
                                    onModelClose(false);
                                }}>
                                Market Order
                            </p>
                        </div>
                        <hr className="hr-style" />

                        <div className="d-flex mt-1">
                            <img src={getImg} alt="getImg" className="get-img" />

                            <div style={{ paddingLeft: '1rem' }}>
                                <p className="first-name-para">
                                    {username} Bought {getSymbol} at amount {getAmt}
                                </p>
                                <p className="modal1-username">@{username}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ padding: '1rem' }}>
                        <div style={{ backgroundColor: '#FFFFFF' }}>
                            <div className="d-flex justify-content-between mt-4">
                                <div className="">
                                    <div className="d-flex">
                                        <div>
                                            <p className="m-1 symbol-text">{getSymbol}</p>
                                            <div>
                                                <p
                                                    style={{
                                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                        fontStyle: 'normal',
                                                        fontSize: '16px',
                                                        fontFamily: 'Figtree',
                                                        fontWeight: '600',
                                                        lineHeight: '125%',
                                                        marginLeft: '4px',
                                                    }}>
                                                    ${getStocksDetails ? getStocksDetails : 0}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="m-1" style={{ opacity: 0.5 }}>
                                            |
                                        </div>{' '}
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '12px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1 mt-2">
                                            {getName}
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <div className="d-flex">
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1">
                                            ${getPrice}
                                        </p>
                                        <div
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                            }}
                                            className="">
                                            <img
                                                src="/assets/stockdetails/arrow_drop_up_black_24dp (1) 1.png"
                                                style={{ width: '10px', height: '10px', bottom: '1px' }}
                                                className="mt-1"
                                                alt="arrow"
                                            />
                                        </div>
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-limeade-71-c-901, #71C901)',
                                                fontStyle: 'normal',
                                                fontSize: '14px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: '125%',
                                                top: '6px',
                                            }}
                                            className="">
                                            {getAmt}
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <img
                                            src="/assets/stockdetails/alertCircle.png"
                                            style={{ width: '15px', height: '15px', bottom: '5px' }}
                                            className="mt-1"
                                            alt="alertcircle"
                                        />
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '12px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: '125%',
                                                marginLeft: '5px',
                                            }}>
                                            Price Difference
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center p-3">
                            <select
                                style={{
                                    width: '90px',
                                    height: '30px',
                                    borderRadius: '69px',
                                    backgroundColor: '#F5F8F8',
                                    border: 'none',
                                    backgroundPosition: 'right 10px bottom 50%',
                                    backgroundSize: '0.625em',
                                    backgroundRepeat: 'no-repeat',
                                    appearance: 'none',
                                    backgroundImage: `url("data:image/svg+xml;charset=utf-8,<svg width='10' height='6' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
                                    top: '2rem',
                                }}
                                value={selectUnit}
                                className="d-flex justify-content-center align-items-center text-center"
                                onChange={(e) => onChangeUnit(e)}>
                                <option value="USD">USD</option>
                                <option value="UNIT">UNIT</option>
                            </select>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-4">
                            {selectUnit === 'USD' || selectUnit === '' ? (
                                <sup
                                    style={{
                                        color: '#1F1F1F',
                                        fontStyle: 'normal',
                                        fontSize: '25px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '400',
                                        lineHeight: '125%',
                                        marginLeft: '1rem',
                                        marginTop: '1rem',
                                    }}>
                                    $
                                </sup>
                            ) : (
                                ''
                            )}
                            <input
                                style={{
                                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                    fontStyle: 'normal',
                                    fontSize: '40px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    border: 'none',
                                    textAlign: 'center',
                                    width: '120px',
                                }}
                                value={shareData}
                                type="number"
                                onChange={(e) => setShareData(e.target.value)}></input>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: '125%',
                                    bottom: '10px',
                                }}>
                                1 share
                            </p>
                        </div>

                        <div className="p-3 robesHood-container">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <img
                                        src="/assets/stockdetails/robinhood_green.png"
                                        style={{
                                            width: '38px',
                                            height: '38px',
                                            borderRadius: '118px',
                                            border: '2px solid #FFF',
                                        }}
                                        alt="robinhood"
                                    />
                                    <div className="robesHood">
                                        <select
                                            className="form-control"
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '18px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                                border: 'none',
                                                background: 'var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8)',
                                            }}
                                            onChange={(e) => handleBrokerageDropdown(e)}
                                            value={getBrokerageDropdownData}>
                                            <option selected>Select Brokerage</option>
                                            {brokerDropdown?.map((ele) => {
                                                return (
                                                    <>
                                                        <option value={ele.accountId}>{ele.accountName}</option>
                                                    </>
                                                );
                                            })}
                                        </select>
                                        <p
                                            style={{
                                                color: '#1F1F1F',
                                                fontStyle: 'normal',
                                                fontSize: '14px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: '125%',
                                                marginLeft: '5%',
                                            }}>
                                            ${Brokeragecash !== 'undefined' ? Brokeragecash : 0} Available
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <i class="fas fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex justify-content-center align-items-center "
                            style={{ marginTop: '1rem' }}>
                            <p
                                style={{
                                    color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                    fontStyle: 'normal',
                                    fontSize: '12px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '400',
                                    lineHeight: '125%',
                                }}>
                                ${Brokeragecash !== 'undefined' ? Brokeragecash : 0} available
                            </p>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mt-2">
                            <button
                                style={{
                                    width: '100%',
                                    height: '52px',
                                    color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                    fontStyle: 'normal',
                                    fontSize: '14px',
                                    fontFamily: 'Figtree',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    borderRadius: '54px',
                                    letterSpacing: '0.7px',
                                    backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                }}
                                onClick={handleReview}>
                                REVIEW
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalTradeOptionConformation}
                onRequestClose={secModalCloseConfirametion}
                style={customStyles}
                contentLabel="Order Confirmation Modal">
                <div
                    style={{
                        width: '100%',
                        borderRadius: '25px 25px 0px 0px',
                    }}
                    className="p-3 ">
                    <div className="d-flex justify-content-between mt-2 mb-4 ">
                        <img
                            src="/assets/stockdetails/arrow_left_black.png"
                            alt="arrow-img"
                            className="confirm-arrow-img"
                            onClick={() => {
                                if (onModelClose) {
                                    setModalTradeConformation(false);
                                    onModelClose(true);
                                }
                            }}
                        />
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>
                            Order Confirmation
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-additional-sunset-ff-5445, #FF5445)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                right: '2rem',
                                cursor: 'cursor',
                            }}
                            onClick={() => setModalTradeConformation(false)}>
                            Cancel
                        </p>
                    </div>

                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className="mt-1">
                            <div>
                                <div
                                    className="d-flex  justify-content-between "
                                    style={{ paddingLeft: '1.7rem', paddingRight: '2rem' }}>
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '16px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="m-1">
                                        Stock
                                    </p>
                                    <div className="d-flex">
                                        <img
                                            src="/assets/images2/portfolio_icon.png"
                                            alt="logo"
                                            style={{ width: '25px', height: '25px' }}
                                        />
                                        <p
                                            style={{
                                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '600',
                                                lineHeight: '125%',
                                            }}
                                            className="m-1">
                                            {getSymbol}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    className="d-flex  justify-content-between "
                                    style={{ paddingLeft: '2rem', paddingRight: '2rem', paddingTop: '1rem' }}>
                                    <div className="">
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '16px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '400',
                                                lineHeight: 'normal',
                                            }}>
                                            Broker
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        {' '}
                                        <img
                                            src="/assets/images2/Robinhoodimg.png"
                                            alt="Robinhood"
                                            style={{ width: '17px', height: '23px' }}
                                        />
                                        <p
                                            style={{
                                                color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                                fontStyle: 'normal',
                                                fontSize: '20px',
                                                fontFamily: 'Figtree',
                                                fontWeight: '500',
                                                lineHeight: 'normal',
                                                marginLeft: '5px',
                                            }}>
                                            {console.log(brokerageName, 'brokerageName')}
                                            {brokerageName !== null ? brokerageName : 'Robinhood'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="m-4 mt-4 p-3 cost-estimation">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '20px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                    className="text-center">
                                    $ {estimateCost}
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="mt-1">
                                        Estimated cost
                                    </p>
                                </p>
                            </div>

                            <img src="/assets/Navbar/Middle_line.png" alt="Middle Line" style={{ height: '48px' }} />

                            <div className="">
                                <p
                                    style={{
                                        color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                        fontStyle: 'normal',
                                        fontSize: '20px',
                                        fontFamily: 'Figtree',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                    className="text-center">
                                    {estimateAmount}
                                    <p
                                        style={{
                                            color: 'var(--pf-additional-aluminium-8-f-8-f-8-f, #8F8F8F)',
                                            fontStyle: 'normal',
                                            fontSize: '14px',
                                            fontFamily: 'Figtree',
                                            fontWeight: '400',
                                            lineHeight: 'normal',
                                        }}
                                        className="mt-1">
                                        Estimated shares
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-2">
                        <button
                            style={{
                                width: '351px',
                                height: '52px',
                                color: 'var(--pf-primary-canary-d-3-ff-5-b, #D3FF5B)',
                                fontStyle: 'normal',
                                fontSize: '14px',
                                fontFamily: 'Figtree',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                borderRadius: '54px',
                                letterSpacing: '0.7px',
                                backgroundColor: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                            }}
                            onClick={handleConfirm}>
                            CONFIRM
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalTradeOptionSuccess}
                onRequestClose={secModalCloseSuccess}
                style={customStyles}
                contentLabel="Sec Order Success Modal">
                <div
                    className="p-4"
                    style={{
                        width: '88%',
                        borderRadius: '25px 25px 0px 0px',
                        margin: 'auto',
                    }}>
                    <div className="d-flex justify-content-between mt-2 mb-4 w-100">
                        <img
                            src="/assets/stockdetails/arrow_left_black.png"
                            alt="arrow-img"
                            className="confirm-arrow-img"
                            onClick={() => {
                                if (setModalTradeConformation) {
                                    setModalTradeSuccess(false);
                                    setModalTradeConformation(true);
                                }
                            }}
                        />
                        <p
                            style={{
                                color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                            }}>
                            Order Success
                        </p>

                        <p
                            style={{
                                color: 'var(--pf-additional-sunset-ff-5445, #FF5445)',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                fontFamily: 'Figtree',
                                fontWeight: '500',
                                lineHeight: '24px',
                                right: '2rem',
                                cursor: 'cursor',
                            }}
                            onClick={() => setModalTradeSuccess(false)}>
                            Cancel
                        </p>
                    </div>

                    <div className="success-div">
                        <img
                            src="/assets/stockdetails/amanda_mimic.png"
                            alt="user-img"
                            className="success-user-image"
                        />
                        <img
                            src="/assets/stockdetails/microsoftIconWhiteBorder.png"
                            alt="logo"
                            className="success-white-border-logo"
                        />
                        <p className="success-you-mimic-text">You've mimicked</p>
                        <p className="success-username">@{username}</p>
                    </div>

                    <div style={{ backgroundColor: '#FFFFFF' }}>
                        <div className=" mt-3">
                            <div className="d-flex justify-content-between ">
                                <div className="">
                                    <p className="m-1 success-stock-txt">Stock</p>
                                </div>

                                <div className="d-flex">
                                    <img
                                        src="/assets/stockdetails/microsoft_round.png"
                                        alt="round-logo"
                                        className="success-round-logo"
                                    />
                                    <p className="m-1 success-getsymbol">{getSymbol}</p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-4">
                                <div className="">
                                    <p className="success-amt-investment-txt">Amount Invested</p>
                                </div>

                                <div className="">
                                    <p className="share-data">$ {shareData}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mt-2">
                        <p className="success-estimate-txt">Estimated Share</p>
                        <p className="success-estimare-digit">{estimateAmount}</p>
                    </div>
                    <div className="d-flex justify-content-center mt-4 mb-4">
                        <button
                            onClick={() => {
                                setModalTradeSuccess(false);
                                setModalTradeueued(true);
                            }}
                            className="success-done-btn">
                            DONE
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={modalTradeOptionQueued}
                onRequestClose={secModalCloseQueued}
                style={customStyles5}
                contentLabel="Example Modal">
                <div className="p-3 queued-parent-div">
                    <div className="d-flex justify-content-center" style={{ marginTop: '10px' }}>
                        <p className="order-queued-para">Order Queued</p>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                        <p className="queud-para">
                            Your order to buy {shareData} of {getSymbol} has been queued until market open
                        </p>
                    </div>

                    <div style={{ top: '2rem', textAlign: 'center' }}>
                        <img
                            src="/assets/stockdetails/amanda_mimic.png"
                            className="queued-user-img"
                            alt="queued-user-img"
                        />
                        <img
                            src="/assets/stockdetails/microsoftIconWhiteBorder.png"
                            alt="queued-icon"
                            className="queued-icon"
                        />
                        <p className="queued-you-mimic-txt">You've mimicked</p>
                        <p className="queued-username">@{username}</p>
                    </div>

                    <div style={{ top: '1rem' }}>
                        {' '}
                        <div style={{ backgroundColor: '#FFFFFF' }}>
                            <div className="" style={{ marginTop: '25px' }}>
                                <div className="d-flex justify-content-evenly">
                                    <div className="">
                                        <p className="m-1 queued-stock-text">Stock</p>
                                    </div>

                                    <div className="d-flex">
                                        <img
                                            src="/assets/stockdetails/microsoft_round.png"
                                            alt="round-logo"
                                            className="round-logo"
                                        />
                                        <p className="m-1 get-symbol-queued">{getSymbol}</p>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-evenly mt-4">
                                    <p className="broker-para">Broker</p>

                                    <div className="d-flex">
                                        <img
                                            src="/assets/images2/Robinhoodimg.png"
                                            className="robin-img-modal"
                                            alt="robin-img-modal"
                                        />
                                        <p className="robinhood-txt">Robinhood</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-evenly" style={{ marginTop: '40px' }}>
                        <button onClick={handleQueuedEdit} className="edit-btn">
                            EDIT
                        </button>

                        <button
                            onClick={(e) => {
                                handleBuyCost(getAmt, getSymbol);
                            }}
                            className="handle-buy-btn">
                            DONE
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Mimicking;
