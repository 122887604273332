import React from 'react';
import './style.scss';

const Reviews = ({ reviewCount, clickToNavigate, fetchReview }) => {
    return (
        <div className="mt-4 reviews-container w-100">
            <div className="d-flex justify-content-between reviews-flex w-100">
                <div className="d-flex justify-content-between review-sub-header">
                    <img src="/assets/images2/Star icon.png" alt="star icon" className="m-3 review-sub-star-icon" />
                    <div className="review-text-container">
                        <p className="review-sub-count">
                            ${reviewCount}
                            <p className="review-sub-label">All Reviews</p>
                        </p>
                    </div>
                </div>
                <div>
                    <p className="view-all-label" onClick={clickToNavigate}>
                        View All
                    </p>
                </div>
            </div>

            {fetchReview &&
                fetchReview?.map((ele) => {
                    return (
                        <>
                            <div className="mt-4">
                                <div className="person-name-info d-flex">
                                    <div className="d-flex align-items-center">
                                        <div className="p-3">
                                            <img
                                                src="/assets/images2/johnkim.png"
                                                alt="person"
                                                className="user-image"
                                            />
                                        </div>
                                        <div>
                                            <p className="user-name-sub-review mb-0">
                                                {ele.user}
                                                <p className="ele-reviews mb-0">{ele.review}</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })}
        </div>
    );
};

export default Reviews;
