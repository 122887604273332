import React from 'react';
import './style.scss';

const Spotlight = ({ profilePicture, twitterFollowers, firstName, lastName, returns }) => {
    return (
        <div className="m-2 influencer-spotlight">
            <div className="d-flex justify-content-center align-item-center p-3">
                <img
                    src={profilePicture || '/assets/images/image (1).png'}
                    className="influencer-profile-profile-picture"
                    alt="img"
                />
            </div>
            <div className="d-flex justify-content-center mt-2">
                <img
                    src="/assets/images2/twitter_icon.png"
                    className="m-1 influencer-profile-twitter-picture"
                    alt="img"
                />
                <span className="m-1 spotlight-twitter-count">{twitterFollowers || 0}</span>
            </div>

            <div className="d-flex justify-content-center">
                <p className="m-1 spotlight-user-name">
                    {firstName} {lastName}
                </p>
                <span>
                    <img src="/assets/images2/verify 1.png" className="spotlight-user-check" alt="img" />
                </span>
            </div>

            <div className="d-flex justify-content-center mb-3">
                <p className="m-1 spotlight-return-label">Returns</p>
                <div className="d-flex justify-content-between mt-1 spotlight-green-area-container">
                    <img src="/assets/dashboardImg/Path.png" alt="img" className="up-arrow-icon" />

                    <p className="spotlight-return-count">{returns}%</p>
                </div>
            </div>
        </div>
    );
};

export default Spotlight;
