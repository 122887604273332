import api from '../util/instance';
import { urls } from './Api';

export const getProfitLossGraphUser = async (data) => {
    return await api.get(`${urls.userProfitLossGraph}?range=${data}`);
};

export const getUserTopTradeAggregate = async (data) => {
    return await api.get(`${urls.UserTopTradesAggregateApi}`);
};
export const getProfitLossUserHeading = async (data) => {
    return await api.get(`${urls.InfluencerProfitLossPerformanceGraph}?range=${data}`);
};
