import React from 'react';
import Modal from 'react-modal';
import { customStyles } from './style';
import { get } from 'lodash';
import './style.scss';
import * as CONSTANT from './constant';

const SubscriptionPlan = ({ isOpened = false, onModelClose, influencer, setPaymentCardOpen, isConfirmModelOpened }) => {
    const planName = get(influencer, 'firstName', '');
    const cards = CONSTANT.STATIC_CARDS;

    const handleContinue = () => {
        setPaymentCardOpen(false);
        isConfirmModelOpened(true);
    };

    return (
        <Modal isOpen={isOpened} onRequestClose={onModelClose} style={customStyles} contentLabel="subscription plan">
            <div className="subscription-plan p-4">
                <span className="angle-icon">
                    <i class="fas fa-angle-left"></i>
                </span>

                <div className="d-flex justify-content-center">
                    <p className="plan-info">{planName} subscription plan</p>
                </div>

                <div class="mt-1">
                    <p className="payment-method">Select payment method</p>
                </div>

                <div class="px-2 rounded mt-4 date">
                    {cards?.map((card) => (
                        <div className="debit-card mb-2" key={card?.id}>
                            <div className="d-flex justify-content-between p-3">
                                <div className="d-flex">
                                    <img src={card?.image} alt="debit card icon" className="debit-card-icon" />
                                    <p className="card-text">{card?.text}</p>
                                </div>
                                <div>
                                    <input type="radio" id="card" name="paymentMethod" value="card" checked />
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="d-flex justify-content-between mb-2 p-3 coupon-code">
                        <p className="code-text">54WR5FD65yu5</p>

                        <p className="applied-text">Applied</p>
                    </div>
                    <img src={CONSTANT.USER_IMAGE} alt="user" className="user-image" />

                    <div class="d-flex justify-content-center mt-2 ">
                        <button className="btn btn-sm m-2 continue-btn" onClick={handleContinue}>
                            CONTINUE
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default React.memo(SubscriptionPlan);
