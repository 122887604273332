import React from 'react';
import './style.scss';
const SideBarHeading = ({ text, imageUrl, onclick, color, isAccordion }) => {
    return !isAccordion ? (
        <div
            style={{
                backgroundColor: color,
            }}
            className="p-3 side-bar-heading">
            <div className="d-flex cursor-pointer" onClick={onclick}>
                {typeof imageUrl === 'string' ? (
                    <img src={imageUrl} className="image-icon-sidebar" alt="img" />
                ) : (
                    imageUrl
                )}

                <p className="mt-1 text">{text}</p>
            </div>
        </div>
    ) : (
        <div className="d-flex align-items-center">
            {typeof imageUrl === 'string' ? <img src={imageUrl} className="image-icon-sidebar" alt="img" /> : imageUrl}
            <p
                style={{
                    color: 'var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)',
                    fontSize: '15px',
                    fontFamily: 'Figtree',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    marginLeft: '7px',
                }}
                className="mb-0 accordion-text">
                {text}
            </p>
        </div>
    );
};

export default SideBarHeading;
